import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LogRocket from 'logrocket';

import AppBar from 'containers/AppBar';
import { NotificationStack } from 'components/Notification';
import NotificationsPanel from 'containers/NotificationsPanel';

import { AppRoutes } from 'routes';
import config from 'config';
import * as utils from 'utils';

class App extends Component {
  constructor(props) {
    const { loggedInUser } = props;
    if (loggedInUser.accessUpgradeBs) {
      utils.redirectSite(config.UPGRADE_ACCESS_BS);
    }
    super(props);
  }

  componentDidMount() {
    this.init();

    const { loggedInUser } = this.props;

    if (config.REACT_APP_ENABLE_LOG_ROCKET && loggedInUser.id) {
      LogRocket.identify(loggedInUser.id, {
        name: loggedInUser.firstName,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { authenticationStatus, history } = this.props;
    if (authenticationStatus === 'unauthenticated') {
      history.push('/sso');
    }
    if (prevProps.boardId !== this.props.boardId) {
      this.init();
    }
  }

  init = () => {
    const {
      history,
      authenticationStatus,
      fetchUserBoardAccess,
      fetchNotificationsPanel,
      fetchRoles,
      fetchGlobalNotifications,
      fetchProfessionsByBoard,
      boardId,
    } = this.props;

    if (authenticationStatus === 'unauthenticated') {
      history.push('/sso');
      return;
    }
    fetchUserBoardAccess();
    fetchRoles();
    fetchGlobalNotifications();
    fetchNotificationsPanel();
    if (boardId) {
      fetchProfessionsByBoard({ id: boardId });
    }
  };

  render() {
    const { notifications, logout, boardAccess } = this.props;
    const permissions = boardAccess.permissions || [];
    return (
      <div className="bg-body">
        <NotificationsPanel />
        <AppBar logout={() => logout({ redirect: true })} permissions={permissions} />
        <AppRoutes />
        <NotificationStack notifications={notifications} dismissInOrder={false} onDismiss={() => {}} />
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.node,
  authenticationStatus: PropTypes.string,
  notifications: PropTypes.array,
  fetchUserBoardAccess: PropTypes.func,
  logout: PropTypes.func,
};

App.defaultProps = {
  fetchUserBoardAccess: () => {},
  logout: () => {},
};

export default App;
