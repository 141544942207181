import queryString from 'query-string';

const routerUtility = (history) => {
  const deleteEmptyFilters = (filters, queryParams) => {
    const keys = Object.keys(filters);
    keys.forEach((key) => {
      if (!queryParams[key] && !filters[key]) {
        delete filters[key];
      }
    });
    return { ...filters };
  };

  const updateQueryParams = (filters) => {
    const { replace, location } = history;
    const queryParams = queryString.parse(location.search);
    const notEmptyFilters = deleteEmptyFilters(filters, queryParams);
    const newFilters = { ...queryParams, ...notEmptyFilters };
    if (Object.keys(newFilters).length) {
      replace({
        search: queryString.stringify(newFilters),
      });
    }
  };

  const getQueryParams = () => {
    const { location = {} } = history;
    return queryString.parse(location.search);
  };

  const getLocation = () => history.location;

  const redirectTo = (event) => {
    event.preventDefault();
    event.stopPropagation();
    history.push(event.target.getAttribute('href'));
  };

  const clearQueryParams = () => {
    history.replace({
      search: '',
    });
  };

  return {
    updateQueryParams,
    getQueryParams,
    redirectTo,
    getLocation,
    clearQueryParams,
  };
};

export default routerUtility;

export { routerUtility };
