import { call, put, select, fork } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import * as api from 'api/boardApplications';

import isEmpty from 'lodash/isEmpty';

import * as schema from '../schema';
import at from '../actions/types';

import { selectors } from 'state/boardApplications';

export function* fetchBoardApplications(filters) {
  const state = yield select();
  const { authentication } = state;
  const boardId = state.selectedBoard.id;
  yield put({ type: at.FETCH_REQUEST, filters, meta: {} });
  try {
    let response;
    try {
      response = yield call(api.fetch, { boardId, filters, token: authentication.accessToken });
    } catch (e) {
      console.log(e);
      yield put({ type: at.FETCH_FAILURE, filters, message: e.message || 'Something went wrong', meta: {} });
      return;
    }

    const { headers } = response;
    const totalItems = parseInt(headers['x-total-count'], 10);
    const normalizedData = normalize(response.data, schema.boardApplications);
    yield put({ type: at.FETCH_SUCCESS, ...normalizedData, filters, totalItems, meta: {} });
  } catch (error) {
    console.log('error', error);
  }
}

export function* fetchPrevAndNextIfNeeded(filters) {
  const state = yield select();
  const bas = selectors(state);

  let { size, page } = filters;
  const [prevPage, nextPage] = [page - 1, page + 1];
  const { totalItems } = bas.getPagination();

  const prevEntities = bas.getEntitiesByPage(prevPage);
  const nextEntities = bas.getEntitiesByPage(nextPage);

  if (isEmpty(prevEntities) && page > 1) {
    yield fork(fetchBoardApplications, { ...filters, page: prevPage });
  }

  if (isEmpty(nextEntities) && page * size < totalItems) {
    yield fork(fetchBoardApplications, { ...filters, page: nextPage });
  }
}

export function* watchFetchBoardApplications({ filters }) {
  yield call(fetchBoardApplications, filters);
}
