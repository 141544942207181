import at from './types';

export const fetchBulks = (boardId, token, filters = null) => ({
  type: at.FETCH_BULKS,
  boardId,
  token,
  filters,
});

export const fetchBulksUrl = (url, token, filters = null) => ({
  type: at.FETCH_BULKS,
  url,
  token,
  filters,
});

export const confirmBulk = (bulkId, token) => ({
  type: at.CONFIRM_BULK,
  bulkId,
  token,
});

export const deleteBulk = (bulkId, token) => ({
  type: at.DELETE_BULK,
  bulkId,
  token,
});

export const stopBulk = (bulkId, token) => ({
  type: at.STOP_BULK,
  bulkId,
  token,
});

export const fetchBulkLicenses = (bulkId, token, filters = null) => ({
  type: at.FETCH_BULK_LICENSES,
  bulkId,
  token,
  filters,
});

export const fetchBulkLicensesUrl = (url, token, filters = null) => ({
  type: at.FETCH_BULK_LICENSES,
  url,
  token,
  filters,
});

export const deleteBulkLicense = (bulkId, bulkLicenseId, token, filters = null) => ({
  type: at.DELETE_BULK_LICENSES,
  bulkId,
  bulkLicenseId,
  token,
  filters,
});

export const archiveBulkLicense = (bulkId, bulkLicenseId, token, filters = null) => ({
  type: at.ARCHIVE_BULK_LICENSES,
  bulkId,
  bulkLicenseId,
  token,
  filters,
});
