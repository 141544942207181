import { SELECT_BOARD } from '../actions/types';

export const selectedBoard = (state = {}, action) => {
  switch (action.type) {
    case SELECT_BOARD:
      if (action.payload) return action.payload;
      return {};
    default:
      return state;
  }
};
