import { fork, takeLatest } from 'redux-saga/effects';
import config from '../config';
import * as cookies from 'utils/cookies';

import auditsBulks from './auditsBulks/sagas';
import { rootSaga as licenses } from './licenses';
import { rootSaga as audits } from './audits';
import { rootSaga as notifications } from './notifications';
import { rootSaga as transcripts } from './transcripts';
import { rootSaga as licensesStatuses } from './licensesStatuses';
import { rootSaga as licensesPeriods } from './licensesPeriods';
import { rootSaga as users } from './users';
import { rootSaga as usersByState } from './usersByState';
import { rootSaga as boardProfile } from './boardProfile';
import { rootSaga as boards } from './boards';
import { rootSaga as states } from './states';
import { rootSaga as common } from './common';
import { rootSaga as boardApplications } from './boardApplications';
import { rootSaga as roles } from './roles';
import { rootSaga as boardUser } from './boardUser';
import { rootSaga as globalNotifications } from './globalNotifications';
import { rootSaga as boardsAccess } from './boardsAccess';
import { rootSaga as compliance } from './compliance';
import { rootSaga as feed } from './feed';
import { rootSaga as conversations } from './conversations';
import { rootSaga as notificationsPanel } from './notificationsPanel';
import { rootSaga as courseHistory } from './courseHistory';



import * as at from 'actions/types';

function logoutRequest(action) {
  const { meta = {} } = action;
  if (meta.redirect) {
    cookies.deleteCookie(config.COOKIE_NAME);
    window.location.replace(`${config.LAUNCHPAD_URL}/logout`);
  }
}

function* logout() {
  yield takeLatest(at.LOGOUT, logoutRequest);
}

export default function* rootSaga() {
  yield [
    fork(licenses),
    fork(audits),
    fork(auditsBulks),
    fork(notifications),
    fork(transcripts),
    fork(licensesStatuses),
    fork(licensesPeriods),
    fork(users),
    fork(usersByState),
    fork(boardProfile),
    fork(boards),
    fork(states),
    fork(common),
    fork(licensesPeriods),
    fork(boardApplications),
    fork(roles),
    fork(boardUser),
    fork(logout),
    fork(globalNotifications),
    fork(boardsAccess),
    fork(compliance),
    fork(feed),
    fork(conversations),
    fork(notificationsPanel),
    fork(courseHistory),
  ];
}
