import { call, put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { normalize } from 'normalizr';
import * as api from 'api/usersBoardsAccess';

import * as schema from '../schema';
import at from '../actions/types';

export function* fetchBoardAccess(filters = {}, meta = {}) {
  const state = yield select();
  const { authentication, selectedBoard, loggedInUser } = state;
  const boardId = selectedBoard.id;
  const userId = loggedInUser.id;

  filters = { ...filters, boardId, userId };
  yield put({ type: at.FETCH_ONE_REQUEST, filters, meta: {} });
  try {
    yield call(delay, 300);
    let response;
    try {
      response = yield call(api.fetch, { filters, token: authentication.accessToken });
    } catch (e) {
      console.log(e);
      yield put({ type: at.FETCH_ONE_FAILURE, filters, message: e.message || 'Something went wrong', meta: {} });
      return;
    }
    const normalizedData = normalize(response.data, schema.boardAccess);
    yield put({ type: at.FETCH_ONE_SUCCESS, ...normalizedData, filters, meta: {} });
  } catch (error) {
    console.log('error', error);
  }
}

export function* watchFetchBoardAccess({ filters, meta = {} }) {
  yield call(fetchBoardAccess, filters, meta);
}
