import { call, put, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import * as schema from '../schema';
import moment from 'moment';

import * as api from 'api/notificationsPanel';
import at from '../actions/types';

export function* fetchNotifications() {
  const state = yield select();
  const { authentication } = state;
  let filters = { page: 1 };
  yield put({ type: at.FETCH_REQUEST, filters });
  try {
    let response;
    try {
      const from = moment().startOf('week').format('L');
      const to = moment().endOf('week').format('L');
      filters = { ...filters, from, to };
      response = yield call(api.fetchByUser, authentication.accessToken, filters);
    } catch (e) {
      console.log(e);
      yield put({ type: at.FETCH_FAILURE, filters, message: e.message || 'Something went wrong' });
      return;
    }

    const normalizedData = normalize(response.data.rooms, schema.notificationsPanel);
    yield put({ type: at.FETCH_SUCCESS, ...normalizedData, filters });
  } catch (error) {
    console.log('error', error);
  }
}
