import { combineReducers } from 'redux';

import at from '../actions/types';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return { ...state, ...action.entities.users };
    }
    case at.UPDATE_SUCCESS: {
      return {
        ...state,
        [action.userId]: { ...state[action.userId], ...action.data },
      };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const pages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return {
        ...state,
        [action.filters.page]: action.result,
      };
    }
    case at.CLEAR: {
      return {};
    }
    default:
      return state;
  }
};

export const fetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      return { ...state, [action.filters.page]: 'loading' };
    }
    case at.FETCH_SUCCESS: {
      return { ...state, [action.filters.page]: 'loaded' };
    }
    case at.FETCH_FAILURE: {
      return { ...state, [action.filters.page]: 'failed' };
    }
    case at.FETCH_CANCELED: {
      return { ...state, [action.filters.page]: 'loaded' };
    }
    default:
      return state;
  }
};

export const pagination = (state = { currentPage: 1 }, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return { ...state, totalItems: action.totalItems };
    }
    case at.SET_PAGINATION: {
      return action.pagination;
    }
    default:
      return state;
  }
};

export const count = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      return 0;
    }
    case at.FETCH_SUCCESS: {
      return action.totalItems;
    }
    case at.FETCH_FAILURE: {
      return 0;
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  pages,
  fetchStatus,
  pagination,
  count,
});
