export default function (globalState) {
  const routingState = globalState.routing;

  const query = routingState.locationBeforeTransitions ? routingState.locationBeforeTransitions.query : {};

  const getQueryParams = () => query;

  const getUrlParams = () => query;

  const getUrlParamsWith = (func) => func(query);

  return {
    getQueryParams,
    getUrlParams,
    getUrlParamsWith,
  };
}
