import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const BoxNotification = ({ visible, children, className, style }) => {
  const hiddenClass = visible ? '' : 'box-notification-hidden';
  return (
    <section className={`box-notification ${hiddenClass} ${className}`} style={style}>
      {children}
    </section>
  );
};

BoxNotification.propTypes = {
  visible: PropTypes.bool,
  style: PropTypes.object,
};

BoxNotification.defaultProps = {
  visible: false,
  style: {},
};

export default BoxNotification;
