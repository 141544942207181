import { combineReducers } from 'redux';

import at from '../actions/types';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return { ...state, ...action.entities.transcripts };
    }
    default:
      return state;
  }
};

export const isFetching = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      return {
        ...state,
        [action.licensePeriodId]: true,
      };
    }
    case at.FETCH_SUCCESS:
      return {
        ...state,
        [action.result]: false,
      };
    case at.FETCH_BLANK:
      return {
        ...state,
        [action.result]: false,
      };
    case at.FETCH_FAILURE: {
      return {
        ...state,
        [action.licensePeriodId]: false,
      };
    }
    default:
      return state;
  }
};

export const errorMessage = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_FAILURE: {
      return {
        ...state,
        [action.licensePeriodId]: action.message,
      };
    }
    case at.FETCH_REQUEST: {
      return {
        ...state,
        [action.licensePeriodId]: '',
      };
    }
    case at.FETCH_SUCCESS: {
      return {
        ...state,
        [action.result]: '',
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  isFetching,
  errorMessage,
});
