import React from 'react';
import moment from 'moment';
import debounce from 'lodash/debounce';

import { Loader } from 'components';
import ConversationRow from '../ConversationRow';
import './styles.css';

export class ConversationList extends React.Component {
  constructor(props) {
    super(props);

    this.debouncedHandleScroll = debounce(this.handleScroll, 300);
  }

  componentWillReceiveProps(nextProps) {
    const { isScrollEnabled } = nextProps;
    if (isScrollEnabled && !this.props.isScrollEnabled) {
      window.addEventListener('scroll', this.debouncedHandleScroll);
    } else {
      if (!isScrollEnabled && this.props.isScrollEnabled) {
        window.removeEventListener('scroll', this.debouncedHandleScroll);
      }
    }
  }

  componentDidMount() {
    const { isScrollEnabled } = this.props;
    if (isScrollEnabled) {
      window.addEventListener('scroll', this.debouncedHandleScroll);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.debouncedHandleScroll);
  }

  handleScroll = () => {
    const { onBottomReached } = this.props;
    const windowHeight = window.innerHeight ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      onBottomReached();
    }
  };

  render() {
    const { conversations, isFetching, type, onConversationClick, onCompleteMessageClick, emptyMessage } = this.props;

    if (conversations.length === 0 && !isFetching) {
      return <div className="conversation-list-empty-message">{emptyMessage}</div>;
    }

    const elements = [];
    conversations.forEach((conversation, index, array) => {
      const first = index === 0 || moment(conversation.updatedAt).month() < moment(array[index - 1].updatedAt).month();
      if (first) {
        elements.push(
          <div key={`${conversation._id}-month-title`} className="conversation-list-month">
            {moment(conversation.updatedAt).format('MMMM')}
          </div>
        );
      }
      elements.push(
        <ConversationRow
          key={`${type}-${conversation._id}`}
          className={first ? 'conversation-list-first' : ''}
          conversation={conversation}
          onClick={onConversationClick}
          onClickCompleteButton={onCompleteMessageClick}
        />
      );
    });
    return (
      <div>
        <div>{elements}</div>
        {isFetching && <Loader className="conversation-list-loader" size="40px" border="6px" />}
      </div>
    );
  }
}

export default ConversationList;
