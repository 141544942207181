import { call, put } from 'redux-saga/effects';
import * as api from 'api/licensee';

import at from '../actions/types';

export function* requestDownloadPdf(credentials) {
  try {
    const { data }  = yield call(api.requestDownloadPdf, credentials);
    yield put({ type: at.FETCH_PDF_SUCCESS, data });
  } catch (e) {
    yield put({ type: at.FETCH_PDF_FAILURE, message: e.message || 'Something went wrong' });
  }
}
