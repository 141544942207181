import { createAction } from 'state/utils';

export default {
  ...createAction('compliance', 'UPDATE'),
  ...createAction('compliance', 'FETCH'),
  ...createAction('compliance', 'FETCH_REPORTS'),
  ...createAction('compliance', 'FETCH_DOWNLOAD_REPORTS'),
  ...createAction('compliance', 'FETCH_EXEMPTIONS_BY_PROFESSION'),

  CLEAR: 'compliance/CLEAR',
  CLEAR_ENTITIES: 'compliance/CLEAR_ENTITIES',
  SET_PAGINATION: 'compliance/SET_PAGINATION',
  FETCH_CANCELED: 'compliance/FETCH_CANCELED',
  CLEAR_DOWNLOAD_REPORTS: 'CLEAR_DOWNLOAD_REPORTS',
};
