import axios from 'axios';
import * as at from './types';
import { addSuccessNotification } from './notifications';
import { API_URL } from '../config';
import { fetchBulks } from '../state/auditsBulks/actions';

export const updateAuditCustomSelection = (payload, meta) => ({
  type: at.UPDATE_AUDIT_CUSTOM_SELECTION,
  data: payload,
});

export const clearAuditCustomSelection = (payload, meta) => ({
  type: at.CLEAR_AUDIT_CUSTOM_SELECTION,
});

export function addToAuditCustomSelection(data, token) {
  return function (dispatch) {
    dispatch({ type: at.ADD_TO_AUDIT_CUSTOM_REQUEST });
    return axios
      .request({
        url: `${API_URL}/audits-bulks`,
        method: 'post',
        headers: {
          authorization: `Bearer ${token}`,
        },
        data,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: at.ADD_TO_AUDIT_CUSTOM_SUCCESS,
            data: response.data,
          });
          //Add notification
          const n = {
            type: 'AUDIT_CUSTOM_SELECTION_STARTED',
            text: 'The bulk was created',
          };
          dispatch(addSuccessNotification(n));
          dispatch({ type: 'DELETE_AUDIT_SELECTION_TRACK' });
          dispatch(fetchBulks(data.boardId, token, { offset: 0, limit: 10 }));
        }
      })
      .catch((err) => {
        const message = err.response.data.message;
        dispatch({ type: at.ADD_TO_AUDIT_CUSTOM_FAILURE, data, message });
      });
  };
}
