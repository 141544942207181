import axios from 'axios';
import { API_URL } from '../config';

export function fetchStates(token) {
  return axios.request({
    url: `${API_URL}/states`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}
