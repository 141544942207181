import { call, put, select } from 'redux-saga/effects';

import * as api from 'api/feed';
import at from '../actions/types';

export function* addCommentToFeed({ feedId, text, data }) {
  const state = yield select();
  const { authentication } = state;
  yield put({ type: at.ADD_COMMENT_REQUEST, feedId });
  try {
    let response;
    try {
      response = yield call(api.addCommentToFeed, feedId, text, data, authentication.accessToken);
    } catch (e) {
      console.log(e);
      yield put({
        type: at.ADD_COMMENT_FAILURE,
        feedId,
        message: e.message || 'Something went wrong',
      });
      return;
    }

    yield put({ type: at.ADD_COMMENT_SUCCESS, feedId, comment: response.data });
  } catch (error) {
    console.log('error', error);
  }
}
