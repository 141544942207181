import moment from 'moment';

export default function (globalState) {
  const state = globalState.notificationsPanel;

  const getEntityById = (id) => state.entities[id] || {};

  const getIdsByPage = (page) => state.pages[page] || [];

  const getEntitiesByPage = (page) => {
    const ids = getIdsByPage(page);
    return ids.length > 0 ? ids.map((id) => getEntityById(id)) : [];
  };

  const getEntitiesCreatedToday = () => {
    const entities = getEntitiesByPage(1);
    return entities.filter((e) => moment(new Date(e.createdAt)).isSame(new Date(), 'day'));
  };

  const getEntitiesCreatedYesterday = () => {
    const entities = getEntitiesByPage(1);
    const yesterday = moment().subtract(1, 'days');
    return entities.filter((e) => moment(new Date(e.createdAt)).isSame(yesterday, 'day'));
  };

  const getEntitiesCreatedThisWeek = () => {
    const entities = getEntitiesByPage(1);
    const from = moment().startOf('week');
    const to = moment().subtract(2, 'days');
    return entities.filter((e) => moment(new Date(e.createdAt)).isBetween(from, to, 'day', '[]'));
  };

  return {
    getEntityById,
    getIdsByPage,
    getEntitiesByPage,
    getEntitiesCreatedToday,
    getEntitiesCreatedYesterday,
    getEntitiesCreatedThisWeek,
  };
}
