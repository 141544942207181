import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectBoard } from 'actions';
import isEmpty from 'lodash/isEmpty';
import SearchableSelect from 'emerald-ui-2/lib/SearchableSelect';

import './styles.css';

import { actions as boardActions } from 'state/boards';

export class BoardsDropdown extends Component {
  componentWillMount() {
    const { boards, authentication, fetchBoards, selectedBoard } = this.props;
    if (authentication.status === 'authenticated' && !boards.length) {
      if (fetchBoards) fetchBoards(authentication.accessToken, selectedBoard);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { boards, authentication, selectedBoard, selectBoard } = nextProps;
    if (authentication.status === 'authenticated') {
      if (boards.length > 0 && !Object.keys(selectedBoard).length) {
        if (selectBoard) selectBoard(boards[0]);
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    return this.props.selectedBoard !== nextProps.selectBoard;
  }

  handleChange = (boardId) => {
    const { boards, selectBoard, selectedBoard } = this.props;
    if (boardId) {
      const sb = boards.find((b) => {
        return b.id === boardId;
      });
      if (sb.id !== selectedBoard.id) {
        selectBoard(sb);
      }
    }
  };

  mapBoards = (boards, selectedBoardId) => {
    const result = boards.reduce((prevValue, currentValue) => {
      const { state, ...rest } = currentValue;
      const stateContainer = prevValue[state.id];

      if (!stateContainer) {
        const newData = { state, boards: [rest] };
        return { ...prevValue, [state.id]: newData };
      }

      stateContainer.boards.push(rest);

      return { ...prevValue, [state.id]: { ...stateContainer } };
    }, {});

    const getMapped = Object.values(result);

    return getMapped
      .sort((a, b) => {
        if (a.state.name < b.state.name) return -1;
        if (a.state.name > b.state.name) return 1;
        return 0;
      })
      .map(({ state, boards }) => (
        <optgroup key={state.id} label={state.name}>
          {boards.map((board) => (
            <option key={board.id} value={board.id} selected={board.id === selectedBoardId}>
              {board.name}
            </option>
          ))}
        </optgroup>
      ));
  };

  render() {
    const { boards, selectedBoard } = this.props;
    let selectedBoardId = null;
    if (boards.length > 0) {
      const board = boards.find((b) => b.id === selectedBoard.id);
      selectedBoardId = !isEmpty(board) ? board.id : boards[0].id;
    }

    const boardOptions = this.mapBoards(boards, selectedBoardId);

    return (
      <SearchableSelect
        label="Board"
        placeholder="Select a board"
        className="boards-dropdown-dropdown"
        onSelect={this.handleChange}
        shape="flat"
      >
        {boardOptions}
      </SearchableSelect>
    );
  }
}

BoardsDropdown.propTypes = {
  className: PropTypes.string,
};

BoardsDropdown.defaultProps = {
  boards: [],
  selectedBoard: {},
  className: '',
};

function mapStateToProps({ boards, selectedBoard, authentication }) {
  return {
    boards,
    selectedBoard,
    authentication,
  };
}

export default connect(mapStateToProps, {
  fetchBoards: boardActions.fetchBoards,
  selectBoard,
})(BoardsDropdown);
