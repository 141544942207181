export default function (globalState) {
  const state = globalState.licenses;

  const getEntityById = (id) => state.entities[id] || {};
  const getEntityFetchStatusById = (id) => state.entitiesFetchStatus[id] || '';
  const getIdsByPage = (page) => state.pages[page] || [];

  const getEntitiesByPage = (page) => {
    const ids = getIdsByPage(page);
    return ids.length > 0 ? ids.map((id) => getEntityById(id)) : [];
  };

  const getLicenseById = (id) => getEntityById(id);
  const getLicenseFetchStatusById = (id) => getEntityFetchStatusById(id);

  const getLicensesByPage = (page) => getEntitiesByPage(page);
  const getPageFetchStatus = (page) => state.pagesFetchStatus[page];

  const getTotalCount = () => state.totalCount;
  const getTotalCountFetchStatus = () => state.totalCountFetchStatus;

  const getCustomSearchTotalCount = () => state.customSearchTotalCount;
  const getCustomSearchTotalCountFetchStatus = () => state.customSearchTotalCountFetchStatus;

  return {
    getEntityById,
    getEntityFetchStatusById,
    getIdsByPage,
    getEntitiesByPage,
    getLicenseById,
    getLicenseFetchStatusById,
    getLicensesByPage,
    getPageFetchStatus,
    getTotalCount,
    getTotalCountFetchStatus,
    getCustomSearchTotalCount,
    getCustomSearchTotalCountFetchStatus,
  };
}
