import React, { Component } from 'react';
import Panel from 'emerald-ui-2/lib/Panel';
import { removeTextInsideBrackets, getDisplayCourseType } from 'utils';

import './styles.css';

class SelfReportedCECreditInfo extends Component {
  render() {
    const { postCECredit, selfReportedCE } = this.props;
    return (
      <Panel className="self-reported-ce-detail-page-card">
        <div className="self-reported-ce-detail-page-cardHeader">
          <p>Credit info</p>
        </div>
        <div className="self-reported-ce-detail-page-cardBody">
          {postCECredit.course.courseTrackingNumber && (
            <div>
              <div>
                <p className="self-reported-ce-detail-page-cardTitle">Tracking</p>
                <span>{postCECredit.course.courseTrackingNumber}</span>
              </div>
            </div>
          )}
          {selfReportedCE.creditName && (
            <div>
              <div>
                <p className="self-reported-ce-detail-page-cardTitle">Credit name</p>
                <span>{removeTextInsideBrackets(selfReportedCE.creditName)}</span>
              </div>
            </div>
          )}
          <div>
            {postCECredit.course.name && (
              <div>
                <p className="self-reported-ce-detail-page-cardTitle">Activity name</p>
                <span>{postCECredit.course.name || 'Not found'}</span>
              </div>
            )}
            {postCECredit.course.courseType && (
              <div>
                <p className="self-reported-ce-detail-page-cardTitle">Activity type</p>
                <span>{getDisplayCourseType(postCECredit.course.courseType) || 'Not found'}</span>
              </div>
            )}
          </div>
          <div>
            {postCECredit.course.deliveryMethod && (
              <div>
                <p className="self-reported-ce-detail-page-cardTitle">Delivery method</p>
                <span>{postCECredit.course.deliveryMethod || 'Not found'}</span>
              </div>
            )}
            {postCECredit.completedDate && (
              <div>
                <p className="self-reported-ce-detail-page-cardTitle">Date completed</p>
                <span>{postCECredit.completedDate}</span>
              </div>
            )}
          </div>
        </div>
      </Panel>
    );
  }
}

export default SelfReportedCECreditInfo;
