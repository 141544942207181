import { call, put } from 'redux-saga/effects';

import * as api from '../../../api/boardUser';
import at from '../actions/types';

export function* fetchBoardUser(action) {
  const createPasswordCode = action.createPasswordCode;
  yield put({ type: at.FETCH_REQUEST });
  try {
    let response;
    try {
      response = yield call(api.fetchBoardUser, createPasswordCode);
    } catch (e) {
      console.log(e);
      yield put({ type: at.FETCH_FAILURE, message: e.message || 'Something went wrong' });
      return;
    }
    yield put({ type: at.FETCH_SUCCESS, ...response.data });
  } catch (error) {
    console.log('error', error);
  }
}
