import { call, put, select } from 'redux-saga/effects';

import * as api from 'api/licensee';

import at from '../actions/types';

export function* getRequestStatus(credentials) {
  try {
    const {data} = yield call(api.getRequestStatus, credentials);
    yield put({ type: at.FETCH_PDF_STATUS_SUCCESS, data });
  } catch (e) {
    yield put({ type: at.FETCH_PDF_STATUS_FAILURE, message: e.message || 'Something went wrong' });
  }
}
