import { combineReducers } from 'redux';
import at from '../actions/types';

import { types as licensesPeriodsAt } from 'state/licensesPeriods';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS:
    case at.FETCH_ONE_SUCCESS: {
      return { ...state, ...action.entities.licenses };
    }
    case licensesPeriodsAt.FETCH_SUCCESS: {
      const { licenseId, licensePeriods } = action;
      return {
        ...state,
        [licenseId]: { ...state[licenseId], periods: licensePeriods },
      };
    }
    case at.FETCH_ACCOUNT_TYPE_REQUEST: {
      const { licenseId } = action;
      return {
        ...state,
        [licenseId]: { ...state[licenseId], accountType: 'loading...' },
      };
    }
    case at.FETCH_ACCOUNT_TYPE_SUCCESS: {
      const { licenseId, accountType } = action;
      return {
        ...state,
        [licenseId]: { ...state[licenseId], accountType },
      };
    }
    case at.FETCH_ACCOUNT_TYPE_FAILURE: {
      const { licenseId } = action;
      return {
        ...state,
        [licenseId]: { ...state[licenseId], accountType: `Couldn't get account type` },
      };
    }
    case at.FETCH_EMAIL_REQUEST: {
      const { licenseId } = action;
      return {
        ...state,
        [licenseId]: { ...state[licenseId], realEmail: 'loading...' },
      };
    }
    case at.FETCH_EMAIL_SUCCESS: {
      const { licenseId, realEmail } = action;
      return {
        ...state,
        [licenseId]: { ...state[licenseId], realEmail },
      };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const entitiesFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_ONE_REQUEST: {
      return { ...state, [action.licenseId]: 'loading' };
    }
    case at.FETCH_ONE_SUCCESS: {
      return { ...state, [action.licenseId]: 'loaded' };
    }
    case at.FETCH_ONE_FAILURE: {
      return { ...state, [action.licenseId]: 'failed' };
    }
    case at.FETCH_ONE_CANCELED: {
      return { ...state, [action.licenseId]: 'loaded' };
    }
    case at.FETCH_SUCCESS: {
      const mapped = action.result.reduce((prev, next) => ({ ...prev, [next]: 'loaded' }), {});
      return { ...state, ...mapped };
    }
    default:
      return state;
  }
};

export const pages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return {
        ...state,
        [action.filters.page]: action.result,
      };
    }
    case at.CLEAR: {
      return {};
    }
    default:
      return state;
  }
};

export const pagesFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      return { ...state, [action.filters.page]: 'loading' };
    }
    case at.FETCH_SUCCESS: {
      return { ...state, [action.filters.page]: 'loaded' };
    }
    case at.FETCH_FAILURE: {
      return { ...state, [action.filters.page]: 'failed' };
    }
    default:
      return state;
  }
};

export const totalCount = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return action.totalCount;
    }
    case at.FETCH_FAILURE: {
      return 0;
    }
    default:
      return state;
  }
};

export const totalCountFetchStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_COUNT_REQUEST: {
      return 'loading';
    }
    case at.FETCH_COUNT_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_COUNT_FAILURE: {
      return 'failed';
    }
    case at.FETCH_COUNT_CANCELED: {
      return 'loaded';
    }
    default:
      return state;
  }
};

export const customSearchTotalCount = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_COUNT_REQUEST: {
      return 0;
    }
    case at.FETCH_COUNT_SUCCESS: {
      return action.count;
    }
    case at.FETCH_COUNT_FAILURE: {
      return 0;
    }
    default:
      return state;
  }
};

export const customSearchTotalCountFetchStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_COUNT_REQUEST: {
      return 'loading';
    }
    case at.FETCH_COUNT_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_COUNT_FAILURE: {
      return 'failed';
    }
    case at.FETCH_COUNT_CANCELED: {
      return 'loaded';
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  entitiesFetchStatus,
  pages,
  pagesFetchStatus,
  totalCount,
  totalCountFetchStatus,
  customSearchTotalCount,
  customSearchTotalCountFetchStatus,
});
