import React, { Component } from 'react';
import './styles.css';

export default class Loader extends Component {
  render() {
    const className = this.props.className || '';
    return (
      <div
        className={`loader ${className}`}
        style={{
          width: this.props.size,
          height: this.props.size,
          borderWidth: this.props.border,
        }}
      ></div>
    );
  }
}
