import axios from 'axios';
import {
  SELECT_BOARD,
  FETCH_PROFESSIONS,
  AUTHENTICATE,
  REQUEST_AUTHENTICATE,
  FAILED_AUTHENTICATE,
  LOGOUT,
} from './types';

import { API_URL } from '../config';

export { fetchPostCEcredit } from './postCEcredits';

export { fetchExemption } from './exemptions';

export {
  updateAuditCustomSelection,
  clearAuditCustomSelection,
  addToAuditCustomSelection,
} from './auditCustomSelection';

export { fetchAccount, createPassword } from './account';

export function fetchProfessionsByBoard(board) {
  const request = axios.get(`${API_URL}/boards/${board.id}/professions`);
  return {
    type: FETCH_PROFESSIONS,
    payload: request,
  };
}

export const selectBoard = (board) => {
  return function (dispatch) {
    dispatch({ type: SELECT_BOARD, payload: board });
  };
};

export function authenticate({ username = '', password = '' }) {
  return function (dispatch) {
    dispatch({ type: REQUEST_AUTHENTICATE, status: 'authenticating' });
    return axios
      .request({
        url: `${API_URL}/auth/token`,
        method: 'post',
        timeout: 10000,
        data: {
          username,
          password,
          grant_type: 'password',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const { status, data } = response;
          dispatch({
            type: AUTHENTICATE,
            status: 'authenticating',
            payload: { status, data },
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            dispatch({
              type: FAILED_AUTHENTICATE,
              status: 'wrong_credentials',
              message: error.response.data.message,
            });
          } else {
            dispatch({
              type: FAILED_AUTHENTICATE,
              status: 'failed',
              message: error.response.data.message,
            });
          }
        } else {
          dispatch({
            type: FAILED_AUTHENTICATE,
            status: 'failed',
            message: 'timeout',
          });
        }
      });
  };
}

export function verifyToken(token = '') {
  return function (dispatch) {
    dispatch({ type: REQUEST_AUTHENTICATE, status: 'authenticating' });
    return axios
      .request({
        url: `${API_URL}/auth/verify`,
        headers: {
          authorization: `Bearer ${token}`,
        },
        method: 'get',
        timeout: 10000,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: AUTHENTICATE,
            status: 'authenticating',
            payload: { status: response.status, data: { accessToken: token } },
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            dispatch({
              type: FAILED_AUTHENTICATE,
              status: 'wrong_credentials',
              message: error.response.data.message,
            });
          } else {
            dispatch({
              type: FAILED_AUTHENTICATE,
              status: 'failed',
              message: error.response.data.message,
            });
          }
        } else {
          dispatch({
            type: FAILED_AUTHENTICATE,
            status: 'failed',
            message: 'timeout',
          });
        }
      });
  };
}

export function logout(meta = {}) {
  return { type: LOGOUT, meta };
}
