import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const AccordionActivities = ({ count, onFetchActivities, visible }) => {
  const contain = !visible ? null : (
    <div className="activities-accordion text-info">
      <span onClick={onFetchActivities}>See all {count} comments</span>
    </div>
  );
  return contain;
};

AccordionActivities.propTypes = {
  count: PropTypes.number,
  onFetchActivities: PropTypes.func,
  visible: PropTypes.bool,
};

AccordionActivities.defaultProps = {
  visible: true,
};

export default AccordionActivities;
