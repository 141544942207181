import { call, put, select } from 'redux-saga/effects';
// import { normalize } from 'normalizr';
import * as api from 'api/boards';

// import * as schema from '../schema';
import at from '../actions/types';

const defaultBoardExistsInMyBoardList = (boards, selectedBoardId) => boards.some((b) => b.id === selectedBoardId);

export function* fetchBoards() {
  const state = yield select();
  const { authentication } = state;
  yield put({ type: at.FETCH_REQUEST });
  let response;
  try {
    response = yield call(api.fetchBoards, authentication.accessToken);
  } catch (e) {
    console.log(e);
    yield put({ type: at.FETCH_FAILURE, message: e.message || 'Something went wrong' });
    return;
  }
  yield put({ type: at.FETCH_SUCCESS, data: response.data });

  if (!defaultBoardExistsInMyBoardList(response.data, state.selectedBoard.id)) {
    // Dispatching an empty payload in this action will choose the first board in the list.
    yield put({ type: 'SELECT_BOARD' });
  }
}
