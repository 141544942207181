import { takeLatest, put, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';

import at from '../actions/types';
import * as actions from '../actions';
import selectors from '../reducers/selectors';

import { fetchGlobalNotifications } from './fetchGlobalNotifications';

export function* watchLocationChange({ payload }) {
  const state = yield select();
  const globalNotifications = selectors(state).getEntitiesByPage(1);
  const shouldFetchNotifications = payload.pathname.indexOf('login') < 0 ? true : false;

  if (shouldFetchNotifications && globalNotifications.length === 0) {
    yield put(actions.fetchGlobalNotifications());
  }
}

export default function* root() {
  yield takeLatest(LOCATION_CHANGE, watchLocationChange);
  yield takeLatest(at.FETCH, fetchGlobalNotifications);
}
