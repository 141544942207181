export default function (globalState) {
  const state = globalState.roles;

  const getEntitiesObject = () => state.entities;

  const getEntityById = (id) => state.entities[id] || {};

  const getIdsByPage = (page) => state.pages[page] || [];

  const getEntitiesByPage = (page) => {
    const ids = getIdsByPage(page);
    return ids.length > 0 ? ids.map((id) => getEntityById(id)) : [];
  };

  const getAllRoles = () => getEntitiesByPage(1);

  const getFetchStatusByPage = (page) => state.fetchStatus[page];

  const getFetchStatus = () => state.fetchStatus;

  const getPagination = () => state.pagination || {};

  const getCount = () => state.count;

  return {
    getAllRoles,
    getEntitiesObject,
    getEntityById,
    getEntitiesByPage,
    getIdsByPage,
    getFetchStatusByPage,
    getFetchStatus,
    getPagination,
    getCount,
  };
}
