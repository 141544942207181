import React from 'react';
import PropTypes from 'prop-types';

import Button from 'emerald-ui-2/lib/Button';
import TextField from 'emerald-ui-2/lib/TextField';
import Avatar from 'emerald-ui-2/lib/Avatar';
import './styles.css';

const PostForm = ({ onSendActivity, user = {}, text, onInputChange, focus }) => {
  const { name = '' } = user;
  return (
    <div className="activities-postForm">
      <Avatar title={name} size="md" className="activity-avatarImg" />
      <form onSubmit={onSendActivity}>
        <TextField
          value={text}
          onChange={(e) => onInputChange(e.target.value)}
          placeholder="Leave a comment"
          ref={(input) => {
            setFocus(focus, input);
          }}
        />
        <Button color="info" type="submit" disabled={text === ''} className="activities-postForm-button">
          POST
        </Button>
      </form>
    </div>
  );
};

function setFocus(focus, input) {
  if (focus && input) {
    input.focus();
  }
}

PostForm.propTypes = {
  onSendActivity: PropTypes.func,
  user: PropTypes.object,
  text: PropTypes.string,
  onInputChange: PropTypes.func,
  focus: PropTypes.bool,
};

export default PostForm;
