import { combineReducers } from 'redux';
import at from '../actions/types';

export const completedAmount = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_REPORTS_REQUEST:
    case at.FETCH_REPORTS_FAILURE: {
      return 0;
    }
    case at.FETCH_REPORTS_SUCCESS: {
      return action.completedAmount;
    }
    default:
      return state;
  }
};

export const notCompletedAmount = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_REPORTS_REQUEST:
    case at.FETCH_REPORTS_FAILURE: {
      return 0;
    }
    case at.FETCH_REPORTS_SUCCESS: {
      return action.notCompletedAmount;
    }
    default:
      return state;
  }
};

export default combineReducers({
  completedAmount,
  notCompletedAmount,
});
