import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'emerald-ui-2/lib/Modal';
import Table from 'emerald-ui-2/lib/Table';
import TextField from 'emerald-ui-2/lib/TextField';
import Button from 'emerald-ui-2/lib/Button';
import * as transcriptRequest from 'api/transcripts';

class EditHours extends Component {
  state = {
    comment: '',
    subjectAreas: [],
    formChanged: false,
  };

  componentDidMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  init = (props) => {
    const subjectAreas = this.getSubjectAreas(props);
    let comment;
    if (subjectAreas.length && subjectAreas[0].comment) {
      comment = subjectAreas[0].comment;
    }
    this.setState({ subjectAreas, comment });
  };

  getSubjectAreas = (props) => {
    const { postCEcredit = {}, disallowInfo = [], post = {} } = props;
    let subjectAreas = [];
    if (postCEcredit.professions && postCEcredit.professions.length) {
      const professionSubjectAreas = postCEcredit.professions.map((profession) => profession.subjectArea);
      subjectAreas.push(...professionSubjectAreas);
    }

    if (postCEcredit.subjectAreas && postCEcredit.subjectAreas.length) {
      subjectAreas.push(...postCEcredit.subjectAreas);
    }

    if (!this.state.formChanged) {
      //prefill disallowed hours
      subjectAreas.map((subjectArea) => {
        const disallow = disallowInfo.find(
          (d) => d.subjectAreaId === subjectArea.id && d.rosterAttendeeId === post.rosterAttendeeId
        );
        if (disallow) {
          subjectArea.disallowedHours = disallow.hoursToDisallow;
          subjectArea.comment = disallow.comment;
        }
        return subjectArea;
      });
    }

    return subjectAreas;
  };

  formChanged = (value) => {
    this.setState({ formChanged: value });
  };

  handleDisallowedHoursChange = (event, postedHours, subjectAreaId) => {
    this.formChanged(true);
    const value = event.target.value;
    //value should be a number, shouldn't be less than 0 or greater than applied hours
    //should accept empty
    const regex = /[A-Z@~`!@#$%^&*()_=+\\\\';:"\\/?><,-]+/i;
    if (value !== '' && (regex.test(value) || value < 0 || value > postedHours)) {
      return;
    }
    const { subjectAreas } = this.state;
    const subjectArea = subjectAreas.find((sa) => sa.id === subjectAreaId);
    subjectArea.disallowedHours = value;
    this.setState({ subjectAreas });
  };

  handleCommentChange = (event) => {
    this.formChanged(true);
    const value = event.target.value;
    this.setState({ comment: value });
  };

  handleSubmit = () => {
    const { licensePeriod, post = {}, postCEcredit, board, token, onHide } = this.props;
    if (!licensePeriod || !licensePeriod.id) {
      //throw error
      return;
    }

    let { comment, subjectAreas } = this.state;
    //validate if all disallowed hours fields are empty
    const willDisallow = subjectAreas.some((sa) => !!sa.disallowedHours);
    if (!willDisallow) {
      //throw error
      return;
    }

    //filter by subjects that have disallowedHours defined
    subjectAreas = subjectAreas.filter((subject) => subject.disallowedHours);

    const { postCEcreditId, rosterAttendeeId } = post;
    const { name: boardName } = board;
    const courseName = this.getCourseName(post, postCEcredit);
    const data = {
      licensePeriodId: licensePeriod.id,
      subjectAreas,
      postCEcreditId,
      rosterAttendeeId,
      courseName,
      boardName,
      comment,
    };
    transcriptRequest.postDisallowHours(data, token);
    this.formChanged(false);
    onHide();
  };

  onHide = () => {
    this.props.onHide();
    this.formChanged(false);
  };

  getCourseName = (post, postCEcredit) => {
    if (postCEcredit && postCEcredit.course) {
      return postCEcredit.course.name ? postCEcredit.course.name : '';
    } else {
      return post ? post.courseName : '';
    }
  };

  render() {
    let { post = {}, postCEcredit } = this.props;
    const { subjectAreas } = this.state;
    const courseName = this.getCourseName(post, postCEcredit);

    return (
      <Modal show={this.props.show} onHide={this.onHide} bsSize="lg" id="edit-hours-modal">
        <Modal.Header closeButton>
          <Modal.Title>Edit Course Hours</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="edit-hours-course-title">
            <strong>{courseName}</strong>
          </div>
          <Table condensed>
            <thead>
              <tr>
                <th>APPROVED SUBJECT AREA</th>
                <th>HOURS</th>
              </tr>
            </thead>
            <tbody>
              {subjectAreas.length > 0 &&
                subjectAreas.map((sa, index) => (
                  <tr key={index}>
                    <td>
                      <div className="edit-hours-sa">{sa.name}</div>
                    </td>
                    <td className="edit-hours-input">
                      <TextField label="Submitted" defaultValue={sa.appliedHours || sa.earnedHours} disabled />

                      <div className="edit-hours-input-separator">-</div>

                      <TextField
                        label="Disallowed"
                        value={sa.disallowedHours || ''}
                        onChange={(event) =>
                          this.handleDisallowedHoursChange(event, sa.appliedHours || sa.earnedHours, sa.id)
                        }
                      />

                      <div className="edit-hours-input-separator">=</div>

                      <TextField
                        label="Allowed"
                        disabled
                        defaultValue={
                          (parseFloat(sa.appliedHours) || parseFloat(sa.earnedHours)) -
                          (parseFloat(sa.disallowedHours) || 0)
                        }
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="edit-hours-comment-box">
            <TextField
              textarea
              label="Comment (optional)"
              value={this.state.comment || ''}
              onChange={this.handleCommentChange}
              placeholder="Reason for change"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Cancel</Button>
          <Button color="info" onClick={this.handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    loadingPostCEcredit: state.postCEcredit.status,
    postCEcredit: state.postCEcredit.data,
    token: state.authentication.accessToken,
    board: state.selectedBoard,
  };
}

export default connect(mapStateToProps, {})(EditHours);
