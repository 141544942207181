

export default function initializePendo(user) {
    if (window.pendo) {
      window.pendo.initialize({
        visitor: {
          id: `30-${user.id}`,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email || `Not Found`,
          userAccess: user.userName,
          boardName: user.boardName,

        },
        account: {
          id: 'Board',
        },
      });
    }
  }