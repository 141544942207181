import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'emerald-ui-2/lib/DatePicker';
import Modal from 'emerald-ui-2/lib/Modal';
import Toggle from 'emerald-ui-2/lib/Toggle';
import Button from 'emerald-ui-2/lib/Button';

import './styles.css';

const ConversationsFilters = ({
  lastMessageSentStartDate,
  onStartDateChange,
  lastMessageSentEndDate,
  onEndDateChange,
  myMessages,
  onMyMessagesChange,
  active,
  onResetFilters,
  onApplyFilters,
  onClose,
}) => {
  const maxDate = lastMessageSentStartDate ? new Date(lastMessageSentStartDate) : null;
  const minDate = lastMessageSentEndDate ? new Date(lastMessageSentEndDate) : null;
  return (
    <div>
      <Modal show={active} onHide={onClose} className="conversation-filters-dialog">
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="conversation-filters-dialog-date-container">
              <label>Added date range</label>
              <div className="conversation-filters-dialog-date-range">
                <DatePicker
                  label="Start date"
                  ariaLabel="Start date"
                  className="no-label"
                  selected={maxDate}
                  selectsStart
                  startDate={maxDate}
                  endDate={minDate}
                  onChange={onStartDateChange}
                />
                <p>TO</p>
                <DatePicker
                  label="End date"
                  ariaLabel="End date"
                  className="no-label"
                  selected={minDate}
                  selectsEnd
                  startDate={maxDate}
                  endDate={minDate}
                  onChange={onEndDateChange}
                />
              </div>
            </div>
            <div className="conversation-filters-dialog-userFilter">
              <label>My Messages</label>
              <Toggle checked={myMessages} onChange={onMyMessagesChange} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="info" shape="flat" onClick={onResetFilters}>
            Reset Filters
          </Button>
          <Button color="info" onClick={onApplyFilters}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

class ConnectedConversationsFilters extends React.Component {
  state = {
    lastMessageSentStartDate: null,
    lastMessageSentEndDate: null,
    myMessages: false,
  };

  componentDidMount() {
    const { filters } = this.props;
    const { lastMessageSentStartDate, lastMessageSentEndDate, myMessages } = filters;
    this.setState({
      lastMessageSentStartDate,
      lastMessageSentEndDate,
      myMessages,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps;
    const { lastMessageSentStartDate, lastMessageSentEndDate, myMessages } = filters;
    this.setState({
      lastMessageSentStartDate,
      lastMessageSentEndDate,
      myMessages,
    });
  }

  handlenStartDateChange = (date) => {
    date = date ? moment(date).utc().format('L') : '';
    this.setState({ lastMessageSentStartDate: date });
  };

  handlenEndDateChange = (date) => {
    date = date ? moment(date).utc().format('L') : '';
    this.setState({ lastMessageSentEndDate: date });
  };

  handleMyMessagesChange = (event) => {
    const value = event.target.checked;
    this.setState({ myMessages: value });
  };

  handleResetFilters = () => {
    const { updateFilters, onClose } = this.props;
    updateFilters({
      lastMessageSentStartDate: '',
      lastMessageSentEndDate: '',
      myMessages: '',
    });
    onClose();
  };

  handleApplyFilters = () => {
    const { updateFilters, onClose } = this.props;
    const { lastMessageSentStartDate, lastMessageSentEndDate, myMessages } = this.state;
    updateFilters({
      offsetInbox: 0,
      limitInbox: 20,
      offsetCompleted: 0,
      limitCompleted: 20,
      offsetSent: 0,
      limitSent: 20,
      lastMessageSentStartDate,
      lastMessageSentEndDate,
      myMessages,
    });
    onClose();
  };

  render() {
    const { active, ...rest } = this.props;
    const { lastMessageSentStartDate, lastMessageSentEndDate, myMessages } = this.state;

    return (
      <ConversationsFilters
        {...{
          active,
          lastMessageSentStartDate,
          lastMessageSentEndDate,
          myMessages,
          onStartDateChange: this.handlenStartDateChange,
          onEndDateChange: this.handlenEndDateChange,
          onMyMessagesChange: this.handleMyMessagesChange,
          onResetFilters: this.handleResetFilters,
          onApplyFilters: this.handleApplyFilters,
          ...rest,
        }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps, {})(ConnectedConversationsFilters);
