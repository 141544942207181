export default function (globalState) {
  const state = globalState.boardUser;

  const getEntities = () => state.entities;

  const getIsFetching = () => state.isFetching;

  const getErrorMessage = () => state.errorMessage;

  return {
    getEntities,
    getIsFetching,
    getErrorMessage,
  };
}
