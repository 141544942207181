import { call, put, select } from 'redux-saga/effects';

import * as api from 'api/users';

import at from '../actions/types';

export function* deleteUser({ userId, meta = {} }) {
  const state = yield select();
  const { authentication, selectedBoard } = state;
  yield put({ type: at.DELETE_REQUEST });
  const boardId = selectedBoard.id;
  const token = authentication.accessToken;
  try {
    yield call(api.deleteUser, userId, boardId, token);
  } catch (e) {
    console.log(e);
    const message = e.response ? e.response.data.message : e.message;
    yield put({
      type: at.DELETE_FAILURE,
      userId,
      message: message || 'Something went wrong',
    });
    return;
  }
  yield put({ type: at.DELETE_SUCCESS, userId });
}
