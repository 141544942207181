import React, { Component } from 'react';
import { connect } from 'react-redux';
import IconButton from 'emerald-ui-2/lib/IconButton';

import { selectors as usersSelectors } from 'state/users';
import { actions as userActions } from 'state/users';
import TieUsersRow from './TieUsersRow';
import * as UserRequest from 'api/users';

class TieUsersList extends Component {
  handleDelete = async (userId) => {
    const { boardId, accessToken, showToast, fetchUsers } = this.props;

    if (!boardId || !userId) {
      showToast('Error deleting tied user');
      return;
    }
    await UserRequest.untieUsers(userId, boardId, accessToken);
    fetchUsers();
  };

  renderTiedUsers = () => {
    const { boardSuiteUsers, legacyUsers } = this.props;

    return boardSuiteUsers
      .filter((u) => u.tiedToId)
      .map((u) => {
        const tiedTo = legacyUsers.find((l) => l.id === u.tiedToId) || {};
        let tiedToTxt;
        if (tiedTo && tiedTo.firstName) {
          tiedToTxt = `${tiedTo.firstName} ${tiedTo.middleName || ''} ${tiedTo.lastName}`;
        } else {
          tiedToTxt = 'Tied with other board user';
        }
        return (
          <TieUsersRow key={u.id}>
            <span>{tiedToTxt}</span>
            <span>{`${u.firstName} ${u.middleName || ''} ${u.lastName}`}</span>
            <IconButton
              icon="link_off"
              onClick={() => {
                this.handleDelete(u.id);
              }}
              title="Utie users"
            />
          </TieUsersRow>
        );
      });
  };

  render() {
    return <tbody>{this.renderTiedUsers()}</tbody>;
  }
}

function mapStateToProps(state) {
  const us = usersSelectors(state);
  return {
    boardId: state.selectedBoard.id,
    boardSuiteUsers: us.getBoardSuiteSingleLoginUsers(1),
    legacyUsers: us.getAllActiveLegacyUsers(),
    accessToken: state.authentication.accessToken,
  };
}

export default connect(mapStateToProps, {
  fetchUsers: userActions.fetchUsers,
})(TieUsersList);
