import React from 'react';
import Loadable from 'react-loadable';
import Loader from 'components/Loader';

const AsyncReportsPage = Loadable({
  loader: () => import('./ReportsPage' /* webpackChunkName: "reports" */).then((m) => m.default),
  loading() {
    return <Loader />;
  },
});

export default AsyncReportsPage;
