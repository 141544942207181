import at from './types';

export const createConversation = (data, meta) => ({
  type: at.CREATE,
  data,
  meta,
});

export const fetchConversation = (conversationId) => ({
  type: at.FETCH_ONE,
  conversationId,
  meta: {},
});

export const fetchConversations = (filters, meta) => ({
  type: at.FETCH,
  filters,
  meta,
});

export const clear = () => ({ type: at.CLEAR, meta: {} });
export const clearEntities = () => ({ type: at.CLEAR_ENTITIES });

export const updateConversationParticipant = (conversationId, participantId, data = {}, meta = {}) => ({
  type: at.UPDATE_PARTICIPANT,
  conversationId,
  participantId,
  data,
  meta,
});

export const updateConversationParticipants = (conversationId, data = {}, meta = {}) => ({
  type: at.UPDATE_PARTICIPANTS,
  conversationId,
  data,
  meta,
});

export const addMessageToConversation = (conversationId, data, meta = {}) => {
  return {
    type: at.ADD_MESSAGE,
    conversationId,
    data,
    meta,
  };
};
