import axios from 'axios';
import { PAGE_SIZE, getOffset } from 'constants.js';
import { API_URL } from 'config';
import querystring from 'querystring';

export const fetch = (boardId, filters = {}) => {
  const { size = PAGE_SIZE, page = 1, ...rest } = filters;
  const offset = getOffset(page, size);
  const newFilters = { ...rest, offset, limit: size };
  const queryString = querystring.stringify(newFilters);
  return axios.get(`${API_URL}/boards/${boardId}/audits?${queryString}`);
};

export const update = (auditId, data, token) => {
  const options = {
    method: 'put',
    url: `${API_URL}/audits/${auditId}`,
    headers: { authorization: `Bearer ${token}` },
    data,
  };
  return axios.request(options);
};

export function fetchAudit(boardId, auditId) {
  return axios.get(`${API_URL}/boards/${boardId}/audits/${auditId}`);
}

export const archive = (auditId, token) => {
  return axios.request({
    url: `${API_URL}/audits/${auditId}/archive`,
    method: 'post',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export function unarchive(auditId, token) {
  return axios.request({
    url: `${API_URL}/audits/${auditId}/unarchive`,
    method: 'post',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}

export const create = ({ data, token }) => {
  return axios.request({
    url: `${API_URL}/audits`,
    method: 'post',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const createAuditAttachments = (auditId, attachment, roomId, token) => {
  return axios.request({
    url: `${API_URL}/audits/${auditId}/attachments`,
    method: 'post',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: {
      attachment,
      roomId,
    },
  });
};

export const deleteAttachment = (auditId, attachmentId, roomId, token) => {
  return axios.request({
    url: `${API_URL}/audits/${auditId}/attachments/${attachmentId}`,
    method: 'delete',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: {
      roomId,
    },
  });
};

export const runComplianceForUditedUsers = (boardId) => {
  return axios.request({
    url: `${API_URL}/audits/users/run-compliance`,
    method: 'post',
    data: {
      boardId,
    },
  });
};
