import { call, put, select } from 'redux-saga/effects';

import { getFiltersFromQuery } from '../common';

import * as api from 'api/conversations';
import at from '../actions/types';
import * as actions from '../actions';

import { actions as notificationsActions } from 'state/notifications';

export function* updateConversationParticipants({ conversationId, data, meta }) {
  const state = yield select();
  const { authentication } = state;
  yield put({ type: at.UPDATE_PARTICIPANTS_REQUEST });
  try {
    try {
      yield call(api.updateConversationParticipants, conversationId, data, authentication.accessToken, meta);
    } catch (e) {
      console.log(e);
      yield put({
        type: at.UPDATE_PARTICIPANTS_FAILURE,
        message: e.message || 'Something went wrong',
      });
      return;
    }

    if (meta.withMessage) {
      const n = {
        type: 'MESSAGE_UPDATED',
        text: meta.message,
      };
      yield put(notificationsActions.addAndRemove(n, 5000));
    }

    yield put({
      type: at.UPDATE_PARTICIPANTS_SUCCESS,
      conversationId,
      data,
    });

    // Fetch the conversations again if needed
    const { query } = meta;
    const filters = getFiltersFromQuery(query);
    if (filters.tab === 'inbox' && data.completed === true) {
      yield put(actions.clear());
      yield put(actions.clearEntities());
      yield put(actions.fetchConversations(filters));
    }
  } catch (error) {
    console.log('error', error);
  }
}
