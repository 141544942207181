import React, { Component } from 'react';
import { connect } from 'react-redux';
import SingleSelect from 'emerald-ui-2/lib/SingleSelect';
import IconButton from 'emerald-ui-2/lib/IconButton';
import TableHeader from 'emerald-ui-2/lib/TableHeader';
import Table from 'emerald-ui-2/lib/Table';

import { selectors as usersSelectors } from 'state/users';
import { actions as userActions } from 'state/users';
import TieUsersRow from './TieUsersRow';
import * as UserRequest from 'api/users';

class TieUsersForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      boardSuiteUserId: '',
      legacyUserId: '',
    };
  }

  handleLegacyUsersChange = (id) => {
    const value = id || '';
    this.setState({ legacyUserId: value });
  };

  handleBoardSuiteUsersChange = (id) => {
    const value = id || '';
    this.setState({ boardSuiteUserId: value });
  };

  clearForm = () => {
    this.setState({
      boardSuiteUserId: '',
      legacyUserId: '',
    });
  };

  handleSubmit = async () => {
    const { boardId, accessToken, showToast, fetchUsers } = this.props;
    const { boardSuiteUserId, legacyUserId } = this.state;

    if (!boardId || !boardSuiteUserId || !legacyUserId) {
      showToast("Error: User can't be blank");
      return;
    }

    this.clearForm();
    await UserRequest.tieUsers(boardSuiteUserId, legacyUserId, boardId, accessToken);

    fetchUsers();
  };

  generateOptions = (array) =>
    array.map(({ value, label }) => (
      <option key={`${value}-${label}`} value={value}>
        {label}
      </option>
    ));

  render() {
    const { legacyUsers, boardSuiteUsers } = this.props;

    const buildSource = (u) => ({
      value: u.id,
      label: `(30-${u.id}) - ${u.firstName} ${u.middleName || ''} ${u.lastName}`,
    });

    const isTied = (userId) => {
      return boardSuiteUsers.some((u) => u.tiedToId === userId);
    };

    const defaultValue = { value: -1, label: 'All' };

    const legacyUsersSource = legacyUsers.filter((u) => !isTied(u.id)).map(buildSource);
    legacyUsersSource.unshift(defaultValue);

    const boardSuiteUsersSource = boardSuiteUsers.filter((u) => !u.tiedToId).map(buildSource);
    boardSuiteUsersSource.unshift(defaultValue);

    return (
      <Table className="tie-users-page-table">
        <TableHeader useCheckbox={false} />
        <tbody>
          <TieUsersRow>
            <SingleSelect
              name="legacyUsers"
              className="tie-users-field-select"
              value={this.state.legacyUserId}
              onSelect={this.handleLegacyUsersChange}
            >
              {legacyUsersSource.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </SingleSelect>
            <SingleSelect
              name="boardSuiteUsers"
              className="tie-users-field-select"
              value={this.state.boardSuiteUserId}
              onSelect={this.handleBoardSuiteUsersChange}
            >
              {boardSuiteUsersSource.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </SingleSelect>
            <div>
              <IconButton icon="check" onClick={this.handleSubmit} />
            </div>
          </TieUsersRow>
        </tbody>
      </Table>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const us = usersSelectors(state);
  return {
    boardId: state.selectedBoard.id,
    boardSuiteUsers: us.getBoardSuiteSingleLoginUsers(),
    legacyUsers: us.getAllActiveLegacyUsers(),
    accessToken: state.authentication.accessToken,
    ...ownProps,
  };
}

export default connect(mapStateToProps, {
  fetchUsers: userActions.fetchUsers,
})(TieUsersForm);
