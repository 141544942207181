import { takeLatest } from 'redux-saga/effects';

import { createConversation } from './createConversation';
import { fetchConversations } from './fetchConversations';
import { fetchConversation } from './fetchConversation';
import { updateConversationParticipant } from './updateConversation';
import { updateConversationParticipants } from './updateConversations';
import { addMessageToConversation } from './addMessageToConversation';

import at from '../actions/types';

export default function* root() {
  yield takeLatest(at.FETCH, fetchConversations);
  yield takeLatest(at.FETCH_ONE, fetchConversation);
  yield takeLatest(at.CREATE, createConversation);
  yield takeLatest(at.UPDATE_PARTICIPANT, updateConversationParticipant);
  yield takeLatest(at.UPDATE_PARTICIPANTS, updateConversationParticipants);
  yield takeLatest(at.ADD_MESSAGE, addMessageToConversation);
}
