import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const BlankState = ({ text }) => (
  <div className="table-v2-blank-state">
    <div className="table-v2-blank-state-text">{text}</div>
  </div>
);

BlankState.propTypes = {
  text: PropTypes.string,
};

BlankState.defaultProps = {
  text: 'No results found',
};

export default BlankState;
