export function getFiltersFromQuery(query) {
  let {
    applicationsPage,
    applicationsSize,
    applicationsSort,
    applicationsTerm,
    applicationsMinSubmissionDate,
    applicationsMaxSubmissionDate,
    applicationsApplicationType,
    applicationsApproverId,
  } = query;

  applicationsPage = !isNaN(applicationsPage) ? parseInt(applicationsPage, 10) : null;
  applicationsSize = !isNaN(applicationsSize) ? parseInt(applicationsSize, 10) : null;

  const filters = { page: applicationsPage, size: applicationsSize, sort: applicationsSort };
  if (applicationsTerm) {
    filters.term = applicationsTerm;
  }
  if (applicationsMinSubmissionDate) {
    filters.minSubmissionDate = applicationsMinSubmissionDate;
  }
  if (applicationsMaxSubmissionDate) {
    filters.maxSubmissionDate = applicationsMaxSubmissionDate;
  }
  if (applicationsApplicationType) {
    filters.applicationType = applicationsApplicationType;
  }
  if (applicationsApproverId) {
    filters.approverId = applicationsApproverId;
  }

  return filters;
}

export function removePaginationFromFilters(filters) {
  const newFilters = { ...filters };
  delete newFilters.page;
  return newFilters;
}
