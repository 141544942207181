import { combineReducers } from 'redux';

import at from '../actions/types';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return { ...state, ...action.entities.feeds };
    }
    case at.CREATE_SUCCESS: {
      const { feed } = action;
      return { ...state, [feed._id]: feed };
    }
    case at.ADD_COMMENT_SUCCESS: {
      const { feedId, comment } = action;
      const feed = state[feedId];
      feed.messages = [...feed.messages, comment];
      return { ...state, [feedId]: feed };
    }
    case at.DELETE_COMMENT_SUCCESS: {
      const { feedId, commentId } = action;
      const feed = state[feedId];
      feed.messages = feed.messages.filter((m) => m._id !== commentId);
      return { ...state, [feedId]: feed };
    }
    case at.UPDATE_COMMENT_SUCCESS: {
      const { feedId, commentId, comment } = action;
      const feed = state[feedId];
      feed.messages = feed.messages.map((m) => {
        if (m._id === commentId) {
          return comment;
        }
        return m;
      });
      return { ...state, [feedId]: feed };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const entitiesFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      return { ...state, [action.feedId]: 'loading' };
    }
    case at.FETCH_SUCCESS: {
      return { ...state, [action.feedId]: 'loaded' };
    }
    case at.FETCH_FAILURE: {
      return { ...state, [action.feedId]: 'failed' };
    }
    case at.FETCH_CANCELED: {
      return { ...state, [action.feedId]: 'canceled' };
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  entitiesFetchStatus,
});
