import React from 'react';
import Loadable from 'react-loadable';

import LoggedInUserAvatar from './LoggedInUserAvatar';
import LoginScreen from './LoginScreen';
import LoginForm from './LoginForm';
import AuditAccount from './AuditAccount';
import BoardUserProfile from './BoardUserProfile';
import TieUsersPage from './TieUsersPage';
import Loader from 'components/Loader';
import SingleLoginPage from './SingleLoginPage';
import SelfReportedCEDetailPage from './SelfReportedCEDetailPage';

const LicensesPage = Loadable({
  loader: () => import('./LicensesPage' /* webpackChunkName: "licenses" */).then((m) => m.default),
  loading() {
    return <Loader />;
  },
});

const AuditsPage = Loadable({
  loader: () => import('./AuditsPage' /* webpackChunkName: "audits" */).then((m) => m.default),
  loading() {
    return <Loader />;
  },
});

const AuditDetailPage = Loadable({
  loader: () => import('./AuditDetailPage' /* webpackChunkName: "audit-detail-page" */).then((m) => m.default),
  loading() {
    return <Loader />;
  },
});

const BoardProfile = Loadable({
  loader: () => import('./BoardProfile' /* webpackChunkName: "board-profile" */).then((m) => m.default),
  loading() {
    return <Loader />;
  },
});

const LicenseDetailPage = Loadable({
  loader: () => import('./LicenseDetailPage' /* webpackChunkName: "license-detail-page" */).then((m) => m.default),
  loading() {
    return <Loader />;
  },
});

const NotificationsPanel = Loadable({
  loader: () => import('./NotificationsPanel' /* webpackChunkName: "notifications-panel" */).then((m) => m.default),
  loading() {
    return <Loader />;
  },
});

const CeApprovalDetailPage = Loadable({
  loader: () =>
    import('./CeApprovalDetailPage' /* webpackChunkName: "ceapproval-detail-page" */).then((m) => m.default),
  loading() {
    return <Loader />;
  },
});

const ProvidersPage = Loadable({
  loader: () => import('./ProvidersPage' /* webpackChunkName: "providers" */).then((m) => m.default),
  loading() {
    return <Loader />;
  },
});

const ProvidersDetailPage = Loadable({
  loader: () => import('./ProvidersDetailPage' /* webpackChunkName: "providers-detail-page" */).then((m) => m.default),
  loading() {
    return <Loader />;
  },
});

const CoursesPage = Loadable({
  loader: () => import('./CoursesPage' /* webpackChunkName: "Courses-page" */).then((m) => m.default),
  loading() {
    return <Loader />;
  },
});

const CoursesDetailPage = Loadable({
  loader: () => import('./CoursesDetailPage' /* webpackChunkName: "Courses-detail-page" */).then((m) => m.default),
  loading() {
    return <Loader />;
  },
});

export {
  LicensesPage,
  LoggedInUserAvatar,
  LoginScreen,
  LoginForm,
  LicenseDetailPage,
  AuditsPage,
  AuditDetailPage,
  AuditAccount,
  BoardProfile,
  NotificationsPanel,
  BoardUserProfile,
  SingleLoginPage,
  TieUsersPage,
  SelfReportedCEDetailPage,
  CeApprovalDetailPage,
  ProvidersPage,
  ProvidersDetailPage,
  CoursesPage,
  CoursesDetailPage,
};

export { default as App } from './App';
export { default as UsersPage } from './UsersPage';
export { default as ReportsPage } from './ReportsPage';
