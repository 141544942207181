import * as at from '../actions/types';

export const auditCustomSelection = (state = { loading: false }, action) => {
  switch (action.type) {
    case at.ADD_TO_AUDIT_CUSTOM_REQUEST:
      return { isAuditing: true, loading: true };
    case at.ADD_TO_AUDIT_CUSTOM_SUCCESS:
      return { isAuditing: true, loading: false, id: action.data.auditSelectionTrackId };
    case at.ADD_TO_AUDIT_CUSTOM_FAILURE:
      return { isAuditing: false, loading: false, error: action.message };
    case 'DELETE_AUDIT_SELECTION_TRACK':
      return {};
    default:
      return state;
  }
};

export default auditCustomSelection;
