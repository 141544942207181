import { getFiltersFromQuery } from '../common';

export default function (globalState) {
  const state = globalState.usersByState;

  const getEntityById = (id) => state.entities[id] || {};

  const getIdsByPage = (page) => state.pages[page] || [];

  const getEntitiesByPage = (page) => {
    const ids = getIdsByPage(page);
    return ids.length > 0 ? ids.map((id) => getEntityById(id)) : [];
  };

  const getFetchStatusByPage = (page) => state.fetchStatus[page];

  const getFetchStatus = () => state.fetchStatus;

  const getPagination = () => state.pagination || {};

  const getFilters = () => {
    let query = {};
    if (globalState.routing.locationBeforeTransitions) {
      query = globalState.routing.locationBeforeTransitions.query;
    }
    return getFiltersFromQuery(query);
  };

  const getCount = () => state.count;

  return {
    getEntityById,
    getEntitiesByPage,
    getIdsByPage,
    getFetchStatusByPage,
    getFetchStatus,
    getFilters,
    getPagination,
    getCount,
  };
}
