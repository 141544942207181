import { FETCH_PROFESSIONS, FETCH_PROFESSIONS_SUCCESS } from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case FETCH_PROFESSIONS:
      return [...action.payload.data];

    case FETCH_PROFESSIONS_SUCCESS:
      return [...action.payload.data];

    default:
      return state;
  }
}
