import { call, put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as api from 'api/licenses';

import at from '../actions/types';
import { actions as notifications } from 'state/notifications';

export function* resendEmail(auditId, licenseId) {
  const state = yield select();
  const { authentication } = state;
  yield put({ type: at.RESEND_EMAIL_REQUEST, licenseId });
  try {
    yield call(delay, 300);
    try {
      yield call(api.resendEmail, { auditId, licenseId, token: authentication.accessToken });
    } catch (e) {
      console.log(e);
      yield put({
        type: at.RESEND_EMAIL_FAILURE,
        auditId,
        licenseId,
        message: e.message || 'Something went wrong',
        meta: {},
      });
      const n = { type: 'RESEND_AUDIT_INVITATION', text: 'Fail: Error sending audit invitation' };
      yield put(notifications.addAndRemove(n, 5000));
      return;
    }

    yield put({ type: at.RESEND_EMAIL_SUCCESS });
    //toaster
    const n = { type: 'RESEND_AUDIT_INVITATION', text: 'Success: Audit invitation sent' };
    yield put(notifications.addAndRemove(n, 5000));
  } catch (error) {
    console.log('error', error);
  }
}

export function* watchResendEmail({ auditId, licenseId }) {
  yield call(resendEmail, auditId, licenseId);
}
