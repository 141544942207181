export function getFiltersFromQuery(query) {
  let {
    tab,
    offsetCompleted,
    limitCompleted,
    offsetInbox,
    limitInbox,
    offsetSent,
    limitSent,
    lastMessageSentStartDate,
    lastMessageSentEndDate,
    myMessages,
    q,
  } = query;
  tab = tab === 'inbox' || tab === 'completed' || tab === 'sent' ? tab : 'inbox';
  const filters = {
    tab,
    myMessages: myMessages === 'true',
  };
  if (lastMessageSentStartDate) {
    filters.lastMessageSentStartDate = lastMessageSentStartDate;
  }
  if (lastMessageSentEndDate) {
    filters.lastMessageSentEndDate = lastMessageSentEndDate;
  }
  if (q) {
    filters.q = q;
  }

  switch (tab) {
    case 'inbox': {
      filters.offset = !isNaN(offsetInbox) ? parseInt(offsetInbox, 10) : null;
      filters.limit = !isNaN(limitInbox) ? parseInt(limitInbox, 10) : null;
      break;
    }
    case 'completed': {
      filters.offset = !isNaN(offsetCompleted) ? parseInt(offsetCompleted, 10) : null;
      filters.limit = !isNaN(limitCompleted) ? parseInt(limitCompleted, 10) : null;
      break;
    }
    case 'sent': {
      filters.offset = !isNaN(offsetSent) ? parseInt(offsetSent, 10) : null;
      filters.limit = !isNaN(limitSent) ? parseInt(limitSent, 10) : null;
      break;
    }
    default: {
      break;
    }
  }
  return filters;
}
