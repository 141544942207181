import React from 'react';
import moment from 'moment';
import Icon from 'emerald-ui-2/lib/Icon';
import './styles.css';

const NotificationPanelItem = ({ icon, title, date, desc, opened }) => {
  const fromNow = date ? moment(new Date(date)).fromNow() : '';
  return (
    <div className={`notification-panel-item ${!opened ? 'notification-panel-item-unread' : ''}`}>
      <div className="notification-panel-item-iconContainer">
        <div className="notification-panel-item-oval">
          <Icon name={icon} />
        </div>
      </div>
      <div className="notification-panel-item-messageContainer">
        <div className="notification-panel-item-title">
          <span>{title.toString().toUpperCase()}</span>
          <span>{fromNow}</span>
        </div>
        <div className="notification-panel-item-description">{desc}</div>
      </div>
    </div>
  );
};

export default NotificationPanelItem;
