import React, { useState, useEffect } from 'react';
import ToastUI from 'emerald-ui-2/lib/Toast';

export const useToast = () => {
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const getRandomIndex = (arr) => Math.floor(Math.random() * arr.length);

  const close = () => {
    setIsToastVisible(false);
    setMessage('');
  };

  const successIcons = ['🎉', '😎', '😘', '👍🏻', '👏🏻'];

  const Toast = () => {
    let msgType;
    switch (messageType) {
      case 'success':
        msgType = successIcons[getRandomIndex(successIcons)];
        break;
      case 'error': {
        msgType = '😱';
        break;
      }
      default:
        msgType = '🙂';
        break;
    }
    return (
      <ToastUI
        message={<div style={{ fontSize: '20px' }}>{message}</div>}
        visible={isToastVisible}
        onTimeout={close}
        onActionClick={() => {}}
        actionText={<div style={{ fontSize: '30px' }}>{msgType}</div>}
      />
    );
  };

  Toast.defaultProps = {
    type: 'success',
  };

  const showToast = (messageText, type = 'success') => {
    setMessageType(type);
    setMessage(messageText);
    setIsToastVisible(true);
  };

  return [Toast, showToast];
};

export const useImageLoad = (ref) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('load', function () {
        setLoaded(!loaded);
      });
    }
  }, []);

  return [loaded];
};
