import React from 'react';
import Button from 'emerald-ui-2/lib/Button';
import Icon from 'emerald-ui-2/lib/Icon';
import './styles.css';

const FiltersBadge = ({ onFiltersClick, amount }) => {
  const filtersApplied = amount && amount > 0 ? `(${amount})` : null;
  return (
    <div>
      <Button id="inboxFiltersBtn" onClick={onFiltersClick}>
        <Icon name="filter_list" />
        <span>Filters {filtersApplied}</span>
      </Button>
    </div>
  );
};

export default FiltersBadge;
