import { call, put, select } from 'redux-saga/effects';

import * as api from '../../../api/boardUser';
import at from '../actions/types';

export function* fetchBoardUserProfile(action) {
  const state = yield select();
  const { userId } = action;

  yield put({ type: at.FETCH_ONE_REQUEST });
  try {
    let response;
    try {
      response = yield call(api.fetchBoardUserProfile, userId, state.selectedBoard.id);
    } catch (e) {
      console.log(e);
      yield put({ type: at.FETCH_ONE_FAILURE, message: e.message || 'Something went wrong' });
      return;
    }
    yield put({ type: at.FETCH_ONE_SUCCESS, ...response.data });
  } catch (error) {
    console.log('error', error);
  }
}
