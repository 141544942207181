import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import config from '../../config';
import styles from './styles.scss';

import { authenticate, logout, verifyToken } from '../../actions/index';

export class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
  }

  componentWillMount() {
    const { authentication = {}, location = {}, history } = this.props;
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
      this.props.logout();
      this.props.verifyToken(token);
      return;
    }
    if (authentication.status === 'authenticated') {
      history.push('/licenses');
    }
  }

  componentWillReceiveProps(nextProps) {
    const { authentication = {}, history } = nextProps;

    if (authentication.status === 'authenticated') {
      history.push('/licenses');
    }
  }

  handleUsernameInputChange(username) {
    this.setState({ username });
  }

  handlePasswordInputChange(password) {
    this.setState({ password });
  }

  handleFormSubmit(event) {
    event.preventDefault();
    const { username, password } = this.state;
    if (this.props.authenticate) this.props.authenticate({ username, password });
  }

  render() {
    const { authentication = {} } = this.props;
    let spanText = '';
    switch (authentication.requestStatus) {
      case 'failed':
        spanText = 'Something went wrong in our servers';
        break;
      case 'wrong_credentials':
        spanText = 'Invalid user or password';
        break;
      default:
        break;
    }
    return (
      <div className={styles.body}>
        <div className={styles.logo} />
        <form className={styles.loginBlock} onSubmit={this.handleFormSubmit.bind(this)}>
          <h1>Login</h1>
          <input
            type="text"
            value={this.state.username}
            onChange={(event) => this.handleUsernameInputChange(event.target.value)}
            autoFocus={true}
            required
            disabled={authentication.requestStatus === 'authenticating'}
          />
          <input
            type="password"
            value={this.state.password}
            onChange={(event) => this.handlePasswordInputChange(event.target.value)}
            required
            disabled={authentication.requestStatus === 'authenticating'}
          />
          <button onClick={this.handleFormSubmit.bind(this)}>Login</button>
          <span className={styles.errorSpan}>{spanText}</span>
          <div className={styles.forgotPassword}>
            <b>Help: </b>
            <a href={`${config.LAUNCHPAD_URL}/forgot_password`}>I forgot my username/password</a>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps() {
  return {
    authenticate,
    logout,
    verifyToken,
  };
}

LoginForm.contextTypes = {
  router: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps())(LoginForm);
