import React from 'react';
import { connect } from 'react-redux';
import NewUserModal from './components/NewUserModal';
import RedirectButton from 'components/RedirectButton';
import { selectors as boardAccessSelectors } from 'state/boardsAccess';
import PageHeader from 'emerald-ui-2/lib/PageHeader';
import Container from 'emerald-ui-2/lib/Container';
import Row from 'emerald-ui-2/lib/Row';
import Col from 'emerald-ui-2/lib/Col';
import Button from 'emerald-ui-2/lib/Button';
import { UsersRoutes } from 'routes';

import './styles.css';

export class UsersPage extends React.Component {
  state = {
    isNewUserModalOpen: false,
  };

  componentDidMount() {
    const { history, boardAccessFetchStatus } = this.props;
    if (boardAccessFetchStatus === 'loaded' && !this.canReadUsers()) {
      history.replace('/licenses');
    }
  }

  componentDidUpdate() {
    const { history, boardAccessFetchStatus } = this.props;
    if (boardAccessFetchStatus === 'loaded' && !this.canReadUsers()) {
      history.replace('/licenses');
    }
  }

  canReadUsers = () => {
    const { boardAccess } = this.props;
    const permissions = boardAccess.permissions || [];
    const canReadUsers = permissions.some((p) => p.code === 'READ_USERS');
    return canReadUsers;
  };

  handleToggleNewUserModal = () => this.setState((state) => ({ isNewUserModalOpen: !state.isNewUserModalOpen }));

  render() {
    const { location, history } = this.props;
    const { goBack } = history;
    const inUserList = /^(\/)?users\/list$/.test(location.pathname);
    return (
      this.canReadUsers() && (
        <div>
          <NewUserModal isOpen={this.state.isNewUserModalOpen} onClose={this.handleToggleNewUserModal} />
          <PageHeader className="board-nav-header-bg">
            <RedirectButton />
            <h1 className="clearfix">
              <span className="h2">Users</span>
            </h1>
          </PageHeader>
          <div className="users-page-tabsButtonsLayer">
            <Container>
              <Row>
                <Col xs={12}>
                  <div className="users-page-tabsButtonsContainer">
                    <div className="users-page-leftButtons">
                      {!inUserList && (
                        <Button onClick={goBack} shape="flat">
                          <i className="material-icons">keyboard_backspace</i>
                          <span>BACK</span>
                        </Button>
                      )}
                    </div>
                    <div className="users-page-rightButtons">
                      {inUserList && (
                        <Button color="info" onClick={this.handleToggleNewUserModal}>
                          NEW USER
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <UsersRoutes />
        </div>
      )
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const bas = boardAccessSelectors(state);

  return {
    boardAccess: bas.getSelectedBoardAccess(),
    boardAccessFetchStatus: bas.getSelectedBoardFetchStatus(),
    ...ownProps,
  };
};

export default connect(mapStateToProps, {})(UsersPage);
