import React from 'react';
import Button from 'emerald-ui-2/lib/Button';
import Icon from 'emerald-ui-2/lib/Icon';
import { connect } from 'react-redux';

import './styles.css';

const RedirectButton = ({ loggedInUser }) => {
  const { externalSSO } = loggedInUser;

  const redirectPortal = (_) => {
    window.location.replace(externalSSO.returnURL);
  };

  return (
    <React.Fragment>
      {externalSSO && externalSSO.tokenLogo ? (
        <Button className="redirect-button" onClick={redirectPortal}>
          <Icon name="keyboard_arrow_left" />
          <span className="mr-8">Return to {externalSSO.clientId} portal</span>
        </Button>
      ) : null}
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  const { loggedInUser } = state;
  return {
    loggedInUser,
  };
}

export default connect(mapStateToProps)(RedirectButton);
