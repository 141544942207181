export default function (globalState) {
  const state = globalState.conversations;

  const getEntityById = (id) => state.entities[id] || {};
  const getEntityFetchStatusById = (id) => state.entitiesFetchStatus[id] || '';

  const getConversationById = (id) => getEntityById(id);

  const getConversationFetchStatusById = (id) => getEntityFetchStatusById(id);

  const getInboxConversations = () => {
    return state.inbox.entityIds.map(getEntityById).filter((c) => !c.participants.some((p) => p.completed));
  };

  const getInboxFetchStatus = () => {
    return state.inbox.fetchStatus;
  };

  const getInboxTotalCount = () => {
    return state.inbox.totalCount;
  };

  const getCompletedConversations = () => {
    return state.completed.entityIds.map(getEntityById).filter((c) => c.participants.some((p) => p.completed));
  };

  const getCompletedFetchStatus = () => {
    return state.completed.fetchStatus;
  };

  const getCompletedTotalCount = () => {
    return state.completed.totalCount;
  };

  const getSentConversations = () => {
    return state.sent.entityIds.map(getEntityById).filter((c) => !c.participants.some((p) => p.completed));
  };

  const getSentFetchStatus = () => {
    return state.sent.fetchStatus;
  };

  const getSentTotalCount = () => {
    return state.sent.totalCount;
  };

  return {
    getConversationById,
    getConversationFetchStatusById,
    getInboxConversations,
    getInboxFetchStatus,
    getInboxTotalCount,
    getCompletedConversations,
    getCompletedFetchStatus,
    getCompletedTotalCount,
    getSentConversations,
    getSentFetchStatus,
    getSentTotalCount,
  };
}
