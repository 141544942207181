export default function (globalState) {
  const state = globalState.feed;

  const getFeed = () => state.entities;

  const getFeedById = (id) => state.entities[id] || {};

  const getMessagesByFeedId = (id) => getFeedById(id).messages || [];

  const getFetchStatusById = (id) => state.entitiesFetchStatus[id];

  return {
    getFeed,
    getFeedById,
    getFetchStatusById,
    getMessagesByFeedId,
  };
}
