import { call, put, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import * as api from 'api/transcripts';

import * as schema from '../schema';
import at from '../actions/types';

export function* fetchTranscript({ licensePeriodId, licenseNumber, licenseId, professionId, userId }, spinner = false) {
  const state = yield select();
  const { authentication } = state;
  const stateCode = state.selectedBoard.state.code;
  if (!spinner) {
    yield put({ type: at.FETCH_REQUEST, licensePeriodId });
  }

  let response;
  try {
    response = yield call(api.fetchTranscript, {
      licensePeriodId,
      licenseNumber,
      licenseId,
      professionId,
      stateCode,
      userId,
      token: authentication.accessToken,
    });
  } catch (e) {
    console.log(e);
    yield put({ type: at.FETCH_FAILURE, licensePeriodId, message: e.message || 'Something went wrong' });
    return;
  }
  const { transcript, disallowInfo } = response.data;
  if (transcript) {
    let normalizedData = normalize({ ...transcript, userId, disallowInfo }, schema.transcript);
    yield put({ type: at.FETCH_SUCCESS, ...normalizedData });
  } else {
    let data = {
      result: licensePeriodId,
    };
    yield put({ type: at.FETCH_BLANK, ...data });
  }
}

export function* updateTranscript({ transcript }) {
  const state = yield select();
  const { authentication } = state;
  const { number } = transcript;
  delete transcript.number;
  delete transcript.stateCode;

  let response;
  try {
    yield put({ type: at.FETCH_REQUEST, licensePeriodId: transcript.licensePeriodId });
    response = yield call(api.updateTranscript, {
      transcript,
      token: authentication.accessToken,
    });
  } catch (e) {
    console.log(e);
    yield put({ type: at.FETCH_FAILURE, licensePeriodId: transcript.licensePeriodId, message: e.message || 'Something went wrong' });
    return;
  }

  const payload = {
    licensePeriodId: transcript.licensePeriodId,
    licenseNumber: number,
    professionId: transcript.professionId,
    licenseId: transcript.licenseId,
    userId: transcript.userId,
  };

  yield fetchTranscript(payload, true);
}

export function* deleteWaiverScenario({ data, scenarioId, search }) {
  const state = yield select();
  const { authentication } = state;

  let response;
  try {
    yield put({ type: at.FETCH_REQUEST, licensePeriodId: data.licensePeriodId });
    response = yield call(api.deleteWaiverScenario, {
      scenarioId,
      data,
      token: authentication.accessToken,
    });
  } catch (e) {
    console.log(e);
    yield put({ type: at.FETCH_FAILURE, licensePeriodId: data.licensePeriodId, message: e.message || 'Something went wrong' });
    return;
  }
  yield fetchTranscript(search, true);
}