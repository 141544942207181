import { takeEvery } from 'redux-saga/effects';

import at from '../actions/types';

import { fetchBoard } from './fetchBoard';
import { updateBoardProfile } from './updateBoardProfile';

export default function* root() {
  yield takeEvery(at.FETCH, fetchBoard);
  yield takeEvery(at.ADD, updateBoardProfile);
}
