import at from '../actions/types';

const exemptions = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_EXEMPTIONS_BY_PROFESSION_FAILURE: {
      return [];
    }
    case at.FETCH_EXEMPTIONS_BY_PROFESSION_SUCCESS: {
      const { exemptions = [] } = action;
      return exemptions;
    }
    default:
      return state;
  }
};

export default exemptions;
