export function getArchivedFiltersFromQuery(query) {
  let { archivedPage, archivedSize, archivedSort = '-archive_date', archivedTerm } = query;
  archivedPage = !isNaN(archivedPage) ? parseInt(archivedPage, 10) : 1;
  archivedSize = !isNaN(archivedSize) ? parseInt(archivedSize, 10) : 10;

  const filters = { page: archivedPage, size: archivedSize, sort: archivedSort, archived: true };
  if (archivedTerm) {
    filters.term = archivedTerm;
  }
  return filters;
}

export function getCurrentFiltersFromQuery(query) {
  let {
    currentPage,
    currentSize,
    currentSort = 'deadline',
    currentTerm,
    minDateAdded,
    maxDateAdded,
    minDeadline,
    maxDeadline,
    transcriptCompletion,
    selectedUser,
    complianceStatus,
  } = query;

  currentPage = !isNaN(currentPage) ? parseInt(currentPage, 10) : 1;
  currentSize = !isNaN(currentSize) ? parseInt(currentSize, 10) : 10;

  const filters = { page: currentPage, size: currentSize, sort: currentSort, archived: false };
  if (currentTerm) {
    filters.term = currentTerm;
  }
  if (minDateAdded) {
    filters.minDateAdded = minDateAdded;
  }
  if (maxDateAdded) {
    filters.maxDateAdded = maxDateAdded;
  }
  if (minDeadline) {
    filters.minDeadline = minDeadline;
  }
  if (maxDeadline) {
    filters.maxDeadline = maxDeadline;
  }
  if (transcriptCompletion) {
    filters.transcriptCompletion = transcriptCompletion;
  }
  if (selectedUser) {
    filters.selectedUser = selectedUser;
  }
  if (complianceStatus) {
    filters.complianceStatus = complianceStatus;
  }

  return filters;
}

export function getFiltersFromQuery(query, type) {
  return type === 'current' ? getCurrentFiltersFromQuery(query) : getArchivedFiltersFromQuery(query);
}

export function removePaginationFromFilters(filters) {
  const newFilters = { ...filters };
  delete newFilters.page;
  return newFilters;
}
