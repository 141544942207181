import { combineReducers } from 'redux';
import bulks from './bulks';
import bulkLicenses from './bulkLicenses';

const audits = combineReducers({
  bulks,
  bulkLicenses,
});

export default audits;
