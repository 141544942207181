import { createAction } from 'state/utils';

export default {
  ...createAction('courseHistory', 'FETCH_PDF'),
  ...createAction('courseHistory', 'FETCH_PDF_STATUS'),
  ...createAction('courseHistory', 'UPDATE_PDF_STATUS'),
  ...createAction('courseHistory', 'DOWNLOAD_PDF_BROWSER'),
  ...createAction('courseHistory', 'CLEAR_REQUEST_PDF'),
  ...createAction('courseHistory', 'CLEAR_PDF_STATUS'),
  ...createAction('courseHistory', 'CLEAR_REQUEST_BROWSER'),
  FETCH_BLANK: 'courseHistory/FETCH_BLANK',
  FETCH_CANCELED: 'courseHistory/FETCH_CANCELED',
};
