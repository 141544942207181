import at from './types';

export const fetchBoardApplications = (filters) => ({ type: at.FETCH, filters });

export const setFetchCanceled = (filters, meta) => ({ type: at.FETCH_CANCELED, filters, meta });

export const clear = () => ({ type: at.CLEAR });
export const clearEntities = () => ({ type: at.CLEAR_ENTITIES });

export const fetchReports = (filters) => ({ type: at.FETCH_REPORTS, filters });
