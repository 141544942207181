import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import FiltersBadge from './FiltersBadge';
import IconButton from 'emerald-ui-2/lib/IconButton';

class FiltersBar extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      nextProps.term !== this.props.term ||
      nextProps.amountAppliedFilters !== this.props.amountAppliedFilters ||
      nextProps.downloadUrl !== this.props.downloadUrl
    ) {
      return true;
    }
    return false;
  }

  render() {
    const {
      term,
      onTermChange,
      amountAppliedFilters,
      onFiltersClick,
      showSearchBox,
      showFilterButton,
      showDownloadButton,
      downloadUrl,
    } = this.props;
    return (
      <div className="filters-bar-filters">
        <div className="filters-bar-searchInput">
          {showSearchBox && (
            <span>
              <IconButton icon="search" className="text-lighter" />
            </span>
          )}
          {showSearchBox && (
            <input
              type="text"
              placeholder="Search"
              value={term}
              onChange={(event) => onTermChange(event.target.value)}
            />
          )}
        </div>
        <div className="filters-bar-actions">
          {showDownloadButton && (
            <a href={downloadUrl}>
              <IconButton icon="file_download" ripple={false} />
            </a>
          )}
          {showFilterButton && <FiltersBadge onFiltersClick={onFiltersClick} amount={amountAppliedFilters} />}
        </div>
      </div>
    );
  }
}

FiltersBar.propTypes = {
  showFilterButton: PropTypes.bool,
  showSearchBox: PropTypes.bool,
  term: PropTypes.string,
  onTermChange: PropTypes.func,
  onFiltersClick: PropTypes.func,
  amountAppliedFilters: PropTypes.number,
};

FiltersBar.defaultProps = {
  showSearchBox: true,
  showFilterButton: true,
  showDownloadButton: false,
  term: '',
  amountAppliedFilters: 0,
  onFiltersClick: () => {},
  onTermChange: () => {},
};

export default FiltersBar;
