import { call, put, select } from 'redux-saga/effects';
import { actions as notificationsActions } from 'state/notifications';

import * as api from 'api/conversations';
import at from '../actions/types';

export function* createConversation({ data, meta }) {
  const state = yield select();
  const { authentication } = state;
  const board = {
    id: state.selectedBoard.id,
    name: state.selectedBoard.name,
  };
  data = { ...data, boardId: board.id };
  meta = { ...meta, board };
  yield put({ type: at.CREATE_REQUEST });
  try {
    let response;
    try {
      response = yield call(api.createConversation, data, authentication.accessToken, meta);
    } catch (e) {
      console.log(e);
      yield put({
        type: at.CREATE_FAILURE,
        message: e.message || 'Something went wrong',
      });
      return;
    }
    const conversation = response.data;
    yield put({ type: at.CREATE_SUCCESS, conversation });

    if (meta.withMessage) {
      const n = {
        type: 'MESSAGE_SENT',
        text: `Message sent to ${meta.message.sentTo.name}`,
      };
      yield put(notificationsActions.addAndRemove(n, 5000));
    }

    if (meta.successCallback && typeof meta.successCallback === 'function') {
      meta.successCallback();
    }
  } catch (error) {
    console.log('error', error);
  }
}
