import React from 'react';
import PropTypes from 'prop-types';

import Avatar from 'emerald-ui-2/lib/Avatar';
import * as utils from 'utils';
import './styles.css';
import ShowMore from 'emerald-ui-2/lib/ShowMore';
import Icon from 'emerald-ui-2/lib/Icon';
import IconButton from 'emerald-ui-2/lib/IconButton';
import { API_URL } from '../../config';
import querystring from 'querystring';

import ActivityDetail, { ElapsedDate } from './ActivityDetail';

const downloadFeed = (roomId, messageId, filename) => {
  const qs = querystring.stringify({ roomId, messageId, filename });
  window.open(`${API_URL}/download/activity?${qs}`, '_blank');
};
const buildActivity = (type, user = {}, data, date, displayDate, roomId, messageId) => {
  return (
    <div className="activities-wrapper">
      <div className="activities-activity">
        <div className="activities-avatar">
          <Avatar title={user.name} className="activity-avatarImg" />
        </div>
        <div className="activities-container text-light">
          <p>
            <ActivityDetail fullName={user.name} data={data} date={date} displayDate={displayDate} type={type} />
          </p>
        </div>
      </div>
      {data.emailContent && (
        <div className="activities-showMessage">
          <Icon name="email" />
          <ShowMore toggleCollapsed="Show message" toggleExpanded="Hide message" toggle show={0}>
            <p dangerouslySetInnerHTML={{ __html: data.emailContent }} />
            <p>------------- END OF MESSAGE ------------</p>
          </ShowMore>
          <IconButton icon="file_download" onClick={() => downloadFeed(roomId, messageId, user.name)} />
        </div>
      )}
    </div>
  );
};

const Activity = (props) => {
  const {
    subtype,
    user = {},
    data,
    date,
    displayDate,
    enableEditing,
    onEditActivity,
    isEditing,
    onRemoveActivity,
    onClick,
    isUpdated,
    roomId,
    messageId,
  } = props;

  const textEdit = isEditing ? 'Cancel' : 'Edit comment';
  const formattedDate = utils.parseDate(date);
  const edit = enableEditing ? (
    <em className="activities-edit" onClick={() => onEditActivity()}>
      {textEdit}
    </em>
  ) : (
    ''
  );
  const remove = enableEditing ? (
    <em className="activities-edit" onClick={() => onRemoveActivity()}>
      Remove
    </em>
  ) : (
    ''
  );

  let activity =
    subtype === 'audit/comment' ? (
      <div className={`activities-comment ${isEditing ? 'activities-editing' : ''}`}>
        <div className="activities-avatar">
          <Avatar title={user.name} className="activity-avatarImg" />
        </div>
        <div className="activities-container">
          <p>
            <span>{user.name}</span>
            <ElapsedDate date={formattedDate} displayDate={displayDate} />
            {edit}
            {remove}
          </p>
          {data.text ? (
            <p>
              {data.text}
              <em style={{ position: 'relative', marginLeft: '8px' }}>{isUpdated ? '(Edited)' : ''}</em>
            </p>
          ) : null}
        </div>
      </div>
    ) : (
      <div onClick={onClick}>{buildActivity(subtype, user, data, formattedDate, displayDate, roomId, messageId)}</div>
    );

  return activity;
};

Activity.propTypes = {
  type: PropTypes.string,
  subtype: PropTypes.string,
  user: PropTypes.object,
  text: PropTypes.string,
  data: PropTypes.object,
  date: PropTypes.string,
  enableEditing: PropTypes.bool,
  onEditActivity: PropTypes.func,
  isEditing: PropTypes.bool,
  onRemoveActivity: PropTypes.func,
};

export default Activity;
