import React, { Component } from 'react';
import { connect } from 'react-redux';

import Popover from 'emerald-ui-2/lib/Popover';
import OverlayTrigger from 'emerald-ui-2/lib/OverlayTrigger';
import Spinner from 'emerald-ui-2/lib/Spinner';
import * as utils from 'utils';

import './styles.css';

class PostingDetailPopover extends Component {
  buildHeader() {
    let { post, postCEcredit } = this.props;
    if (postCEcredit.course) {
      let postTypeText = '';
      switch (utils.getCreditType(post.creditType).toUpperCase()) {
        case 'LICENSEE':
          postTypeText = 'Licensee CE posting';
          break;
        case 'PROVIDER':
          postTypeText = 'Provider CE posting';
          break;
        case 'BOARD':
          postTypeText = 'Board CE posting';
          break;
        default:
          postTypeText = '';
          break;
      }
      return (
        <div className="posting-head">
          <small className="post-type text-light">{postTypeText}</small>
          <h6>{postCEcredit.course.name || post.courseName}</h6>
        </div>
      );
    } else {
      return '';
    }
  }

  buildQuestions() {
    const { postCEcredit } = this.props;
    return (
      postCEcredit &&
      postCEcredit.questions &&
      postCEcredit.questions.map((question) => (
        <div className="transcript-question" key={question.id}>
          <p>
            <strong>{question.text}</strong>
          </p>
          {question.answers.map((answer) => (
            <p key={answer.id}>{answer.text}</p>
          ))}
        </div>
      ))
    );
  }

  buildAttachments() {
    const { postCEcredit } = this.props;
    return (
      postCEcredit &&
      postCEcredit.attachments &&
      postCEcredit.attachments.map((attachment) => (
        <div key={attachment.id} className="transcript-attachmentBox">
          <img src={attachment.path} alt="" className="attachmentImg" />
          <a href={attachment.path} target="_blank" rel="noopener noreferrer">
            {attachment.fileName}
          </a>
        </div>
      ))
    );
  }

  buildSubjectAreas() {
    let { post, postCEcredit } = this.props;
    const creditType = utils.getCreditType(post.creditType);
    const isCorrectType = creditType.toUpperCase() === 'LICENSEE' || creditType.toUpperCase() === 'BOARD';
    return (
      isCorrectType &&
      postCEcredit.subjectAreas && (
        <div>
          <h6>Subject areas</h6>
          <table className="subjectAreas">
            <thead>
              <tr>
                <th>Name</th>
                <th>Requested Hours</th>
              </tr>
            </thead>
            <tbody>
              {postCEcredit.subjectAreas
                .filter((sa) => sa.requestedHours > 0)
                .map((sa, index) => (
                  <tr key={index}>
                    <td>{sa.name}</td>
                    <td>{sa.requestedHours}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )
    );
  }

  buildProfession() {
    let { post, postCEcredit } = this.props;
    const creditType = utils.getCreditType(post.creditType);
    const isCorrectType = creditType.toUpperCase() === 'PROVIDER';
    return (
      isCorrectType &&
      postCEcredit.professions && (
        <div>
          <h6>Professions</h6>
          {postCEcredit.professions.map((prof, index) => (
            <div key={index}>
              <table className="subjectAreas">
                <thead>
                  <tr className="text-light">
                    <th>{prof.name}</th>
                    <th>Applied hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{prof.subjectArea.name}</td>
                    <td>{prof.subjectArea.earnedHours}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      )
    );
  }

  buildBody() {
    let { postCEcredit } = this.props;
    return (
      postCEcredit.course && (
        <div className="popoverOverlayBody">
          <div className="courseInfoSection">
            <table className="courseInfo text-light">
              <tbody>
                <tr>
                  <td>Tracking #:</td>
                  <td>{postCEcredit.course.courseTrackingNumber}</td>
                </tr>
                <tr className={postCEcredit.course.courseType ? '' : 'transcript-hidden'}>
                  <td>Course type:</td>
                  <td>
                    {postCEcredit.course.courseType === 'CD_LIVE' || postCEcredit.course.courseType === 'Live'
                      ? 'Live'
                      : 'Anytime'}
                  </td>
                </tr>
                <tr className={postCEcredit.provider.name ? '' : 'transcript-hidden'}>
                  <td>Provider Name:</td>
                  <td>{postCEcredit.provider.name}</td>
                </tr>
                <tr className={postCEcredit.course.deliveryMethod ? '' : 'transcript-hidden'}>
                  <td>Delivery method:</td>
                  <td>{postCEcredit.course.deliveryMethod}</td>
                </tr>
                <tr className={postCEcredit.completedDate ? '' : 'transcript-hidden'}>
                  <td>Completed date:</td>
                  <td>{postCEcredit.completedDate}</td>
                </tr>
                <tr className={postCEcredit.submittedDate ? '' : 'transcript-hidden'}>
                  <td>Submitted date:</td>
                  <td>{postCEcredit.submittedDate}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className={`
              popoverOverlayBodySeparator
              ${postCEcredit.questions.length !== 0 || postCEcredit.attachments.length !== 0 ? '' : 'transcript-hidden'}
              `}
          />
          <div className={postCEcredit.questions.length !== 0 ? '' : 'transcript-hidden'}>
            <h6>{postCEcredit.questions ? postCEcredit.questions.length : ''} Questions</h6>
            {this.buildQuestions()}
          </div>
          <div
            className={postCEcredit.attachments.length !== 0 ? '' : 'transcript-hidden'}
            style={{ paddingTop: '2rem' }}
          >
            <h6>{postCEcredit.attachments ? postCEcredit.attachments.length : ''} Attachments</h6>
            {this.buildAttachments()}
          </div>
          <div className={`popoverOverlayBodySeparator`} />
          {this.buildSubjectAreas()}
          {this.buildProfession()}
        </div>
      )
    );
  }

  handleClick = () => {
    this.props.onClick();
  };

  overlayIsVisible = () => {
    try {
      const { post, postCEcredit } = this.props;
      const { course: coursePostCEcredit = {} } = postCEcredit || {};
      const { id: idCourse = 0, courseTrackingNumber } = coursePostCEcredit;
      const { postCEcreditId } = post;
      return idCourse === post.courseId || String(courseTrackingNumber).includes(postCEcreditId);
    } catch (error) {
      return false;
    }
  };

  render() {
    const { post, id, postCEcredit, loadingPostCEcredit } = this.props;

    let postCECreditBody =
      loadingPostCEcredit === 'loading' ? (
        <div className="transcript-loader-container ">
          <Spinner color="info" size="lg" />
        </div>
      ) : postCEcredit ? (
        this.buildBody()
      ) : (
        ''
      );

    let postCECreditHeader = loadingPostCEcredit === 'loading' ? <div /> : postCEcredit ? this.buildHeader() : '';

    const handleLocalClick = () => {
      if (this.handleClick) {
        this.handleClick();
      }
    };

    const popover = (
      <Popover id={`postingDetailPopover-${id}`} placement="bottom" className="transcript-popover">
        {postCECreditHeader}
        {postCECreditBody}
      </Popover>
    );

    return (
      <OverlayTrigger
        triggerType="click"
        placement="bottom"
        className="transcript-popover-trigger"
        overlay={popover}
        show={this.overlayIsVisible()}
      >
       <div id={id} onClick={handleLocalClick} className="name text-info" style={ {color: post.creditType === "LICENSEE_POST" ? "#5DD89E" : "" } }>
          {post.courseName}
        </div>
      </OverlayTrigger>
    );
  }
}

function mapStateToProps(state) {
  return {
    loadingPostCEcredit: state.postCEcredit.status,
    postCEcredit: state.postCEcredit.data,
  };
}

export default connect(mapStateToProps, {})(PostingDetailPopover);
