import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'emerald-ui-2/lib/Button';
import Container from 'emerald-ui-2/lib/Container';
import Row from 'emerald-ui-2/lib/Row';
import Col from 'emerald-ui-2/lib/Col';
import Checkbox from 'emerald-ui-2/lib/Checkbox';
import TextField from 'emerald-ui-2/lib/TextField';
import SingleSelect from 'emerald-ui-2/lib/SingleSelect';
import routerUtils from 'utils/router';

import { actions as userActions } from 'state/users';
import { actions as rolesActions, selectors as rolesSelectors } from 'state/roles';
import { selectors as notificationsSelectors } from 'state/globalNotifications';
import './styles.css';
import { isValidEmail } from 'utils';

import NotificationsSection from '../NotificationsSection';

class CreateUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      prepopulated: false,
      userId: null,
      selectedNotifications: [],
      roleId: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      accessClassicSite: false,
      permissions: [],
      errors: {},
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const queryParams = routerUtils(history).getQueryParams();
    const { firstName, lastName, email, phone = '', userId } = queryParams;
    if (userId) {
      this.setState({
        firstName,
        lastName,
        email,
        phone,
        userId,
        prepopulated: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { roles: newRoles } = this.props;
    const { roles } = prevProps;

    if (newRoles !== roles) {
      this.setState({ roles: newRoles });
    }
  }

  handleRoleChange = (value) => {
    const roleNotSelected = !value;
    this.setState({
      roleId: value,
      errors: { ...this.state.errors, roleNotSelected },
    });
  };

  handleNotificationsChange = (type, value) => {
    const { selectedNotifications } = this.state;
    let newNotifications = value ? [...selectedNotifications, type] : selectedNotifications.filter((t) => t !== type);
    this.setState({ selectedNotifications: newNotifications });
  };

  handlePermissionsChange = (type, value) => {
    const { permissions } = this.state;
    let newPermissions = [];
    if (value) {
      newPermissions = [...permissions, type];
    } else {
      newPermissions = permissions.filter((t) => t !== type);
    }
    this.setState({ permissions: newPermissions });
  };

  hasErrors = () => {
    const { errors, firstName, lastName, email } = this.state;
    return (
      errors.firstName || errors.lastName || errors.email || !firstName || !lastName || !email || !isValidEmail(email)
    );
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    const { selectedNotifications, roleId, firstName, lastName, email, phone, accessClassicSite, permissions, userId } =
      this.state;
    const { createUser, addUserToBoard, history } = this.props;
    const { push } = history;

    if (this.hasErrors()) {
      return;
    }

    if (!roleId) {
      this.setState({
        errors: { ...this.state.errors, roleNotSelected: true },
      });
      return;
    }
    const user = {
      notifications: selectedNotifications,
      roleId,
      firstName,
      lastName,
      email,
      phone,
      accessClassicSite,
      permissions,
    };
    if (userId) {
      addUserToBoard(userId, user, {
        successCallback: () => push('/users/list'),
      });
    } else {
      createUser(user, { successCallback: () => push('/users/list') });
    }
  };

  renderBoardPermissions(roleId) {
    const { permissions } = this.state;
    const { roles } = this.props;
    const selectedRole = roles.find((rol) => rol._id === roleId);
    return selectedRole.extraPermissions.map((permission) => {
      return (
        <div key={permission._id}>
          <Checkbox
            onChange={(event) => {
              const value = event.target.checked;
              this.handlePermissionsChange(permission._id, value);
            }}
            value={permissions.includes(permission._id)}
            label={permission.name}
          />
        </div>
      );
    });
  }

  handleChange = (key) => (event) => {
    this.setState({ [key]: event.target.value });
  };

  validate =
    (key, permissions = []) =>
    (event) => {
      const value = event.target.value;

      if (permissions.indexOf('required') >= 0) {
        this.setError(key, !value ? 'This field is required' : '');
      }

      if (permissions.indexOf('email') >= 0) {
        this.setError(key, !isValidEmail(value) ? "It's not a valid email" : '');
      }
    };

  setError = (key, errorMessage) => {
    const { errors } = this.state;
    this.setState({ errors: { ...errors, [key]: errorMessage } });
  };

  render() {
    const { roleId, prepopulated, errors, selectedNotifications } = this.state;
    const { roles, notifications } = this.props;
    let rolesOptions = roles.map((rol) => ({
      value: rol._id,
      label: rol.name,
    }));

    rolesOptions = rolesOptions.map(({ value, label }) => (
      <option key={value} value={value} selected={roleId === value}>
        {label}
      </option>
    ));
    const selectedRole = roles.find((rol) => rol._id === roleId) || {};
    const selectedRolePermissions = selectedRole.extraPermissions || [];
    return (
      <Container className="create-user-container">
        <Row className="create-user-title" center>
          <Col xs={12} md={8}>
            Enter new user
          </Col>
        </Row>
        <Row center>
          <Col xs={12} md={8}>
            <form onSubmit={this.handleFormSubmit}>
              <div className="create-user-form">
                <Row className="create-user-form-section">
                  <Col xs={12} md={4} className="create-user-left-column">
                    User info
                  </Col>
                  <Col xs={12} md={8}>
                    <section className="create-user-inputs-section">
                      <TextField
                        label="First Name"
                        disabled={prepopulated}
                        value={this.state.firstName}
                        onBlur={this.validate('firstName', ['required'])}
                        onChange={this.handleChange('firstName')}
                        errorMessage={errors && errors.firstName ? errors.firstName : ''}
                      />
                      <TextField
                        label="Last Name"
                        disabled={prepopulated}
                        value={this.state.lastName}
                        onBlur={this.validate('lastName', ['required'])}
                        onChange={this.handleChange('lastName')}
                        errorMessage={errors && errors.lastName ? errors.lastName : ''}
                      />
                      <SingleSelect
                        label="Role"
                        id="assigned-user-dropdown"
                        className="create-user-roleDropdown main-index"
                        placeholder="Select..."
                        name="assigned-user"
                        onSelect={this.handleRoleChange}
                        required
                        errorMessage={errors.roleNotSelected ? 'You need to select a role' : null}
                      >
                        {rolesOptions}
                      </SingleSelect>
                    </section>
                  </Col>
                </Row>
                <Row className="create-user-form-section">
                  <Col xs={12} md={4} className="create-user-left-column">
                    Contact info
                  </Col>
                  <Col xs={12} md={8}>
                    <section className="create-user-inputs-section">
                      <TextField
                        label="Email"
                        disabled={prepopulated}
                        value={this.state.email}
                        onBlur={this.validate('email', ['required', 'email'])}
                        onChange={this.handleChange('email')}
                        errorMessage={errors && errors.email ? errors.email : ''}
                      />
                      <TextField
                        label="Phone number (optional)"
                        disabled={prepopulated}
                        value={this.state.phone}
                        onChange={this.handleChange('phone')}
                      />
                    </section>
                  </Col>
                </Row>
                {!prepopulated && (
                  <Row className="create-user-form-section">
                    <Col xs={12} md={4} className="create-user-left-column">
                      Access
                    </Col>
                    <Col xs={12} md={8}>
                      <section className="create-user-inputs-section">
                        <Checkbox
                          label="Classic site"
                          onChange={(event) =>
                            this.setState({
                              accessClassicSite: event.target.checked,
                            })
                          }
                        />
                      </section>
                    </Col>
                  </Row>
                )}
                {selectedRolePermissions.length > 0 && (
                  <Row className="create-user-form-section">
                    <Col xs={12} md={4} className="create-user-left-column">
                      Board info
                    </Col>
                    <Col xs={12} md={8}>
                      <section className="create-user-inputs-section">
                        <div className="create-user-board-checkboxes-group">
                          <label className="create-user-permissions-label text-light">
                            Permissions <span>(Check all that apply)</span>
                          </label>
                          {roleId && this.renderBoardPermissions(roleId)}
                        </div>
                      </section>
                    </Col>
                  </Row>
                )}
                <Row className="create-user-form-section">
                  <Col xs={12} md={4} className="create-user-left-column">
                    Notifications
                  </Col>
                  <Col xs={12} md={8}>
                    <section className="create-user-inputs-section text-light">
                      <NotificationsSection
                        notifications={notifications}
                        selectedNotifications={selectedNotifications}
                        onNotificationChange={this.handleNotificationsChange}
                      />
                    </section>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="create-user-buttonsSection">
                    <Button color="info" type="submit" size="large" className="create-user-submitButton">
                      SUBMIT
                    </Button>
                  </Col>
                </Row>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

CreateUsers.propTypes = {
  roles: PropTypes.array,
};

CreateUsers.defaultProps = {
  roles: [],
};

function mapStateToProps(state, ownProps) {
  const rs = rolesSelectors(state);
  const gns = notificationsSelectors(state);
  return {
    roles: rs.getEntitiesByPage(1),
    notifications: gns.getEntitiesByPage(1),
    ...ownProps,
  };
}

export default connect(mapStateToProps, {
  createUser: userActions.createUser,
  addUserToBoard: userActions.addUserToBoard,
  fetchRoles: rolesActions.fetchRoles,
})(CreateUsers);
