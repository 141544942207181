const { REACT_APP_AWS_S3_ACCESS_ID, REACT_APP_AWS_S3_SECRET_ACCESS, REACT_APP_AWS_S3_BUCKETNAME } = process.env;

const AWS = require('aws-sdk');

AWS.config.update({
  accessKeyId: REACT_APP_AWS_S3_ACCESS_ID,
  secretAccessKey: REACT_APP_AWS_S3_SECRET_ACCESS,
});

const s3 = new AWS.S3();

const getObject = (Key, callback) => {
  const params = { Bucket: REACT_APP_AWS_S3_BUCKETNAME, Key };
  s3.getObject(params, (error, data) => {
    // eslint-disable-next-line no-eq-null
    if (error != null) {
      throw error;
    } else {
      const type = 'text/csv;charset=utf-8;';
      const csvBlob = new Blob([data.Body.toString()], { type });
      // eslint-disable-next-line callback-return
      callback(csvBlob, Key);
    }
  });
};

module.exports = { getObject };
