import React from 'react';
import { Notification as N, NotificationStack as NS } from 'react-notification';

export const NotificationActiveBarStyles = (index, style) => ({
  ...style,
  fontSize: '14px',
  paddingTop: '17px',
  paddingRight: '32px',
  paddingBottom: '20px',
  paddingLeft: '21px',
  borderRadius: '3px',
  bottom: `${2 + index * 5.5}rem`,
});

export const NotificationActionStyles = (index, style) => ({
  ...style,
  fontSize: '12px',
  fontWeight: 'bold',
  marginLeft: '2rem',
});

export const Notification = (props) => <N {...props} />;

export const NotificationStack = (props) => (
  <NS {...props} activeBarStyleFactory={NotificationActiveBarStyles} barStyleFactory={NotificationActionStyles} />
);

export default Notification;
