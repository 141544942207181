import axios from 'axios';
import { API_URL } from 'config';

export function fetchExemptionsByProfession({ professionId, token }) {
  return axios.request({
    url: `${API_URL}/professions/${professionId}/exemptions`,
    method: 'get',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}
