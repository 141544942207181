import at from './types';

export const fetchLicenses = (filters) => ({ type: at.FETCH, filters });
export const fetchLicense = (licenseId) => ({ type: at.FETCH_ONE, licenseId });
export const fetchLicensesCount = (filters) => ({ type: at.FETCH_COUNT, filters });
export const fetchAccountType = (licenseId) => ({ type: at.FETCH_ACCOUNT_TYPE, licenseId });
export const fetchLicenseEmail = (licenseId) => ({ type: at.FETCH_EMAIL, licenseId });
export const updateLicenseEmail = (licenseId, email) => ({ type: at.UPDATE_EMAIL, licenseId, email });
export const resendLicenseEmail = (auditId, licenseId) => ({ type: at.RESEND_EMAIL, auditId, licenseId });

export const clear = () => ({ type: at.CLEAR, meta: {} });
export const clearEntities = () => ({ type: at.CLEAR_ENTITIES });
