import { call, put, select, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { normalize } from 'normalizr';
import * as api from 'api/users';

import isEmpty from 'lodash/isEmpty';

import * as schema from '../schema';
import at from '../actions/types';

import { selectors } from 'state/usersByState';

export function* fetchUsers(filters) {
  const state = yield select();
  const { authentication } = state;
  const stateId = state.selectedBoard.state.id;
  const boardId = state.selectedBoard.id;
  yield put({ type: at.FETCH_REQUEST, filters, meta: {} });
  try {
    yield call(delay, 300);
    let response;
    try {
      response = yield call(api.fetchByStateIdExcludingBoard, {
        stateId,
        boardId,
        filters,
        token: authentication.accessToken,
      });
    } catch (e) {
      console.log(e);
      yield put({ type: at.FETCH_FAILURE, filters, message: e.message || 'Something went wrong', meta: {} });
      return;
    }
    const { headers } = response;
    const totalItems = parseInt(headers['x-total-count'] || 0, 10);

    const normalizedData = normalize(response.data, schema.users);

    yield put({ type: at.FETCH_SUCCESS, ...normalizedData, filters, totalItems, meta: {} });
  } catch (error) {
    console.log('error', error);
  }
}

export function* fetchPrevAndNextIfNeeded(filters) {
  const state = yield select();
  const us = selectors(state);

  let { size, page } = filters;
  const [prevPage, nextPage] = [page - 1, page + 1];
  const { totalItems } = us.getPagination();

  const prevEntities = us.getEntitiesByPage(prevPage);
  const nextEntities = us.getEntitiesByPage(nextPage);

  if (isEmpty(prevEntities) && page > 1) {
    yield fork(fetchUsers, { ...filters, page: prevPage });
  }
  if (isEmpty(nextEntities) && page * size < totalItems) {
    yield fork(fetchUsers, { ...filters, page: nextPage });
  }
}

export function* watchFetchUsers({ filters, meta = {} }) {
  const { force = false } = meta;
  const state = yield select();
  const us = selectors(state);
  const currentEntities = us.getEntitiesByPage(filters.page);
  if (!force && !isEmpty(currentEntities)) {
    yield fork(fetchPrevAndNextIfNeeded, filters);
  }
  yield call(fetchUsers, filters);
  yield fork(fetchPrevAndNextIfNeeded, filters);
}
