import React from 'react';
import './styles.css';
import Body from './Body';
import Header from './Header';

const Table = ({ children, className, sort = '', onSort, ...rest }) => {
  const classes = `table-v2-table ${className}`;
  const newChildren = React.Children.map(children, (child) => {
    if (child.type.name === Body.name) {
      return React.cloneElement(child, rest);
    }
    if (child.type.name === Header.name) {
      return React.cloneElement(child, { sort, onSort });
    }
    return React.cloneElement(child);
  });
  return <div className={classes}>{newChildren}</div>;
};

Table.propTypes = {};

Table.defaultProps = {};

export default Table;
