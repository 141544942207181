import { connect } from 'react-redux';
import { actions as notificationsPanelActions } from '../../state/notificationsPanel';
import { actions as boardsAccessActions } from 'state/boardsAccess';
import { actions as notificationsActions } from 'state/globalNotifications';
import { selectors as boardAccessSelectors } from 'state/boardsAccess';
import { actions as rolesActions } from 'state/roles';
import { getNotificationsByTypes } from 'reducers';
import { logout, fetchProfessionsByBoard } from 'actions';
import App from './App';

function mapStateToProps(state) {
  let notifications = getNotificationsByTypes(state, [
    'CREATE_AUDIT',
    'ARCHIVE_AUDIT',
    'UPDATE_AUDIT',
    'UNARCHIVE_AUDIT',
    'CREATE_USER',
    'UPDATE_USER',
    'MESSAGE_SENT',
    'MESSAGE_UPDATED',
    'UPDATE_USER_EMAIL',
    'RESEND_AUDIT_INVITATION',
  ]);

  notifications = notifications.map((notification) => ({
    message: notification.text,
    key: notification.id,
    dismissAfter: 4500,
  }));

  const ubas = boardAccessSelectors(state);

  return {
    boardId: state.selectedBoard.id,
    notifications,
    authenticationStatus: state.authentication.status,
    boardAccess: ubas.getSelectedBoardAccess(),
    loggedInUser: state.loggedInUser,
  };
}

export default connect(mapStateToProps, {
  fetchUserBoardAccess: boardsAccessActions.fetchBoardAccess,
  fetchNotificationsPanel: notificationsPanelActions.fetchNotificationsPanel,
  fetchRoles: rolesActions.fetchRoles,
  fetchGlobalNotifications: notificationsActions.fetchGlobalNotifications,
  fetchProfessionsByBoard,
  logout,
})(App);
