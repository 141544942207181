import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Modal from 'emerald-ui-2/lib/Modal';
import './styles.css';

const NewUserModal = ({ isOpen, onClose }) => {
  return (
    <Modal show={isOpen} onHide={onClose} hideOnOutsideClick>
      <Modal.Body>
        <div className="new-user-modal-link-wrapper">
          <Link to="/users/search" onClick={onClose}>
            <i className="material-icons">search</i>Search existing users
          </Link>
        </div>
        <hr />
        <div className="new-user-modal-link-wrapper">
          <Link to="/users/new" onClick={onClose}>
            <i className="material-icons">add</i>Enter new user
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};

NewUserModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

NewUserModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

export default NewUserModal;
