import { createAction } from 'state/utils';

export default {
  ...createAction('users', 'UPDATE'),
  ...createAction('users', 'CREATE'),
  ...createAction('users', 'DELETE'),
  ...createAction('users', 'RESEND'),
  ...createAction('users', 'FETCH'),
  ...createAction('users', 'UPDATE_STATUS'),
  ...createAction('users', 'ADD_USER_TO_BOARD'),
  CLEAR: 'users/CLEAR',
  CLEAR_ENTITIES: 'users/CLEAR_ENTITIES',
  FETCH_CANCELED: 'users/FETCH_CANCELED',
};
