import uuidV4 from 'uuid';
import * as at from './types';

export function addNotification(notification, meta = {}, timeout) {
  return (dispatch) => {
    if (meta.showNotification !== false) {
      notification.id = uuidV4();
      dispatch({ type: at.ADD_NOTIFICATION, notification });
      if (timeout) {
        setTimeout(() => dispatch(removeNotification(notification.id)), timeout);
      }
    }
  };
}

export function removeNotification(id) {
  return { type: at.REMOVE_NOTIFICATION, id };
}

// export function addNotificationWithTimeout(notification, meta = {}, timeout = 5000) {
//   return addNotification(notification, meta, timeout)
// }

export function addSuccessNotification(notification, meta = {}, timeout = 5000) {
  return (dispatch) => {
    if (meta.notification && meta.notification.success) {
      notification.text = meta.notification.success;
    }
    dispatch(addNotification(notification, meta, timeout));
  };
}

export function addFailureNotification(notification, meta = {}, timeout = 5000) {
  return (dispatch) => {
    if (meta.notification && meta.notification.failure) {
      notification.text = meta.notification.failure;
    }
    dispatch(addNotification(notification, meta, timeout));
  };
}
