import { call, put, select } from 'redux-saga/effects';
// import { normalize } from 'normalizr';
import * as api from 'api/states';

// import * as schema from '../schema';
import at from '../actions/types';

export function* fetchStates() {
  const state = yield select();
  const { authentication } = state;
  yield put({ type: at.FETCH_REQUEST });
  let response;
  try {
    response = yield call(api.fetchStates, authentication.accessToken);
  } catch (e) {
    console.log(e);
    yield put({ type: at.FETCH_FAILURE, message: e.message || 'Something went wrong' });
    return;
  }
  yield put({ type: at.FETCH_SUCCESS, ...response.data });
}
