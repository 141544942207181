import { takeLatest } from 'redux-saga/effects';
import at from '../actions/types';

import { fetchNotifications } from './fetchNotifications';
import { markAllAsRead } from './markAllAsRead';

export default function* root() {
  yield takeLatest(at.MARK_ALL_AS_READ, markAllAsRead);
  yield takeLatest(at.FETCH, fetchNotifications);
}
