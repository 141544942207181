const env = process.env.REACT_APP_ENV || 'development';

// Production and shared settings
let config = {
  API_URL: 'http://api.boards.dev.cebroker.com:4000',
  APP_NAME: 'BS',
  LAUNCHPAD_URL: 'http://beta.dev.launchpad.cebroker.com',
  SECURE_URL: 'http://dev.secure.cebroker.com',
  HOME_URL: 'http://beta.dev.cebroker.com',
  STORAGE_API: 'https://test.storage.cebroker.com/cebroker',
  SOCKET_URL: 'http://api.boards.dev.cebroker.com',
  SOCKET_INTERVAL: 7000,
  BS_HOME: 'http://boards.dev.cebroker.com',
  SECURE_GATEWAY: 'http://dev.secure.cebroker.com/public/pb_auth_gateway.aspx',
  LEGACY_COOKIE_NAME: 'dev_cebtoken',
  COOKIE_NAME: 'dev_ceb_board_token',
  UPGRADE_ACCESS_BS: 'http://boards.dev.cebroker.com:3000',
  APM_SERVER_URL: 'https://c9b34aeaf1844e3ba4a4281022dff253.apm.us-east-1.aws.cloud.es.io:443',
  AWS_S3_ACCESS_ID: process.env.REACT_APP_AWS_S3_ACCESS_ID,
  AWS_S3_SECRET_ACCESS: process.env.REACT_APP_AWS_S3_SECRET_ACCESS,
  AWS_S3_BUCKETNAME: process.env.REACT_APP_AWS_S3_BUCKETNAME,
  INPUT_FORMAT_DATE: 'YYYY-MM-DD',
  LICENSEE_INTERNAL_API: "https://licensees.test.cebroker.com"
};

// Override settings according to the enviroment
switch (env) {
  case 'test':
    config.API_URL = 'https://api.boards.test.cebroker.com';
    config.LAUNCHPAD_URL = 'https://test.launchpad.cebroker.com';
    config.HOME_URL = 'https://test.cebroker.com';
    config.SECURE_URL = 'https://test.secure.cebroker.com';
    config.SOCKET_URL = 'https://api-boards-socket.test.cebroker.com';
    config.SOCKET_INTERVAL = 7000;
    config.BS_HOME = 'https://boards.test.cebroker.com';
    config.SECURE_GATEWAY = 'https://test.secure.cebroker.com/public/pb_auth_gateway.aspx';
    config.LEGACY_COOKIE_NAME = 'test_cebtoken';
    config.COOKIE_NAME = 'test_ceb_board_token';
    config.UPGRADE_ACCESS_BS = 'https://boards-eui2.test.cebroker.com';
    config.REACT_APP_ENABLE_LOG_ROCKET = true;
    config.REACT_APP_LOG_ROCKET_PROJECT_ID = 'g5aspn/board-suite-test';
    config.LICENSEE_INTERNAL_API = 'https://licensees.test.cebroker.com/api'
    break;
  case 'demo':
    config.API_URL = 'https://api.boards.demo.cebroker.com';
    config.LAUNCHPAD_URL = 'https://demo.launchpad.cebroker.com';
    config.HOME_URL = 'https://demo.cebroker.com';
    config.SECURE_URL = 'https://demo.secure.cebroker.com';
    config.SOCKET_URL = 'https://api-boards-socket.demo.cebroker.com';
    config.SOCKET_INTERVAL = 7000;
    config.BS_HOME = 'https://boards.demo.cebroker.com';
    config.SECURE_GATEWAY = 'https://demo.secure.cebroker.com/public/pb_auth_gateway.aspx';
    config.LEGACY_COOKIE_NAME = 'demo_cebtoken';
    config.COOKIE_NAME = 'demo_ceb_board_token';
    config.UPGRADE_ACCESS_BS = 'https://boards.demo.cebroker.com';
    config.REACT_APP_ENABLE_LOG_ROCKET = true;
    config.REACT_APP_LOG_ROCKET_PROJECT_ID = 'g5aspn/board-suite-test';
    config.LICENSEE_INTERNAL_API = 'https://licensees.demo.cebroker.com/api'
    break;
  case 'production':
    config.API_URL = 'https://api.boards.cebroker.com';
    config.LAUNCHPAD_URL = 'https://launchpad.cebroker.com';
    config.HOME_URL = 'https://cebroker.com';
    config.SECURE_URL = 'https://secure.cebroker.com';
    config.STORAGE_API = 'https://storage.cebroker.com/cebroker';
    config.SOCKET_URL = 'https://api-boards-socket.cebroker.com';
    config.SOCKET_INTERVAL = 7000;
    config.BS_HOME = 'https://boards.cebroker.com';
    config.SECURE_GATEWAY = 'https://secure.cebroker.com/public/pb_auth_gateway.aspx';
    config.LEGACY_COOKIE_NAME = 'cebtoken';
    config.COOKIE_NAME = 'ceb_board_token';
    config.UPGRADE_ACCESS_BS = 'https://boards-eui2.cebroker.com';
    config.AWS_S3_ACCESS_ID = '';
    config.AWS_S3_SECRET_ACCESS = '';
    config.AWS_S3_BUCKETNAME = '';
    config.REACT_APP_ENABLE_LOG_ROCKET = true;
    config.REACT_APP_LOG_ROCKET_PROJECT_ID = 'g5aspn/board-suite-7eyrl';
    config.LICENSEE_INTERNAL_API = 'https://licensees.cebroker.com/api'
    break;
  default:
}

export const API_URL = config.API_URL;
export const LICENSEE_INTERNAL_API = config.LICENSEE_INTERNAL_API
export const APP_NAME = config.APP_NAME;
export const STORAGE_API = config.STORAGE_API;
export const EXEMPTIONS_SHOW_OPTIONS = false;
export const INPUT_FORMAT_DATE = config.INPUT_FORMAT_DATE;
export default config;
