import { call, put, select } from 'redux-saga/effects';

import * as api from 'api/users';

import at from '../actions/types';
import { actions as notifications } from 'state/notifications';

export function* createUser({ data, meta }) {
  const state = yield select();

  const { authentication, selectedBoard } = state;
  yield put({ type: at.CREATE_REQUEST });
  const boardId = selectedBoard.id;
  const token = authentication.accessToken;
  try {
    yield call(api.create, data, boardId, token);
  } catch (e) {
    console.log(e);
    const message = e.response ? e.response.data.message : e.message;
    yield put({
      type: at.CREATE_FAILURE,
      data,
      message: message || 'Something went wrong',
    });

    // Add Failure notification
    const n = { type: 'CREATE_USER', text: `Error: ${message}` };
    yield put(notifications.addAndRemove(n, 5000));

    if (meta.errorCallback && typeof meta.errorCallback === 'function') {
      meta.errorCallback();
    }
    return;
  }

  // Add success notification
  const n = { type: 'CREATE_USER', text: `User ${data.firstName || ''} ${data.lastName || ''} created` };
  yield put(notifications.addAndRemove(n, 5000));

  yield put({ type: at.CREATE_SUCCESS, data });

  if (meta.successCallback && typeof meta.successCallback === 'function') {
    meta.successCallback();
  }
}
