import { createAction } from 'state/utils';

export default {
  ...createAction('transcripts', 'FETCH'),
  ...createAction('transcripts', 'UPDATE'),
  ...createAction('transcripts', 'UPDATE_LICENSEE_CLEAR'),
  ...createAction('transcripts', 'DELETE_WAIVER_SCENARIO'),
  FETCH_BLANK: 'transcripts/FETCH_BLANK',
  FETCH_CANCELED: 'transcripts/FETCH_CANCELED',
};
