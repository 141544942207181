import React from 'react';
import './styles.css';

const Header = ({ children, className }) => {
  const customClassName = className ? className : '';
  return (
    <div className={`floating-box-header ${customClassName}`} data-ref="floatingbox-header">
      {children}
    </div>
  );
};

export default Header;
