export function getFiltersFromQuery(query) {
  let { page, size, sort, term } = query;

  page = !isNaN(page) ? parseInt(page, 10) : null;
  size = !isNaN(size) ? parseInt(size, 10) : null;

  const filters = { page, size, sort };
  if (term) {
    filters.term = term;
  }
  return filters;
}

export function removePaginationFromFilters(filters) {
  const newFilters = { ...filters };
  delete newFilters.page;
  return newFilters;
}
