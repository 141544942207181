import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles.css';
import PostingDetailPopover from './PostingDetailPopover';
import IconDropdown from 'emerald-ui-2/lib/IconDropdown';
import DropdownItem from 'emerald-ui-2/lib/DropdownItem';
import EditHours from './EditHours';
import DisallowedDetailPopover from './DisallowedDetailPopover';
import * as utils from 'utils';

import { fetchPostCEcredit } from '../../actions';
import { selectors as boardAccessSelectors } from 'state/boardsAccess';
import { actions as transcriptsActions } from 'state/transcripts';

export class Transcript extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visiblePopover: '',
      visibleEditHours: false,
    };

    this.currentPost = {};
    this.licensePeriod = {};
    this.disallowInfo = [];
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { transcript = {} } = nextProps;
    const { licensePeriod = {} } = transcript;
    const { transcript: oldTranscript } = this.props;
    const { licensePeriod: oldLicensePeriod = {} } = oldTranscript;
    if (
      this.props.detailedTranscriptView !== nextProps.detailedTranscriptView ||
      this.state.visibleEditHours !== nextState.visibleEditHours ||
      licensePeriod.id !== oldLicensePeriod.id
    ) {
      return true;
    } else {
      return false;
    }
  }

  reloadTranscript = () => {
    const { transcript = {} } = this.props;
    const { licensePeriod = {} } = transcript;
    const { license } = licensePeriod;
    this.props.fetchTranscript(licensePeriod.id, license.number, license.id, license.profession.id, license.owner.id);
  };

  handleEditHours = (post, userId, licensePeriod, disallowInfo) => {
    this.setState({ visibleEditHours: true });
    this.fetchPostingDetails(post, userId);
    this.licensePeriod = licensePeriod;
    this.disallowInfo = disallowInfo;
  };

  closeEditHours = () => {
    this.reloadTranscript();
    this.setState({ visibleEditHours: false });
  };

  fetchPostingDetails = (post, userId) => {
    const { fetchPostCEcredit } = this.props;
    let { rosterAttendeeId, postCEcreditId, creditType } = post;
    if (fetchPostCEcredit) {
      this.currentPost = post;
      creditType = utils.getCreditType(creditType);
      fetchPostCEcredit(rosterAttendeeId, postCEcreditId, userId, creditType);
    }
  };

  getPostingsFromSubjectArea(
    subjectArea,
    subjectAreaIndex,
    scenario,
    isViewOnlyCompleted,
    isShowCreditCourseType,
    userId,
    licensePeriod,
    disallowInfo
  ) {
    const { creditCourses = [] } = subjectArea;
    const { boardAccess, detailedTranscriptView } = this.props;

    const permissions = boardAccess.permissions || [];
    const canDisallowHours = permissions.some((p) => p.code === 'DISALLOW_HOURS');

    const postings = [];

    let index = 0;
    for (const post of creditCourses) {
      // don't display courses that doesn't have hours applied in the transcript simple view
      //!subjectArea.isFlag: don't have in account subject areas, only courses (this is necessary since this function is used to display both, subjects and courses)
      if (!detailedTranscriptView && post.courseHoursApplied === 0 && !subjectArea.isFlag) continue;

      const transcriptMessage = post.messages.map((item) => (
        <div className="messages" key={item.message}>
          {item.message}
        </div>
      ));
      const reactHoursMessage =
        post.hoursToReact > 0
          ? `${post.hoursToReact} hour${post.hoursToReact > 1 ? 's' : ''} used for reactivation of license.`
          : '';

      const excessHoursMessage =
        post.excessHours > 0 &&
          scenario.type !== 'DISCIPLINARY' &&
          !isViewOnlyCompleted &&
          post.isDiscCourseManipulation === 0
          ? 'Excess Hours not applied:' + post.excessHours
          : '';

      let courseType = '';
      if (isShowCreditCourseType) {
        switch (post.courseType) {
          case 'CD_LIVE': {
            courseType = '(Live)';
            break;
          }
          case 'CD_ANYTIME': {
            courseType = '(Anytime)';
            break;
          }
          default: {
          }
        }
      }

      const disallow = disallowInfo ? disallowInfo.find((d) => d.rosterAttendeeId === post.rosterAttendeeId) : {};
      const postingId = `sb-${subjectAreaIndex}-posting-${index}-course-${post.courseId}`;
      const posting = (
        <tr
          className={`posting ${creditCourses.length === index + 1 ? 'lastPosting' : ''}`}
          key={Math.random(1, 999999999)}
        >
          <td>
            <div className="postName">
              <div className="postingDetailPopover">
                <PostingDetailPopover
                  post={post}
                  id={postingId}
                  onClick={() => this.fetchPostingDetails(post, userId)}
                />
                <div className="dateEarned text-lighter">{utils.parseDate(post.dateEarned)}</div>
                <div className="postingMoreOptions">
                  {canDisallowHours && (
                    <IconDropdown id="editHoursIcon" icon="more_horiz" className="text-light">
                      <DropdownItem onClick={() => this.handleEditHours(post, userId, licensePeriod, disallowInfo)}>
                        Edit Hours
                      </DropdownItem>
                    </IconDropdown>
                  )}
                </div>
              </div>
              {disallow && disallow.hoursToDisallow > 0 && <DisallowedDetailPopover disallow={disallow} />}
              {transcriptMessage}
              {excessHoursMessage !== '' ? <div className="messages">{excessHoursMessage}</div> : ''}
              {reactHoursMessage !== '' ? <div className="messages">{reactHoursMessage}</div> : ''}
            </div>
          </td>
          <td />
          {subjectArea.isFlag && <td>{'Yes'}</td>}
          {!subjectArea.isFlag && (
            <td>
              {post.hours}
              <div className="courseType">{courseType}</div>
            </td>
          )}
          <td>{ }</td>
        </tr>
      );

      postings.push(posting);
      index++;
    }

    return postings;
  }

  renderNormalTranscript() {
    const { transcript = {} } = this.props;
    const {
      items: subjectAreas = [],
      scenario = {},
      isViewOnlyCompleted,
      isShowCreditCourseType,
      licensePeriod,
      disallowInfo,
    } = transcript;
    const transcriptNormal = []; //Transcript hours requirements

    subjectAreas.forEach((item, index) => {
      const isRequired = item.isRequired ? 'Yes' : 'No';
      const isPosted = item.isPosted ? 'Yes' : 'No';
      const isNeeded = item.isNeeded ? 'Yes' : 'No';

      const subjectArea = (
        <tr className="subjectAreas text-light" key={Math.random(1, 999999999)}>
          <td>{item.name}</td>
          <td>{item.isFlag ? isRequired : item.requiredHours}</td>
          <td>{item.isFlag ? isPosted : item.postedHours}</td>
          <td>{item.isFlag ? isNeeded : item.outstandingHours}</td>
        </tr>
      );

      if (!item.isFlag) {
        transcriptNormal.push(subjectArea);

        const posting = this.getPostingsFromSubjectArea(
          item,
          index,
          scenario,
          isViewOnlyCompleted,
          isShowCreditCourseType,
          transcript.userId,
          licensePeriod,
          disallowInfo
        );
        transcriptNormal.push(posting);
      }

      const { children = [] } = item;
      children.forEach((subjectChild, childrenIndex) => {
        if (!subjectChild.isFlag) {
          const isRequiredSpeciality = subjectChild.isExemptionDependent && subjectChild.requiredHours > 0;

          const subjectChildren = (
            <tr className="posting subjectChildren text-light" key={Math.random(1, 999999999)}>
              <td>{subjectChild.name}</td>
              <td>{isRequiredSpeciality ? subjectChild.requiredHours : null}</td>
              <td>{isRequiredSpeciality ? subjectChild.postedHours : null}</td>
              <td>{isRequiredSpeciality ? subjectChild.outstandingHours : null}</td>
            </tr>
          );

          if (subjectChild.isVisible) transcriptNormal.push(subjectChildren);
          const posting = this.getPostingsFromSubjectArea(
            subjectChild,
            `${index}-${childrenIndex}`,
            scenario,
            isViewOnlyCompleted,
            isShowCreditCourseType,
            transcript.userId,
            licensePeriod,
            disallowInfo
          );
          transcriptNormal.push(posting);
        }
      });
    });

    const totalNormal = (
      <tr className="total text-light" key={Math.random(1, 999999999)}>
        <td>Total</td>
        <td>{transcript.totalHoursRequired}</td>
        <td>{transcript.totalHoursPosted}</td>
        <td>{transcript.totalHoursNeeded}</td>
      </tr>
    );
    transcriptNormal.push(totalNormal);

    const normalTranscriptTable = (
      <table className="transcriptTable">
        <thead>
          <tr className="header">
            <th className="subjectArea text-light">
              <div>Subject Area</div>
            </th>
            <th>Hours required</th>
            <th>Hours posted</th>
            <th>Hours needed</th>
          </tr>
        </thead>
        <tbody className="transcript-body">{transcriptNormal}</tbody>
      </table>
    );

    return normalTranscriptTable;
  }

  renderYesNoRequirements() {
    const { transcript = {} } = this.props;
    const {
      items: subjectAreas = [],
      scenario = {},
      isViewOnlyCompleted,
      isShowCreditCourseType,
      licensePeriod,
      disallowInfo,
    } = transcript;

    const transcriptFlag = []; //Transcript Yes/No requirements
    subjectAreas.forEach((item, index) => {
      if (item.isFlag) {
        const isRequired = item.isRequired ? 'Yes' : 'No';
        const isPosted = item.isPosted ? 'Yes' : 'No';
        const isNeeded = item.isNeeded ? 'Yes' : 'No';

        const subjectArea = (
          <tr className="subjectAreas text-light" key={Math.random(1, 999999999)}>
            <td>{item.name}</td>
            <td>{item.isFlag ? isRequired : item.requiredHours}</td>
            <td>{item.isFlag ? isPosted : item.postedHours}</td>
            <td>{item.isFlag ? isNeeded : item.outstandingHours}</td>
          </tr>
        );
        transcriptFlag.push(subjectArea);

        if (item.creditCourses.length > 0) {
          const posting = this.getPostingsFromSubjectArea(
            item,
            index,
            scenario,
            isViewOnlyCompleted,
            isShowCreditCourseType,
            transcript.userId,
            licensePeriod,
            disallowInfo
          );
          transcriptFlag.push(posting);
        }
      }

      const { children = [] } = item;
      children.forEach((subjectChild, childrenIndex) => {
        const subjectChildren = (
          <tr className="posting subjectChildren" key={Math.random(1, 999999999)}>
            <td>{subjectChild.name}</td>
            <td>{ }</td>
            <td>{ }</td>
            <td>{ }</td>
          </tr>
        );

        if (subjectChild.isFlag) {
          if (subjectChild.creditCourses.length > 0) {
            const posting = this.getPostingsFromSubjectArea(
              subjectChild,
              `${index}-${childrenIndex}`,
              scenario,
              isViewOnlyCompleted,
              isShowCreditCourseType,
              transcript.userId,
              licensePeriod,
              disallowInfo
            );
            transcriptFlag.push(posting);
          } else if (subjectChild.isVisible) {
            transcriptFlag.push(subjectChildren);
          }
        }
      });
    });

    const totalFlag = (
      <tr className="total" key={Math.random(1, 999999999)}>
        <td>Total Yes/No flag:</td>
        <td>{transcript.totalFlagsRequired}</td>
        <td>{transcript.totalFlagsPosted}</td>
        <td>{transcript.totalFlagsNeeded}</td>
      </tr>
    );

    const flagTranscriptTable = transcriptFlag.length > 0 && (
      <table className="transcriptTable flagTranscriptTable">
        <thead>
          <tr className="header">
            <th>
              <div>Subject Area</div>
            </th>
            <th>Required</th>
            <th>Posted</th>
            <th>Needed</th>
          </tr>
        </thead>
        <tbody className="transcript-body">
          {transcriptFlag}
          {totalFlag}
        </tbody>
      </table>
    );

    return flagTranscriptTable;
  }

  render() {
    return (
      <div className="transcript">
        {this.renderNormalTranscript()}
        {this.renderYesNoRequirements()}
        <EditHours
          show={this.state.visibleEditHours}
          post={this.currentPost}
          onHide={this.closeEditHours}
          licensePeriod={this.licensePeriod}
          disallowInfo={this.disallowInfo}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const ubas = boardAccessSelectors(state);

  return {
    boardAccess: ubas.getSelectedBoardAccess(),
  };
}

export default connect(mapStateToProps, {
  fetchPostCEcredit,
  fetchTranscript: transcriptsActions.fetchTranscript,
})(Transcript);