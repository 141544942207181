import axios from 'axios';
import { API_URL } from 'config';
import qs from 'querystring';
import { removeEmptyProperties } from 'utils';

export function createConversation(data, token, meta) {
  const { withMessage, message, generateActivity, feedId, board } = meta;
  return axios.request({
    url: `${API_URL}/conversations?withMessage=${withMessage}&generateActivity=${generateActivity}`,
    method: 'post',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: { data, message, feedId, board },
  });
}
export function fetchConversation(conversationId, token) {
  return axios.request({
    url: `${API_URL}/conversations/${conversationId}`,
    method: 'get',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}

export function fetchConversations(filters, token) {
  const cleanFilters = removeEmptyProperties(filters);
  return axios.request({
    url: `${API_URL}/conversations?expandMessages=true&${qs.encode(cleanFilters)}`,
    method: 'get',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}

export function updateConversationParticipant(conversationId, participantId, data, token) {
  return axios.request({
    url: `${API_URL}/conversations/${conversationId}/participants/${participantId}`,
    method: 'put',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data,
  });
}

export function updateConversationParticipants(conversationId, data, token) {
  return axios.request({
    url: `${API_URL}/conversations/${conversationId}/participants`,
    method: 'put',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data,
  });
}

export function addMessageToConversation(conversationId, data, token) {
  return axios.request({
    url: `${API_URL}/conversations/${conversationId}/messages`,
    method: 'post',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data,
  });
}
