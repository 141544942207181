
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

import { BrowserAgent } from '@newrelic/browser-agent/dist/esm/loaders/browser-agent'

import 'emerald-ui-2/lib/styles.css';
import 'emerald-ui-2/lib/styles-ceb.css';
import 'emerald-ui-2/lib/dark-theme-ceb.css';

import 'react-select/dist/react-select.css';
//Configured store and history for the project
import { store } from './configStore';
import { MainRoutes } from 'routes';
import config from 'config';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV === 'production') {
  ReactGA.initialize('UA-58010485-1', { titleCase: false, debug: true });
}

if (REACT_APP_ENV && REACT_APP_ENV !== "development") {
  const options = {
    init: { distributed_tracing: {  enabled: true },
            privacy: { cookies_enabled: true },
            ajax: { deny_list: ["bam.nr-data.net"] } },
    info: { beacon: "bam.nr-data.net", errorBeacon: "bam.nr-data.net", licenseKey: "NRBR-dc1916963c23c09faab", applicationID: "594453968", sa: 1 },
    loader_config: { accountID: "3968367", trustKey: "3716375", agentID: "594453968", licenseKey: "NRBR-dc1916963c23c09faab", applicationID: "594453968" }
  }

  new BrowserAgent(options);
}

if (config.REACT_APP_LOG_ROCKET_PROJECT_ID && config.REACT_APP_ENABLE_LOG_ROCKET) {
  LogRocket.init(config.REACT_APP_LOG_ROCKET_PROJECT_ID, {
    rootHostname: 'cebroker.com',
  });
  setupLogRocketReact(LogRocket);
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <MainRoutes />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
