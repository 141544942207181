import axios from 'axios';
import { PAGE_SIZE, getOffset } from 'constants.js';
import { API_URL } from 'config';
import querystring from 'querystring';

export function fetch({ url, boardId, filters = {} }) {
  if (url) return axios.get(url);
  const { size = PAGE_SIZE, page = 1, ...rest } = filters;
  const offset = getOffset(page, size);
  const newFilters = { ...rest, offset, limit: size };
  const queryString = querystring.stringify(newFilters);
  return axios.get(`${API_URL}/boards/${boardId}/users?${queryString}`);
}

export function getUsersByBoard(boardId) {
  return axios.get(`${API_URL}/boards/${boardId}/users`);
}

export function fetchByStateIdExcludingBoard({ stateId, boardId, token, filters = {} }) {
  const { size = PAGE_SIZE, page = 1, ...rest } = filters;
  const offset = getOffset(page, size);
  const newFilters = { ...rest, offset, limit: size, boardId };
  const queryString = querystring.stringify(newFilters);
  return axios.request({
    method: 'get',
    url: `${API_URL}/states/${stateId}/users?${queryString}`,
    headers: { authorization: `Bearer ${token}` },
  });
}

export function update({ userId, data, token }) {
  return axios.request({
    method: 'put',
    url: `${API_URL}/users/${userId}`,
    headers: { authorization: `Bearer ${token}` },
    data,
  });
}

export function addUserToBoard({ userId, boardId, data, token }) {
  return axios.request({
    method: 'put',
    url: `${API_URL}/boards/${boardId}/users/${userId}`,
    headers: { authorization: `Bearer ${token}` },
    data,
  });
}

export const create = (user, boardId, token) => {
  return axios.request({
    url: `${API_URL}/boards/${boardId}/users`,
    method: 'post',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: user,
  });
};

export const deleteUser = (userId, boardId, token) => {
  return axios.request({
    method: 'delete',
    url: `${API_URL}/users/${userId}`,
    headers: { authorization: `Bearer ${token}` },
    data: { boardId },
  });
};

export const resend = (userId, boardId, token) => {
  return axios.request({
    method: 'POST',
    url: `${API_URL}/boards/${boardId}/users/${userId}/resend-invitation`,
    headers: { authorization: `Bearer ${token}` },
  });
};

export const getAddress = (userId) => {
  return axios.request({
    method: 'GET',
    url: `${API_URL}/users/${userId}/address`,
  });
};

export function tieUsers(userId, tieToId, boardId, token) {
  return axios.request({
    method: 'put',
    url: `${API_URL}/boards/${boardId}/users/${userId}/tie`,
    headers: { authorization: `Bearer ${token}` },
    data: {
      tieToId,
    },
  });
}

export function untieUsers(userId, boardId, token) {
  return axios.request({
    method: 'put',
    url: `${API_URL}/boards/${boardId}/users/${userId}/untie`,
    headers: { authorization: `Bearer ${token}` },
  });
}
