import axios from 'axios';
import { API_URL } from 'config';

export const createCustomCycle = (licensePeriodId, data, token) => {
  return axios.request({
    url: `${API_URL}/license-periods/${licensePeriodId}/customCycle`,
    method: 'post',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const fetchScenario = ({ licensePeriodId, token }) => {
  return axios.request({
    url: `${API_URL}/license-periods/${licensePeriodId}/scenario`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};
