import { call, put, select } from 'redux-saga/effects';

import * as api from 'api/feed';
import at from '../actions/types';

export function* updateCommentInFeed({ feedId, commentId, text }) {
  const state = yield select();
  const { authentication } = state;
  yield put({ type: at.UPDATE_COMMENT_REQUEST, feedId });
  try {
    let response;
    try {
      response = yield call(api.updateCommentInFeed, feedId, commentId, text, authentication.accessToken);
    } catch (e) {
      console.log(e);
      yield put({
        type: at.UPDATE_COMMENT_FAILURE,
        feedId,
        commentId,
        message: e.message || 'Something went wrong',
      });
      return;
    }

    yield put({
      type: at.UPDATE_COMMENT_SUCCESS,
      feedId,
      commentId,
      comment: response.data,
    });
  } catch (error) {
    console.log('error', error);
  }
}
