import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import SkeletonLoader from 'emerald-ui-2/lib/SkeletonLoader';
import { useImageLoad } from 'hooks/common';

import './styles.css';

const SkeletonImage = ({ src, alt }) => {
  const refImage = useRef();

  const [loaded] = useImageLoad(refImage);

  return (
    <React.Fragment>
      <SkeletonLoader loading={!loaded} width="143px" height={loaded ? 'auto' : '40px'} className="loader-logo">
        <img ref={refImage} src={src} className={`external-logo ${loaded && 'loaded'}`} alt={alt} />
      </SkeletonLoader>
    </React.Fragment>
  );
};

SkeletonImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default SkeletonImage;
