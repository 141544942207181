import { combineReducers } from 'redux';
import at from '../actions/types';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return { ...state, ...action.boardProfile };
    }
    default:
      return state;
  }
};

export const result = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return action;
    }
    default:
      return state;
  }
};

export const isFetching = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      return true;
    }
    case at.FETCH_SUCCESS: {
      return false;
    }
    case at.FETCH_FAILURE: {
      return false;
    }
    default:
      return state;
  }
};

export const errorMessage = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_FAILURE: {
      return action.message;
    }
    case at.FETCH_REQUEST: {
      return '';
    }
    case at.FETCH_SUCCESS: {
      return '';
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  result,
  isFetching,
  errorMessage,
});
