import axios from 'axios';
import { API_URL } from '../config';

export function fetchBoards(token) {
  return axios.request({
    url: `${API_URL}/me/boards`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}

export function fetchBoard(boardId, token) {
  return axios.request({
    url: `${API_URL}/boards/${boardId}`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}

export function updateBoardProfile(board, token) {
  return axios.request({
    url: `${API_URL}/boards/${board.boardId}`,
    method: 'POST',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: board,
  });
}
