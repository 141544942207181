import { call, put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';
// import isEmpty from 'lodash/isEmpty';

import * as api from 'api/conversations';
import at from '../actions/types';
import * as schema from '../schema';

export function* fetchConversation({ conversationId }) {
  const state = yield select();
  const { authentication } = state;
  yield put({ type: at.FETCH_ONE_REQUEST, conversationId });
  try {
    yield call(delay, 300);
    let response;
    try {
      response = yield call(api.fetchConversation, conversationId, authentication.accessToken);
    } catch (e) {
      console.log(e);
      yield put({
        type: at.FETCH_ONE_FAILURE,
        conversationId,
        message: e.message || 'Something went wrong',
        meta: {},
      });
      return;
    }

    const normalizedData = normalize(response.data, schema.conversation);
    yield put({
      type: at.FETCH_ONE_SUCCESS,
      ...normalizedData,
      conversationId,
      meta: {},
    });
  } catch (error) {
    console.log('error', error);
  }
}
