import at from '../actions/types';

export const downloadReport = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_DOWNLOAD_REPORTS_REQUEST:
    case at.FETCH_DOWNLOAD_REPORTS_FAILURE: {
      return 0;
    }
    case at.FETCH_DOWNLOAD_REPORTS_SUCCESS: {
      return action.downloadReport;
    }
    case at.CLEAR_DOWNLOAD_REPORTS: {
      return 0;
    }
    default:
      return state;
  }
};

export default downloadReport;
