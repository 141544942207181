import React, { Component } from 'react';
import { connect } from 'react-redux';

import ConversationsList from '../ConversationsList';

import { selectors as conversationsSelectors, common as conversationsCommon } from 'state/conversations';

import { actions as commonActions, selectors as commonSelectors } from 'state/common';

export class InboxConversationsList extends Component {
  fetchMoreConversations = () => {
    const { filters, updateFilters } = this.props;
    const { limit } = filters;
    updateFilters({
      offsetInbox: limit,
      limitInbox: limit + 10,
    });
  };

  render() {
    const { filters, conversations, fetchStatus, totalCount, onConversationClick } = this.props;

    return (
      <ConversationsList
        {...{
          conversations,
          isFetching: fetchStatus === 'loading',
          onConversationClick,
          emptyMessage: 'Your inbox is clean :)',
          isScrollEnabled: filters.tab === 'inbox' && conversations.length < totalCount,
          onBottomReached: this.fetchMoreConversations,
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  const conversations = conversationsSelectors(state);
  const common = commonSelectors(state);
  return {
    filters: common.getUrlParamsWith(conversationsCommon.getFiltersFromQuery),
    conversations: conversations.getInboxConversations(),
    fetchStatus: conversations.getInboxFetchStatus(),
    totalCount: conversations.getInboxTotalCount(),
  };
}

export default connect(mapStateToProps, {
  updateFilters: commonActions.updateFilters,
})(InboxConversationsList);
