import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import './styles.css';
import LoaderOverlay from './LoaderOverlay';
import BlankState from './BlankState';

const Body = ({ children, fixedHeight, height, showLoading, loadingText, showBlankState, blankStateText }) => {
  let h = fixedHeight && height ? height : 'auto';
  if ((showLoading || showLoading) && h === 'auto') {
    h = 500;
  }
  return (
    <div className="table-v2-body">
      {showBlankState && <BlankState text={blankStateText} />}

      {showLoading && <LoaderOverlay text={loadingText} />}

      {!fixedHeight && <div style={{ height: h }}></div>}

      {fixedHeight ? <Scrollbars style={{ height: h }}>{children}</Scrollbars> : children}
    </div>
  );
};

Body.propTypes = {
  fixedHeight: PropTypes.bool,
  height: PropTypes.number,
  showBlankState: PropTypes.bool,
  blankStateText: PropTypes.string,
  showLoading: PropTypes.bool,
  loadingText: PropTypes.string,
};

Body.defaultProps = {
  fixedHeight: true,
  height: 500,
  showBlankState: false,
  showLoading: false,
};

export default Body;
