import * as at from '../actions/types';

import { types as newAT } from 'state/notifications';

export const notifications = (state = [], action) => {
  switch (action.type) {
    case at.ADD_NOTIFICATION:
    case newAT.ADD:
      return [...state, action.notification];
    case at.REMOVE_NOTIFICATION:
    case newAT.REMOVE:
      return state.filter((notification) => notification.id !== action.id);
    default:
      return state;
  }
};

// Returns one notification that match with just one type
export const getOneByType = (state = [], type) => state.find((notification) => notification.type === type);

// Returns an array the notifications that match with one of the type provided
export const getByTypes = (state = [], types = []) =>
  state.filter((notification) => types.some((type) => notification.type === type));

export default notifications;
