import at from './types';

export const fetchNotificationsPanel = () => ({ type: at.FETCH });

export const toggleNotificationsPanel = () => {
  return (dispatch) => {
    dispatch({ type: at.TOGGLE_NOTIFICATIONS_PANEL });
    dispatch({ type: at.MARK_ALL_AS_READ });
    dispatch(fetchNotificationsPanel());
  };
};
