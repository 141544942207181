import { takeLatest, put } from 'redux-saga/effects';
// import { LOCATION_CHANGE } from 'react-router-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import at from '../actions/types';
import * as actions from '../actions';

import { watchFetchUsers } from './fetchUsers';

import { getFiltersFromQuery, removePaginationFromFilters } from '../common';

// This method verify some condition to know if with should fetch the audits
// this allows to make the fetch only when it is needed to avoid to waste resources
export const usersPageMemory = (() => {
  let previousFilters = {};
  function* usersPage({ payload }) {
    if (isEmpty(payload.query)) {
      return;
    }

    const filters = getFiltersFromQuery(payload.query);
    if (!isEqual(removePaginationFromFilters(filters), removePaginationFromFilters(previousFilters))) {
      yield put(actions.clear());
    }
    previousFilters = filters;

    yield put(actions.fetchUsers(filters));
  }
  return usersPage;
})();

// export function* watchLocationChange({ payload }) {
//   // We call the apropiate function depending on what page we are
//   if (/users\/search\/?$/.test(payload.pathname)) {
//     yield call(usersPageMemory, { payload });
//     return;
//   }
// };

// export function* watchSelectedBoardChange({ payload }) {
//   const state = yield select();
//   const { query, pathname } = state.routing.locationBeforeTransitions;

//   yield put(actions.clearEntities());
//   yield put(actions.clear(''));

//   if (/users\/search\/?$/.test(pathname)) {
//     const filters = getFiltersFromQuery(query);
//     yield put(actions.fetchUsers(filters, { force: true }));
//   }
// }

export default function* root() {
  // yield takeLatest(LOCATION_CHANGE, watchLocationChange);
  // yield takeLatest('SELECT_BOARD', watchSelectedBoardChange);

  yield takeLatest(at.FETCH, watchFetchUsers);
}
