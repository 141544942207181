import { call, put, select } from 'redux-saga/effects';
import * as api from 'api/users';

import at from '../actions/types';

export function* resendInvitation({ user, meta = {} }) {
  const state = yield select();
  const { authentication } = state;
  const boardId = state.selectedBoard.id;
  const userId = user.id;
  yield put({ type: at.RESEND_REQUEST, meta });
  try {
    try {
      yield call(api.resend, userId, boardId, authentication.accessToken);
    } catch (e) {
      console.log(e);
      yield put({ type: at.RESEND_FAILURE, message: e.message || 'Something went wrong', meta });
      return;
    }
    yield put({ type: at.RESEND_SUCCESS, message: 'Email invitation sent', meta });
  } catch (error) {
    console.log('error', error);
  }
}
