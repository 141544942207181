import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import has from 'lodash/has';
import Icon from 'emerald-ui-2/lib/Icon';
import Avatar from 'emerald-ui-2/lib/Avatar';
import IconDropdown from 'emerald-ui-2/lib/IconDropdown';
import DropdownItem from 'emerald-ui-2/lib/DropdownItem';
import Radio from 'emerald-ui-2/lib/Radio';
import Popover from 'emerald-ui-2/lib/Popover';
import OverlayTrigger from 'emerald-ui-2/lib/OverlayTrigger';
import './styles.css';

import { actions as conversationsActions } from 'state/conversations';
import { selectors as boardAccessSelectors } from 'state/boardsAccess';

import routerUtils from 'utils/router';

import { LOGGED_USER_ROLES } from '../../../../constants';

const ConversationRow = ({
  conversation,
  loggedInUserId,
  onClick,
  className,
  onCompleteButtonClick,
  onGoToDetailClick,
  push,
  boardAccess,
}) => {
  const handleGoToDetailClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onGoToDetailClick(conversation);
  };

  const handleCompleteClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onCompleteButtonClick(conversation);
  };

  const participantsNames = conversation.participants
    .filter(({ user }) => user.type !== 'BOARD')
    .map(({ user }) => (parseInt(user.id, 10) === parseInt(loggedInUserId, 10) ? 'Me' : user.name))
    .join(', ');

  const isOpened = conversation.participants.some(
    (p) => (p.user.type === 'BOARD' || p.user.type === 'BOARD_USER') && p.opened
  );

  const unreadClass = isOpened ? 'text-light' : 'text-info';
  const messages = conversation.messages;
  const firstMessage = messages[0];
  const lastMessage = messages[messages.length - 1];

  let user = lastMessage.sentBy;
  let subject = firstMessage.subject;
  let content = lastMessage.text;

  const isComplete = conversation.participants.some((p) => p.completed);
  const hasAttachment = conversation.messages.some((m) => m.attachments.length > 0);

  const permissions = boardAccess.permissions || [];
  const canMarkAsCompleted = permissions.some((p) => p.code === 'INBOX_MARK_AS_COMPLETED');

  return (
    <div className={`conversation-row-message-inbox ${className}`} onClick={() => onClick(conversation)}>
      <Avatar size="sm" title={user.name} className="conversation-row-avatar" />

      {hasAttachment ? (
        <Icon name="attach_file" className="conversation-row-attachment-icon text-lighter" />
      ) : (
        <div className="conversation-row-empty-icon" />
      )}

      <p className={`conversation-row-ellipsis conversation-row-participants text-light`}>{participantsNames}</p>

      <OverlayTrigger
        triggerType="hover"
        placement="right"
        className="conversation-row-content"
        overlay={<Popover id="messageBoxTooltip">{content}</Popover>}
      >
        <div className="conversation-row-content conversation-row-content-wrapper ">
          <h4 className={`conversation-row-ellipsis ${unreadClass}`}>{subject}</h4>
          <p className="conversation-row-divider"> - </p>
          <p className={`conversation-row-ellipsis conversation`}>{content}</p>
        </div>
      </OverlayTrigger>

      <p className="conversation-row-date text-lighter">{moment(conversation.updatedAt).format('DD MMM YY h:mma')}</p>
      {canMarkAsCompleted && (
        <div id="" title={isComplete ? 'Mark as not complete' : 'Mark as complete'} onClick={handleCompleteClick}>
          <Radio id="mark-as-completed-converstaion-list" className="conversation-row-radio-button" />
        </div>
      )}

      <IconDropdown id="menu" icon="more_vert" onClick={(e) => e.stopPropagation()}>
        <DropdownItem onClick={handleGoToDetailClick}>Go to detail</DropdownItem>
      </IconDropdown>
    </div>
  );
};

class ConnectedConversationRow extends React.Component {
  handleGoToDetailClick = (conversation) => {
    const { history } = this.props;
    if (has(conversation, 'data.startedFromPage')) {
      switch (conversation.data.startedFromPage) {
        case 'AUDIT_DETAIL_PAGE': {
          history.push(`/audits/${conversation.data.auditId}`);
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  handleCompleteButtonClick = (conversation) => {
    const { loggedInUser, boardAccess, updateConversationParticipant, updateConversationParticipants } = this.props;
    const loggedUserRole = boardAccess.role;
    const participant = conversation.participants.find((p) => Number(p.user.id) === Number(loggedInUser.id)) || {};

    const query = routerUtils(this.props.history).getQueryParams();

    const meta = {
      query,
      withMessage: true,
      message: 'Message updated successfully.',
    };

    const { ADMINISTRATOR, STANDARD } = LOGGED_USER_ROLES;

    if (loggedUserRole && (loggedUserRole.name === ADMINISTRATOR || loggedUserRole.name === STANDARD)) {
      const isComplete = conversation.participants.some((p) => p.completed);
      updateConversationParticipants(conversation._id, { completed: !isComplete }, meta);
    } else {
      let completed = !participant.completed;
      updateConversationParticipant(conversation._id, loggedInUser.id, { completed }, meta);
    }
  };

  render() {
    const { loggedInUser, ...rest } = this.props;
    return (
      <ConversationRow
        {...{
          onCompleteButtonClick: this.handleCompleteButtonClick,
          onGoToDetailClick: this.handleGoToDetailClick,
          loggedInUserId: loggedInUser.id,
          ...rest,
        }}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  let loggedInUser = state.loggedInUser;
  const boardAccess = boardAccessSelectors(state);
  return {
    loggedInUser,
    boardAccess: boardAccess.getSelectedBoardAccess(),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    updateConversationParticipant: conversationsActions.updateConversationParticipant,
    updateConversationParticipants: conversationsActions.updateConversationParticipants,
    push,
  })(ConnectedConversationRow)
);
