import { call, put, select } from 'redux-saga/effects';

import * as api from 'api/feed';
import at from '../actions/types';
import { types as auditAT } from 'state/audits';

export function* createFeed({ meta }) {
  const state = yield select();
  const { addToAudit = false, auditId = '' } = meta;
  const { authentication } = state;
  const boardId = state.selectedBoard.id;

  yield put({ type: at.CREATE_REQUEST });
  try {
    let response;
    let data = {
      name: 'audit-detail',
      data: {
        addToAudit,
        auditId,
      },
    };
    try {
      response = yield call(api.create, boardId, data, authentication.accessToken);
    } catch (e) {
      console.log(e);
      yield put({
        type: at.CREATE_FAILURE,
        message: e.message || 'Something went wrong',
      });
      return;
    }
    const feed = response.data;
    yield put({ type: at.CREATE_SUCCESS, feed });
    if (addToAudit) {
      yield put({ type: auditAT.UPDATE_ROOM_ID, auditId, roomId: feed._id });
    }
  } catch (error) {
    console.log('error', error);
  }
}
