import { call, put, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import * as schema from '../schema';

import * as api from 'api/feed';
import at from '../actions/types';

export function* fetchFeed({ feedId }) {
  const state = yield select();
  const { authentication } = state;
  yield put({ type: at.FETCH_REQUEST, feedId });
  try {
    let response;
    try {
      response = yield call(api.fetch, feedId, authentication.accessToken);
    } catch (e) {
      console.log(e);
      yield put({
        type: at.FETCH_FAILURE,
        message: e.message || 'Something went wrong',
      });
      return;
    }

    const normalizedData = normalize(response.data, schema.feed);
    yield put({ type: at.FETCH_SUCCESS, feedId, ...normalizedData });
  } catch (error) {
    console.log('error', error);
  }
}
