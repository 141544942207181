import { FETCH_ACCOUNT_REQUEST, FETCH_ACCOUNT_SUCCESS, FETCH_ACCOUNT_FAILURE } from '../../../actions/types';

import { combineReducers } from 'redux';

export const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_REQUEST:
      return true;
    case FETCH_ACCOUNT_SUCCESS:
    case FETCH_ACCOUNT_FAILURE:
      return false;
    default:
      return state;
  }
};

export const fetchSucceed = (state = false, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_SUCCESS:
      return true;
    case FETCH_ACCOUNT_FAILURE:
    case FETCH_ACCOUNT_REQUEST:
      return false;
    default:
      return state;
  }
};

export const fetchFailed = (state = '', action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_FAILURE:
      return 'error';
    case FETCH_ACCOUNT_REQUEST:
    case FETCH_ACCOUNT_SUCCESS:
      return '';
    default:
      return state;
  }
};

const fetch = combineReducers({
  isFetching,
  fetchSucceed,
  fetchFailed,
});

export default fetch;
