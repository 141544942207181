import { combineReducers } from 'redux';

import at from '../actions/types';

const defaultEntity = {
  role: {},
  notifications: [],
  permissions: [],
};

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_ONE_SUCCESS: {
      return { ...state, ...action.entities.boardAccess };
    }
    case at.FETCH_ONE_FAILURE: {
      const { filters } = action;
      return { ...state, [filters.boardId]: defaultEntity };
    }
    default:
      return state;
  }
};

export const entityFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_ONE_REQUEST: {
      const { boardId } = action.filters;
      return { ...state, [boardId]: 'loading' };
    }
    case at.FETCH_ONE_SUCCESS: {
      const { boardId } = action.filters;
      return { ...state, [boardId]: 'loaded' };
    }
    case at.FETCH_ONE_FAILURE: {
      const { boardId } = action.filters;
      return { ...state, [boardId]: 'failed' };
    }
    case at.FETCH_ONE_CANCELED: {
      const { boardId } = action.filters;
      return { ...state, [boardId]: 'canceled' };
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  entityFetchStatus,
});
