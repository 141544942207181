import React from 'react';
import { connect } from 'react-redux';

import SearchForm from 'emerald-ui-2/lib/SearchForm';
import Toolbar from 'emerald-ui-2/lib/Toolbar';
import debounce from 'lodash/debounce';

import FiltersBadge from 'components/FiltersBar/FiltersBadge';

import './styles.css';

class ConversationsFiltersBar extends React.Component {
  state = { term: '' };

  componentDidMount() {
    this.setState({ term: this.props.filters.q });
  }

  amountAppliedFilters = () => {
    const { filters } = this.props;
    let amout = 0;
    if (filters.lastMessageSentStartDate) {
      amout++;
    }
    if (filters.lastMessageSentEndDate) {
      amout++;
    }
    if (filters.myMessages) {
      amout++;
    }
    return amout;
  };

  debouncedUpdateFilters = debounce((filters) => {
    const { updateFilters } = this.props;
    updateFilters({
      offsetInbox: 0,
      limitInbox: 20,
      offsetCompleted: 0,
      limitCompleted: 20,
      offsetSent: 0,
      limitSent: 20,
      ...filters,
    });
  }, 0);

  handleSearchInputChange = (e) => {
    const term = e.target.value;
    this.debouncedUpdateFilters({ q: term });
  };

  render() {
    const { filters, onFiltersIconClick } = this.props;
    return (
      <div className="eui-table-wrapper">
        <Toolbar className="search-conversation-term">
          <SearchForm
            clearable
            value={filters.q || ''}
            onChange={this.handleSearchInputChange}
            placeholder="Search messages"
          />
          <FiltersBadge amount={this.amountAppliedFilters()} onFiltersClick={onFiltersIconClick} />
        </Toolbar>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps, {})(ConversationsFiltersBar);
