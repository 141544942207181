import React from 'react';
import './styles.css';

const FloatingBox = ({ children, className, fixed, customTop = 194, customFixedClass = '' }) => {
  let boxRef = '';
  let floatingBoxClass = 'floating-box';

  if (fixed) window.addEventListener('scroll', handleScroll);

  function handleScroll() {
    if (boxRef) {
      const topNavOffset = customTop;
      const scrollY = window.pageYOffset - topNavOffset;
      const boxY = boxRef.getBoundingClientRect().top;
      if (scrollY >= boxY) {
        boxRef.classList.add('floating-box-fix-box');
        if (customFixedClass) {
          boxRef.classList.add(customFixedClass);
        }
      } else {
        boxRef.classList.remove('floating-box-fix-box');
        if (customFixedClass) {
          boxRef.classList.remove(customFixedClass);
        }
      }
    }
  }

  return (
    <section ref={(box) => (boxRef = box)} className={`${floatingBoxClass} ${className}`}>
      {children}
    </section>
  );
};

export default FloatingBox;
