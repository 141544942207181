import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actions as boardUserActions } from 'state/boardUser';

import { selectors as boardAccessSelectors } from 'state/boardsAccess';
import { selectors as boardUsersSelectors } from 'state/boardUser';
import { selectors as notificationsSelectors } from 'state/globalNotifications';
import RedirectButton from 'components/RedirectButton';
import './styles.css';

import Container from 'emerald-ui-2/lib/Container';
import PageHeader from 'emerald-ui-2/lib/PageHeader';
import Button from 'emerald-ui-2/lib/Button';
import Checkbox from 'emerald-ui-2/lib/Checkbox';
import TextField from 'emerald-ui-2/lib/TextField';
import Panel from 'emerald-ui-2/lib/Panel';

export class BoardUserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boardUserProfile: null,
      selectedNotifications: [],
    };
  }

  componentWillMount() {
    const { fetchBoardUserProfile, loggedInUser } = this.props;
    fetchBoardUserProfile(loggedInUser.id);
  }

  componentWillReceiveProps(newProps) {
    const { boardUserProfile: newBoardUserProfile } = newProps;
    const { boardUserProfile } = this.props;

    if (boardUserProfile !== newBoardUserProfile) {
      this.setState({ boardUserProfile: newBoardUserProfile });
    }

    if (newProps.boardAccess && newProps.boardAccess.notifications) {
      this.setState({ selectedNotifications: newProps.boardAccess.notifications.map((n) => n._id) });
    }
  }

  handleInputChange = (boardUserProfile) => {
    this.setState({ boardUserProfile });
  };

  handleNotificationsChange = (type, value) => {
    const { selectedNotifications } = this.state;

    let newNotifications = value ? [...selectedNotifications, type] : selectedNotifications.filter((t) => t !== type);
    this.setState({ selectedNotifications: newNotifications });
  };

  renderChildrenNotification = (notificationId) => {
    const { notifications } = this.props;
    const children = notifications.filter((n) => n.parent === notificationId);
    const parentChecked = this.state.selectedNotifications.some((n) => n === notificationId);
    const display = parentChecked ? 'block' : 'none';
    return children.map((notification, index) => (
      <div style={{ marginLeft: '20px', display }} key={index}>
        <Checkbox
          key={notification._id}
          label={notification.name}
          onChange={(event) => {
            const value = event.target.checked;
            const type = notification._id;
            this.handleNotificationsChange(type, value);
          }}
          checked={this.state.selectedNotifications.some((n) => n === notification._id)}
        />
      </div>
    ));
  };

  renderGlobalNotifications() {
    const { notifications } = this.props;
    const mainNotifications = notifications.filter((n) => !n.parent);
    return mainNotifications.map((notification, index) => (
      <div key={index}>
        <div>
          <Checkbox
            key={notification._id}
            label={notification.name}
            onChange={(event) => {
              const value = event.target.checked;
              const type = notification._id;
              this.handleNotificationsChange(type, value);
            }}
            checked={this.state.selectedNotifications.some((n) => n === notification._id)}
          />
        </div>
        <div>{this.renderChildrenNotification(notification._id)}</div>
      </div>
    ));
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { boardUserProfile, selectedNotifications } = this.state;
    let { updateBoardUserProfile, selectedBoard } = this.props;
    boardUserProfile.boardId = selectedBoard.id;
    boardUserProfile.globalNotifications = selectedNotifications;
    const successText = `User ${boardUserProfile.firstName || ''} ${boardUserProfile.middleName || ''} ${
      boardUserProfile.lastName || ''
    } updated`;

    updateBoardUserProfile(boardUserProfile, { notification: { successText } });
  };

  render() {
    let { boardAccess, boardUserProfile } = this.props;
    const role = boardAccess.role || 'Read-only';
    let disabledForm = role.name === 'Read-only' ? true : false;

    const firstName = `${boardUserProfile.firstName || ''}`;
    const middleName = `${boardUserProfile.middleName || ''}`;
    const lastName = `${boardUserProfile.lastName || ''}`;
    const fullname = `${boardUserProfile.firstName || ''} ${boardUserProfile.middleName || ''} ${
      boardUserProfile.lastName || ''
    }`;
    const email = `${boardUserProfile.userEmail || ''}`;
    const phone = `${boardUserProfile.phone || ''}`;
    const phoneExt = `${boardUserProfile.phoneExt || ''}`;

    const receiveNotificationsByEmail = boardAccess.receiveNotificationsByEmail;

    return (
      <div>
        <PageHeader className="board-nav-header-bg">
          <RedirectButton />
          <p className="board-user-profile-header-title">My profile</p>
          <h2 className="board-user-profile-board-user-name">{fullname}</h2>
        </PageHeader>
        <Container>
          <Panel className="board-user-profile-container">
            <Panel.Body>
              <form id="form" method="POST" onSubmit={this.handleSubmit}>
                <div className="board-user-profile-submit-form">
                  <p>Saving will update your profile</p>
                  <Button type="submit" color="info" size="lg">
                    Save
                  </Button>
                </div>
                {/* My Profile Info*/}
                <div className="board-user-profile-section-form">
                  <div className="section-despcription text-lighter">
                    <h6>Profile Info</h6>
                    <p>Profile Information is not shared with CE Providers or Professionals.</p>
                  </div>
                  <div className="section-inputs">
                    <div className="board-user-profile-input-group">
                      <TextField
                        label="First name"
                        value={firstName}
                        onChange={(event) => {
                          const value = event.target.value;
                          boardUserProfile.firstName = value;
                          this.handleInputChange(boardUserProfile);
                        }}
                        disabled={disabledForm}
                      />
                    </div>
                    <div className="board-user-profile-input-group">
                      <TextField
                        label="Middle name"
                        value={middleName}
                        onChange={(event) => {
                          const value = event.target.value;
                          boardUserProfile.middleName = value;
                          this.handleInputChange(boardUserProfile);
                        }}
                        disabled={disabledForm}
                      />
                    </div>
                    <div className="board-user-profile-input-group">
                      <TextField
                        label="Last name"
                        value={lastName}
                        onChange={(event) => {
                          const value = event.target.value;
                          boardUserProfile.lastName = value;
                          this.handleInputChange(boardUserProfile);
                        }}
                        disabled={disabledForm}
                      />
                    </div>
                    <div className="board-user-profile-input-group">
                      <TextField
                        label="Email address"
                        value={email}
                        onChange={(event) => {
                          const value = event.target.value;
                          boardUserProfile.userEmail = value;
                          this.handleInputChange(boardUserProfile);
                        }}
                        disabled={disabledForm}
                      />
                    </div>
                    <div className="board-user-profile-input-group board-user-profile-two-inputs-group">
                      <TextField
                        label="Phone"
                        value={phone}
                        onChange={(event) => {
                          const value = event.target.value;
                          boardUserProfile.phone = value;
                          this.handleInputChange(boardUserProfile);
                        }}
                        disabled={disabledForm}
                      />
                      <div>
                        <TextField
                          label="Phone Ext"
                          value={phoneExt}
                          onChange={(event) => {
                            const value = event.target.value;
                            boardUserProfile.phoneExt = value;
                            this.handleInputChange(boardUserProfile);
                          }}
                          disabled={disabledForm}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="board-user-profile-section-form">
                  <div className="section-despcription text-lighter">
                    <h6>Email settings</h6>
                    <p>Decide whether or not you want to receive notifications by email</p>
                  </div>
                  <div className="section-inputs">
                    <div className="board-user-profile-input-group">
                      <Checkbox
                        key={boardAccess.userId}
                        label="Receive emails about notifications"
                        onChange={(event) => {
                          const value = event.target.checked;
                          boardUserProfile.receiveNotificationsByEmail = value;
                          this.handleInputChange({ ...boardUserProfile });
                        }}
                        defaultChecked={receiveNotificationsByEmail}
                      />
                    </div>
                  </div>
                </div>
                <div className="board-user-profile-section-form">
                  <div className="section-despcription text-lighter">
                    <h6>Global notifications</h6>
                    <p>
                      These options will allow the user to get notified of all activity in a certain area, by all users.
                      If you leave them unchecked, the user will only get notifications about things they've been
                      assigned to or messages they've been included in
                    </p>
                  </div>
                  <div className="section-inputs">
                    <div className="board-user-profile-input-group">{this.renderGlobalNotifications()}</div>
                  </div>
                </div>
                <div className="board-user-profile-submit-form">
                  <p>Saving will update your profile</p>
                  <Button type="submit" color="info" size="lg">
                    Save
                  </Button>
                </div>
              </form>
            </Panel.Body>
          </Panel>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const ubas = boardAccessSelectors(state);
  const bus = boardUsersSelectors(state);
  const gns = notificationsSelectors(state);
  const boardUserProfile = bus.getEntities();
  const loggedInUser = state.loggedInUser;

  return {
    loggedInUser,
    boardUserProfile,
    boardAccess: ubas.getSelectedBoardAccess(),
    selectedBoard: state.selectedBoard,
    notifications: gns.getEntitiesByPage(1),
    ...ownProps,
  };
}

export default connect(mapStateToProps, {
  fetchBoardUserProfile: boardUserActions.fetchBoardUserProfile,
  updateBoardUserProfile: boardUserActions.updateBoardUserProfile,
})(BoardUserProfile);

export const BoardUserProfileComponent = BoardUserProfile;
