import { takeLatest } from 'redux-saga/effects';

import at from '../actions/types';

import { watchFetchBoardAccess } from './fetchBoardAccess';

export default function* root() {
  yield takeLatest('SELECT_BOARD', watchFetchBoardAccess);
  yield takeLatest(at.FETCH_ONE, watchFetchBoardAccess);
}
