import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'emerald-ui-2/lib/Container';
import Row from 'emerald-ui-2/lib/Row';
import Col from 'emerald-ui-2/lib/Col';
import routerUtils from 'utils/router';

import { FiltersBar, Paginator } from 'components';
import { Table, Header, Row as TableRow, Col as TableCol } from 'components/Table/v2';
import TableBody from './TableBody';

import './styles.css';

import { selectors as usersSelectors, actions as userActions } from 'state/usersByState';

export class SearchUsers extends Component {
  state = {
    expandedRow: -1,
  };

  componentDidMount() {
    this.setDefaultsFiltersIfNeeded(this.props);
    this.fetchUsers();
  }

  componentDidUpdate(prevProps) {
    this.setDefaultsFiltersIfNeeded(this.props);
    if (this.props.selectedBoard.id !== prevProps.selectedBoard.id) {
      this.fetchUsers();
    }
    if (JSON.stringify(this.props.filters) !== JSON.stringify(prevProps.filters)) {
      this.fetchUsers();
    }
  }

  fetchUsers = () => {
    this.props.fetchUsers(this.props.filters);
  };

  setDefaultsFiltersIfNeeded = (props) => {
    const { filters, history } = props;
    if (!filters.page || !filters.size || !filters.sort || (!filters.term && filters.sort === 'search')) {
      routerUtils(history).updateQueryParams({
        page: 1,
        size: 10,
        sort: 'name',
      });
    }
  };

  handleToggleRow = (row, expand) => this.setState({ expandedRow: expand ? row : -1 });

  handlePageSizeChange = (size) => {
    const { history } = this.props;
    routerUtils(history).updateQueryParams({ size, page: 1 });
  };

  handleSortChange = (sort) => {
    const { history } = this.props;
    routerUtils(history).updateQueryParams({ sort });
  };

  handlePageChange = (dir) => {
    const { filters, history } = this.props;
    let { page } = filters;
    page = dir === 'prev' ? --page : ++page;
    page = page <= 0 ? 1 : page;
    routerUtils(history).updateQueryParams({ page });
  };

  handleRowClick = (userId) => {
    const { users, selectedBoard, history } = this.props;
    const user = users.find((u) => u.id === userId);
    const boards = user.states[selectedBoard.state.code].boards;
    if (boards.some((b) => b.id === selectedBoard.id)) {
      // alert('You can\'t do that man')
    } else {
      history.push(
        `/users/new?userId=${user.id}&firstName=${user.firstName}&lastName=${user.lastName}&email=${user.email}&phone=${
          user.phone || ''
        }`
      );
    }
  };

  handleTermChange = (term) => {
    const { history } = this.props;
    routerUtils(history).updateQueryParams({ term, sort: 'search', page: 1 });
  };

  render() {
    const { users, fetchStatus, filters, usersCount } = this.props;
    let { size, page, term, sort } = filters;

    const { expandedRow } = this.state;
    return (
      <Container className="search-user-container">
        <Row>
          <Col xs={12}>
            <FiltersBar term={term} onTermChange={this.handleTermChange} showFilterButton={false} />
            <Table className="search-user-table">
              <Header sort={sort} onSort={this.handleSortChange}>
                <TableRow>
                  <TableCol className="search-user-name-column" sorteable value="name">
                    Name
                  </TableCol>
                  <TableCol className="search-user-email-column">Email</TableCol>
                  <TableCol>Boards</TableCol>
                  <TableCol />
                </TableRow>
              </Header>
              <TableBody
                users={users}
                onToggleRow={this.handleToggleRow}
                expandedRow={expandedRow}
                onRowClick={this.handleRowClick}
                showLoading={fetchStatus === 'loading'}
                loadingText="Loading users"
                showBlankState={fetchStatus !== 'loading' && users.length === 0}
                blankStateText="No users found"
              />
            </Table>
            <Paginator
              page={page}
              size={size}
              total={usersCount}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

SearchUsers.propTypes = {};

SearchUsers.defaultProps = {};

function getFiltersFromQuery(query) {
  let { page, size, sort, term } = query;

  page = !isNaN(page) ? parseInt(page, 10) : null;
  size = !isNaN(size) ? parseInt(size, 10) : null;

  const filters = {};

  if (page) {
    filters.page = page;
  }
  if (size) {
    filters.size = size;
  }
  if (sort) {
    filters.sort = sort;
  }
  if (term) {
    filters.term = term;
  }

  return filters;
}

function mapStateToProps(state, ownProps) {
  const us = usersSelectors(state);
  const queryParams = routerUtils(ownProps.history).getQueryParams();
  const filters = getFiltersFromQuery(queryParams);

  return {
    users: us.getEntitiesByPage(filters.page),
    fetchStatus: us.getFetchStatusByPage(filters.page),
    filters,
    usersCount: us.getCount(),
    selectedBoard: state.selectedBoard,
  };
}

export default connect(mapStateToProps, {
  fetchUsers: userActions.fetchUsers,
})(SearchUsers);
