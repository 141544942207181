import { combineReducers } from 'redux';

import at from '../actions/types';
import inbox from './inbox';
import completed from './completed';
import sent from './sent';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_ONE_SUCCESS:
    case at.FETCH_SUCCESS: {
      return { ...state, ...action.entities.conversations };
    }
    case at.CREATE_SUCCESS: {
      const { conversation } = action;
      return { ...state, [conversation._id]: conversation };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    case at.UPDATE_PARTICIPANT_SUCCESS: {
      const { conversationId, participantId, data } = action;
      const conversation = { ...state[conversationId] };
      conversation.participants = conversation.participants.map((p) => {
        if (p.user.id === participantId) {
          if (data.opened !== undefined) {
            p.opened = data.opened;
          }
          if (data.completed !== undefined) {
            p.completed = data.completed;
          }
        }
        return p;
      });
      return { ...state, [conversation._id]: conversation };
    }
    case at.UPDATE_PARTICIPANTS_SUCCESS: {
      const { conversationId, data } = action;
      const conversation = { ...state[conversationId] };
      conversation.participants = conversation.participants.map((p) => {
        if (data.opened !== undefined) {
          p.opened = data.opened;
        }
        if (data.completed !== undefined) {
          p.completed = data.completed;
        }
        return p;
      });
      return { ...state, [conversation._id]: conversation };
    }
    case at.ADD_MESSAGE_SUCCESS: {
      const { conversationId, message } = action;
      const conversation = { ...state[conversationId] };
      conversation.messages = [...conversation.messages, message];
      return { ...state, [conversation._id]: conversation };
    }
    default:
      return state;
  }
};

export const entitiesFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_ONE_REQUEST: {
      return { ...state, [action.conversationId]: 'loading' };
    }
    case at.FETCH_ONE_SUCCESS: {
      return { ...state, [action.conversationId]: 'loaded' };
    }
    case at.FETCH_ONE_FAILURE: {
      return { ...state, [action.conversationId]: 'failed' };
    }
    case at.FETCH_ONE_CANCELED: {
      return { ...state, [action.conversationId]: 'canceled' };
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  entitiesFetchStatus,
  inbox,
  completed,
  sent,
});
