import axios from 'axios';
import { API_URL } from 'config';
import querystring from 'querystring';

export function fetch({ filters = {}, token }) {
  const queryString = querystring.stringify(filters);
  return axios.request({
    method: 'get',
    url: `${API_URL}/users-boards-access?${queryString}`,
    headers: { authorization: `Bearer ${token}` },
  });
}
