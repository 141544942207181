import React from 'react';
import PropTypes from 'prop-types';
import { BoxNotification } from 'components';
import './styles.css';
import Button from 'emerald-ui-2/lib/Button';

const OverlayConfirmationBox = ({
  visible,
  phrase1,
  phrase2,
  confirmButtomLabel,
  onCancel,
  onConfirm,
  scrollContent,
}) => {
  return (
    <BoxNotification
      visible={visible}
      className="confirmation-box"
      style={{ top: scrollContent > 0 ? `${scrollContent}px` : undefined }}
    >
      <div className="phrase">
        <h4 style={{ marginBottom: '1rem' }}>{phrase1}</h4>
        <h6 style={{ marginBottom: '4rem' }}>{phrase2}</h6>
      </div>
      <div className="action-buttons">
        <div className="cancel-button">
          <Button onClick={onCancel}>Cancel</Button>
        </div>
        <div className="confirm-Button">
          <Button onClick={onConfirm} color="info">
            {confirmButtomLabel}
          </Button>
        </div>
      </div>
    </BoxNotification>
  );
};

OverlayConfirmationBox.propTypes = {
  visible: PropTypes.bool,
  phrase1: PropTypes.string,
  phrase2: PropTypes.string,
  confirmButtomLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  scrollContent: PropTypes.number,
};

OverlayConfirmationBox.defaultProps = {
  visible: false,
  phrase1: 'This is a confirm box',
  phrase2: '',
  confirmButtomLabel: 'Procced',
  onCancel: () => {},
  onConfirm: () => {},
  scrollContent: 0,
};

export default OverlayConfirmationBox;
