import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Popover from 'emerald-ui-2/lib/Popover';
import Progressbar from 'emerald-ui-2/lib/Progressbar';
import Button from 'emerald-ui-2/lib/Button';
import Icon from 'emerald-ui-2/lib/Icon';
import { getObject } from '../../utils/s3';
import { useOutsideClick } from 'hooks/useOutsideClick';
import './styles.css';

export const DownloadPopover = ({ boardName, progress, url, onDownload, clickOutside, className }) => {
  const popoverRef = useRef(null);

  useOutsideClick(popoverRef, clickOutside);

  const downloadBlob = (blob, name = `${url}`) => {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = name;
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link, downloadBlob);
  };

  const handleDownload = () => {
    onDownload();
    getObject(url, downloadBlob);
  };

  const renderProgress = () => {
    if (isNaN(progress)) {
      return <Progressbar className="progress-popover" continuous />;
    }

    return <Progressbar className="progress-popover" progress={Number(progress)} />;
  };

  return (
    <div ref={popoverRef} className={`floater-popover ${className}`}>
      <Popover title={url ? 'Completed' : 'Generating'}>
        <p>{boardName}</p>
        {renderProgress()}
        <div className="wrapper-button-popover">
          <Button onClick={handleDownload} disabled={!url} className={!url ? 'hidden-button-popover' : ''}>
            <Icon name="file_download" />
            <span>Download</span>
          </Button>
        </div>
      </Popover>
    </div>
  );
};

DownloadPopover.propTypes = {
  boardName: PropTypes.string.isRequired,
  progress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  url: PropTypes.string,
  onDownload: PropTypes.func,
  clickOutside: PropTypes.func,
  className: PropTypes.string,
};

DownloadPopover.defaultProps = {
  progress: 'empty',
  url: '',
  onDownload: () => false,
  clickOutside: () => false,
  className: '',
};

export default DownloadPopover;
