import { connect } from 'react-redux';
import {
  actions as notificationsPanelActions,
  selectors as notificationsPanelSelectors,
} from '../../state/notificationsPanel';
import NotificationsPanel from './NotificationsPanel';

function mapStateToProps(state, ownProps) {
  const noti = notificationsPanelSelectors(state);
  return {
    notificationPanelOpened: state.notificationsPanel.opened,
    todayNotifications: noti.getEntitiesCreatedToday(),
    yesterdayNotifications: noti.getEntitiesCreatedYesterday(),
    weekNotifications: noti.getEntitiesCreatedThisWeek(),
    userLogonId: state.loggedInUser.id,
  };
}

export default connect(mapStateToProps, {
  toggleNotificationsPanel: notificationsPanelActions.toggleNotificationsPanel,
})(NotificationsPanel);
