import { combineReducers } from 'redux';
import at from '../actions/types';

export const requestDownloadPdf = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_PDF: {
      return { ...state };
    }
    case at.FETCH_PDF_SUCCESS: {
      return { ...state, ...action.data };
    }
    case at.FETCH_PDF_FAILURE: {
      return { ...state };
    }
    case at.CLEAR_REQUEST_PDF:
      return {}
    default:
      return state;
  }
};

export const requestPDFStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_PDF_STATUS: {
      return { ...state };
    }
    case at.FETCH_PDF_STATUS_SUCCESS: {
      return { ...state, ...action.data };
    }
    case at.FETCH_PDF_STATUS_FAILURE: {
      return { ...state };
    }
    case at.CLEAR_PDF_STATUS: {
      return {};
    }
    default:
      return state;
  }
};

export const requestBrowserDownload = (state = {}, action) => {
  switch (action.type) {
    case at.DOWNLOAD_PDF_BROWSER: {
      return { ...state };
    }
    case at.DOWNLOAD_PDF_BROWSER_SUCCESS: {
      return { ...state, ...action.data };
    }
    case at.DOWNLOAD_PDF_BROWSER_FAILURE: {
      return { ...state };
    }
    case at.CLEAR_REQUEST_BROWSER: {
      return {};
    }
    default:
      return state;
  }
};

export const requestUpdatePdfStatus = (state = {}, action) => {
  switch (action.type) {
    case at.UPDATE_PDF_STATUS_FAILURE: {
      return { ...state };
    }
    default:
      return state;
  }
};

export default combineReducers({
  requestDownloadPdf,
  requestPDFStatus,
  requestBrowserDownload,
  requestUpdatePdfStatus
});
