import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import Select from 'react-select/lib/Select';

class Dropdown extends Component {
  render() {
    const { type, className, ...rest } = this.props;
    const classes = `${className} ${styles[type]}`;
    return <Select {...rest} className={classes} />;
  }
}

Dropdown.propTypes = {
  type: PropTypes.string,
};

Dropdown.defaultProps = {
  type: 'default',
};

export default Dropdown;
