import { combineReducers } from 'redux';
import at from '../../actions/types';

export const ids = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_BULKS_SUCCESS:
      return action.response.result;
    case at.DELETE_BULK_SUCCESS:
      return state.filter((id) => id !== action.response.id);
    default:
      return state;
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_BULKS_SUCCESS: {
      let bulks = action.response.entities.bulks;
      return bulks ? bulks : state;
    }
    case at.CONFIRM_BULK_SUCCESS: {
      const newBulk = action.response.bulk;
      return { ...state, [action.response.bulk._id]: newBulk };
    }
    case at.DELETE_BULK_SUCCESS: {
      if (state[action.response.id]) {
        const newState = { ...state };
        delete newState[action.response.id];
        return newState;
      }
      return state;
    }
    case at.STOP_BULK_SUCCESS: {
      const newBulk = action.response.bulk;
      return { ...state, [action.response.bulk._id]: newBulk };
    }
    default:
      return state;
  }
};

export const pagination = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_BULKS_SUCCESS: {
      return action.bulkPagination;
    }
    default:
      return state;
  }
};

export const isFetching = (state = false, action) => {
  switch (action.type) {
    case at.FETCH_BULKS_REQUEST:
      return true;
    case at.FETCH_BULKS_SUCCESS:
    case at.FETCH_BULKS_FAILURE:
      return false;
    default:
      return state;
  }
};

export const errorMessage = (state = null, action) => {
  switch (action.type) {
    case at.FETCH_BULKS_FAILURE:
      return action.message;
    case at.FETCH_BULKS_REQUEST:
    case at.FETCH_BULKS_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  ids,
  byId,
  isFetching,
  errorMessage,
  pagination,
});

const getBulk = (state) => (id) => state.byId[id];
const getBulks = (state) => state.ids.map((id) => state.byId[id]);
const getIsFetching = (state) => state.isFetching;
const getErrorMessage = (state) => state.errorMessage;
const getPagination = (state) => state.pagination;

export const selectors = {
  getBulk,
  getBulks,
  getIsFetching,
  getErrorMessage,
  getPagination,
};
