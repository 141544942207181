import { createAction } from 'state/utils';

export default {
  ...createAction('audits', 'FETCH_BULKS'),
  ...createAction('audits', 'FETCH_BULKS_URL'),
  ...createAction('audits', 'CONFIRM_BULK'),
  ...createAction('audits', 'DELETE_BULK'),
  ...createAction('audits', 'STOP_BULK'),
  ...createAction('audits', 'FETCH_BULK_LICENSES'),
  ...createAction('audits', 'DELETE_BULK_LICENSES'),
  ...createAction('audits', 'ARCHIVE_BULK_LICENSES'),
};
