// import { call, put, select, fork } from 'redux-saga/effects'
// import { delay } from 'redux-saga';

// import { normalize } from 'normalizr'
// import * as api from 'api/licenses'

// import isEmpty from 'lodash/isEmpty';

// import * as schema from '../schema';
// import at from '../actions/types';
// import * as actions from '../actions';

// import { selectors } from 'state/licenses';

export function* updateLicensePeriod() {
  console.log('update license period saga');
  yield 1;
}
