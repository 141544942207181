import { createAction } from 'state/utils';

export default {
  ...createAction('boardApplications', 'UPDATE'),
  ...createAction('boardApplications', 'FETCH'),
  ...createAction('boardApplications', 'FETCH_REPORTS'),

  CLEAR: 'boardApplications/CLEAR',
  CLEAR_ENTITIES: 'boardApplications/CLEAR_ENTITIES',
  SET_PAGINATION: 'boardApplications/SET_PAGINATION',
  FETCH_CANCELED: 'boardApplications/FETCH_CANCELED',
};
