import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from '../../config';
import * as cookies from 'utils/cookies';
import routerUtils from 'utils/router';

import './styles.css';

import { logout, verifyToken, selectBoard } from '../../actions/index';

export class LoginScreen extends Component {
  redirectToPage = (history) => {
    const queryParams = routerUtils(history).getQueryParams();
    const { continue_to = '' } = queryParams;
    if (continue_to && typeof continue_to === 'string' && continue_to.indexOf('audit') !== -1) {
      const params = continue_to.split('_');
      const path = params.length > 1 ? `/audits/${params[1]}` : '/audits';
      const boardId = parseInt(params[2], 10) || null;
      history.push(`${path}?boardId=${boardId}`);
      return;
    }

    if (continue_to === 'messagesInbox') {
      history.push('/inbox');
      return;
    }

    history.push('/licenses');
  };

  componentDidMount() {
    const { authentication = {}, history } = this.props;
    const queryParams = routerUtils(history).getQueryParams();
    const { token } = queryParams;

    //This line is require to allow logout, don't delete it
    queryParams.token = '';
    const cookie = cookies.getCookie(config.COOKIE_NAME);

    if (token) {
      this.verifyToken(token);
      return;
    }

    if (cookie && authentication.status === 'unauthenticated') {
      this.verifyToken(cookie);
      return;
    }

    if (authentication.status === 'authenticated') {
      this.redirectToPage(history);
    } else {
      window.location.replace(`${config.LAUNCHPAD_URL}/logout`);
    }
  }

  componentDidUpdate() {
    const { authentication = {}, history } = this.props;

    if (authentication.status === 'authenticated') {
      this.redirectToPage(history);
    }
  }

  verifyToken = (token) => {
    this.props.logout({ redirect: false }); // Commented to work with the new logout
    this.props.verifyToken(token);
  };

  render() {
    return (
      <div className="login-screen-container">
        <div className="login-screen-logo">
          <svg width="100px" height="122px" viewBox="362 6 100 122" version="1.1">
            <g id="Group" stroke="none" fill="none" transform="translate(362.000000, 6.000000)">
              <path
                d="M42.1125,43.15 C42.1125,43.15 26.9875,20.825 40.625,12.075 C40.625,12.075 41.1125,19.375 43.125,22.225 C43.125,22.225 51.3125,4.2625 56.4875,0.625 C59.4,-1.425 61.7125,5.4 60.3125,12.175 C60.3125,12.175 54.1375,18.6375 55.0375,22.3625 C55.9375,26.0875 61.7125,11.1125 70.95,21.9375 L42.1125,43.15 Z"
                className="login-screen-shape-black"
                fill="#000000"
              />
              <path
                d="M42.65,45.125 C42.65,45.125 69.95,23.5 82.5375,40.2375 C82.5375,40.2375 73.175,41.5625 69.7,44.4875 C69.7,44.4875 93.625,53.3125 98.8125,59.6375 C101.725,63.1875 93.1625,66.8125 84.3,65.6625 C84.3,65.6625 75.3875,58.325 70.675,59.8375 C65.9625,61.35 85.775,67.3375 72.7375,80.2875 L42.65,45.125 Z"
                className="login-screen-shape-green"
                fill="#72A893"
              />
              <path
                d="M41.45,46.075 C41.45,46.075 72.7,78.0375 53.0375,96.3625 C53.0375,96.3625 50.0875,84.6 45.8875,80.55 C45.8875,80.55 37.825,112.2875 30.45,119.75 C26.3,123.95 20.525,113.5 20.8125,102.025 C20.8125,102.025 28.9875,89.6625 26.425,83.8375 C23.8625,78.0125 18.8375,104.1375 0.5875,89.2125 L41.45,46.075 Z"
                className="login-screen-shape-red"
                fill="#D2232A"
              />
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps() {
  return {
    verifyToken,
    logout,
    selectBoard,
  };
}

export default connect(mapStateToProps, mapDispatchToProps())(LoginScreen);
