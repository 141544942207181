import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'emerald-ui-2/lib/IconButton';

import './styles.css';

const Arrow = ({ order }) => (
  <IconButton icon={order === 'desc' ? 'arrow_upward' : 'arrow_downward'} className="table-v2-arrow" />
);

const Col = ({ sorteable, sorted, order, value, children, className, onClick, ...rest }) => {
  const classes = `${className} table-v2-col ${sorteable ? 'table-v2-sorteable' : ''} ${
    sorted ? 'table-v2-sorted' : ''
  }`;
  return (
    <div
      {...rest}
      className={classes}
      onClick={(e) => {
        if (sorteable) {
          onClick(order === 'asc' ? `-${value}` : value);
        } else {
          onClick(e);
        }
      }}
    >
      {children}
      {sorted && <Arrow {...{ order }} />}
    </div>
  );
};

Col.propTypes = {
  sorteable: PropTypes.bool,
  sorted: PropTypes.bool,
  order: PropTypes.oneOf(['asc', 'desc']),
  value: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Col.defaultProps = {
  sorteable: false,
  sorted: false,
  order: 'desc',
  className: '',
  onClick: () => {},
};

export default Col;
