import { call, put, select } from 'redux-saga/effects';
import * as api from 'api/boardUser';
import at from '../actions/types';
import * as boardAccessActions from '../../boardsAccess/actions';

import { actions as notifications } from 'state/notifications';

export function* updateBoardUserProfile({ boardUserProfile, meta = {} }) {
  const { addNotification = true, notification = {} } = meta;
  const state = yield select();
  const { authentication } = state;
  yield put({ type: at.ADD_REQUEST });
  let response;
  try {
    response = yield call(api.updateBoardUserProfile, boardUserProfile, authentication.accessToken);
  } catch (e) {
    console.log(e);
    yield put({ type: at.FETCH_FAILURE, message: e.message || 'Something went wrong' });
    return;
  }
  yield put({ type: at.FETCH_SUCCESS, ...response.data });
  yield put(boardAccessActions.fetchBoardAccess());
  if (addNotification) {
    // Add success notification
    const { successText } = notification;
    const n = { type: 'UPDATE_USER', text: successText || 'User updated' };
    yield put(notifications.addAndRemove(n, 5000));
  }
}
