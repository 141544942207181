import { combineReducers } from 'redux';
import at from '../actions/types';

export const entityIds = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { filters, meta, result } = action;
      if (filters.tab !== 'inbox') {
        return state;
      }
      if (meta.adding) {
        return [...new Set([...state, ...result])];
      }
      return result;
    }
    case at.CLEAR: {
      return [];
    }
    default:
      return state;
  }
};

export const fetchStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      return action.filters.tab !== 'inbox' ? state : 'loading';
    }
    case at.FETCH_SUCCESS: {
      return action.filters.tab !== 'inbox' ? state : 'loaded';
    }
    case at.FETCH_FAILURE: {
      return action.filters.tab !== 'inbox' ? state : 'failed';
    }
    case at.FETCH_CANCELED: {
      return action.filters.tab !== 'inbox' ? state : 'loaded';
    }
    default:
      return state;
  }
};

export const totalCount = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      return action.filters.tab !== 'inbox' ? state : 0;
    }
    case at.FETCH_SUCCESS: {
      return action.filters.tab !== 'inbox' ? state : action.totalCount;
    }
    case at.FETCH_FAILURE: {
      return action.filters.tab !== 'inbox' ? state : 0;
    }
    case at.FETCH_CANCELED:
    default:
      return state;
  }
};

export default combineReducers({
  entityIds,
  fetchStatus,
  totalCount,
});
