import { takeLatest, takeEvery } from 'redux-saga/effects';
// import { LOCATION_CHANGE } from 'react-router-redux';

import at from '../actions/types';
// import * as actions from '../actions';

import { createUser } from './createUser';
import { updateUser } from './updateUser';
import { deleteUser } from './deleteUser';
import { resendInvitation } from './resendInvitation';
import { addUserToBoard } from './addUserToBoard';
import { watchFetchUsers } from './fetchUsers';

// export function* watchLocationChange({ payload }) {
//   if (/users\/*/.test(payload.pathname)) {
//     const filters = { page: 1 };
//     yield put(actions.clear());
//     yield put(actions.fetchUsers(filters));
//   }
// }

// export function* watchSelectedBoardChange({ payload }) {
//   const state = yield select();
//   const { pathname } = state.routing.locationBeforeTransitions;

//   yield put(actions.clearEntities());
//   yield put(actions.clear(''));

//   if (/users\/*/.test(pathname)) {
//     const filters = { page: 1 };
//     yield put(actions.fetchUsers(filters, { force: true }));
//   }

//   if (/reports\*/.test(pathname)) {
//     const filters = { page: 1 };
//     yield put(actions.fetchUsers(filters, { force: true }));
//   }
// }

export default function* root() {
  // yield takeLatest('SELECT_BOARD', watchSelectedBoardChange);
  // yield takeLatest(LOCATION_CHANGE, watchLocationChange);
  yield takeLatest(at.FETCH, watchFetchUsers);
  yield takeLatest(at.UPDATE, updateUser);
  yield takeLatest(at.CREATE, createUser);
  yield takeEvery(at.DELETE, deleteUser);
  yield takeEvery(at.RESEND, resendInvitation);
  yield takeLatest(at.ADD_USER_TO_BOARD, addUserToBoard);
}
