import { takeLatest } from 'redux-saga/effects';

import at from '../actions/types';

import { updateLicensePeriod } from './updateLicensePeriod';
import { fetchLicensePeriods } from './fetchLicensePeriods';

export default function* root() {
  yield takeLatest(at.UPDATE, updateLicensePeriod);
  yield takeLatest(at.FETCH, fetchLicensePeriods);
}
