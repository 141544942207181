import { call, put, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
// import isEmpty from 'lodash/isEmpty';

import * as api from 'api/conversations';
import at from '../actions/types';
import * as schema from '../schema';

export function* fetchConversations({ filters, meta = {} }) {
  const state = yield select();
  const { authentication } = state;
  const boardId = state.selectedBoard.id;
  yield put({ type: at.FETCH_REQUEST, filters, meta });
  try {
    let response;
    try {
      response = yield call(api.fetchConversations, { boardId, ...filters }, authentication.accessToken);
    } catch (e) {
      console.log(e);
      yield put({
        type: at.FETCH_FAILURE,
        filters,
        message: e.message || 'Something went wrong',
        meta,
      });
      return;
    }

    const { headers } = response;
    const totalCount = parseInt(headers['x-total-count'], 10);
    const normalizedData = normalize(response.data, schema.conversations);
    yield put({
      type: at.FETCH_SUCCESS,
      ...normalizedData,
      filters,
      totalCount,
      meta,
    });
  } catch (error) {
    console.log('error', error);
  }
}
