import React from 'react';
import legacyIcon from 'media/legacy.svg';
import cebrokerLogoIcon from 'media/cebroker-logo-icon.svg';
import Icon from 'emerald-ui-2/lib/Icon';

const TieUsersRow = (props) => {
  return (
    <tr>
      <td className="tie-users-col tie-users-column">
        <img src={legacyIcon} alt="legacy icon" className="tie-users-page-board-icon" />
        <span className="tie-users-row-content">{props.children && props.children[0]}</span>
      </td>
      <td className="tie-users-page-icon tie-users-column-vertical">
        <Icon name="link" />
      </td>
      <td className="tie-users-col tie-users-column">
        <img src={cebrokerLogoIcon} alt="cebroker logo icon" className="tie-users-page-board-icon" />
        <span className="tie-users-row-content">{props.children && props.children[1]}</span>
      </td>
      <td className="tie-users-page-actions tie-users-column">{props.children && props.children[2]}</td>
    </tr>
  );
};

export default TieUsersRow;
