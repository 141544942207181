import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Panel from 'emerald-ui-2/lib/Panel';

import './styles.css';

class SelfReportedCEQuestions extends Component {
  renderAnswers = (answers = []) => {
    return answers.map((answer) => <div key={answer.id}>{answer.text}</div>);
  };

  render() {
    const { questions } = this.props;
    return (
      <Panel className="self-reported-ce-detail-page-card">
        <div className="self-reported-ce-detail-page-cardHeader">
          <p>Questions</p>
        </div>
        <div className="self-reported-ce-detail-page-cardBody">
          {questions.map((question) => (
            <div key={question.id}>
              <div>
                <p className="self-reported-ce-detail-page-cardTitle">{question.text}</p>
                <span>{this.renderAnswers(question.answers)}</span>
              </div>
            </div>
          ))}
        </div>
      </Panel>
    );
  }
}

SelfReportedCEQuestions.propTypes = {
  questions: PropTypes.array,
};

SelfReportedCEQuestions.defaultProps = {
  questions: [],
};

export default SelfReportedCEQuestions;
