import at from './types';

export const fetchAudits = (filters, meta) => ({ type: at.FETCH, filters, meta });
export const fetchAudit = (auditId) => ({ type: at.FETCH_ONE, auditId });

export const updateAudit = (auditId, data, meta) => ({ type: at.UPDATE, auditId, data, meta });

export const archiveAudit = (auditId, meta) => ({ type: at.ARCHIVE, auditId, meta });

export const unarchiveAudit = (auditId, meta) => ({ type: at.UNARCHIVE, auditId, meta });

export const updateAndArchiveAudit = (auditId, data, meta) => ({ type: at.UPDATE_AND_ARCHIVE, auditId, data, meta });

export const clear = (auditType = 'current') => ({ type: at.CLEAR, meta: { auditType } });
export const clearEntities = () => ({ type: at.CLEAR_ENTITIES });

export const createAudit = (data) => ({ type: at.CREATE, data });
export const createAuditWithCustomCycle = (data, cycle) => ({ type: at.CREATE_WITH_CUSTOM_CYCLE, data, cycle });

export const setFetchCanceled = (filters, meta) => ({ type: at.FETCH_CANCELED, filters, meta });
