import { combineReducers } from 'redux';

import at from '../actions/types';
import current from './current';
import archived from './archived';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return { ...state, ...action.entities.audits };
    }
    case at.FETCH_ONE_SUCCESS: {
      const { auditId, entities } = action;
      return {
        ...state,
        [auditId]: entities.audits[auditId],
      };
    }
    case at.UPDATE_SUCCESS: {
      return {
        ...state,
        [action.auditId]: { ...state[action.auditId], ...action.data },
      };
    }
    case at.ARCHIVE_SUCCESS: {
      return {
        ...state,
        [action.auditId]: { ...state[action.auditId], isArchived: true },
      };
    }
    case at.UNARCHIVE_SUCCESS: {
      return {
        ...state,
        [action.auditId]: { ...state[action.auditId], isArchived: false },
      };
    }
    case at.UPDATE_ROOM_ID: {
      const { auditId, roomId } = action;
      return {
        ...state,
        [auditId]: { ...state[auditId], roomId },
      };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const fetchStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_ONE_REQUEST: {
      return 'loading';
    }
    case at.FETCH_ONE_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_ONE_FAILURE: {
      return 'failed';
    }
    case at.FETCH_ONE_CANCELED: {
      return 'loaded';
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  fetchStatus,
  current,
  archived,
});
