import openSocket from 'socket.io-client';
import config from './../config';

export const createWebSocketConnection = () => {
  const { SOCKET_URL: url, SOCKET_BASE: path } = config;
  return openSocket(url, path ? { path } : {});
};

export const suscribeNotificationPanelIcon = (handler = () => {}, params = {}) => {
  const socket = createWebSocketConnection();
  socket.on('showRedIndicator', handler);
  socket.emit('subscribeShowRedIndicator', config.SOCKET_INTERVAL, params);
};
