import { call, put, select } from 'redux-saga/effects';

import * as api from 'api/conversations';
import at from '../actions/types';
import { actions as notificationsActions } from 'state/notifications';

export function* addMessageToConversation({ conversationId, data, meta }) {
  const state = yield select();
  const { authentication } = state;
  yield put({ type: at.ADD_MESSAGE_REQUEST });
  try {
    let response;
    try {
      response = yield call(api.addMessageToConversation, conversationId, data, authentication.accessToken);
    } catch (e) {
      console.log(e);
      yield put({
        type: at.ADD_MESSAGE_FAILURE,
        message: e.message || 'Something went wrong',
      });
      return;
    }
    const message = response.data;

    if (meta.withMessage) {
      const n = {
        type: 'MESSAGE_SENT',
        text: `Message sent to ${meta.message.sentTo.name}`,
      };
      yield put(notificationsActions.addAndRemove(n, 5000));
    }

    yield put({ type: at.ADD_MESSAGE_SUCCESS, conversationId, message });
    if (meta.successCallback && typeof meta.successCallback === 'function') {
      meta.successCallback();
    }
  } catch (error) {
    console.log('error', error);
  }
}
