import { createAction } from 'state/utils';

export default {
  ...createAction('licenses', 'UPDATE'),
  ...createAction('licenses', 'FETCH'),
  ...createAction('licenses', 'FETCH_COUNT'),
  ...createAction('licenses', 'FETCH_PERIODS'),
  ...createAction('licenses', 'FETCH_ONE'),
  ...createAction('licenses', 'FETCH_ACCOUNT_TYPE'),
  ...createAction('licenses', 'FETCH_EMAIL'),
  ...createAction('licenses', 'FETCH_EMAIL'),
  ...createAction('licenses', 'UPDATE_EMAIL'),
  ...createAction('licenses', 'RESEND_EMAIL'),

  SET_FILTERS: 'licenses/SET_FILTERS',
  CLEAR: 'licenses/CLEAR',
  CLEAR_ENTITIES: 'licenses/CLEAR_ENTITIES',
  SET_PAGINATION: 'licenses/SET_PAGINATION',
};
