import { takeEvery, } from "redux-saga/effects";

import at from "../actions/types";

import { requestDownloadPdf } from "./requestDownloadPdf";
import { getRequestStatus } from "./getRequestPdfStatus";
import { updatePdfStatus } from "./updatePdfStatus";
import { browserDownload } from "./browserDownload";

export default function* root() {
  yield takeEvery(at.FETCH_PDF, requestDownloadPdf);
  yield takeEvery(at.FETCH_PDF_STATUS, getRequestStatus);
  yield takeEvery(at.UPDATE_PDF_STATUS, updatePdfStatus);
  yield takeEvery(at.DOWNLOAD_PDF_BROWSER, browserDownload);
}
