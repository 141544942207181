import axios from 'axios';
import { PAGE_SIZE, getOffset } from 'constants.js';
import { API_URL } from 'config';
import querystring from 'querystring';

export const fetch = ({ boardId, filters = {}, token }) => {
  const { size = PAGE_SIZE, page = 1, ...rest } = filters;
  const offset = getOffset(page, size);
  if (rest.sort === 'search') {
    delete rest.sort;
  }
  const newFilters = { ...rest, offset, limit: size };
  const queryString = querystring.stringify(newFilters);
  return axios.request({
    method: 'get',
    url: `${API_URL}/boards/${boardId}/applications?${queryString}`,
    headers: { authorization: `Bearer ${token}` },
  });
};

export const fetchReports = ({ boardId, filters = {}, token }) => {
  const queryString = querystring.stringify(filters);
  return axios.request({
    method: 'get',
    url: `${API_URL}/boards/${boardId}/applications/reports?${queryString}`,
    headers: { authorization: `Bearer ${token}` },
  });
};
