import * as at from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case at.FETCH_ACCOUNT_SUCCESS:
      return { ...state, ...action.data };
    case at.FETCH_ACCOUNT_FAILURE:
      return {
        ...state,
      };
    case at.CREATE_PASSWORD_REQUEST:
      return { ...state, message: '', error: '' };
    case at.CREATE_PASSWORD_SUCCESS:
      return { ...state, message: action.message };
    case at.CREATE_PASSWORD_FAILURE:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
