export function getFiltersFromQuery(query) {
  let {
    compliancePage,
    complianceSize,
    complianceSort,
    complianceMinExpirationDate,
    complianceMaxExpirationDate,
    complianceProfessionId,
    complianceCurrentLicensesStatuses,
    complianceLicensesStatusesType,
    complianceLicensesStatuses,
    complianceComplianceStatus,
    complianceOmitInitialCycle,
    complianceExemptions,
  } = query;

  compliancePage = !isNaN(compliancePage) ? parseInt(compliancePage, 10) : null;
  complianceSize = !isNaN(complianceSize) ? parseInt(complianceSize, 10) : null;

  const filters = {
    page: compliancePage,
    size: complianceSize,
    sort: complianceSort,
  };
  if (complianceMinExpirationDate) {
    filters.minExpirationDate = complianceMinExpirationDate;
  }
  if (complianceMaxExpirationDate) {
    filters.maxExpirationDate = complianceMaxExpirationDate;
  }
  if (complianceProfessionId) {
    filters.professionId = parseInt(complianceProfessionId, 10);
  }
  if (complianceCurrentLicensesStatuses) {
    filters.currentLicensesStatuses = complianceCurrentLicensesStatuses;
  }
  if (complianceLicensesStatusesType) {
    filters.licensesStatusesType = complianceLicensesStatusesType;
  }
  if (complianceLicensesStatuses) {
    filters.licensesStatuses = complianceLicensesStatuses;
  }
  if (complianceComplianceStatus) {
    filters.complianceStatus = complianceComplianceStatus;
  }
  if (complianceOmitInitialCycle) {
    filters.omitInitialCycle = complianceOmitInitialCycle === 'true';
  }
  if (complianceExemptions) {
    filters.exemptions = complianceExemptions;
  }
  return filters;
}

export function removePaginationFromFilters(filters) {
  const newFilters = { ...filters };
  delete newFilters.page;
  return newFilters;
}
