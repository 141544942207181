import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'emerald-ui-2/lib/Icon';
import IconButton from 'emerald-ui-2/lib/IconButton';
import IconDropdown from 'emerald-ui-2/lib/IconDropdown';
import DropdownItem from 'emerald-ui-2/lib/DropdownItem';
import Avatar from 'emerald-ui-2/lib/Avatar';
import SingleSelect from 'emerald-ui-2/lib/SingleSelect';
import { Body, Row, Col } from 'components/Table/v2';
import Overlay from 'components/Overlay';

import './styles.css';

const RowConfirmation = ({ onConfirm, onCancel, message }) => {
  return (
    <Overlay className="user-list-confirmation-overlay" onClick={(e) => e.stopPropagation()}>
      <div />
      <div style={{ margin: 'auto' }}>
        <p>{message}</p>
      </div>
      <div className="user-list-action-icons">
        <IconButton icon="done" onClick={onConfirm} />
        <IconButton icon="close" onClick={onCancel} />
      </div>
    </Overlay>
  );
};

const StatusChangeError = ({ onClose }) => {
  return (
    <Overlay className="user-list-error-overlay" onClick={(e) => e.stopPropagation()}>
      <div />
      <div style={{ margin: 'auto' }}>
        <p>
          This is your board's primary contact. You'll need to assign someone else as the primary contact before you can
          inactivate this user.
        </p>
      </div>
      <div className="user-list-action-icons">
        <IconButton icon="close" onClick={onClose} />
      </div>
    </Overlay>
  );
};

class TableBody extends React.Component {
  state = {
    showChangeStatusConfirmation: -1,
    showChangeStatusError: -1,
    showDeleteConfirmation: -1,
    showResendConfirmation: -1,
    newStatus: false,
  };

  handleStatusChange = (value, user) => {
    if (!value && user.isPrimaryContact) {
      this.setState({ showChangeStatusError: user.id });
    } else {
      this.setState({
        showChangeStatusConfirmation: user.id,
        newStatus: value,
      });
    }
  };

  handleDeleteChange = (user) => {
    if (user.isPending) {
      this.setState({ showDeleteConfirmation: user.id });
    }
  };

  handleResendChange = (user) => {
    if (user.isPending) {
      this.setState({ showResendConfirmation: user.id });
    }
  };

  hideStatusChangeConfimation = () => this.setState({ showChangeStatusConfirmation: '' });

  hideDeleteConfimation = () => this.setState({ showDeleteConfirmation: '' });

  hideStatusChangeError = () => this.setState({ showChangeStatusError: '' });

  hideResendConfimation = () => this.setState({ showResendConfirmation: '' });

  renderPendingOptions = (user) => {
    return (
      <IconDropdown
        id="menu"
        className="rowMenu"
        icon="more_vert"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DropdownItem
          caption={'Delete'}
          onClick={(e) => {
            e.stopPropagation();
            this.handleDeleteChange(user);
          }}
        >
          Delete
        </DropdownItem>
        <DropdownItem
          caption={'Re-send Invitation'}
          onClick={(e) => {
            e.stopPropagation();
            this.handleResendChange(user);
          }}
        >
          Re-send Invitation
        </DropdownItem>
      </IconDropdown>
    );
  };

  render() {
    const {
      loggedInUser,
      boardAccess,
      users,
      roles,
      onRoleChange,
      onStatusChange,
      onDeleteAction,
      onResendAction,
      handleUserProfileClick,
      ...rest
    } = this.props;
    const {
      showChangeStatusConfirmation,
      showChangeStatusError,
      showDeleteConfirmation,
      showResendConfirmation,
      newStatus,
    } = this.state;

    let canUpdate = false;
    if (boardAccess.permissions) {
      canUpdate = boardAccess.permissions.some((p) => p.code === 'UPDATE_USERS');
    }

    const options = roles.map((role) => ({ value: role._id, label: role.name }));

    const rows = users.map((user, index) => {
      let role = user.role ? user.role : null;
      if (!role) {
        const filteredRoles = options ? options.filter((o) => o.label === 'Read-only') : [];
        role = filteredRoles.length > 0 ? filteredRoles[0].value : null;
      }

      const optionsRenderer = options.map((option) => (
        <option key={option.value} value={option.value} selected={option.value === role}>
          {option.label}
        </option>
      ));

      const isUserLogged = user.id.toString() === loggedInUser.id;
      const rowClasses = `user-list-row ${isUserLogged ? 'user-list-row.logged-in-user-row' : ''}`;
      const newStatusLabel = newStatus ? 'Activate' : 'Inactivate';
      return (
        <Row key={user.id} className={rowClasses} onClick={() => handleUserProfileClick(user)}>
          {user.id === showChangeStatusConfirmation && (
            <RowConfirmation
              message={`Are you sure you want to ${newStatusLabel} this user?`}
              onConfirm={() => {
                this.hideStatusChangeConfimation();
                onStatusChange(newStatus, user);
              }}
              onCancel={this.hideStatusChangeConfimation}
            />
          )}
          {user.id === showDeleteConfirmation && (
            <RowConfirmation
              message={`Are you sure you want to DELETE this user?`}
              onConfirm={() => {
                this.hideDeleteConfimation();
                onDeleteAction(user);
              }}
              onCancel={this.hideDeleteConfimation}
            />
          )}
          {user.id === showResendConfirmation && (
            <RowConfirmation
              message={`Are you sure you want to resend invitation?`}
              onConfirm={() => {
                this.hideResendConfimation();
                onResendAction(user);
              }}
              onCancel={this.hideResendConfimation}
            />
          )}
          {user.id === showChangeStatusError && <StatusChangeError onClose={this.hideStatusChangeError} />}
          <Col className="user-list-name-column">
            <div className="user-list-avatar-wrapper">
              <Avatar
                title={`${user.firstName || ''} ${user.lastName || ''}`}
                size="sm"
                className="user-list-avatar-image"
              />
              <div className="user-list-user-name-wrapper">
                <p style={{ marginBottom: '0px' }} className="text-color">
                  {`${user.firstName}  ${user.lastName}`}
                  {isUserLogged && <span className="logged-in-user-label">(You)</span>}
                </p>
                {user.isPrimaryContact && <span className="primary-contact-label text-danger">Primary Contact</span>}
              </div>
            </div>
          </Col>
          <Col className="user-list-role-column" onClick={(e) => e.stopPropagation()}>
            <SingleSelect
              id="user-list-assigned-user-dropdown"
              className="user-list-dropdown"
              style={{ zIndex: `${users.length - index}` }}
              name="assigned-user"
              disabled={!canUpdate || user.isPrimaryContact}
              onSelect={(value) => void onRoleChange(value, user)}
            >
              {optionsRenderer}
            </SingleSelect>
          </Col>
          <Col className="user-list-status-column">
            <div className="user-list-status-indicator text-color">
              <Icon
                name="lens"
                className={`${
                  user.isPending ? 'text-off-white-darkest' : user.isActive ? 'text-success' : 'text-danger'
                } status-indicator-bullet`}
              />
              {user.isPending ? 'Pending' : user.isActive ? 'Active' : 'Inactive'}
            </div>
          </Col>
          <Col className="user-list-menu-column row-icon-dropdown">
            {canUpdate && user.isPending && this.renderPendingOptions(user)}
            {canUpdate && !user.isPending && (
              <IconDropdown
                id="menu"
                icon="more_vert"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <DropdownItem
                  caption={user.isActive ? 'Inactive' : 'Active'}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.handleStatusChange(!user.isActive, user);
                  }}
                >
                  {user.isActive ? 'Inactive' : 'Active'}
                </DropdownItem>
              </IconDropdown>
            )}
          </Col>
        </Row>
      );
    });
    return <Body {...rest}>{rows}</Body>;
  }
}

TableBody.propTypes = {
  users: PropTypes.array,
  roles: PropTypes.array,
  handleSelectChange: PropTypes.func,
  handleUserProfileClick: PropTypes.func,
};

TableBody.defaultProps = {
  users: [],
  roles: [],
  handleSelectChange: () => {},
  handleUserProfileClick: () => {},
};

export default TableBody;
