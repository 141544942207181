import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';

import './styles.css';
import { actions as userActions } from 'state/users';

import InboxConversationsList from './components/InboxConversationsList';
import CompletedConversationsList from './components/CompletedConversationsList';
import SentConversationsList from './components/SentConversationsList';
import ConversationsFiltersDialog from './components/ConversationsFiltersDialog';
import ConversationsFiltersBar from './components/ConversationsFiltersBar';
import RedirectButton from 'components/RedirectButton';
import PageHeader from 'emerald-ui-2/lib/PageHeader';
import Nav from 'emerald-ui-2/lib/Nav';
import Tab from 'emerald-ui-2/lib/Tab';
import Container from 'emerald-ui-2/lib/Container';
import routerUtils from 'utils/router';

import { actions as conversationsActions } from 'state/conversations';

const AsyncConversationDetail = Loadable({
  loader: () =>
    import('containers/ConversationDetail' /* webpackChunkName: "conversation-detail" */).then((m) => m.default),
  loading() {
    return <span />;
  },
});

export class ConversationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      showConversationDetailDialog: false,
      selectedConversationId: '',
      showFiltersDialog: false,
    };

    this.paginationDefaults = {
      offsetInbox: 0,
      limitInbox: 20,
      offsetCompleted: 0,
      limitCompleted: 20,
      offsetSent: 0,
      limitSent: 20,
    };
  }

  componentDidMount() {
    const { fetchUsers } = this.props;
    this.init();
    this.setDefaultsFilters(this.props);
    fetchUsers({ page: 1 });
  }

  componentDidUpdate(prevProps) {
    const { filters, boardId } = this.props;
    if (JSON.stringify(prevProps.filters) !== JSON.stringify(filters) || prevProps.boardId !== boardId) {
      this.init();
      this.setDefaultsFilters(this.props);
    }
  }

  init = () => {
    this.props.fetchConversations(this.props.filters);
  };

  setDefaultsFilters = () => {
    const { filters } = this.props;
    let { tab } = filters;
    tab = tab === 'inbox' || tab === 'completed' || tab === 'sent' ? tab : 'inbox';

    this.setState({ selectedTab: tab });

    this.updateFilters({
      ...this.paginationDefaults,
      tab,
    });
  };

  updateFilters = (newFilters) => {
    routerUtils(this.props.history).updateQueryParams({ ...newFilters });
  };

  handleTabChange = (tab) => {
    this.setState({ selectedTab: tab });
    this.updateFilters({ tab });
  };

  handleCloseConversationDetailDialog = () => {
    this.setState({ showConversationDetailDialog: false });
  };

  handleConversationClick = (conversation) => {
    this.setState({
      selectedConversationId: conversation._id,
      showConversationDetailDialog: true,
    });
  };

  handleFiltersIconClick = () => {
    this.setState({ showFiltersDialog: true });
  };

  handleFiltersDialogClose = () => {
    this.setState({ showFiltersDialog: false });
  };

  render() {
    const { selectedTab, selectedConversationId, showConversationDetailDialog, showFiltersDialog } = this.state;

    const { filters } = this.props;

    return (
      <div className="inbox">
        <PageHeader className="board-nav-header-bg">
          <RedirectButton />
          <h1 className="clearfix">
            <span className="h2">Inbox</span>
          </h1>
        </PageHeader>
        <Tab.Container id="myTabs" activeKey={selectedTab} onSelect={this.handleTabChange}>
          <div>
            <div className="eui-dark-theme board-nav-header-bg">
              <Container className="inbox-tablist">
                <Nav>
                  <a id="inbox" href="/">
                    INBOX
                  </a>
                  <a id="completed" href="/">
                    COMPLETED
                  </a>
                  <a id="sent" href="/">
                    SENT
                  </a>
                </Nav>
              </Container>
            </div>
            <Container>
              <Tab.Content>
                <Tab.Pane eventKey="inbox">
                  <div className="conversations-messages-section">
                    <ConversationsFiltersBar
                      onFiltersIconClick={this.handleFiltersIconClick}
                      updateFilters={this.updateFilters}
                      filters={filters}
                    />
                    <InboxConversationsList onConversationClick={this.handleConversationClick} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="completed">
                  <div className="conversations-messages-section">
                    <ConversationsFiltersBar
                      onFiltersIconClick={this.handleFiltersIconClick}
                      updateFilters={this.updateFilters}
                      filters={filters}
                    />
                    <CompletedConversationsList onConversationClick={this.handleConversationClick} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="sent">
                  <div className="conversations-messages-section">
                    <ConversationsFiltersBar
                      onFiltersIconClick={this.handleFiltersIconClick}
                      updateFilters={this.updateFilters}
                      filters={filters}
                    />
                    <SentConversationsList onConversationClick={this.handleConversationClick} />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Container>
          </div>
        </Tab.Container>
        <AsyncConversationDetail
          active={showConversationDetailDialog}
          onClose={this.handleCloseConversationDetailDialog}
          conversationId={selectedConversationId}
          onSent={() => {
            this.setState({ showConversationDetailDialog: false });
          }}
          showGoToDetailOption={true}
        />

        <ConversationsFiltersDialog
          active={showFiltersDialog}
          onClose={this.handleFiltersDialogClose}
          updateFilters={this.updateFilters}
          filters={filters}
        />
      </div>
    );
  }
}

function getFiltersFromQuery(query) {
  let {
    tab,
    offsetCompleted,
    limitCompleted,
    offsetInbox,
    limitInbox,
    offsetSent,
    limitSent,
    lastMessageSentStartDate,
    lastMessageSentEndDate,
    myMessages,
    q,
  } = query;
  tab = tab === 'inbox' || tab === 'completed' || tab === 'sent' ? tab : 'inbox';
  const filters = {
    tab,
    myMessages: myMessages === 'true',
  };
  if (lastMessageSentStartDate) {
    filters.lastMessageSentStartDate = lastMessageSentStartDate;
  }
  if (lastMessageSentEndDate) {
    filters.lastMessageSentEndDate = lastMessageSentEndDate;
  }
  if (q) {
    filters.q = q;
  }

  switch (tab) {
    case 'inbox': {
      filters.offset = !isNaN(offsetInbox) ? parseInt(offsetInbox, 10) : null;
      filters.limit = !isNaN(limitInbox) ? parseInt(limitInbox, 10) : null;
      break;
    }
    case 'completed': {
      filters.offset = !isNaN(offsetCompleted) ? parseInt(offsetCompleted, 10) : null;
      filters.limit = !isNaN(limitCompleted) ? parseInt(limitCompleted, 10) : null;
      break;
    }
    case 'sent': {
      filters.offset = !isNaN(offsetSent) ? parseInt(offsetSent, 10) : null;
      filters.limit = !isNaN(limitSent) ? parseInt(limitSent, 10) : null;
      break;
    }
    default: {
      break;
    }
  }
  return filters;
}

function mapStateToProps(state, ownProps) {
  const queryParams = routerUtils(ownProps.history).getQueryParams();
  const filters = getFiltersFromQuery(queryParams);
  return {
    boardId: state.selectedBoard.id,
    filters,
    ...ownProps,
  };
}

export default connect(mapStateToProps, {
  fetchUsers: userActions.fetchUsers,
  fetchConversations: conversationsActions.fetchConversations,
  updateConversationParticipant: conversationsActions.updateConversationParticipant,
})(ConversationPage);
