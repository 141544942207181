import { call, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import * as api from 'api/users';

import * as schema from '../schema';
import at from '../actions/types';

export function* fetchUsers(filters = {}) {
  const state = yield select();
  const { authentication } = state;
  const boardId = state.selectedBoard.id;
  yield put({ type: at.FETCH_REQUEST, filters, meta: {} });
  try {
    let response;
    try {
      response = yield call(api.fetch, { boardId, filters, token: authentication.accessToken });
    } catch (e) {
      console.log(e);
      yield put({ type: at.FETCH_FAILURE, filters, message: e.message || 'Something went wrong', meta: {} });
      return;
    }

    const { headers } = response;
    const totalItems = parseInt(headers['x-total-count'], 10);

    const normalizedData = normalize(response.data, schema.users);
    yield put({ type: at.FETCH_SUCCESS, ...normalizedData, filters, totalItems, meta: {} });
  } catch (error) {
    console.log('error', error);
  }
}

export function* watchFetchUsers({ filters, meta = {} }) {
  yield call(fetchUsers, filters);
}
