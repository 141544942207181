import { schema } from 'normalizr';

export const licensesStatus = new schema.Entity(
  'licensesStatuses',
  {},
  {
    idAttribute: (value) => value.status,
    mergeStrategy: (...entities) => {
      entities = Array.from(entities);

      let entitySelected = {};

      for (const entity of entities) {
        const [board] = entity.boards;

        if (typeof board.isRenewable === 'boolean') entitySelected = { ...entity };
      }

      if (!Object.keys(entitySelected).length) entitySelected = entities.pop();

      return entitySelected;
    },
  }
);

export const licensesStatuses = new schema.Array(licensesStatus);
