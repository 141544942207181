import { combineReducers } from 'redux';
import at from '../actions/types';

export const dates = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_REPORTS_REQUEST:
    case at.FETCH_REPORTS_FAILURE: {
      return [];
    }
    case at.FETCH_REPORTS_SUCCESS: {
      return action.dates;
    }
    default:
      return state;
  }
};

export const daysToFirstAction = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_REPORTS_REQUEST:
    case at.FETCH_REPORTS_FAILURE: {
      return [];
    }
    case at.FETCH_REPORTS_SUCCESS: {
      return action.daysToFirstAction;
    }
    default:
      return state;
  }
};

export const daysToFinalDecision = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_REPORTS_REQUEST:
    case at.FETCH_REPORTS_FAILURE: {
      return [];
    }
    case at.FETCH_REPORTS_SUCCESS: {
      return action.daysToFinalDecision;
    }
    default:
      return state;
  }
};

export const approverDaysToFinalDecision = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_REPORTS_REQUEST:
    case at.FETCH_REPORTS_FAILURE: {
      return [];
    }
    case at.FETCH_REPORTS_SUCCESS: {
      return action.approverDaysToFinalDecision || [];
    }
    default:
      return state;
  }
};

export default combineReducers({
  dates,
  daysToFirstAction,
  daysToFinalDecision,
  approverDaysToFinalDecision,
});
