import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TableHeader from 'emerald-ui-2/lib/TableHeader';
import Table from 'emerald-ui-2/lib/Table';
import Container from 'emerald-ui-2/lib/Container';
import PageHeader from 'emerald-ui-2/lib/PageHeader';
import Toast from 'emerald-ui-2/lib/Toast';
import { selectors as boardAccessSelectors } from 'state/boardsAccess';
import { actions as userActions } from 'state/users';

import RedirectButton from 'components/RedirectButton';

import './styles.css';

import TieUsersForm from './TieUsersForm';
import TieUserList from './TiedUsersList';

const TieUsersPage = ({ boardAccess, boardAccessFetchStatus, history, fetchUsers, boardId }) => {
  const [toastMessage, setToastMessage] = useState('');
  const [toastVisible, setToastVisible] = useState(false);

  useEffect(() => {
    const permissions = boardAccess.permissions || [];
    const canTieUsers = permissions.some((p) => p.code === 'TIE_USERS');
    if (boardAccessFetchStatus === 'loaded' && !canTieUsers) {
      history.push('/licenses');
    }
    fetchUsers({ page: 1 });
  }, [boardId]);

  const showToast = (msg) => {
    setToastMessage(msg);
    setToastVisible(true);
  };

  const handleTimeout = () => {
    setToastVisible(true);
  };

  const permissions = boardAccess.permissions || [];
  const canTieUsers = permissions.some((p) => p.code === 'TIE_USERS');

  return (
    canTieUsers && (
      <section className="tie-users-page">
        <PageHeader className="board-nav-header-bg">
          <RedirectButton />
          <h1>
            <span className="h2">Tie Users</span>
          </h1>
        </PageHeader>
        <Container>
          <TieUsersForm showToast={showToast} />
          <Table className="tie-users-page-table">
            <TableHeader useCheckbox={false} />
            <TieUserList showToast={showToast} />
          </Table>
        </Container>
        <Toast message={toastMessage} visible={toastVisible} onTimeout={handleTimeout} />
      </section>
    )
  );
};

function mapStateToProps(state, ownProps) {
  const ubas = boardAccessSelectors(state);
  return {
    boardAccess: ubas.getSelectedBoardAccess(),
    boardAccessFetchStatus: ubas.getSelectedBoardFetchStatus(),
    boardId: state.selectedBoard.id,
    ...ownProps,
  };
}

export const TieUserPageComponent = TieUsersPage;

export default connect(mapStateToProps, {
  fetchUsers: userActions.fetchUsers,
})(TieUsersPage);
