import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RedIndicator from 'emerald-ui-2/lib/RedIndicator';
import IconButton from 'emerald-ui-2/lib/IconButton';

import { actions as notificationsPanelActions } from 'state/notificationsPanel';

import { suscribeNotificationPanelIcon } from 'utils/socket';

export class NotificationsPanelIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRedIndicator: false,
    };

    suscribeNotificationPanelIcon(this.handleShowRedIndicator, {
      userId: this.props.loggedInUser,
    });
  }

  handleShowRedIndicator = (showRedIndicatorParam) => {
    const showRedIndicator =
      typeof showRedIndicatorParam === 'string' ? showRedIndicatorParam === 'true' : showRedIndicatorParam;
    this.setState({ showRedIndicator });
  };

  handleToggleNotificationPanel = (e) => {
    const { toggleNotificationsPanel } = this.props;

    e.preventDefault();
    toggleNotificationsPanel();
  };

  render() {
    return (
      <a href="/" className="nav-item-icon disable-indicator-bar" onClick={this.handleToggleNotificationPanel}>
        <RedIndicator active={this.state.showRedIndicator}>
          <IconButton ariaLabel="Notifications" icon="notifications" title="Notifications" />
        </RedIndicator>
      </a>
    );
  }
}

NotificationsPanelIcon.propTypes = {
  toggleNotificationsPanel: PropTypes.func,
};

NotificationsPanelIcon.defaultProps = {
  toggleNotificationsPanel: () => {},
};

function mapStateToProps(state) {
  return {
    loggedInUser: state.loggedInUser.id,
    notificationsPanelStatus: state.notificationsPanel.opened,
  };
}

export default connect(mapStateToProps, {
  toggleNotificationsPanel: notificationsPanelActions.toggleNotificationsPanel,
})(NotificationsPanelIcon);
