const getEntityById = (state) => (id) => state.entities[id] || {};

const getIsFetchingById = (state) => (id) => state.isFetching[id];

const getErrorMessageById = (state) => (id) => state.errorMessage[id];

export default {
  getEntityById,
  getIsFetchingById,
  getErrorMessageById,
};
