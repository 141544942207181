import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAccount, createPassword } from '../../actions';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Button from 'emerald-ui-2/lib/Button';
import Panel from 'emerald-ui-2/lib/Panel';
import TextField from 'emerald-ui-2/lib/TextField';
import { LoadingBox } from '../../components';
import config from '../../config';
import { isComplexPassword } from '../../utils';

import './styles.css';
import logo from './logo-ceb.svg';

export class AuditAccount extends Component {
  state = {
    password: '',
    confirmPassword: '',
    passwordError: '',
    passwordConfirmError: '',
    complexError: null,
  };

  form = {};
  componentWillMount() {
    const { fetchAccount, match = {} } = this.props;
    const { params = {} } = match;
    if (fetchAccount) fetchAccount(params.token);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.account.message === 'Password updated' && this.form.submit) {
      this.form.submit();
    }
  }

  handleChangePassword = (event) => {
    const password = event.target.value;
    this.setState({ password, passwordError: '' });
  };

  handleChangeConfirmPassword = (event) => {
    const confirmPassword = event.target.value;
    this.setState({ confirmPassword, passwordConfirmError: '' });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { createPassword, account } = this.props;
    const { password, confirmPassword } = this.state;
    let passwordError = '';
    let passwordConfirmError = '';
    let complexError;

    if (password === '') {
      passwordError = "The password can't be empty";
    }

    if (password !== confirmPassword) {
      passwordConfirmError = 'The confirm password input must match the password input.';
    }

    if (confirmPassword === '') {
      passwordConfirmError = "The password can't be empty";
    }
    let validation = isComplexPassword(password);

    if (!validation.result) {
      complexError = validation.error;
    }
    this.setState({
      passwordError,
      passwordConfirmError,
      complexError,
    });

    if (passwordError === '' && passwordConfirmError === '' && !complexError) {
      createPassword({
        userId: account.userId,
        password,
        auditId: account.auditId,
      });
    }
  };

  renderPasswordErrors = () => {
    const { complexError } = this.state;
    if (complexError) {
      return (
        <ul className="audit-account-complex-password-errors">
          <p>Password must:</p>
          {complexError.map((error) => {
            return <li key={error}>{error}</li>;
          })}
        </ul>
      );
    }
  };

  renderForm = () => {
    const { account } = this.props;
    const { password, confirmPassword, passwordError, passwordConfirmError } = this.state;
    return (
      <Grid>
        <div className="audit-account-logo">
          <img src={logo} alt="CE Broker" />
        </div>
        <Grid>
          <Row>
            <Col xs={6} xsOffset={3}>
              <h4 className="audit-account-cardTitle">Activate Account</h4>
              <Panel className="audit-account-card">
                <form
                  ref={(form) => {
                    this.form = form;
                  }}
                  id="form"
                  method="POST"
                  onSubmit={this.handleSubmit}
                  action={`${config.SECURE_URL}/public/pb_login.aspx`}
                >
                  <input type="hidden" id="hAction" name="hAction" value="login" />
                  <input type="hidden" id="mLogin" name="mLogin" value={account.username} />
                  <input type="hidden" id="mpPassword" name="mpPassword" value={password} />
                  <input type="hidden" id="hIgnoreLogonCount" name="hIgnoreLogonCount" value="1" />
                  <input type="hidden" id="hPasswordEncrypted" name="hPasswordEncrypted" value="0" />
                  <input type="hidden" id="hSectionLogon" name="hSectionLogon" value="BOARD" />
                  <input
                    type="hidden"
                    id="hTargetPage"
                    name="hTargetPage"
                    value={`${config.SECURE_URL}/licensee/lc_dashboard.aspx`}
                  />

                  <p className="audit-account-audit-account-cardMessage">
                    Hi, <strong>{account.name}</strong>! Please create a password for your audit account.
                  </p>
                  <div className="audit-account-textfields">
                    <TextField
                      type="password"
                      id="password"
                      name="password"
                      label="Password"
                      value={password}
                      onChange={this.handleChangePassword}
                      errorMessage={passwordError}
                    />
                    <TextField
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      label="Confirm your password"
                      value={confirmPassword}
                      onChange={this.handleChangeConfirmPassword}
                      errorMessage={passwordConfirmError}
                    />
                    <Button type="submit" color="primary" className="audit-account-audit-account-cardButton">
                      Activate
                    </Button>
                  </div>
                  {this.renderPasswordErrors()}
                </form>
              </Panel>
            </Col>
          </Row>
          <Row>
            <Col xs={6} xsOffset={5}>
              <div className="audit-account-footerText">
                <p>
                  <small>CE Broker © 2019</small>
                </p>
              </div>
            </Col>
          </Row>
        </Grid>
      </Grid>
    );
  };

  renderInvalidToken = () => {
    return (
      <Grid>
        <div className="audit-account-logo">
          <img src={logo} alt="CE Broker" />
        </div>
        <Grid>
          <Row>
            <Col xs={6} xsOffset={3}>
              <Panel className="audit-account-card">
                <h4 className="audit-account-audit-account-cardMessage">
                  You've already created your audit account. Click <a href={config.LAUNCHPAD_URL}>here</a> to log in
                </h4>
              </Panel>
            </Col>
          </Row>
          <Row>
            <Col xs={6} xsOffset={5}>
              <div className="audit-account-footerText">
                <p>
                  <small>CE Broker © 2019</small>
                </p>
              </div>
            </Col>
          </Row>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { accountRequest } = this.props;
    if (accountRequest.fetchSucceed) {
      return this.renderForm();
    }
    if (accountRequest.fetchFailed) {
      return this.renderInvalidToken();
    }
    return (
      <div className="audit-account-loader">
        <LoadingBox visible={true} text="Loading..." />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const accountRequest =
    state.requests && state.requests.account && state.requests.account.fetch ? state.requests.account.fetch : {};
  return {
    account: state.account,
    accountRequest,
  };
}

export default connect(mapStateToProps, { fetchAccount, createPassword })(AuditAccount);
