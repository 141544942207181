import * as at from '../actions/types';

export const auditCustomSelectionForm = (state = {}, action) => {
  switch (action.type) {
    case at.UPDATE_AUDIT_CUSTOM_SELECTION:
      return { ...state, ...action.data };
    case at.CLEAR_AUDIT_CUSTOM_SELECTION:
      return {};
    default:
      return state;
  }
};

export default auditCustomSelectionForm;
