import { AUTHENTICATE } from '../actions/types';

const loggedInUser = (state = {}, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      if (action.payload.status !== 200) return {};
      return JSON.parse(new Buffer(action.payload.data.accessToken.split('.')[1], 'base64').toString());
    default:
      return state;
  }
};

export default loggedInUser;
