// BOARDS
export const SELECT_BOARD = 'SELECT_BOARD';

//PROFESSION
export const FETCH_PROFESSIONS = 'FETCH_PROFESSIONS';
export const FETCH_PROFESSIONS_REQUEST = 'FETCH_PROFESSIONS_REQUEST';
export const FETCH_PROFESSIONS_SUCCESS = 'FETCH_PROFESSIONS_SUCCESS';
export const FETCH_PROFESSIONS_FAILURE = 'FETCH_PROFESSIONS_FAILURE';

// LICENSES
// export const FETCH_LICENSES = "FETCH_LICENSES";
export const FETCH_LICENSES_REQUEST = 'FETCH_LICENSES_REQUEST';
export const FETCH_LICENSES_SUCCESS = 'FETCH_LICENSES_SUCCESS';
export const FETCH_LICENSES_FAILURE = 'FETCH_LICENSES_FAILURE';

export const FETCH_LICENSE_REQUEST = 'FETCH_LICENSE_REQUEST';
export const FETCH_LICENSE_SUCCESS = 'FETCH_LICENSE_SUCCESS';
export const FETCH_LICENSE_FAILURE = 'FETCH_LICENSE_FAILURE';

export const FETCH_ACCOUNT_REQUEST = 'FETCH_ACCOUNT_REQUEST';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const FETCH_ACCOUNT_FAILURE = 'FETCH_ACCOUNT_FAILURE';

export const CREATE_PASSWORD_REQUEST = 'CREATE_PASSWORD_REQUEST';
export const CREATE_PASSWORD_SUCCESS = 'CREATE_PASSWORD_SUCCESS';
export const CREATE_PASSWORD_FAILURE = 'CREATE_PASSWORD_FAILURE';

export const FETCH_LICENSES_COUNT_REQUEST = 'FETCH_LICENSES_COUNT_REQUEST';
export const FETCH_LICENSES_COUNT_SUCCESS = 'FETCH_LICENSES_COUNT_SUCCESS';
export const FETCH_LICENSES_COUNT_FAILURE = 'FETCH_LICENSES_COUNT_FAILURE';
export const FETCH_LICENSES_STATUSES = 'FETCH_LICENSES_STATUSES';

export const FETCH_LICENSE = 'FETCH_LICENSE';
export const SET_LICENSE = 'SET_LICENSE';

// LICENSES PERIODS
export const FETCH_LICENSE_PERIODS_REQUEST = 'FETCH_LICENSE_PERIODS_REQUEST';
export const FETCH_LICENSE_PERIODS_SUCCESS = 'FETCH_LICENSE_PERIODS_SUCCESS';
export const FETCH_LICENSE_PERIODS_FAILURE = 'FETCH_LICENSE_PERIODS_FAILURE';
export const CUSTOM_CYCLE_REQUEST = 'CUSTOM_CYCLE_REQUEST';
export const CUSTOM_CYCLE_SUCCESS = 'CUSTOM_CYCLE_SUCCESS';
export const CUSTOM_CYCLE_FAILURE = 'CUSTOM_CYCLE_FAILURE';

//AUDIT
export const FETCH_AUDIT = 'FETCH_AUDIT';
export const ADD_TO_AUDIT_REQUEST = 'ADD_TO_AUDIT_REQUEST';
export const ADD_TO_AUDIT_SUCCESS = 'ADD_TO_AUDIT_SUCCESS';
export const ADD_TO_AUDIT_FAILURE = 'ADD_TO_AUDIT_FAILURE';

//TRANSCRIPT
export const FETCH_TRANSCRIPT_REQUEST = 'FETCH_TRANSCRIPT_REQUEST';
export const FETCH_TRANSCRIPT_SUCCESS = 'FETCH_TRANSCRIPT_SUCCESS';
export const FETCH_TRANSCRIPT_FAILURE = 'FETCH_TRANSCRIPT_FAILURE';

//CURRENT AUDITS
export const FETCH_CURRENT_AUDITS = 'FETCH_CURRENT_AUDITS';
export const FETCH_CURRENT_AUDITS_REQUEST = 'FETCH_CURRENT_AUDITS_REQUEST';
export const FETCH_CURRENT_AUDITS_SUCCESS = 'FETCH_CURRENT_AUDITS_SUCCESS';
export const FETCH_CURRENT_AUDITS_FAILURE = 'FETCH_CURRENT_AUDITS_FAILURE';

export const FETCH_AUDIT_REQUEST = 'FETCH_AUDIT_REQUEST';
export const FETCH_AUDIT_SUCCESS = 'FETCH_AUDIT_SUCCESS';
export const FETCH_AUDIT_FAILURE = 'FETCH_AUDIT_FAILURE';

export const UPDATE_AUDIT_REQUEST = 'UPDATE_AUDIT_REQUEST';
export const UPDATE_AUDIT_SUCCESS = 'UPDATE_AUDIT_SUCCESS';
export const UPDATE_AUDIT_FAILURE = 'UPDATE_AUDIT_FAILURE';

export const ARCHIVE_AUDIT_REQUEST = 'ARCHIVE_AUDIT_REQUEST';
export const ARCHIVE_AUDIT_SUCCESS = 'ARCHIVE_AUDIT_SUCCESS';
export const ARCHIVE_AUDIT_FAILURE = 'ARCHIVE_AUDIT_FAILURE';

export const UNARCHIVE_AUDIT_REQUEST = 'UNARCHIVE_AUDIT_REQUEST';
export const UNARCHIVE_AUDIT_SUCCESS = 'UNARCHIVE_AUDIT_SUCCESS';
export const UNARCHIVE_AUDIT_FAILURE = 'UNARCHIVE_AUDIT_FAILURE';

//ARCHIVED AUDITS
export const FETCH_ARCHIVED_AUDITS = 'FETCH_ARCHIVED_AUDITS';
export const FETCH_ARCHIVED_AUDITS_REQUEST = 'FETCH_ARCHIVED_AUDITS_REQUEST';
export const FETCH_ARCHIVED_AUDITS_SUCCESS = 'FETCH_ARCHIVED_AUDITS_SUCCESS';
export const FETCH_ARCHIVED_AUDITS_FAILURE = 'FETCH_ARCHIVED_AUDITS_FAILURE';

//USERS
export const FETCH_BOARD_USERS = 'FETCH_BOARD_USERS';

//AUTH
export const LOGOUT = 'LOGOUT';
export const AUTHENTICATE = 'AUTHENTICATE';
export const REQUEST_AUTHENTICATE = 'REQUEST_AUTHENTICATE';
export const FAILED_AUTHENTICATE = 'FAILED_AUTHENTICATE';

//COMMON
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const UPDATE_AUDIT_CUSTOM_SELECTION = 'UPDATE_AUDIT_CUSTOM_SELECTION';
export const CLEAR_AUDIT_CUSTOM_SELECTION = 'CLEAR_AUDIT_CUSTOM_SELECTION';

//AUDIT CUSTOM SELECTION
export const ADD_TO_AUDIT_CUSTOM_REQUEST = 'ADD_TO_AUDIT_CUSTOM_REQUEST';
export const ADD_TO_AUDIT_CUSTOM_SUCCESS = 'ADD_TO_AUDIT_CUSTOM_SUCCESS';
export const ADD_TO_AUDIT_CUSTOM_FAILURE = 'ADD_TO_AUDIT_CUSTOM_FAILURE';

//FETCH AUDIT SELECTION TRACK
export const FETCH_AUDIT_SELECTION_TRACK_REQUEST = 'FETCH_AUDIT_SELECTION_TRACK_REQUEST';
export const FETCH_AUDIT_SELECTION_TRACK_SUCCESS = 'FETCH_AUDIT_SELECTION_TRACK_SUCCESS';
export const FETCH_AUDIT_SELECTION_TRACK_FAILURE = 'FETCH_AUDIT_SELECTION_TRACK_FAILURE';

//EXEMPTIONS
export const FETCH_EXEMPTION_REQUEST = 'FETCH_EXEMPTION_REQUEST';
export const FETCH_EXEMPTION_SUCCESS = 'FETCH_EXEMPTION_SUCCESS';
export const FETCH_EXEMPTION_FAILURE = 'FETCH_EXEMPTION_FAILURE';

export const REMOVE_MESSAGE_REQUEST = 'REMOVE_MESSAGE_REQUEST';
export const REMOVE_MESSAGE_SUCCESS = 'REMOVE_MESSAGE_SUCCESS';
export const REMOVE_MESSAGE_FAILURE = 'REMOVE_MESSAGE_FAILURE';

//LICENSES_STATUSES
export const FETCH_LICENSES_STATUSES_REQUEST = 'FETCH_LICENSES_STATUSES_REQUEST';
export const FETCH_LICENSES_STATUSES_SUCCESS = 'FETCH_LICENSES_STATUSES_SUCCESS';
export const FETCH_LICENSES_STATUSES_FAILURE = 'FETCH_LICENSES_STATUSES_FAILURE';
