import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Avatar from 'emerald-ui-2/lib/Avatar';

export const LoggedInUserAvatar = ({ firstName, lastName }) => <Avatar title={`${firstName} ${lastName}`} />;

LoggedInUserAvatar.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

LoggedInUserAvatar.defaultProps = {
  firstName: '',
  lastName: '',
};

function mapStateToProps(state) {
  const { loggedInUser } = state;
  return {
    firstName: loggedInUser.firstName,
    lastName: loggedInUser.lastName,
  };
}

export default connect(mapStateToProps)(LoggedInUserAvatar);
