import axios from 'axios';
import { LICENSEE_INTERNAL_API } from 'config';
import { DOWNLOAD_PDF_STATUSES } from '../constants';

export const fetchCourses = (params) => {
    return axios.request({
      url: `${LICENSEE_INTERNAL_API}/course-history`,
      method: 'GET',
      params,
    });
  };

  export const fetchSubjectAreas = (professionId, userId = 0) => {
    return axios.request({
      method: 'GET',
      url: `${LICENSEE_INTERNAL_API}/course-filters`,
      params: {
        isAvailableInCourseSearch: true,
        professionId,
        userId,
      },
    });
  };

  export const fetchCourseDetail = (params,rosterAttendeeId) => {
    return axios.request({
      method: 'GET',
      url: `${LICENSEE_INTERNAL_API}/course-history/${rosterAttendeeId}`,
      params,
    });
  };

  export const fetchReportedExemptions = (params) => {
    return axios.request({
      method: 'GET',
      url: `${LICENSEE_INTERNAL_API}/reported-exemptions`,
      params,
    });
  };

  export const fetchReportedExemptionsQuestions = (lpExemptionId,params) => {
    return axios.request({
      method: 'GET',
      url: `${LICENSEE_INTERNAL_API}/reported-exemptions/${lpExemptionId}/questions`,
      params,
    });
  };

  export const setRevokeExemption = (lpExemptionId,data) => {
    return axios.request({
      method: 'POST',
      url: `${LICENSEE_INTERNAL_API}/reported-exemptions/${lpExemptionId}/revoke`,
      data,
    });
  };

  export const requestDownloadPdf = ({credentials}) => {
    return axios.request({
      url: `${LICENSEE_INTERNAL_API}/cch-report/download-pdf`,
      data: {
        subscriberCredentials: credentials,
      },
      method: 'POST',
    });
  };

  export const getRequestStatus = ({credentials}) => {
    return axios.request({
      url: `${LICENSEE_INTERNAL_API}/cch-report/get-request-status`,
      params: {
        userId: credentials.userId,
        pkLicense: credentials.pkLicense,
      },
      method: 'GET',
    });
  };

  export const updatePdfStatus = ({pdfDataObject}) => {
    pdfDataObject.statusRequest = DOWNLOAD_PDF_STATUSES.DOWNLOADED;
    return axios.request({
      url: `${LICENSEE_INTERNAL_API}/cch-report/update-status-pdf`,
      data: {
        subscriberCredentials: pdfDataObject,
      },
      method: 'POST',
    });
  };

  export const browserDownload = ({urlDocument}) => {
    return axios.request({
      url: `${urlDocument}`,
      method: 'GET',
      responseType: 'blob',
    });
  };
