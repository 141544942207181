import at from '../actions/types';

export const boards = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS:
      return action.data;
    default:
      return state;
  }
};

export default boards;
