import { combineReducers } from 'redux';

import at from '../actions/types';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return { ...state, ...action.entities.globalNotifications };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const pages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return {
        ...state,
        [action.filters.page]: action.result,
      };
    }
    case at.CLEAR: {
      return {};
    }
    default:
      return state;
  }
};

export const fetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      return { ...state, [action.filters.page]: 'loading' };
    }
    case at.FETCH_SUCCESS: {
      return { ...state, [action.filters.page]: 'loaded' };
    }
    case at.FETCH_FAILURE: {
      return { ...state, [action.filters.page]: 'failed' };
    }
    case at.FETCH_CANCELED: {
      return { ...state, [action.filters.page]: 'loaded' };
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  pages,
  fetchStatus,
});
