import React, { Component } from 'react';
import Panel from 'emerald-ui-2/lib/Panel';

import './styles.css';

class SelfReportedCEPaymentInfo extends Component {
  render() {
    const { payment } = this.props;
    return (
      <Panel className="self-reported-ce-detail-page-card">
        <div className="self-reported-ce-detail-page-cardHeader">
          <p>Payment info</p>
        </div>
        <div className="self-reported-ce-detail-page-cardBody">
          <div>
            <div>
              <p className="self-reported-ce-detail-page-cardTitle">Payment type</p>
              <span>{payment.paymentType}</span>
            </div>
            <div>
              <p className="self-reported-ce-detail-page-cardTitle">Fee</p>
              <span>{payment.feeAmount}</span>
            </div>
          </div>
          <div>
            <div>
              <p className="self-reported-ce-detail-page-cardTitle">Authorization number</p>
              <span>{payment.authorizationNumber}</span>
            </div>
            <div>
              <p className="self-reported-ce-detail-page-cardTitle">Date</p>
              <span>{payment.feeDate}</span>
            </div>
          </div>
        </div>
      </Panel>
    );
  }
}

export default SelfReportedCEPaymentInfo;
