import at from './types';

export const requestDownloadPdf = (credentials) => ({
  type: at.FETCH_PDF,
  credentials
});

export const getRequestPDFStatus = (credentials) => ({
  type: at.FETCH_PDF_STATUS,
  credentials
});

export const browserDownload = (urlDocument) => ({
  type: at.DOWNLOAD_PDF_BROWSER,
  urlDocument,
});

export const updatePdfStatus = (pdfDataObject) => ({
  type: at.UPDATE_PDF_STATUS,
  pdfDataObject,
});

export const resetRequestDownload = () => ({
  type: at.CLEAR_REQUEST_PDF,
});

export const resetRequestStatus = () => ({
  type: at.CLEAR_PDF_STATUS,
});

export const resetRequestBrowser = () => ({
  type: at.CLEAR_REQUEST_BROWSER,
});
