import axios from 'axios';
import config from 'config';

export function ssoAuth(token) {
  return axios.request({
    url: `${config.API_URL}/auth/sso`,
    method: 'POST',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}

export function accountsToken(username, password) {
  return axios.request({
    url: `${config.LAUNCHPAD_URL}/api/login`,
    method: 'POST',
    timeout: 10000,
    data: {
      username,
      password,
      grant_type: 'password',
    },
  });
}
