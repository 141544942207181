import { combineReducers } from 'redux';
import { AUTHENTICATE, REQUEST_AUTHENTICATE, FAILED_AUTHENTICATE } from '../actions/types';

export const accessToken = (state = '', action) => {
  switch (action.type) {
    case AUTHENTICATE:
      if (action.payload.status !== 200) return '';
      return action.payload.data.accessToken;
    default:
      return state;
  }
};

export const status = (state = 'unauthenticated', action) => {
  switch (action.type) {
    case AUTHENTICATE:
      if (action.payload.status === 200) return 'authenticated';
      return 'unauthenticated';
    default:
      return state;
  }
};

export const requestStatus = (state = '', action) => {
  switch (action.type) {
    case REQUEST_AUTHENTICATE:
      return 'authenticating';
    case AUTHENTICATE:
      return 'authenticated';
    case FAILED_AUTHENTICATE:
      return action.status;
    default:
      return state;
  }
};

const authentication = combineReducers({
  accessToken,
  status,
  requestStatus,
});

export default authentication;
