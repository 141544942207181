import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import WithTracker from './components/GoogleAnalytics/WithTracker';
import BoardAccount from 'containers/UsersPage/components/BoardAccount';
import ConversationsPage from 'containers/ConversationsPage';
import UsersList from 'containers/UsersPage/components/UsersList';
import CreateUsers from 'containers/UsersPage/components/CreateUsers';
import SearchUsers from 'containers/UsersPage/components/SearchUsers';
import {
  App,
  LoginForm,
  LoginScreen,
  SingleLoginPage,
  AuditAccount,
  LicensesPage,
  LicenseDetailPage,
  AuditsPage,
  AuditDetailPage,
  UsersPage,
  BoardProfile,
  BoardUserProfile,
  TieUsersPage,
  ReportsPage,
  ProvidersPage,
  ProvidersDetailPage,
  CoursesPage,
  CoursesDetailPage,
} from 'containers';

const LoginFormTracked = WithTracker(LoginForm);
const LoginScreenTracked = WithTracker(LoginScreen);
const SingleLoginPageTracked = WithTracker(SingleLoginPage);
const AuditAccountTracked = WithTracker(AuditAccount);
const BoardAccountTracker = WithTracker(BoardAccount);
const AppTracked = WithTracker(App);
const LicensesPageTracked = WithTracker(LicensesPage);
const LicenseDetailPageTracked = WithTracker(LicenseDetailPage);
const ProvidersPageTracked = WithTracker(ProvidersPage);
const ProvidersDetailPageTracked = WithTracker(ProvidersDetailPage);
const CoursesPageTracked = WithTracker(CoursesPage);
const CoursesDetailPageTracked = WithTracker(CoursesDetailPage);


const UsersPageTracked = WithTracker(UsersPage);
const UsersListTracked = WithTracker(UsersList);
const CreateUsersTracked = WithTracker(CreateUsers);
const SearchUsersTracked = WithTracker(SearchUsers);

export const MainRoutes = () => (
  <Switch>
    <Route path="/login-backup" component={LoginFormTracked} />
    <Route path="/login" component={LoginScreenTracked} />
    <Route path="/sso" component={SingleLoginPageTracked} />
    <Route path="/account/:token" component={AuditAccountTracked} />
    <Route path="/board/account/:token" component={BoardAccountTracker} />
    <Route path="/" component={AppTracked} />
  </Switch>
);

export const AppRoutes = () => (
  <Switch>
    <Route exact path="/licenses" component={LicensesPageTracked} />
    <Route path="/licenses/:licenseId" component={LicenseDetailPageTracked} />
    <Route exact path="/audits" component={AuditsPage} />
    <Route path="/audits/:auditId" component={AuditDetailPage} />
    <Route path="/reports" component={ReportsPage} />
    <Route path="/board-profile" component={BoardProfile} />
    <Route path="/users" component={UsersPageTracked} />
    <Route path="/board-profile" component={BoardProfile} />
    <Route path="/user-profile" component={BoardUserProfile} />
    <Route path="/inbox" component={ConversationsPage} />
    <Route path="/tie-users" component={TieUsersPage} />
    <Route exact path="/providers" component={ProvidersPageTracked} />
    <Route path="/providers/:providerId" component={ProvidersDetailPageTracked} />
    <Route exact path="/courses" component={CoursesPageTracked} />
    <Route path="/courses/:coursesId" component={CoursesDetailPageTracked} />
    <Redirect exact to="licenses" />
  </Switch>
);

export const UsersRoutes = () => (
  <Switch>
    <Route path="/users/list" component={UsersListTracked} />
    <Route path="/users/new" component={CreateUsersTracked} />
    <Route path="/users/search" component={SearchUsersTracked} />
    <Redirect exact to="users/list" />
  </Switch>
);
