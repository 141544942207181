export function getFiltersFromQuery(query) {
  let {
    page,
    size,
    sort,
    term,
    minIssueDate,
    maxIssueDate,
    minExpirationDate,
    maxExpirationDate,
    professionId,
    status,
    hasAccount,
  } = query;

  page = !isNaN(page) ? parseInt(page, 10) : null;
  size = !isNaN(size) ? parseInt(size, 10) : null;

  const filters = { page, size, sort };
  if (term) {
    filters.term = term;
  }
  if (minIssueDate) {
    filters.minIssueDate = minIssueDate;
  }
  if (maxIssueDate) {
    filters.maxIssueDate = maxIssueDate;
  }
  if (minExpirationDate) {
    filters.minExpirationDate = minExpirationDate;
  }
  if (maxExpirationDate) {
    filters.maxExpirationDate = maxExpirationDate;
  }
  if (professionId) {
    filters.professionId = professionId;
  }
  if (status) {
    filters.status = status;
  }
  if (hasAccount) {
    filters.hasAccount = hasAccount;
  }

  return filters;
}

export function removePaginationFromFilters(filters) {
  const newFilters = { ...filters };
  delete newFilters.page;
  return newFilters;
}
