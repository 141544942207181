import React from 'react';
import PropTypes from 'prop-types';
import Row from './Row';
import Col from './Col';
import './styles.css';

const Header = ({ children, sort = '', onSort }) => {
  let order = 'asc';
  if (sort && sort.charAt(0) === '-') {
    [order, sort] = ['desc', sort.substring(1)];
  }
  const cols = React.Children.map(children.props.children, (child, i) => (
    <Col
      key={i}
      {...child.props}
      sorted={child.props.value === sort}
      order={order}
      onClick={child.props.sorteable ? onSort : () => {}}
    />
  ));
  return (
    <div className="table-v2-header">
      <Row>{cols}</Row>
    </div>
  );
};

Header.propTypes = {
  children: function (props, propName, componentName) {
    var prop = props[propName];
    // Only accept a single child, of the appropriate type
    if (React.Children.count(prop) !== 1 || prop.type.name !== Row.name) {
      return new Error(`"${componentName}" should have a single child of type Row. You passed ${prop}`);
    }
  },
  onSort: PropTypes.func,
};

Header.defaultProps = {
  onSort: () => {},
};

export default Header;
