import { call, put, select } from 'redux-saga/effects';
import * as api from 'api/audits';

import at from '../actions/types';

import { actions as notifications } from 'state/notifications';

export function* updateAudit({ auditId, data, meta = {} }) {
  const { addNotification = true, notification = {}, archive = false } = meta;
  const state = yield select();
  const { authentication } = state;
  yield put({ type: at.UPDATE_REQUEST });

  try {
    yield call(api.update, { auditId, data, token: authentication.accessToken });
  } catch (e) {
    console.log(e);
    yield put({ type: at.UPDATE_FAILURE, data, message: e.message || 'Something went wrong' });

    if (addNotification) {
      // Add failure notification
      const { failureText } = notification;
      const n = { type: 'UPDATE_AUDIT', text: failureText || 'Error updating the audit' };
      yield put(notifications.addAndRemove(n, 5000));
    }
    return;
  }

  yield put({ type: at.UPDATE_SUCCESS, auditId, data });
  if (archive) yield put({ type: at.ARCHIVE_SUCCESS, auditId });
  if (addNotification) {
    // Add success notification
    const { successText } = notification;
    const n = { type: 'UPDATE_AUDIT', text: successText || 'Audit updated' };
    yield put(notifications.addAndRemove(n, 5000));
  }
}
