import axios from 'axios';
import { API_URL } from 'config';

export function fetchByUser(token, filters = {}) {
  return axios.request({
    url: `${API_URL}/me/notifications-panel?from=${filters.from}&to=${filters.to}`,
    method: 'get',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}

export function markAllAsRead(userId, token) {
  return axios.request({
    url: `${API_URL}/me/notifications-panel/mark-as-read`,
    method: 'post',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}
