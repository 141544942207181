import { takeLatest, put, select } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';

import at from '../actions/types';
import * as actions from '../actions';

import { removePaginationFromFilters } from '../common';
import { watchFetchBoardApplications } from './fetchBoardApplications';
import { watchFetchReports } from './fetchReports';

// This method verify some condition to know if with should fetch the reports
// this allows to make the fetch only when is needed to avoid waste resources
export const reportsPageMemory = (() => {
  let previousFilters = {};
  let previousSelectedBoard;
  return function* page(filters) {
    const state = yield select();
    const { selectedBoard } = state;
    // Don't do anything if the  filters and the selected board haven't changed
    if (isEqual(filters, previousFilters) && selectedBoard.id === previousSelectedBoard) {
      return;
    }
    // clear the licenses state if the filters or the selected board have changed
    if (
      !isEqual(removePaginationFromFilters(filters), removePaginationFromFilters(previousFilters)) ||
      selectedBoard.id !== previousSelectedBoard
    ) {
      yield put(actions.clear());
      yield put(actions.clearEntities());
    }
    previousSelectedBoard = selectedBoard.id;
    previousFilters = filters;
    yield put(actions.fetchBoardApplications(filters));
  };
})();

export default function* root() {
  yield takeLatest(at.FETCH, watchFetchBoardApplications);
  yield takeLatest(at.FETCH_REPORTS, watchFetchReports);
}
