import { createAction } from 'state/utils';

export default {
  ...createAction('conversations', 'CREATE'),
  ...createAction('conversations', 'FETCH'),
  ...createAction('conversations', 'FETCH_ONE'),
  ...createAction('conversations', 'ADD_MESSAGE'),
  ...createAction('conversations', 'UPDATE_PARTICIPANT'),
  ...createAction('conversations', 'UPDATE_PARTICIPANTS'),

  CLEAR: 'licenses/CLEAR',
  CLEAR_ENTITIES: 'licenses/CLEAR_ENTITIES',
};
