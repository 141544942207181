import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Container from 'emerald-ui-2/lib/Container';
import Row from 'emerald-ui-2/lib/Row';
import Col from 'emerald-ui-2/lib/Col';

import { Table } from 'components/Table/v2';
import TableBody from './TableBody';

import './styles.css';

import { selectors as usersSelectors, actions as userActions } from 'state/users';
import { selectors as rolesSelectors } from 'state/roles';
import { selectors as notificationsSelectors } from 'state/globalNotifications';
import { selectors as boardAccessSelectors } from 'state/boardsAccess';

import UserProfileDetail from './components/UserProfileDetail';

export class UsersList extends Component {
  state = {
    currentUser: {},
    isUserProfileModalOpen: false,
  };

  head = [{ item: 'Name' }, { item: 'Role' }, { item: 'Status' }, { item: '' }];

  componentDidMount() {
    this.props.fetchUsers({ page: 1 });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.boardId !== this.props.boardId) {
      this.props.fetchUsers({ page: 1 });
    }
  }

  handleRoleChange = (value, user) => {
    const { updateUser, boardId, roles } = this.props;
    const permissions = roles.find((r) => r._id === value).permissions.map((p) => p._id);
    if (boardId) updateUser(user.id, { ...user, role: value, permissions });
  };

  handleStatusChange = (value, user) => {
    const { updateUser, boardId } = this.props;
    if (boardId) updateUser(user.id, { ...user, isActive: value });
  };

  handleCloseUserProfile = () => this.setState({ isUserProfileModalOpen: false });

  handleShowUserProfile = (user) => this.setState({ currentUser: user, isUserProfileModalOpen: true });

  handleUserProfileSubmit = (user) => {
    this.props.updateUser(user.id, user);
    this.setState({ isUserProfileModalOpen: false });
  };

  handleDeleteAction = (user) => {
    const { deleteUser } = this.props;
    deleteUser(user.id);
  };

  handleResendAction = (user) => {
    const { resendInvitation } = this.props;
    resendInvitation(user);
  };

  render() {
    const { isUserProfileModalOpen, currentUser } = this.state;
    const { loggedInUser, boardAccess, users, roles, notifications, fetchStatus } = this.props;
    return (
      <Container className="user-list-container">
        <UserProfileDetail
          {...{
            isOpen: isUserProfileModalOpen,
            onClose: this.handleCloseUserProfile,
            user: currentUser,
            roles,
            notifications,
            onSubmit: this.handleUserProfileSubmit,
          }}
        />
        <Row>
          <Col xs={12} md={3}>
            <ul className="user-list-side-menu text-color">
              <li className="side-menu-item user-list-selected">All Users</li>
            </ul>
          </Col>
          <Col xs={12} md={9}>
            <Table className="user-list-table">
              <TableBody
                fixedHeight={false}
                loggedInUser={loggedInUser}
                boardAccess={boardAccess}
                users={users}
                roles={roles}
                showLoading={fetchStatus === 'loading'}
                loadingText="Loading users"
                showBlankState={fetchStatus !== 'loading' && users.length === 0}
                blankStateText="No users found"
                handleUserProfileClick={this.handleShowUserProfile}
                onRoleChange={this.handleRoleChange}
                onStatusChange={this.handleStatusChange}
                onDeleteAction={this.handleDeleteAction}
                onResendAction={this.handleResendAction}
              />
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}

UsersList.propTypes = {
  roles: PropTypes.array,
};

UsersList.defaultProps = {
  roles: [],
};

function mapStateToProps(state) {
  const ubas = boardAccessSelectors(state);
  const us = usersSelectors(state);
  const rs = rolesSelectors(state);
  const gns = notificationsSelectors(state);
  return {
    users: us.getAllBoardSuiteUsers(),
    fetchStatus: us.getFetchStatusByPage(1),
    roles: rs.getEntitiesByPage(1),
    notifications: gns.getEntitiesByPage(1),
    boardId: state.selectedBoard.id,
    loggedInUser: state.loggedInUser,
    boardAccess: ubas.getSelectedBoardAccess(),
  };
}

export default connect(mapStateToProps, {
  fetchUsers: userActions.fetchUsers,
  updateUser: userActions.updateUser,
  deleteUser: userActions.deleteUser,
  resendInvitation: userActions.resendInvitation,
})(UsersList);
