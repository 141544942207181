import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactSelect from 'react-select/lib/Select';
import Button from 'emerald-ui-2/lib/Button';
import SingleSelect from 'emerald-ui-2/lib/SingleSelect';

import { FloatingBox, Header, Body } from 'components/FloatingBox';
import { BoxNotification } from 'components';
import { SELF_REPORTED_CE_STATUSES } from 'constants.js';

import './styles.css';

import { actions as userActions, selectors as userSelectors } from 'state/users';
import { selectors as boardAccessSelectors } from 'state/boardsAccess';

export class SelfReportedCEActionBox extends Component {
  state = {
    showConfirmDialog: false,
  };

  componentWillMount() {
    const { fetchUsers, boardUsers } = this.props;
    if (boardUsers.length === 0) fetchUsers({ page: 1 });
  }

  componentWillReceiveProps(nextProps) {
    const { selectedBoard, fetchUsers } = this.props;
    const { selectedBoard: newSelectedBoard } = nextProps;
    if (selectedBoard.id !== newSelectedBoard.id) fetchUsers({ page: 1 });
  }

  renderOptions(option) {
    return (
      <div>
        <span>{option.name}</span>
      </div>
    );
  }

  handleStatusChange = (value) => {
    const canChange = this.props.onStatusChange(value);
    if (!canChange) {
      return;
    }
    this.setState({ showConfirmDialog: true });
  };

  handleSubmit = () => {
    this.setState({ showConfirmDialog: false });
    this.props.onSubmit();
  };

  render() {
    const { boardUsers, canEdit = false } = this.props;

    const userList = boardUsers.map((item) => {
      return {
        value: item.id,
        name: `${item.firstName || ''} ${item.middleName || ''} ${item.lastName || ''}`,
      };
    });

    return (
      <FloatingBox className="self-reported-ce-detail-page-floatingbox">
        <Header>
          <span>Actions</span>
        </Header>
        <Body>
          <div>
            <p style={{ marginBottom: '1.5rem' }}>Assigned to</p>
            <ReactSelect
              name="assigned-user"
              // value={assignmentId}
              options={userList}
              optionRenderer={this.renderOptions}
              valueRenderer={this.renderOptions}
              searchable={false}
              clearable={true}
              placeholder="Select a user to assign"
              // onChange={this.handleAssignChange}
              disabled={!canEdit}
            />
          </div>
          <hr />
          <div style={{ marginTop: '2rem' }} className="self-reported-ce-action-box-actionButtons">
            <SingleSelect label="Status" onSelect={this.handleStatusChange} disabled={!canEdit}>
              <option value="all">All</option>
              {SELF_REPORTED_CE_STATUSES.map((status) => (
                <option value={status.value} selected={status.value === this.props.status}>
                  {status.name}
                </option>
              ))}
            </SingleSelect>
          </div>
        </Body>
        {canEdit && (
          <BoxNotification visible={this.state.showConfirmDialog} className="self-reported-ce-action-box-confirm-box">
            <div className="phrase">
              <h5>Are you sure you want to change the status?</h5>
            </div>
            <div className="actionButtons">
              <div className="cancelButton">
                <Button
                  onClick={() => {
                    this.setState({ showConfirmDialog: false });
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className="confirmButton">
                <Button color="primary" onClick={this.handleSubmit}>
                  Continue
                </Button>
              </div>
            </div>
            <div />
          </BoxNotification>
        )}
      </FloatingBox>
    );
  }
}

SelfReportedCEActionBox.propTypes = {
  boardUsers: PropTypes.array,
  selectedBoard: PropTypes.object,
  onStatusChange: PropTypes.func,
  onSubmit: PropTypes.func,
  fetchUsers: PropTypes.func,
};

SelfReportedCEActionBox.defaultProps = {
  boardUsers: [],
  selectedBoard: {},
  onSubmit: () => {},
  onStatusChange: () => {},
  fetchUsers: () => {},
};

function mapStateToProps(state) {
  const us = userSelectors(state);
  const ubas = boardAccessSelectors(state);

  return {
    boardUsers: us.getAllActiveNotReadyOnlyBoardSuiteUsers(),
    selectedBoard: state.selectedBoard,
    boardAccess: ubas.getSelectedBoardAccess(),
  };
}

export default connect(mapStateToProps, {
  fetchUsers: userActions.fetchUsers,
})(SelfReportedCEActionBox);
