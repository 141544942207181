import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Button from 'emerald-ui-2/lib/Button';
import Loadable from 'react-loadable';

import { Activity, PostForm, AccordionActivities, BoxNotification } from 'components';

import { actions as feedActions, selectors as feedSelectors } from 'state/feed';
import './styles.css';

const AsyncConversationDetail = Loadable({
  loader: () =>
    import('containers/ConversationDetail' /* webpackChunkName: "conversation-detail" */).then((m) => m.default),
  loading() {
    return <span />;
  },
});

export class ActivityFeed extends Component {
  state = {
    text: '',
    expanded: false,
    currentEditing: null,
    showConfirmDialog: false,
    messageToRemove: null,
    selectedConversationId: '',
    showConversationDetailDialog: false,
  };

  componentWillMount(props) {
    const { fetchFeed, roomId = '' } = this.props;
    if (roomId) {
      fetchFeed(roomId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { fetchFeed, roomId = '' } = this.props;
    if (nextProps.roomId && nextProps.roomId !== roomId && fetchFeed) {
      this.setState({ expanded: false });
      fetchFeed(nextProps.roomId);
    }
  }

  addComment = (e) => {
    e.preventDefault();
    const { addCommentToFeed, roomId } = this.props;
    const { text, currentEditing } = this.state;

    if (currentEditing) {
      return this.updateComment();
    }

    if (roomId) {
      addCommentToFeed(roomId, text);
      this.setState({ text: '' });
    }
  };

  updateComment = () => {
    const { roomId, updateCommentInFeed } = this.props;
    const { currentEditing, text } = this.state;

    if (currentEditing) {
      updateCommentInFeed(roomId, currentEditing, text);
      this.setState({ currentEditing: null, text: '' });
    }
  };

  toggleEdit(id, text) {
    const { currentEditing } = this.state;

    if (currentEditing === id) {
      this.setState({ currentEditing: null, text: '' });
      return;
    }

    this.setState({ currentEditing: id, text });
  }

  confirmRemoveAction(id) {
    this.setState({ showConfirmDialog: true, messageToRemove: id });
  }

  deleteComment = () => {
    const { roomId, deleteCommentFromFeed } = this.props;
    const { messageToRemove } = this.state;
    if (messageToRemove) {
      deleteCommentFromFeed(roomId, messageToRemove);
      this.setState({ showConfirmDialog: false, messageToRemove: null });
    }
  };

  showConversationDetail = (conversationId) => {
    this.setState({
      selectedConversationId: conversationId,
      showConversationDetailDialog: true,
    });
  };

  conversationSubtypes = [
    'conversation/initial',
    'conversation/reply',
    'conversation/set_as_completed',
    'conversation/set_as_not_complete',
  ];

  handleActivityClick = (activity) => {
    if (this.conversationSubtypes.includes(activity.subtype)) {
      this.showConversationDetail(activity.data.conversationId);
    }
  };

  renderActivity = (activity) => {
    const { loggedInUser, roomId } = this.props;
    const dateToShow = `Added ${moment(new Date(activity.createdAt)).utc().local().fromNow()}`;
    const user = activity.subtype === 'audit/comment' ? activity.sentBy : activity.data.boardUser;

    return (
      <Activity
        key={activity._id || Math.random()}
        messageId={activity._id}
        roomId={roomId}
        subtype={activity.subtype}
        user={user}
        date={activity.createdAt}
        isUpdated={activity.createdAt !== activity.updatedAt}
        displayDate={dateToShow}
        data={activity.data}
        enableEditing={
          parseInt(loggedInUser.id, 10) === parseInt(activity.sentBy.id, 10) && activity.subtype === 'audit/comment'
        }
        onEditActivity={() => this.toggleEdit(activity._id, activity.data.text)}
        onRemoveActivity={() => this.confirmRemoveAction(activity._id)}
        onClick={() => this.handleActivityClick(activity)}
        isEditing={activity._id === this.state.currentEditing}
      />
    );
  };

  handleCloseConversationDetailDialog = () => {
    this.setState({ showConversationDetailDialog: false });
  };

  render() {
    const { messages = [], loggedInUser = {}, isReadOnly = false, roomId, fetchFeed, canWriteMessages } = this.props;
    const messagesCount = messages.length;
    const { expanded, currentEditing, showConversationDetailDialog, selectedConversationId } = this.state;
    let activities = [];
    if (messagesCount <= 3 || (messagesCount > 3 && expanded)) {
      activities = messages.map(this.renderActivity);
    } else {
      activities.push(this.renderActivity(messages[0]));
      activities.push(this.renderActivity(messages[1]));
      activities.push(
        <AccordionActivities
          key={'AccordionActivitiesKey'}
          count={messagesCount}
          onFetchActivities={() => this.setState({ expanded: true })}
        />
      );
      activities.push(this.renderActivity(messages[messages.length - 1]));
    }

    const blankState =
      messagesCount === 0 ? (
        <div className="blankState">
          <em>Messages or activities not found.</em>
        </div>
      ) : null;

    return (
      <section className="activityFeed">
        <div className="activities">
          {activities}
          {blankState}
        </div>
        {!isReadOnly && (
          <PostForm
            onSendActivity={this.addComment}
            user={{
              name: `${loggedInUser.firstName || ''} ${loggedInUser.lastName || ''}`,
            }}
            text={this.state.text}
            onInputChange={(text) => this.setState({ text })}
            focus={currentEditing !== null}
          />
        )}
        <BoxNotification visible={this.state.showConfirmDialog}>
          <div className="BoxNotification">
            <h5>Are you sure you want to remove this message?</h5>
            <Button
              onClick={() => {
                this.setState({ showConfirmDialog: false });
              }}
            >
              Cancel
            </Button>
            <Button color="info" onClick={this.deleteComment} style={{ marginLeft: '15px' }}>
              Remove
            </Button>
          </div>
        </BoxNotification>
        <AsyncConversationDetail
          canWriteMessages={canWriteMessages}
          active={showConversationDetailDialog}
          onClose={this.handleCloseConversationDetailDialog}
          conversationId={selectedConversationId}
          onSent={() => {
            this.setState({ showConversationDetailDialog: false });
            fetchFeed(roomId);
          }}
          onCompleteClick={() => {
            fetchFeed(roomId);
          }}
        />
      </section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const fs = feedSelectors(state);
  return {
    loggedInUser: state.loggedInUser,
    messages: fs.getMessagesByFeedId(ownProps.roomId),
  };
}

export default connect(mapStateToProps, {
  fetchFeed: feedActions.fetchFeed,
  addCommentToFeed: feedActions.addCommentToFeed,
  deleteCommentFromFeed: feedActions.deleteCommentFromFeed,
  updateCommentInFeed: feedActions.updateCommentInFeed,
})(ActivityFeed);
