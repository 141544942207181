import at from './types';

export const fetchCompliance = (filters) => ({ type: at.FETCH, filters });
export const fetchComplianceReports = (filters) => ({ type: at.FETCH_REPORTS, filters });
export const fetchExemptionsByProfession = (professionId) => ({
  type: at.FETCH_EXEMPTIONS_BY_PROFESSION,
  professionId,
});

export const setFetchCanceled = (filters, meta) => ({ type: at.FETCH_CANCELED, filters, meta });

export const clear = () => ({ type: at.CLEAR });
export const clearEntities = () => ({ type: at.CLEAR_ENTITIES });

export const fetchComplianceDownloadReports = (boardId, filters) => ({
  type: at.FETCH_DOWNLOAD_REPORTS,
  boardId,
  filters,
});
export const clearComplianceDownloadReports = () => ({ type: at.CLEAR_DOWNLOAD_REPORTS });
