import axios from 'axios';
import { PAGE_SIZE, getOffset } from 'constants.js';
import { API_URL } from 'config';
import querystring from 'querystring';

export const fetch = (boardId, filters = {}) => {
  const { size = PAGE_SIZE, page = 1, ...rest } = filters;
  const offset = getOffset(page, size);
  if (!rest.sort) rest.sort = 'owner.full_name.keyword';
  if (rest.sort === 'search') {
    delete rest.sort;
  }
  const newFilters = { ...rest, offset, limit: size };
  const queryString = querystring.stringify(newFilters);
  return axios.get(`${API_URL}/boards/${boardId}/licenses?${queryString}`);
};

export const fetchCount = ({ boardId, filters }) => {
  const queryString = querystring.stringify(filters);
  return axios.get(`${API_URL}/boards/${boardId}/licenses/count?${queryString}`);
};

export const fetchPeriods = ({ licenseId, token }) => {
  return axios.request({
    url: `${API_URL}/licenses/${licenseId}/periods`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const fetchLicense = (licenseId, boardId, token) => {
  return axios.request({
    url: `${API_URL}/boards/${boardId}/licenses/${licenseId}`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const fetchAccountType = (licenseId, token) => {
  return axios.request({
    url: `${API_URL}/licenses/${licenseId}/account-type`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const fetchEmail = (licenseId, token) => {
  return axios.request({
    url: `${API_URL}/licenses/${licenseId}/email`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const fetchScenario = ({ scenarioId, token }) => {
  return axios.request({
    url: `${API_URL}/scenario/${scenarioId}`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const updateEmail = ({ licenseId, email, token }) => {
  return axios.request({
    url: `${API_URL}/licenses/${licenseId}/email`,
    method: 'PUT',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: {
      email,
    },
  });
};

export const resendEmail = ({ auditId, licenseId, token }) => {
  return axios.request({
    url: `${API_URL}/licenses/${licenseId}/email/resend`,
    method: 'POST',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: {
      auditId,
      licenseId,
    },
  });
};

export const fetchLicenseRelation = ({professionId, ownerId, token, type}) => {
  return axios.request({
    url: `${API_URL}/professions/${professionId}/relation?type=${type}&owner=${ownerId}`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};