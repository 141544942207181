import axios from "axios";
import { API_URL } from "../config";

export function fetchPostCECredit(postCECreditId, token) {
  return axios.request({
    url: `${API_URL}/licensee/posting/${postCECreditId}`,
    method: "GET",
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}

export function updatePostCECredit(postCECreditId, data, token) {
  return axios.request({
    url: `${API_URL}/licensee-ce-approval/${postCECreditId}`,
    method: "PUT",
    headers: {
      authorization: `Bearer ${token}`,
    },
    data,
  });
}

export function fetchPostCECreditList(idBoard, _user, filters = {}) {
  const {
    CeApprovalsize: limit,
    CeApprovalpage: page,
    CeApprovalorder: sortOrder,
    CeApprovalsort: sortBy,
    CeApprovalTerm: term,
    CeApprovalAssigned: userAssigned,
    CeApprovalStatus: status,
    minReceived: minCompletedDate,
    maxReceived: maxCompletedDate,
  } = filters;

  return axios.request({
    url: `${API_URL}/licensee-ce-approval/list`,
    method: "GET",
    params: {
      limit,
      page,
      idBoard,
      sortOrder,
      sortBy,
      term,
      minCompletedDate,
      maxCompletedDate,
      userAssigned,
      status,
    }
  });
}

export function getCeApprovalDetail(postCECreditId, token) {
  return axios.request({
    url: `${API_URL}/licensee-ce-approval/${postCECreditId}/detail`,
    method: "GET",
    headers: {
      authorization: `Bearer ${token}`
    },
  });
}