import { call, put, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import * as schema from '../schema';

import * as api from 'api/globalNotifications';
import at from '../actions/types';

export function* fetchGlobalNotifications() {
  const state = yield select();
  const { authentication } = state;
  const filters = { page: 1 };
  yield put({ type: at.FETCH_REQUEST, filters });
  try {
    let response;
    try {
      response = yield call(api.fetch, authentication.accessToken);
    } catch (e) {
      console.log(e);
      yield put({ type: at.FETCH_FAILURE, filters, message: e.message || 'Something went wrong' });
      return;
    }

    const normalizedData = normalize(response.data, schema.globalNotifications);
    yield put({ type: at.FETCH_SUCCESS, ...normalizedData, filters });
  } catch (error) {
    console.log('error', error);
  }
}
