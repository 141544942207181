import axios from 'axios';

import { FETCH_POSTCE_REQUEST, FETCH_POSTCE_SUCCESS, FETCH_POSTCE_FAILURE } from './constants';

import { API_URL } from '../config';

export function fetchPostCEcredit(rosterAttendeeId, postCEcreditId, ownerId, type) {
  return function (dispatch) {
    dispatch({ type: FETCH_POSTCE_REQUEST, status: 'loading', data: [] });
    return axios
      .get(`${API_URL}/${type}/posting/${postCEcreditId}?rosterAttendeeId=${rosterAttendeeId}&ownerId=${ownerId}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: FETCH_POSTCE_SUCCESS, status: 'success', data: response.data });
        }
      })
      .catch((err) => {
        dispatch({ type: FETCH_POSTCE_FAILURE, error: err, status: 'failed' });
      });
  };
}
