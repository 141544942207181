import { getFiltersFromQuery } from '../common';

export default function (globalState) {
  const state = globalState.compliance;

  const getEntityById = (id) => state.entities[id] || {};

  const getIdsByPage = (page) => state.pages[page] || [];

  const getEntitiesByPage = (page) => {
    const ids = getIdsByPage(page);
    return ids.length > 0 ? ids.map((id) => getEntityById(id)) : [];
  };

  const getFetchStatusById = (id) => state.fetchStatus[id];

  const getFetchStatusByPage = (page) => state.pageFetchStatus[page];

  const getPagination = () => state.pagination || {};

  const getFilters = () => {
    const query = globalState.routing.locationBeforeTransitions.query;
    return getFiltersFromQuery(query);
  };

  const getExemptions = () => state.exemptions;

  const getReports = () => state.reports;

  const getDownloadReport = () => state.downloadReport;

  return {
    getEntityById,
    getIdsByPage,
    getEntitiesByPage,
    getFetchStatusById,
    getFetchStatusByPage,
    getPagination,
    getFilters,
    getReports,
    getExemptions,
    getDownloadReport,
  };
}
