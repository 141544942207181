import at from './types';

export const fetchTranscript = (licensePeriodId, licenseNumber, licenseId, professionId, userId, meta) => ({
  type: at.FETCH,
  licensePeriodId,
  licenseNumber,
  licenseId,
  professionId,
  userId,
  meta,
});


export const updateTranscript = (transcript, userId, meta) => ({
  type: at.UPDATE,
  transcript,
  userId,
  meta,
});

export const postLicenseeClearToRenew = (licensePeriodId, licenseTempCompl) => ({
  type: at.UPDATE_LICENSEE_CLEAR,
  licensePeriodId,
  licenseTempCompl,
});

export const deleteWaverScenario = (scenarioId, data, search) => ({
  type: at.DELETE_WAIVER_SCENARIO,
  data,
  scenarioId,
  search
});
