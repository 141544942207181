import { combineReducers } from 'redux';
// Import reducers here
import boards from 'state/boards/reducers';
import professions from './professions';
import { selectedBoard } from './boards';
import authentication from './authentication';
import loggedInUser from './loggedInUser';
import requests from './requests';
import postCEcredit from './postCEcredit';
import notifications, * as fromNotifications from './notifications';
import auditCustomSelectionForm from './auditCustomSelectionForm';
import auditCustomSelection from './auditCustomSelection';
import exemption from './exemption';
import account from './account';

//This is needed to keep navigation syncronized with redux
import { routerReducer } from 'react-router-redux';

import { reducers as auditsBulks } from 'state/auditsBulks';
import { rootReducer as audits } from 'state/audits';
import { rootReducer as licenses } from 'state/licenses';
import { rootReducer as transcripts } from 'state/transcripts';
import { rootReducer as licensesStatuses } from 'state/licensesStatuses';
import { rootReducer as users } from 'state/users';
import { rootReducer as usersByState } from 'state/usersByState';
import { rootReducer as boardsState } from 'state/boardProfile';
import { rootReducer as states } from 'state/states';
import { rootReducer as boardApplications } from 'state/boardApplications';
import { rootReducer as roles } from 'state/roles';
import { rootReducer as boardUser } from 'state/boardUser';
import { rootReducer as globalNotifications } from 'state/globalNotifications';
import { rootReducer as boardsAccess } from 'state/boardsAccess';
import { rootReducer as compliance } from 'state/compliance';
import { rootReducer as feed } from 'state/feed';
import { rootReducer as conversations } from 'state/conversations';
import { rootReducer as notificationsPanel } from 'state/notificationsPanel';
import { rootReducer as courseHistory } from 'state/courseHistory';


const appReducer = combineReducers({
  account,
  audits,
  auditCustomSelection,
  auditCustomSelectionForm,
  auditsBulks,
  authentication,
  boardApplications,
  boards,
  boardsAccess,
  boardsState,
  boardUser,
  compliance,
  conversations,
  exemption,
  feed,
  globalNotifications,
  licenses,
  licensesStatuses,
  loggedInUser,
  notifications,
  postCEcredit,
  professions,
  requests,
  roles,
  routing: routerReducer,
  selectedBoard,
  users,
  usersByState,
  states,
  transcripts,
  notificationsPanel,
  courseHistory,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'LOGOUT': {
      state = {
        selectedBoard: state.selectedBoard,
        loggedInUser: state.loggedInUser,
      };
      break;
    }
    default: {
    }
  }
  return appReducer(state, action);
};

export default rootReducer;

export const getNotificationByType = (state, type) => fromNotifications.getOneByType(state.notifications, type);
export const getNotificationsByTypes = (state, types) => fromNotifications.getByTypes(state.notifications, types);
