import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Navbar from 'emerald-ui-2/lib/Navbar';
import Nav from 'emerald-ui-2/lib/Nav';
import AppCard from 'emerald-ui-2/lib/AppCard';
import Container from 'emerald-ui-2/lib/Container';
import logo from 'containers/AppBar/logo-ceb-by-propelus.svg';
import nextGen from './nextgen.svg';
import classicView from './classic.svg';
import config from 'config';
import * as cookies from 'utils/cookies';
import * as utils from 'utils';
import { ssoAuth } from 'api/auth';
import initializePendo from '../../utils/pendo';
import './styles.css';

import { logout } from 'actions';

const { REACT_APP_ENV = 'test' } = process.env;
const isTestEnv = REACT_APP_ENV === 'test';

class SingleLoginPage extends Component {
  state = {
    showSingleLoginPage: false,
  };

  async componentDidMount() {
    const { LEGACY_COOKIE_NAME, COOKIE_NAME } = config;

    const cookie = cookies.getCookie(LEGACY_COOKIE_NAME);
    if (!cookie && !isTestEnv) {
      this.kickOut();
    }

    const authResponse = await ssoAuth(cookie);
    if (!authResponse || !authResponse.data) {
      this.kickOut();
    }
    const { data } = authResponse;
    cookies.setCookie(LEGACY_COOKIE_NAME, data.legacyAccessToken);
    cookies.setCookie(COOKIE_NAME, data.accessToken);

    if (data.accessUpgradeBs) {
      utils.redirectSite(config.UPGRADE_ACCESS_BS, '/sso');
    }

    //if legacy only user
    if (data.legacyAccessToken && !data.accessToken) {
      this.redirectToLegacy();
      return;
    } else if (data.accessToken && !data.legacyAccessToken) {
      //if bs only user
      this.pendo(data.accessToken);
      this.redirectToBoardSuite(data.accessToken);
      return;
    }
    this.setState({
      showSingleLoginPage: true,
    });


    this.pendo(data.accessToken);
  }

  pendo = (token)=>{
    const user = JSON.parse(new Buffer(token.split('.')[1], 'base64').toString());
    const data= {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      userName: user.userName,
      email: user.email
    }
    initializePendo(data);
  }

  kickOut = () => {
    window.location.replace(`${config.LAUNCHPAD_URL}/logout`);
  };

  loginBoardSuite = () => {
    const cookie = cookies.getCookie(config.COOKIE_NAME);
    this.redirectToBoardSuite(cookie);
  };

  loginLegacy = () => {
    this.redirectToLegacy();
  };

  redirectToLegacy = () => {
    window.location.href = config.SECURE_GATEWAY;
  };

  redirectToBoardSuite = (token) => {
    const { search = '' } = this.props.location;

    const searchObj = queryString.parse(search);

    const initialSearch = queryString.stringify({ token, ...searchObj });

    this.props.history.push(`/login?${initialSearch}`);
  };

  render() {
    const { logout } = this.props;
    const classicViewTxt = (
      <span>
        <strong>Classic View</strong>
        <br />
        Manage Approvals
      </span>
    );

    const boarSuiteViewTxt = (
      <span>
        <strong>Board Suite</strong>
        <br />
        Access audits and reports
      </span>
    );
    return !this.state.showSingleLoginPage ? (
      <div>Redirecting...</div>
    ) : (
      <div className="single-login-page">
        <div>
          <Navbar inverse>
            <Container>
              <Nav grow>
                <Navbar.Brand>
                  <a href={config.BS_HOME}>
                    <img src={logo} alt="Board Suite" />
                  </a>
                </Navbar.Brand>
              </Nav>
              <Nav collapsible>
                <a href="/" onClick={() => logout({ redirect: true })}>
                  Logout
                </a>
              </Nav>
            </Container>
          </Navbar>
        </div>
        <div className="single-login-page-body">
          <Container>
            <div className="single-login-page-text">What would you like to access?</div>
            <div className="single-login-page-appcards">
              <AppCard
                className="single-login-page-classic"
                color="#ECEFF1"
                caption={classicViewTxt}
                image={classicView}
                onClick={this.loginLegacy}
              />
              <AppCard
                className="single-login-page-nextgen"
                color="#E0F2F1"
                caption={boarSuiteViewTxt}
                image={nextGen}
                onClick={this.loginBoardSuite}
              />
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

export default connect(mapStateToProps, {
  logout,
})(SingleLoginPage);
