export const getEntities = (state) => state.boardsState.entities;

export const getIsFetching = (state) => state.isFetching;

export const getErrorMessage = (state) => state.errorMessage;

export default {
  getEntities,
  getIsFetching,
  getErrorMessage,
};
