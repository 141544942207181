import { getFiltersFromQuery } from '../common';

export default function (globalState) {
  const state = globalState.users;

  const isReadyOnlyUser = (roleId) => {
    const roles = globalState.roles.entities;
    if (!roles[roleId]) {
      return false;
    }
    return roles[roleId].name === 'Read-only';
  };

  const getEntitiesObject = () => state.entities;

  const getEntityById = (id) => state.entities[id] || {};

  const getIdsByPage = (page) => state.pages[page] || [];

  const getEntitiesByPage = (page) => {
    const ids = getIdsByPage(page);
    return ids.length > 0 ? ids.map((id) => getEntityById(id)) : [];
  };

  const getAllUsers = () => {
    return getEntitiesByPage(1);
  };

  const getAllLegacyUsers = () => {
    return getEntitiesByPage(1).filter((u) => !u.isBoardSuiteUser);
  };

  const getAllActiveLegacyUsers = () => {
    return getAllLegacyUsers().filter((entity) => !entity.isPending && entity.isActive);
  };

  /**
   * It's a board suite user if it's in user_board_xref table (u.isBoardSuiteUser)
   *  and it's not a legacy exclusive !(u.isLegacy && !u.isBoardSuite)
   */
  const getAllBoardSuiteUsers = () => {
    return getEntitiesByPage(1).filter((u) => u.isBoardSuiteUser && !(u.isLegacy && !u.isBoardSuite));
  };

  const getAllActiveBoardSuiteUsers = (page) => {
    return getAllBoardSuiteUsers().filter((entity) => !entity.isPending && entity.isActive);
  };

  const getAllActiveNotReadyOnlyBoardSuiteUsers = (page) => {
    return getAllBoardSuiteUsers().filter(
      (entity) => !entity.isPending && entity.isActive && !isReadyOnlyUser(entity.role)
    );
  };

  const getBoardSuiteSingleLoginUsers = () => {
    return getAllBoardSuiteUsers().filter((entity) => !entity.isBoardSuite);
  };

  const getFetchStatusByPage = (page) => state.fetchStatus[page];

  const getFetchStatus = () => state.fetchStatus;

  const getPagination = () => state.pagination || {};

  const getFilters = () => {
    let query = {};
    if (globalState.routing.locationBeforeTransitions) {
      query = globalState.routing.locationBeforeTransitions.query;
    }
    return getFiltersFromQuery(query);
  };

  const getCount = () => state.count;

  return {
    getEntitiesObject,
    getEntityById,
    getEntitiesByPage,
    getAllUsers,
    getAllBoardSuiteUsers,
    getAllActiveBoardSuiteUsers,
    getAllActiveNotReadyOnlyBoardSuiteUsers,
    getBoardSuiteSingleLoginUsers,
    getIdsByPage,
    getFetchStatusByPage,
    getFetchStatus,
    getFilters,
    getPagination,
    getCount,
    getAllLegacyUsers,
    getAllActiveLegacyUsers,
  };
}
