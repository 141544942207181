import { takeLatest, put, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';

import at from '../actions/types';
import * as actions from '../actions';
import selectors from '../reducers/selectors';

import { fetchRoles } from './fetchRoles';

export function* watchLocationChange({ payload }) {
  const state = yield select();
  const roles = selectors(state).getEntitiesByPage(1);
  const shouldFetchRoles = payload.pathname.indexOf('login') < 0 ? true : false;

  if (shouldFetchRoles && roles.length === 0) {
    yield put(actions.fetchRoles());
  }
}

export default function* root() {
  yield takeLatest(LOCATION_CHANGE, watchLocationChange);

  yield takeLatest(at.FETCH, fetchRoles);
}
