import React, { Component } from 'react';
import NotificationPanelItem from '../notificationPanelItem';

export default class MessageReceived extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConversationDetailDialog: false,
    };
  }

  handleNotificationClick = (conversationId) => (event) => {
    this.props.onClick(conversationId);
  };

  render() {
    const { messages, opened } = this.props;

    const lastMessage = messages[messages.length - 1];
    const { data = {}, createdAt } = lastMessage;
    let [icon = '', title = '', date = '', plainTemplate = '', conversationId = ''] = [
      data.icon,
      data.title,
      createdAt,
      data.plainTemplate,
      data.conversation,
    ];

    return (
      <div onClick={this.handleNotificationClick(conversationId)}>
        <NotificationPanelItem
          icon={icon}
          title={title}
          date={date}
          desc={`${data.fullname} ${plainTemplate}`}
          opened={opened}
        />
      </div>
    );
  }
}
