import { createStore, applyMiddleware, compose } from 'redux';
import promise from 'redux-promise';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import throttle from 'lodash/throttle';

import reducers from './reducers';
import rootSaga from 'state/sagas';

const loadStateFromLocalStorage = () => {
  try {
    const userId = localStorage.userId;
    const state = userId && localStorage[userId] ? JSON.parse(localStorage[userId]) : {};
    return state;
  } catch (err) {
    return undefined;
  }
};

const saveStateToLocalStorage = (state) => {
  try {
    let userId = state.loggedInUser ? state.loggedInUser.id : null;
    if (userId) {
      localStorage.setItem(userId, JSON.stringify(state));
      localStorage.setItem('userId', userId);
    } else {
      localStorage.setItem('userId', null);
    }
  } catch (err) {
    //Log error if you want
  }
};

const persistedState = loadStateFromLocalStorage();
//this line allows to user redux dev tool on the browser
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  reducers,
  persistedState,
  composeEnhancers(applyMiddleware(promise, thunkMiddleware, sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

//update de localStorage automatically when the redux state change
store.subscribe(
  throttle(() => {
    const state = store.getState();
    saveStateToLocalStorage({
      loggedInUser: state.loggedInUser,
      authentication: state.authentication,
      auditCustomSelection: state.auditCustomSelection,
      selectedBoard: state.selectedBoard,
    });
  }, 1000)
);
