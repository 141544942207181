// import { call, put, select } from 'redux-saga/effects'
// import * as api from 'api/audits'

// import at from '../actions/types';

// import { actions as notifications } from 'state/notifications'

export function* unarchiveAudit({ auditId, meta = {} }) {
  // const {
  //   addNotification = true,
  //   notification = {},
  //   // fetchArchived = false
  // } = meta;
  // const state = yield select();
  // const { authentication } = state;
  // yield put({ type: at.UNARCHIVE_REQUEST });
  // try {
  //   yield call(api.unarchiveAudit, { auditId, token: authentication.accessToken });
  // } catch (e) {
  //   yield put({ type: at.UNARCHIVE_FAILURE, message: e.message || 'Something went wrong' });
  //   if (addNotification) {
  //     // Add failure notification
  //     const { failureText } = notification;
  //     const n = { type: 'UNARCHIVE_AUDIT', text: failureText || 'Error unarchiving the audit' };
  //     yield put(notifications.addAndRemove(n, 5000));
  //     return;
  //   }
  // }
  // yield put({ type: at.UNARCHIVE_SUCCESS, auditId });
  // if (addNotification) {
  //   // Add success notification
  //   const { successText } = notification;
  //   const n = { type: 'UNARCHIVE_AUDIT', text: successText || 'Audit unarchived' };
  //   yield put(notifications.addAndRemove(n, 5000));
  // }
}
