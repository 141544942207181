import React from 'react';
import NotificationPanelItem from '../notificationPanelItem';
import { connect } from 'react-redux';
import { selectBoard } from 'actions';
import { Link } from 'react-router-dom';

const AuditAssignment = (props) => {
  const {
    messages,
    opened,
    onClick: toggleNotificationPanel,
    redirectTo,
    extraData,
    selectedBoard,
    selectBoard,
    boards,
  } = props;

  const lastMessage = messages[messages.length - 1];
  const { data = {}, createdAt } = lastMessage;
  const { boardId } = extraData;
  let [icon = '', title = '', date = '', plainTemplate = '', audit = ''] = [
    data.icon,
    data.title,
    createdAt,
    data.plainTemplate,
    data.audit,
  ];

  if (messages.length > 1) {
    plainTemplate = `assigned ${messages.length} audits to you`;
  }

  let redirectUrl = messages.length === 1 ? `/audits/${audit}` : `/audits`;

  function clickHandler(e) {
    const { id } = selectedBoard;

    const newBoardSelected = boards.find(({ id }) => id === boardId);

    if (id !== boardId) selectBoard(newBoardSelected);

    toggleNotificationPanel(e);
  }

  return (
    <Link to={redirectUrl} onClick={clickHandler}>
      <div>
        <NotificationPanelItem
          icon={icon}
          title={title}
          date={date}
          desc={`${data.fullname} ${plainTemplate}`}
          opened={opened}
          redirectTo={redirectTo}
        />
      </div>
    </Link>
  );
};

const mapStateToProps = ({ selectedBoard, boards, authentication }) => ({ selectedBoard, boards, authentication });

export default connect(mapStateToProps, {
  selectBoard,
})(AuditAssignment);
