import { call, put, select } from 'redux-saga/effects';
import * as api from 'api/transcripts';

import at from '../actions/types';

export function* postLicenseeClearToRenew({licensePeriodId, licenseTempCompl}) {
  const state = yield select();
  const { authentication } = state;
  yield put({ type: at.UPDATE_LICENSEE_CLEAR_REQUEST});
  let response;
  try {
    response = yield call(api.postLicenseeClearToRenew, {licensePeriodId, licenseTempCompl, token: authentication.accessToken,});
  } catch (e) {
    yield put({ type: at.FETCH_FAILURE, message: e.message || 'Something went wrong' });
    return;
  }
  yield put({ type: at.FETCH_SUCCESS });
}
