import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Panel from 'emerald-ui-2/lib/Panel';
import Table from 'emerald-ui-2/lib/Table';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import IconButton from 'emerald-ui-2/lib/IconButton';
import TextField from 'emerald-ui-2/lib/TextField';

import './styles.css';

class SelfReportedCESubjectAreas extends Component {
  renderSubjectAreas = () => {
    const { subjectAreas, canEdit } = this.props;
    return subjectAreas.map((subjectArea) => {
      const hours = canEdit ? (
        <TextField
          shape="classic"
          value={subjectArea.appliedHours}
          onChange={this.handlePostedHoursChange(subjectArea.id)}
        />
      ) : (
        subjectArea.appliedHours
      );
      return (
        <tr key={subjectArea.id}>
          <td>{subjectArea.name}</td>
          <td>{subjectArea.requestedHours}</td>
          <td>{hours}</td>
        </tr>
      );
    });
  };

  handlePostedHoursChange = (subjectAreaId) => (event) => {
    const value = parseInt(event.target.value, 10);
    this.props.onSubjectAreaChange(value, subjectAreaId);
  };

  render() {
    return (
      <Row>
        <Col xs={12}>
          <div className="self-reported-ce-detail-page-title">
            <IconButton icon="access_time" className="self-reported-ce-detail-page-titleIcons" />
            <h5>Hours</h5>
          </div>
        </Col>
        <Col xs={12}>
          <Panel className="self-reported-ce-detail-page-card">
            <div className="self-reported-ce-detail-page-cardBody">
              <Table borderless striped hover>
                <thead>
                  <tr>
                    <th>CE Credit Name</th>
                    <th>Requested Hours</th>
                    <th>Posted Hours</th>
                  </tr>
                </thead>
                <tbody>{this.renderSubjectAreas()}</tbody>
              </Table>
            </div>
          </Panel>
        </Col>
      </Row>
    );
  }
}

SelfReportedCESubjectAreas.propTypes = {
  subjectAreas: PropTypes.array,
};

SelfReportedCESubjectAreas.defaultProps = {
  subjectAreas: [],
};

export default SelfReportedCESubjectAreas;
