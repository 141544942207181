import React, { Component } from 'react';
import Panel from 'emerald-ui-2/lib/Panel';
import FileUploadItem from 'emerald-ui-2/lib/FileUploadItem';

import './styles.css';

class SelfReportedCEAttachmentsInfo extends Component {
  buildAttachments = () => {
    const { attachments = [] } = this.props;
    return (
      attachments.length > 0 &&
      attachments.map((attachment) => (
        <div key={attachment.id} className="self-reported-ce-attachment-box">
          <FileUploadItem
            name={attachment.fileName}
            downloadable
            onDownload={() => window.open(attachment.path, '_blank')}
          />
        </div>
      ))
    );
  };

  render() {
    return (
      <Panel className="self-reported-ce-detail-page-card">
        <div className="self-reported-ce-detail-page-cardHeader">
          <p>Documentation</p>
        </div>
        {this.buildAttachments()}
      </Panel>
    );
  }
}

export default SelfReportedCEAttachmentsInfo;
