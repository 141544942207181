import React from 'react';
import OverlayTrigger from 'emerald-ui-2/lib/OverlayTrigger';
import Popover from 'emerald-ui-2/lib/Popover';
import moment from 'moment';
import Avatar from 'emerald-ui-2/lib/Avatar/Avatar';

const DisallowedDetailPopover = ({ disallow }) => {
  const popover = (
    <Popover id="disallowedDetailPopover" placement="bottom">
      <div className="disallowed-popover-main">
        <div className="disallowed-popover-date">{moment(new Date(disallow.addedDate)).format('lll')}</div>
        <div className="disallowed-popover-desc">
          <b>{disallow.hoursToDisallow}</b> Hours disallowed
        </div>
        <hr />
        <div className="disallowed-popover-comment">
          <Avatar />
          <div className="disallowed-popover-commnet-text">{disallow.comment || 'No comments added'}</div>
        </div>
      </div>
    </Popover>
  );

  return (
    <OverlayTrigger triggerType="click" placement="bottom" overlay={popover}>
      <div className="disallowed-popover-edited">
        <span onClick={(e) => e.preventDefault()} className="transcript-edited-hours-msg">
          (Edited)
        </span>
      </div>
    </OverlayTrigger>
  );
};

export default DisallowedDetailPopover;
