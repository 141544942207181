import { call, put, select, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { normalize } from 'normalizr';
import * as api from 'api/licenses';

import isEmpty from 'lodash/isEmpty';

import * as schema from '../schema';
import at from '../actions/types';

import { selectors } from 'state/licenses';

export function* fetchLicenses(filters) {
  const state = yield select();
  // const { authentication } = state;
  const boardId = state.selectedBoard.id;
  yield put({ type: at.FETCH_REQUEST, filters, meta: {} });
  try {
    yield call(delay, 300);
    let response;
    try {
      response = yield call(api.fetch, { boardId, filters });
    } catch (e) {
      console.log(e);
      yield put({
        type: at.FETCH_FAILURE,
        filters,
        message: e.message || 'Something went wrong',
        meta: {},
      });
      return;
    }

    const { headers } = response;
    const totalCount = parseInt(headers['x-total-count'], 10);
    const normalizedData = normalize(response.data, schema.licenses);
    yield put({
      type: at.FETCH_SUCCESS,
      ...normalizedData,
      filters,
      totalCount,
      meta: {},
    });
  } catch (error) {
    console.log('error', error);
  }
}

export function* fetchLicensesCount(filters) {
  const state = yield select();
  // const { authentication } = state;
  const boardId = state.selectedBoard.id;
  yield put({ type: at.FETCH_COUNT_REQUEST });
  try {
    yield call(delay, 300);
    let response;
    try {
      response = yield call(api.fetchCount, { boardId, filters });
    } catch (e) {
      console.log(e);
      const message = e.response ? e.response.data.message : e.message;
      yield put({
        type: at.FETCH_COUNT_FAILURE,
        filters,
        message: message || 'Something went wrong',
        meta: {},
      });
      return;
    }

    yield put({
      type: at.FETCH_COUNT_SUCCESS,
      count: response.data,
      filters,
      meta: {},
    });
  } catch (error) {
    console.log('error', error);
  }
}

export function* fetchPrevAndNextIfNeeded(filters) {
  const state = yield select();
  const licensesSelectors = selectors(state);

  let { size, page } = filters;
  const [prevPage, nextPage] = [page - 1, page + 1];
  const totalItems = licensesSelectors.getTotalCount();

  const prevEntities = licensesSelectors.getEntitiesByPage(prevPage);
  const nextEntities = licensesSelectors.getEntitiesByPage(nextPage);

  if (isEmpty(prevEntities) && page > 1) {
    yield fork(fetchLicenses, { ...filters, page: prevPage });
  }

  if (isEmpty(nextEntities) && page * size < totalItems) {
    yield fork(fetchLicenses, { ...filters, page: nextPage });
  }
}

export function* watchFetchLicenses({ filters, force = false, meta }) {
  const state = yield select();
  const licensesSelectors = selectors(state);
  const currentEntities = licensesSelectors.getEntitiesByPage(filters.page);
  if (!force && !isEmpty(currentEntities)) {
    yield fork(fetchPrevAndNextIfNeeded, filters);
    return;
  }
  yield call(fetchLicenses, filters);
  yield fork(fetchPrevAndNextIfNeeded, filters);
}

export function* watchFetchLicensesCount({ filters, force = false, meta }) {
  yield call(fetchLicensesCount, filters);
}
