import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import Overlay from 'components/Overlay';
import Spinner from 'emerald-ui-2/lib/Spinner';

const LoaderOverlay = ({ text }) => (
  <Overlay className="table-v2-loader-overlay">
    <div style={{ margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Spinner size="md" color="info" style={{ display: 'block' }} />
      <span className="table-v2-loader-text">{text}</span>
    </div>
  </Overlay>
);

LoaderOverlay.propTypes = {
  text: PropTypes.string,
};

LoaderOverlay.defaultProps = {
  text: 'Loading',
};

export default LoaderOverlay;
