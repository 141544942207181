import React from 'react';
import Checkbox from 'emerald-ui-2/lib/Checkbox';
import './styles.css';

const renderChildrenNotification = (notificationId, notifications, selectedNotifications, onNotificationChange) => {
  const children = notifications.filter((n) => n.parent === notificationId);
  const parentChecked = selectedNotifications.includes(notificationId);
  const display = parentChecked ? 'block' : 'none';

  return children.map((n) => {
    return (
      <div key={n._id} style={{ marginLeft: '20px', display }}>
        <Checkbox
          className="checkbox"
          key={n._id}
          checked={selectedNotifications.includes(n._id)}
          label={n.name}
          onChange={(event) => {
            event.stopPropagation();
            onNotificationChange(n._id, event.target.checked);
          }}
        />
      </div>
    );
  });
};

const NotificationsSection = ({ notifications, selectedNotifications, onNotificationChange }) => {
  const mainNotifications = notifications.filter((n) => !n.parent);
  return (
    <div title="These options will allow the user to get notified of all activity in a certain area, by all users. If you leave them unchecked, the user will only get notifications about things they've been assigned to or messages they've been included in">
      {mainNotifications.map((n, index) => (
        <div key={index}>
          <div>
            <Checkbox
              className="checkbox"
              key={n._id}
              checked={selectedNotifications.includes(n._id)}
              label={n.name}
              onChange={(event) => onNotificationChange(n._id, event.target.checked)}
            />
          </div>
          <div>{renderChildrenNotification(n._id, notifications, selectedNotifications, onNotificationChange)}</div>
        </div>
      ))}
    </div>
  );
};

export default NotificationsSection;
