import axios from 'axios';
import { API_URL } from 'config';

export function fetchBoardUser(createPasswordCode) {
  return axios.get(`${API_URL}/users/${createPasswordCode}`);
}

export function fetchBoardUserProfile(userId, boardId = 0) {
  return axios.get(`${API_URL}/users/${userId}/profile?boardId=${boardId}`);
}

export function createBoardPassword(userId, password) {
  return axios.request({
    url: `${API_URL}/users/${userId}`,
    method: 'POST',
    data: { userId, password },
  });
}

export function updateBoardUserProfile(boardUserProfile, token) {
  return axios.request({
    url: `${API_URL}/users/${boardUserProfile.userId}/profile`,
    method: 'PUT',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: boardUserProfile,
  });
}
