import { call, put, select } from 'redux-saga/effects';
import * as api from 'api/users';
import at from '../actions/types';

import { actions as notifications } from 'state/notifications';

export function* updateUser({ userId, data, meta = {} }) {
  const { notification = {} } = meta;
  const state = yield select();
  const { authentication, selectedBoard } = state;

  yield put({ type: at.UPDATE_REQUEST });
  try {
    data.boardId = selectedBoard.id;
    yield call(api.update, { userId, data, token: authentication.accessToken });
  } catch (e) {
    console.log(e);
    yield put({ type: at.UPDATE_FAILURE, data, message: e.message || 'Something went wrong' });

    // Add failure notification
    const { failureText } = notification;
    const n = { type: 'UPDATE_USER', text: failureText || 'Error updating the user' };
    yield put(notifications.addAndRemove(n, 5000));

    return;
  }

  // Add success notification
  const { successText } = notification;
  const n = { type: 'UPDATE_USER', text: successText || `User ${data.firstName || ''} ${data.lastName || ''} updated` };
  yield put(notifications.addAndRemove(n, 5000));

  yield put({ type: at.UPDATE_SUCCESS, userId, data });
}
