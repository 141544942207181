import { takeLatest } from 'redux-saga/effects';

import at from '../actions/types';

import { fetchFeed } from './fetchFeed';
import { addCommentToFeed } from './addCommentToFeed';
import { deleteCommentFromFeed } from './deleteCommentFromFeed';
import { updateCommentInFeed } from './updateCommentInFeed';
import { createFeed } from './createFeed';

export default function* root() {
  yield takeLatest(at.CREATE, createFeed);
  yield takeLatest(at.FETCH, fetchFeed);
  yield takeLatest(at.ADD_COMMENT, addCommentToFeed);
  yield takeLatest(at.DELETE_COMMENT, deleteCommentFromFeed);
  yield takeLatest(at.UPDATE_COMMENT, updateCommentInFeed);
}
