import { getFiltersFromQuery } from '../common';

export default function (globalState) {
  const state = globalState.audits;

  const getEntityById = (id) => state.entities[id] || {};

  const getIdsByPage = (type, page) => state[type].pages[page] || [];

  const getEntitiesByPage = (type, page) => {
    const ids = getIdsByPage(type, page);
    return ids.length > 0
      ? ids
          .filter((id) => {
            const isArchived = getEntityById(id).isArchived;
            return type === 'archived' ? isArchived : !isArchived;
          })
          .map((id) => getEntityById(id))
      : [];
  };

  const getFetchStatusByPage = (type, page) => state[type].fetchStatus[page];

  const getFetchStatus = () => state.fetchStatus;

  const getPagination = (type) => state[type].pagination || {};

  const getFilters = (type) => {
    let query = {};
    if (globalState.routing.locationBeforeTransitions) {
      query = globalState.routing.locationBeforeTransitions.query;
    }
    return getFiltersFromQuery(query, type);
  };

  return {
    getEntityById,
    getEntitiesByPage,
    getIdsByPage,
    getFetchStatusByPage,
    getFetchStatus,
    getPagination,
    getFilters,
  };
}
