import { combineReducers } from 'redux';

import at from '../actions/types';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return { ...state, ...action.entities.notificationPanel };
    }
    default: {
      return state;
    }
  }
};

export const pages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return {
        ...state,
        1: action.result,
      };
    }
    case at.CLEAR: {
      return {};
    }
    default:
      return state;
  }
};

export const opened = (state = 0, action) => {
  switch (action.type) {
    case at.TOGGLE_NOTIFICATIONS_PANEL: {
      return state ? 0 : 1;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  opened,
  entities,
  pages,
});
