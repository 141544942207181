import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Navbar from 'emerald-ui-2/lib/Navbar';
import Nav from 'emerald-ui-2/lib/Nav';
import DropdownButton from 'emerald-ui-2/lib/DropdownButton';
import DropdownItem from 'emerald-ui-2/lib/DropdownItem';
import Container from 'emerald-ui-2/lib/Container';
import RedIndicator from 'emerald-ui-2/lib/RedIndicator';
import IconButton from 'emerald-ui-2/lib/IconButton';

import LoggedInUserAvatar from 'containers/LoggedInUserAvatar';
import BoardsDropdown from 'containers/BoardsDropdown';
import NotificationsPanelIcon from 'containers/NotificationsPanelIcon';
import SkeletonImage from 'components/SkeletonImage';
import logo from './logo-ceb-by-propelus.svg';
import logoPowered from './powered-by-ceb.svg';
import config from '../../config';
import { NavLink, withRouter } from 'react-router-dom';
import { selectors as boardAccessSelectors } from 'state/boardsAccess';

import './styles.css';

const LeftNav = () => (
  <React.Fragment>
    <NavLink to="/licenses" activeClassName="active" className="format-nav-text">
      Licenses
    </NavLink>
    <NavLink to="/audits" activeClassName="active" className="format-nav-text">
      Audits
    </NavLink>
    <NavLink to="/providers" activeClassName="active" className="format-nav-text">
      Providers
    </NavLink>
    <NavLink to="/courses" activeClassName="active" className="format-nav-text">
      Courses
    </NavLink>
  </React.Fragment>
);

const RightNav = ({ logout, permissions, history, boardAccess }) => {
  const canTieUsers = permissions.some((p) => p.code === 'TIE_USERS');
  const canReadUsers = permissions.some((p) => p.code === 'READ_USERS');

  const [legacyAccess, setLegacyAccess] = useState(null);

  const toggleDropdown = React.useCallback((_) => {
    setLegacyAccess(Boolean(boardAccess.isLegacy));
  });

  const dropdownRedirect = (path) => {
    history.push(path);
  };

  return (
    <React.Fragment>
      <div className="flex-center">
        <BoardsDropdown />
      </div>
      <NavLink to="/reports" activeClassName="active" className="disable-indicator-bar">
        <IconButton ariaLabel="Reports" icon="assessment" title="Reports" />
      </NavLink>
      <NavLink to="/inbox" activeClassName="active" className="disable-indicator-bar">
        <RedIndicator active={false}>
          <IconButton ariaLabel="Inbox" icon="mail" title="Inbox" />
        </RedIndicator>
      </NavLink>
      <NotificationsPanelIcon />
      <DropdownButton
        noCaret
        fromRight
        id="nav-dropdown"
        title={<LoggedInUserAvatar />}
        className="avatar-dropdown disable-indicator-bar"
        onToggle={toggleDropdown}
        onSelect={dropdownRedirect}
      >
        <DropdownItem eventKey="/user-profile">My Profile</DropdownItem>
        {canReadUsers && <DropdownItem eventKey="/users/list">Users</DropdownItem>}
        <DropdownItem eventKey="/board-profile">Board profile</DropdownItem>
        {legacyAccess && (
          <DropdownItem onClick={() => (window.location.href = config.SECURE_GATEWAY)}>Classic View</DropdownItem>
        )}
        {canTieUsers && <DropdownItem eventKey="/tie-users">Tie Users</DropdownItem>}
        <DropdownItem separator />
        <DropdownItem onClick={logout}>Sign out</DropdownItem>
      </DropdownButton>
    </React.Fragment>
  );
};

RightNav.propTypes = {
  logout: PropTypes.func,
  permissions: PropTypes.array,
};

RightNav.default = {
  logout: () => { },
};

const AppBar = ({ logout, permissions, loggedInUser, history, boardAccess }) => {
  const { externalSSO } = loggedInUser;

  const isExternalSSO = externalSSO && externalSSO.tokenLogo;

  return (
    <Navbar breakAt="lg" inverse className="bar-brightness">
      <Container>
        <Navbar.Brand className={isExternalSSO && 'external'}>
          <a href={config.BS_HOME}>
            {isExternalSSO ? (
              <div className="flex-center">
                <SkeletonImage src={`${config.STORAGE_API}/${externalSSO.tokenLogo}`} alt={externalSSO.clientId} />
                <img src={logoPowered} className="powered-logo" alt="Powered by cebroker" />
              </div>
            ) : (
              <img src={logo} alt="Board Suite" />
            )}
          </a>
        </Navbar.Brand>
        <Nav grow collapsible>
          <LeftNav />
        </Nav>
        <Nav collapsible>
          <RightNav logout={logout} permissions={permissions} history={history} boardAccess={boardAccess} />
        </Nav>
      </Container>
    </Navbar>
  );
};

AppBar.propTypes = {
  logout: PropTypes.func,
  permissions: PropTypes.array,
  loggedInUser: PropTypes.object,
};

AppBar.default = {
  logout: () => { },
};

function mapStateToProps(state) {
  const { loggedInUser } = state;
  const ubas = boardAccessSelectors(state);
  return {
    loggedInUser,
    boardAccess: ubas.getSelectedBoardAccess()
  };
}

export default connect(mapStateToProps)(withRouter(AppBar));
