import { createAction } from 'state/utils';

export default {
  ...createAction('audits', 'CREATE'),
  ...createAction('audits', 'CREATE_WITH_CUSTOM_CYCLE'),
  ...createAction('audits', 'UPDATE'),
  ...createAction('audits', 'ARCHIVE'),
  ...createAction('audits', 'UNARCHIVE'),
  ...createAction('audits', 'UPDATE_AND_ARCHIVE'),
  ...createAction('audits', 'CREATE_AUDIT_ATTACHMENTS'),

  ...createAction('audits', 'FETCH'),
  ...createAction('audits', 'FETCH_ONE'),
  CLEAR: 'audits/CLEAR',
  CLEAR_ENTITIES: 'audits/CLEAR_ENTITIES',
  SET_FILTERS: 'audits/SET_FILTERS',
  SET_PAGINATION: 'audits/SET_PAGINATION',
  DELETE_ARCHIVED: 'audits/DELETE_ARCHIVED',
  FETCH_CANCELED: 'audits/FETCH_CANCELED',
  UPDATE_ROOM_ID: 'audits/UPDATE_ROOM_ID',
};
