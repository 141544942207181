export function setCookie(name, value, exdays = 1, domain = 'cebroker.com') {
  const date = new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  const cookieString = `${name}=${value};${expires};domain=${domain};path=/`;
  document.cookie = cookieString;
}

export function getCookie(cookieName) {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let char = ca[i];
    while (char.charAt(0) === ' ') {
      char = char.substring(1);
    }
    if (char.indexOf(name) === 0) {
      return char.substring(name.length, char.length);
    }
  }
  return '';
}

export function deleteCookie(cookieName, domain = 'cebroker.com') {
  const now = new Date();
  let cookie = `${cookieName}=;expires=${now.toUTCString()}`;
  if (domain) {
    cookie += `;domain=${domain}`;
  }
  document.cookie = cookie;
}
