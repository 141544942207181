import { call, put, select } from 'redux-saga/effects';
import * as api from 'api/boards';
import at from '../actions/types';

export function* fetchBoard() {
  const state = yield select();
  const boardId = state.selectedBoard.id;
  const { authentication } = state;
  yield put({ type: at.FETCH_REQUEST });
  let response;
  try {
    response = yield call(api.fetchBoard, boardId, authentication.accessToken);
  } catch (e) {
    console.log(e);
    yield put({ type: at.FETCH_FAILURE, message: e.message || 'Something went wrong' });
    return;
  }
  yield put({ type: at.FETCH_SUCCESS, ...response.data });
}
