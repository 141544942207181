import { call, put, select } from 'redux-saga/effects';

import * as api from 'api/licensee';

import at from '../actions/types';

export function* browserDownload(urlDocument) {
  try {
    const data = yield call(api.browserDownload, urlDocument);
    yield put({ type: at.DOWNLOAD_PDF_BROWSER_SUCCESS, data });
  } catch (e) {
    yield put({ type: at.DOWNLOAD_PDF_BROWSER_FAILURE, message: e.message || 'Something went wrong' });
  }
}
