export function getArchivedFiltersFromQuery(query) {
  let {
    archivedPage,
    archivedSize,
    archivedSort = 'archive_date',
    archivedSortOrder = 'DESC',
    archivedTerm,
    archivedminDateAdded,
    archivedmaxDateAdded,
    archivedminDeadline,
    archivedmaxDeadline,
    archivedtranscriptCompletion,
    archivedselectedUser,
    archivedcomplianceStatus,
    archivedemailInvitationStatus,
  } = query;

  archivedPage = !isNaN(archivedPage) ? parseInt(archivedPage, 10) : 1;
  archivedSize = !isNaN(archivedSize) ? parseInt(archivedSize, 10) : 10;

  const filters = {
    page: archivedPage,
    size: archivedSize,
    sort: archivedSort,
    order: archivedSortOrder,
    archived: true,
  };
  if (archivedTerm) {
    filters.term = archivedTerm;
  }
  if (archivedminDateAdded) {
    filters.minDateAdded = archivedminDateAdded;
  }
  if (archivedmaxDateAdded) {
    filters.maxDateAdded = archivedmaxDateAdded;
  }
  if (archivedminDeadline) {
    filters.minDeadline = archivedminDeadline;
  }
  if (archivedmaxDeadline) {
    filters.maxDeadline = archivedmaxDeadline;
  }
  if (archivedtranscriptCompletion) {
    filters.transcriptCompletion = archivedtranscriptCompletion;
  }
  if (archivedselectedUser) {
    filters.selectedUser = archivedselectedUser;
  }
  if (archivedcomplianceStatus) {
    filters.complianceStatus = archivedcomplianceStatus;
  }
  if (archivedemailInvitationStatus) {
    filters.emailInvitationStatus = archivedemailInvitationStatus;
  }
  return filters;
}

export function getCurrentFiltersFromQuery(query) {
  let {
    currentPage,
    currentSize,
    currentSort = 'deadline',
    currentSortOrder = 'ASC',
    currentTerm,
    minDateAdded,
    maxDateAdded,
    minDeadline,
    maxDeadline,
    transcriptCompletion,
    selectedUser,
    complianceStatus,
    emailInvitationStatus,
  } = query;

  currentPage = !isNaN(currentPage) ? parseInt(currentPage, 10) : 1;
  currentSize = !isNaN(currentSize) ? parseInt(currentSize, 10) : 10;

  const filters = { page: currentPage, size: currentSize, sort: currentSort, order: currentSortOrder, archived: false };
  if (currentTerm) {
    filters.term = currentTerm;
  }
  if (minDateAdded) {
    filters.minDateAdded = minDateAdded;
  }
  if (maxDateAdded) {
    filters.maxDateAdded = maxDateAdded;
  }
  if (minDeadline) {
    filters.minDeadline = minDeadline;
  }
  if (maxDeadline) {
    filters.maxDeadline = maxDeadline;
  }
  if (transcriptCompletion) {
    filters.transcriptCompletion = transcriptCompletion;
  }
  if (selectedUser) {
    filters.selectedUser = selectedUser;
  }
  if (complianceStatus) {
    filters.complianceStatus = complianceStatus;
  }
  if (emailInvitationStatus) {
    filters.emailInvitationStatus = emailInvitationStatus;
  }
  return filters;
}

export function getFiltersFromQuery(query, type) {
  return type === 'current' ? getCurrentFiltersFromQuery(query) : getArchivedFiltersFromQuery(query);
}

export function removePaginationFromFilters(filters) {
  const newFilters = { ...filters };
  delete newFilters.page;
  return newFilters;
}
