import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'emerald-ui-2/lib/Toggle';
import Checkbox from 'emerald-ui-2/lib/Checkbox';
import Button from 'emerald-ui-2/lib/Button';
import SingleSelect from 'emerald-ui-2/lib/SingleSelect';
import Modal from 'emerald-ui-2/lib/Modal';
import './styles.css';
import profileImage from './profile.png';

import NotificationsSection from '../../../NotificationsSection';

const PermissionsSection = ({ permissions, selectedPermissions, onPermissionsChange }) => {
  return (
    <div className="user-profile-detail-row">
      <div className="user-profile-detail-left text-light">Permissions</div>
      <div className="user-profile-detail-right">
        {permissions
          .filter((p) => p.isExtra)
          .map((p) => (
            <Checkbox
              className="user-profile-detail-checkbox"
              key={p._id}
              checked={selectedPermissions.includes(p._id)}
              label={p.name}
              onChange={(event) => onPermissionsChange(p._id, event.target.checked)}
            />
          ))}
      </div>
    </div>
  );
};

class UserDetailProfile extends React.Component {
  state = {
    isActiveUser: false,
    hasLegacyAccess: false,
    selectedNotifications: [],
    selectedPermissions: [],
    selectedRole: '',
  };

  componenentWillMount() {
    const { user } = this.props;
    this.initSetup(user);
  }

  componentWillReceiveProps(nextProps) {
    const { user } = nextProps;
    this.initSetup(user);
  }

  initSetup = (user) => {
    this.setState({
      isActiveUser: user.isActive || false,
      hasLegacyAccess: user.isLegacy || false,
      selectedNotifications: user.notifications || [],
      selectedPermissions: user.permissions || [],
      selectedRole: user.role,
    });
  };

  handleActiveUserChange = (event) => this.setState({ isActiveUser: event.target.checked });

  handleSelecetedNotificationsChange = (type, value) => {
    const { selectedNotifications } = this.state;
    let newNotifications = value ? [...selectedNotifications, type] : selectedNotifications.filter((t) => t !== type);
    this.setState({ selectedNotifications: newNotifications });
  };

  handleSelecetedPermissionsChange = (type, value) => {
    const { selectedPermissions } = this.state;
    let newPermissions = value ? [...selectedPermissions, type] : selectedPermissions.filter((t) => t !== type);
    this.setState({ selectedPermissions: newPermissions });
  };

  handleRoleChange = (value) => {
    const { roles } = this.props;
    const rolePermissionsIds = roles.find((r) => r._id === value).permissions.map((p) => p._id);
    this.setState({
      selectedRole: value,
      selectedPermissions: rolePermissionsIds,
    });
  };

  handleLegacyAccessChange = (event) => this.setState({ hasLegacyAccess: event.target.checked });

  handleFormSubmit = () => {
    const { user, onSubmit } = this.props;
    const { isActiveUser, selectedNotifications, selectedPermissions, selectedRole, hasLegacyAccess } = this.state;
    const newUser = {
      ...user,
      role: selectedRole,
      isActive: isActiveUser,
      isLegacy: hasLegacyAccess,
      notifications: selectedNotifications,
      permissions: selectedPermissions,
    };
    onSubmit(newUser);
  };

  render() {
    const { isActiveUser, selectedNotifications, selectedPermissions, selectedRole, hasLegacyAccess } = this.state;
    const { user, roles, notifications, isOpen, onClose } = this.props;
    const selectedRoleObject = roles.find((r) => r._id === selectedRole) || {};
    const selectedRolePermissions = selectedRoleObject.extraPermissions || [];

    const options = roles.map((role) => ({ value: role._id, label: role.name }));

    const optionsRenderer = options.map((option) => (
      <option key={option.value} value={option.value} selected={option.value === selectedRole}>
        {option.label}
      </option>
    ));

    return (
      <Modal show={isOpen} onHide={onClose} className="user-profile-detail-wrapper">
        <Modal.Header closeButton>
          <div className="eui-modal-title h4">User Profile</div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <section className="user-profile-detail-header">
              <div className="user-profile-detail-header-image">
                <img src={profileImage} alt="presentation" role="presentation" />
              </div>
              <div className="user-profile-detail-header-info">
                <p className="user-profile-detail-header-info-user-name text-lighter">{`${user.firstName || ''} ${
                  user.lastName || ''
                }`}</p>
                <p className="user-profile-detail-header-info-user-email text-lighter">{user.email}</p>
                <p className="user-profile-detail-header-info-user-phone text-lighter">{user.phone}</p>
              </div>
            </section>

            <section className="user-profile-detail-content">
              <div className={`user-profile-detail-row user-profile-detail-role-section`}>
                <div className="user-profile-detail-left text-light">Role</div>
                <div className="user-profile-detail-right">
                  <SingleSelect
                    id="assigned-user-profile-dropdown"
                    className="user-profile-detail-dropdown"
                    name="assigned-user"
                    disabled={user.isPrimaryContact}
                    onSelect={this.handleRoleChange}
                  >
                    {optionsRenderer}
                  </SingleSelect>
                </div>
              </div>
              {selectedRolePermissions.length > 0 && (
                <PermissionsSection
                  {...{
                    permissions: selectedRolePermissions,
                    selectedPermissions,
                    onPermissionsChange: this.handleSelecetedPermissionsChange,
                  }}
                />
              )}

              <div className="user-profile-detail-row">
                <div className="user-profile-detail-left text-light">Status</div>
                <div className={`user-profile-detail-right user-profile-detail-status`}>
                  <Toggle
                    checked={isActiveUser ? true : false}
                    onChange={this.handleActiveUserChange}
                    disabled={user.isActive && user.isPrimaryContact}
                  />
                  <span className="status-text text-light">{isActiveUser ? 'Active' : 'Inactive'}</span>
                </div>
              </div>

              {
                //if it's not an old board suite user, show the toogle
                user.isBoardSuite ? (
                  <div className="user-profile-detail-row">
                    <div className="user-profile-detail-left text-light">Classic site access</div>
                    <div className={`user-profile-detail-right user-profile-detail-status`}>
                      <Toggle checked={hasLegacyAccess} onChange={this.handleLegacyAccessChange} />
                    </div>
                  </div>
                ) : null
              }

              <div className="user-profile-detail-row">
                <div className="user-profile-detail-left text-light">Notifications</div>
                <div className="user-profile-detail-right">
                  <NotificationsSection
                    {...{
                      notifications,
                      selectedNotifications,
                      onNotificationChange: this.handleSelecetedNotificationsChange,
                    }}
                  />
                </div>
              </div>

              <div className="user-profile-detail-buttons">
                <Button color="info" className="updateButton" onClick={this.handleFormSubmit}>
                  Update
                </Button>
              </div>
            </section>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

UserDetailProfile.propTypes = {
  user: PropTypes.object,
};

export default UserDetailProfile;
