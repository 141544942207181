import axios from 'axios';

import * as at from './types';
import { API_URL } from '../config';

export function fetchExemption(licensePeriodId, professionId) {
  return (dispatch) => {
    dispatch({ type: at.FETCH_EXEMPTION_REQUEST });
    return axios
      .get(`${API_URL}/licensePeriod/${licensePeriodId}/profession/${professionId}/exemptions`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: at.FETCH_EXEMPTION_SUCCESS, data: response.data });
        }
      })
      .catch((err) => {
        dispatch({ type: at.FETCH_EXEMPTION_FAILURE, err });
      });
  };
}
