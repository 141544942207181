import { call, put, select } from 'redux-saga/effects';

import * as api from 'api/notificationsPanel';
import at from '../actions/types';

export function* markAllAsRead() {
  const state = yield select();
  //Only mark as read when open the panel
  if (!state.notificationsPanel.opened) {
    return;
  }
  const { authentication, loggedInUser = {} } = state;
  yield put({ type: at.MARK_ALL_AS_READ_REQUEST });
  try {
    try {
      yield call(api.markAllAsRead, loggedInUser.id, authentication.accessToken);
    } catch (e) {
      console.log(e);
      yield put({ type: at.MARK_ALL_AS_READ_FAILURE, message: e.message || 'Something went wrong' });
      return;
    }

    yield put({ type: at.MARK_ALL_AS_READ_SUCCESS });
  } catch (error) {
    console.log('error', error);
  }
}
