import React from 'react';
import BoxNotification from '../BoxNotification';
import Spinner from 'emerald-ui-2/lib/Spinner';
import './styles.css';

const LoadingBox = ({ text, visible }) => (
  <BoxNotification visible={visible} className="loadingBox">
    <Spinner color="info" size="md" />
    <div className="phrase">
      <h5>{text}</h5>
    </div>
  </BoxNotification>
);

export default LoadingBox;
