import { combineReducers } from 'redux';
import at from '../actions/types';

export const pages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      if (action.meta.auditType !== 'archived') {
        return state;
      }
      return {
        ...state,
        [action.filters.page]: action.result,
      };
    }
    case at.CLEAR: {
      if (action.meta.auditType !== 'archived') {
        return state;
      }
      return {};
    }
    default:
      return state;
  }
};

export const pagination = (state = { currentPage: 1 }, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      if (action.meta.auditType !== 'archived') {
        return state;
      }
      return { ...state, totalItems: action.totalItems };
    }
    case at.SET_PAGINATION: {
      if (action.meta.auditType !== 'archived') {
        return state;
      }
      return action.pagination;
    }
    case at.UNARCHIVE_SUCCESS: {
      return {
        ...state,
        totalItems: state.totalItems - 1,
      };
    }
    default:
      return state;
  }
};

export const fetchStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      if (action.meta.auditType !== 'archived') {
        return state;
      }
      return { ...state, [action.filters.page]: 'loading' };
    }
    case at.FETCH_SUCCESS: {
      if (action.meta.auditType !== 'archived') {
        return state;
      }
      return { ...state, [action.filters.page]: 'loaded' };
    }
    case at.FETCH_FAILURE: {
      if (action.meta.auditType !== 'archived') {
        return state;
      }
      return { ...state, [action.filters.page]: 'failed' };
    }
    case at.FETCH_CANCELED: {
      if (action.meta.auditType !== 'archived') {
        return state;
      }
      return { ...state, [action.filters.page]: 'loaded' };
    }
    default:
      return state;
  }
};

export default combineReducers({
  pages,
  pagination,
  fetchStatus,
});
