import { call, put, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import * as api from 'api/licensesStatuses';

import * as schema from '../schema';
import at from '../actions/types';

export function* fetchLicensesStatuses() {
  const state = yield select();
  const boardId = state.selectedBoard.id;

  yield put({ type: at.FETCH_REQUEST });

  let response;
  try {
    response = yield call(api.fetch, { boardId });
  } catch (e) {
    console.log(e);
    yield put({
      type: at.FETCH_FAILURE,
      message: e.message || 'Something went wrong',
    });
    return;
  }

  const normalizedData = normalize(response.data, schema.licensesStatuses);

  normalizedData.result = [...new Set(normalizedData.result)];

  yield put({ type: at.FETCH_SUCCESS, ...normalizedData });
}
