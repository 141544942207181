import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import { LoadingBox } from 'components';
import ActivityFeed from 'containers/ActivityFeed';
import IconButton from 'emerald-ui-2/lib/IconButton';
import Panel from 'emerald-ui-2/lib/Panel';

import './styles.css';

const SelfReportedCEFeed = ({ roomId, isReadOnly }) => {
  return (
    <Row>
      <Col xs={12}>
        <div className="self-reported-ce-detail-page-title">
          <IconButton icon="message" className="self-reported-ce-detail-page-titleIcons" />
          <h5>Activity</h5>
        </div>
      </Col>
      <Col xs={12}>
        <Panel className="self-reported-ce-detail-page-card">
          <div className="self-reported-ce-detail-page-cardBody">
            <LoadingBox visible={false} text="Loading comments..." />
            <p>Internal board / Council activity</p>
            <ActivityFeed roomId={roomId} isReadOnly={isReadOnly} />
          </div>
        </Panel>
      </Col>
    </Row>
  );
};

SelfReportedCEFeed.propTypes = {
  canWriteMessages: PropTypes.bool,
  isFetchingRoom: PropTypes.bool,
  roomId: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default SelfReportedCEFeed;
