import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

function NotAvailable({ title, message, descMessage, size, align }) {
  const [styleFactory, setStyleFactory] = useState({
    padding: 20,
    textAlign: 'center',
    fontStyle: 'italic',
  });

  useMemo(() => {
    const prevStyles = { ...styleFactory };

    switch (size) {
      case 'lg':
        prevStyles.padding = 20;
        break;
      case 'none':
        prevStyles.padding = 0;
        break;
      default:
        prevStyles.padding = 10;
        break;
    }

    switch (align) {
      case 'left':
        prevStyles.textAlign = 'left';
        break;
      default:
        prevStyles.textAlign = 'center';
        break;
    }

    setStyleFactory({ ...prevStyles });
  }, []);

  return (
    <React.Fragment>
      <h5 style={styleFactory}>
        <strong>
          {title} {message}
        </strong>{' '}
        {descMessage}
      </h5>
    </React.Fragment>
  );
}

NotAvailable.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  descMessage: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'none']),
};

NotAvailable.defaultProps = {
  title: '',
  message: 'Not Available.',
  descMessage: 'Please, try again later.',
  size: 'lg',
};

export default NotAvailable;
