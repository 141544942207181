import { call, put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as api from 'api/compliance';
import at from '../actions/types';

export function* fetchDowloadReports(boardId, filters) {
  const state = yield select();
  const { authentication } = state;
  yield put({ type: at.FETCH_DOWNLOAD_REPORTS_REQUEST, filters });
  try {
    let response = { status: 'fetch' };
    try {
      // continous Polling, be carefull
      while (response.status !== 'completed') {
        const { data } = yield call(api.fetchDownloadReports, {
          boardId,
          filters,
          token: authentication.accessToken,
        });

        response = data;

        yield put({
          type: at.FETCH_DOWNLOAD_REPORTS_SUCCESS,
          downloadReport: response,
          filters,
        });

        yield call(delay, 1 * 1000);
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: at.FETCH_DOWNLOAD_REPORTS_FAILURE,
        filters,
        message: e.message || 'Something went wrong',
      });
      return;
    }
  } catch (error) {
    console.log('error', error);
  }
}

export function* watchFetchDowloadReports({ boardId, filters }) {
  yield call(fetchDowloadReports, boardId, filters);
}
