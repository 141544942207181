import { FETCH_POSTCE_SUCCESS, FETCH_POSTCE_REQUEST } from '../actions/constants';

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_POSTCE_REQUEST:
      return { status: action.status, data: action.data };
    case FETCH_POSTCE_SUCCESS:
      return { status: action.status, data: action.data };
    default:
      return state;
  }
}
