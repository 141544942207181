import at from './types';

export const createFeed = (meta) => ({ type: at.CREATE, meta });

export const fetchFeed = (feedId) => ({ type: at.FETCH, feedId, meta: {} });

export const addCommentToFeed = (feedId, text) => ({
  type: at.ADD_COMMENT,
  feedId,
  text,
  meta: {},
});

export const deleteCommentFromFeed = (feedId, commentId) => ({
  type: at.DELETE_COMMENT,
  feedId,
  commentId,
  meta: {},
});

export const updateCommentInFeed = (feedId, commentId, text) => ({
  type: at.UPDATE_COMMENT,
  feedId,
  commentId,
  text,
  meta: {},
});
