import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icon from 'emerald-ui-2/lib/Icon';
import Panel from 'emerald-ui-2/lib/Panel';
import isEmpty from 'lodash/isEmpty';
import Toast from 'emerald-ui-2/lib/Toast';
import { LoadingBox } from 'components';
import { fetchPostCECredit, updatePostCECredit } from 'api/postCECredits';
import { fetchSelfReportedCE } from 'api/selfReportedCE';
import * as feedRequests from 'api/feed';
import SelfReportedCEDetailLicenseeInfo from './SelfReportedCEDetailLicenseeInfo';
import SelfReportedCECreditInfo from './SelfReportedCECreditInfo';
import SelfReportedCEAttachmentsInfo from './SelfReportedCEAttachmentsInfo';
import SelfReportedCEQuestions from './SelfReportedCEQuestions';
import SelfReportedCEActionBox from './SelfReportedCEActionBox';
import SelfReportedCEFeed from './SelfReportedCEFeed';
import SelfReportedCESubjecAreas from './SelfReportedCESubjectAreas';
import SelfReportedCEPaymentInfo from './SelfReportedCEPaymentInfo';
import { selectors as boardAccessSelectors } from 'state/boardsAccess';
import RedirectButton from 'components/RedirectButton';
import './styles.css';

import PageHeader from 'emerald-ui-2/lib/PageHeader';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { getFullNameFromObject } from 'utils';

export class SelfReportedCEDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postCECredit: {},
      selfReportedCE: {},
      license: {},
      loading: false,
      roomId: '',
      subjectAreas: [],
      status: '',
      toast: {
        visible: false,
        message: '',
      },
    };
  }

  componentWillMount() {
    const { params } = this.props;
    this.init(params);
  }

  componentWillReceiveProps(nextProps) {
    const { params } = nextProps;
    this.init(params);
  }

  init = async (params) => {
    this.setState({ loading: true });
    if (params.postCECreditId) {
      await this.fetchPostCECredit(params.postCECreditId);
      await this.fetchSelfReportedCE(params.postCECreditId);
    }
    this.setState({ loading: false });
  };

  fetchPostCECredit = async (postCECreditId) => {
    const { accessToken } = this.props;
    const result = await fetchPostCECredit(postCECreditId, accessToken);
    this.setState({
      postCECredit: result.data,
      roomId: result.data.roomId,
      subjectAreas: [...result.data.subjectAreas],
    });
    if (result.data && !result.data.roomId) {
      this.createRoom(postCECreditId);
    }
  };

  fetchSelfReportedCE = async (postCECreditId) => {
    const { boardId, accessToken } = this.props;
    const result = await fetchSelfReportedCE(boardId, { postCECreditId }, accessToken);
    if (result.data && result.data.length > 0) {
      const data = result.data[0];
      this.setState({ selfReportedCE: data, status: data.status });
    }
  };

  createRoom = async (postCECreditId) => {
    const { boardId, accessToken } = this.props;
    const roomData = {
      name: 'self-reported-ce-detail',
      data: {
        postCECreditId,
      },
    };
    const roomResponse = await feedRequests.create(boardId, roomData, accessToken);
    this.setState({ roomId: roomResponse.data._id });
  };

  onSubjectAreaChange = (appliedHours, subjectAreaId) => {
    const { subjectAreas } = this.state;
    const subject = subjectAreas.find((sa) => sa.id === subjectAreaId) || {};
    subject.appliedHours = appliedHours;
    this.setState({ subjectAreas });
  };

  onStatusChange = (status) => {
    const { subjectAreas = [] } = this.state;
    const totalHours = subjectAreas.reduce((total, sa) => (total += sa.appliedHours), 0);

    if (!totalHours && status === 'APPROVED') {
      this.showToast('You need to enter hours to approve');
      return false;
    }
    this.setState({ status });
    return true;
  };

  onSubmit = async () => {
    const { subjectAreas = [], selfReportedCE = {}, status, postCECredit = {} } = this.state;
    const { accessToken, params } = this.props;

    const payload = {
      subjectAreas,
      status,
      professionId: selfReportedCE.professionId,
      creditSubstitutionId: selfReportedCE.creditSubstitutionId,
      professionCode: selfReportedCE.professionCode,
      licenseId: selfReportedCE.licenseId,
      ownerId: selfReportedCE.userId,
      completedDate: postCECredit.completedDate,
      firstName: selfReportedCE.firstName,
      lastName: selfReportedCE.lastName,
      licenseNumber: selfReportedCE.number,
      pkLicense: selfReportedCE.pkLicense,
    };
    await updatePostCECredit(selfReportedCE.postCECreditId, payload, accessToken);
    this.init(params);
    this.showToast('Reported CE Updated');
  };

  showToast = (message) => {
    this.setState({
      toast: {
        visible: true,
        message,
      },
    });
  };

  renderLoader() {
    return (
      <Grid className="self-reported-ce-detail-page-loader-container">
        <Row>
          <Col xs={12} md={9}>
            <Panel className="self-reported-ce-detail-page">
              <LoadingBox visible={true} text={'Loading self reported CE Detail'} />
            </Panel>
          </Col>
        </Row>
      </Grid>
    );
  }

  renderSelfReportedCEDetail() {
    const { postCECredit, selfReportedCE } = this.state;
    const { boardAccess = [] } = this.props;
    const permissions = boardAccess.permissions || [];
    const canCreateComments = permissions.some((p) => p.code === 'CREATE_COMMENTS');
    const canEditPosting = permissions.some((p) => p.code === 'LICENSEE_CE_POSTING_EDIT');
    return (
      <Grid>
        <Toast
          message={this.state.toast.message}
          visible={this.state.toast.visible}
          onTimeout={() => this.setState({ toast: { visible: false } })}
        />
        <Row>
          <Col xs={12} md={9}>
            <Row>
              <Col xs={12} />
            </Row>
            <div>
              <div className="self-reported-ce-detail-page-title">
                <Icon name="info_outline" className="self-reported-ce-detail-page-titleIcons" />
                <h5>Info</h5>
              </div>
              {postCECredit.payment && Object.keys(postCECredit.payment).length > 0 && (
                <div>
                  <SelfReportedCEPaymentInfo payment={postCECredit.payment} />
                </div>
              )}
              <div>
                <SelfReportedCEDetailLicenseeInfo
                  licenseId={selfReportedCE.pkLicense}
                  name={getFullNameFromObject(selfReportedCE)}
                  number={selfReportedCE.number}
                />
              </div>
              <div>
                <SelfReportedCECreditInfo postCECredit={postCECredit} selfReportedCE={selfReportedCE} />
              </div>
              {postCECredit.questions && postCECredit.questions.length > 0 && (
                <div>
                  <SelfReportedCEQuestions questions={postCECredit.questions} />
                </div>
              )}
              {postCECredit.attachments && postCECredit.attachments.length > 0 && (
                <SelfReportedCEAttachmentsInfo attachments={postCECredit.attachments} />
              )}
              <SelfReportedCESubjecAreas
                subjectAreas={this.state.subjectAreas}
                onSubjectAreaChange={this.onSubjectAreaChange}
                canEdit={canEditPosting}
              />
              <SelfReportedCEFeed roomId={this.state.roomId} isReadOnly={!canCreateComments} />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <SelfReportedCEActionBox
              status={this.state.status}
              onSubmit={this.onSubmit}
              onStatusChange={this.onStatusChange}
              canEdit={canEditPosting}
            />
          </Col>
        </Row>
      </Grid>
    );
  }

  render() {
    const { selfReportedCE, loading } = this.state;
    return (
      <div>
        <PageHeader>
          <RedirectButton />
          <div className="self-reported-ce-detail-page-breadcrumbs">
            <small>
              <a href="/licenses">Licenses</a>
              <Icon name="chevron_right" className="self-reported-ce-detail-page-breadcrumbs-separator" />
              <span>{selfReportedCE.number}</span>
              <Icon name="chevron_right" className="self-reported-ce-detail-page-breadcrumbs-separator" />
              <a href="/licenses">Self Reported CE</a>
            </small>
          </div>
          <div className="self-reported-ce-detail-page-hero-icon-container">
            <span className="self-reported-ce-detail-page-icon-circle">
              <Icon name="hourglass_empty" className="self-reported-ce-detail-page-hero-icon" />
            </span>
          </div>
          <div className="self-reported-ce-detail-page-hero-title">
            <p>SELF REPORTED CE</p>
            <h4>{selfReportedCE.creditName}</h4>
          </div>
        </PageHeader>
        {loading && this.renderLoader()}
        {!loading && !isEmpty(selfReportedCE) && this.renderSelfReportedCEDetail()}
      </div>
    );
  }
}

SelfReportedCEDetailPage.propTypes = {
  // license: PropTypes.object,
  // exemption: PropTypes.object,
  // fetchExemption: PropTypes.func
};

SelfReportedCEDetailPage.defaultProps = {
  // license: {
  //   number: "",
  //   status: "",
  //   expirationDate: "",
  //   profession: {
  //     name: ""
  //   },
  //   owner: {
  //     firstName: "",
  //     middleName: "",
  //     lastName: "",
  //     email: "",
  //     emailAlternative: ""
  //   }
  // },
  // exemption: {},
  // fetchExemption: () => {}
};

function mapStateToProps(state) {
  // const { licenseId } = ownProps.params;
  // const license = licensesSelectors(state);
  const boardAccess = boardAccessSelectors(state);

  // const licensesStatuses = licensesStatusesSelectors.getEntities(
  //   state.licensesStatuses
  // );

  return {
    accessToken: state.authentication.accessToken,
    boardId: state.selectedBoard.id,
    // selectedPeriodId: getSelectedPeriodId(ownProps.location.query),
    // license: license.getLicenseById(licenseId),
    // fetchStatus: license.getLicenseFetchStatusById(licenseId),
    // selectedBoard: state.selectedBoard,
    // licensesStatuses,
    // exemption: state.exemption,
    boardAccess: boardAccess.getSelectedBoardAccess(),
  };
}

export default connect(mapStateToProps, {
  // fetchTranscript: transcriptsActions.fetchTranscript,
  // fetchLicensesStatuses: licensesStatusesActions.fetchLicensesStatuses,
  // fetchAccountType: licensesActions.fetchAccountType,
  // fetchLicenseEmail: licensesActions.fetchLicenseEmail,
  // fetchExemption
})(SelfReportedCEDetailPage);
