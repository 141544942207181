import { call, put, select } from 'redux-saga/effects';
import has from 'lodash/has';
import * as api from 'api/audits';
import * as licensesPeriodsApi from 'api/licensesPeriods';

import at from '../actions/types';
import * as actions from '../actions';

import { types as licensesPeriodsAt } from 'state/licensesPeriods';
import { actions as notificationsActions } from 'state/notifications';
import { getFiltersFromQuery } from '../common';

export function* createNotification(type, notification) {
  const { failureText, successText } = notification;
  const text =
    type === 'success'
      ? successText || 'This license has been added to the audit list'
      : failureText || 'Error creating the audit';
  const n = { type: 'CREATE_AUDIT', text };
  yield put(notificationsActions.addAndRemove(n, 5000));
}

export function* createAudit({ data, meta = {} }) {
  const { addNotification = true, notification = {} } = meta;

  const state = yield select();
  const { authentication } = state;
  yield put({ type: at.CREATE_REQUEST });

  try {
    yield call(api.create, { data, token: authentication.accessToken });
  } catch (e) {
    const message =
      e.response && e.response.data ? e.response.data.message : 'Option not available. Please, try again later.';
    yield put({
      type: at.CREATE_FAILURE,
      data,
      message,
    });
    if (addNotification) {
      // Add failure notification
      notification.failureText = `${message}`;
      yield createNotification('failure', notification);
    }
    return;
  }

  yield put({ type: at.CREATE_SUCCESS, data });
  if (addNotification) {
    // Add success notification
    yield createNotification('success', notification);
  }

  const filters = getFiltersFromQuery({}, 'current');
  yield put(actions.fetchAudits(filters, { force: true }));
}

export function* createAuditWithCustmoCycle({ cycle, data, meta = {} }) {
  const { addNotification = true, notification = {} } = meta;

  const state = yield select();
  const { authentication } = state;
  yield put({ type: licensesPeriodsAt.UPDATE_REQUEST });

  try {
    yield call(licensesPeriodsApi.createCustomCycle, cycle.id, cycle.payload, authentication.accessToken);
  } catch (e) {
    yield put({
      type: licensesPeriodsAt.UPDATE_FAILURE,
      data,
      message: e.message || 'Something went wrong',
    });
    if (addNotification) {
      // Add failure notification
      const additionalMessage = has(e, 'response.data.message') ? e.response.data.message : '';
      notification.failureText = `Error creating the custom cycle: ${additionalMessage}`;
      yield createNotification('failure', notification);
    }
    return;
  }

  yield put({ type: licensesPeriodsAt.UPDATE_SUCCESS, cycle });
  data.renewalEndDate = cycle.payload.customExpirationEnd;
  yield call(createAudit, { data, meta });
}
