import React from 'react';
import IconButton from 'emerald-ui-2/lib/IconButton';
import './styles.css';

const Paginator = ({ onPageSizeChange, onPageChange, page, size = 10, total = 0 }) => {
  const handlePageSizeChange = (pageSize) => {
    let size = 10;
    try {
      size = parseInt(pageSize, 10);
    } catch (error) {}
    if (onPageSizeChange) onPageSizeChange(size);
  };

  const handlePageChange = (dir) => {
    if (onPageChange) onPageChange(dir);
  };

  const offset = (page - 1) * size + 1;
  let range;
  if (total === 0) {
    range = <span className="paginator-paginationNumber">0</span>;
  } else {
    const rangeFirst = <span className="paginator-paginationNumber">{offset}</span>;
    const rangeLast = <span className="paginator-paginationNumber">{total > size ? offset + size - 1 : total}</span>;
    range = (
      <span>
        {rangeFirst} - {rangeLast}
      </span>
    );
  }
  const remaining = <span className="paginator-paginationNumber"> {total}</span>;

  const showPrev = page > 1;
  const showNext = page * size < total;

  const renderRange = (
    <React.Fragment>
      {range}&nbsp;&nbsp;of&nbsp;&nbsp;{remaining}
    </React.Fragment>
  );

  return (
    <div className="paginator">
      <div className="paginator-left text-color">
        <label>Rows per page: </label>
        <select defaultValue={size} onChange={(event) => handlePageSizeChange(event.target.value)}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
      <div className="paginator-right text-color">
        {total > 0 && renderRange}
        <IconButton
          icon="keyboard_arrow_left"
          className={showPrev ? '' : 'paginator-hidden'}
          onClick={() => showPrev && handlePageChange('prev')}
        />
        <IconButton
          icon="keyboard_arrow_right"
          className={showNext ? '' : 'paginator-hidden'}
          onClick={() => showNext && handlePageChange('next')}
        />
      </div>
    </div>
  );
};

export default Paginator;
