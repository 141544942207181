import { combineReducers } from 'redux';
import at from '../actions/types';
import reports from './reports';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { entities = {} } = action;
      const { boardApplications } = entities;
      if (!boardApplications || boardApplications.constructor !== Object) {
        return state;
      }
      return { ...state, ...entities.boardApplications };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const pages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { filters = {} } = action;
      if (!filters.page || !action.result) {
        return state;
      }
      return { ...state, [filters.page]: action.result };
    }
    case at.CLEAR: {
      return {};
    }
    default:
      return state;
  }
};

export const pagination = (state = { currentPage: 1 }, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      if (!action.totalItems) {
        return state;
      }
      return { ...state, totalItems: action.totalItems };
    }
    default:
      return state;
  }
};

export const pageFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      if (!action.filters || !action.filters.page) {
        return state;
      }
      return { ...state, [action.filters.page]: 'loading' };
    }
    case at.FETCH_SUCCESS: {
      if (!action.filters || !action.filters.page) {
        return state;
      }
      return { ...state, [action.filters.page]: 'loaded' };
    }
    case at.FETCH_FAILURE: {
      if (!action.filters || !action.filters.page) {
        return state;
      }
      return { ...state, [action.filters.page]: 'failed' };
    }
    case at.FETCH_CANCELED: {
      if (!action.filters || !action.filters.page) {
        return state;
      }
      return { ...state, [action.filters.page]: 'canceled' };
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  pages,
  pagination,
  pageFetchStatus,
  reports,
});
