import { call, put, takeEvery } from 'redux-saga/effects';
import { delay as RSDelay } from 'redux-saga';

import at from '../actions/types';
import { add, remove } from '../actions';

import uuidV4 from 'uuid';

export function* addAndRemove({ notification, delay = 2000 }) {
  const id = uuidV4();
  notification.id = id;
  yield put(add(notification));
  yield call(RSDelay, delay);
  yield put(remove(id));
}

export default function* root() {
  yield takeEvery(at.ADD_AND_REMOVE, addAndRemove);
}
