import { takeEvery, put, select } from 'redux-saga/effects';
import { replace } from 'react-router-redux';

import at from '../actions/types';

export function* watchSetFilters({ filters }) {
  const state = yield select();
  const { pathname } = state.routing.locationBeforeTransitions;
  yield put(replace({ pathname, query: filters }));
}

export function* watchUpdateFilters({ filters }) {
  const state = yield select();
  const { query, pathname } = state.routing.locationBeforeTransitions;
  yield put(replace({ pathname, query: { ...query, ...filters } }));
}

export function* watchSetUrlParams({ params }) {
  const state = yield select();
  const { pathname } = state.routing.locationBeforeTransitions;
  yield put(replace({ pathname, query: params }));
}

export function* watchUpdateUrlParams({ params }) {
  const state = yield select();
  const { query, pathname } = state.routing.locationBeforeTransitions;
  yield put(replace({ pathname, query: { ...query, ...params } }));
}

export default function* root() {
  yield takeEvery(at.SET_FILTERS, watchSetFilters);
  yield takeEvery(at.UPDATE_FILTERS, watchUpdateFilters);

  yield takeEvery(at.SET_URL_PARAMS, watchSetUrlParams);
  yield takeEvery(at.UPDATE_URL_PARAMS, watchUpdateUrlParams);
}
