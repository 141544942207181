import { takeLatest, takeEvery } from 'redux-saga/effects';

import at from '../actions/types';

import { fetchBoardUser } from './fetchBoardUser';
import { createBoardPassword } from './createBoardPassword';
import { fetchBoardUserProfile } from './fetchBoardUserProfile';
import { updateBoardUserProfile } from './updateBoardUserProfile';

export default function* root() {
  yield takeLatest(at.FETCH, fetchBoardUser);
  yield takeLatest(at.CREATE, createBoardPassword);
  yield takeLatest(at.FETCH_ONE, fetchBoardUserProfile);
  yield takeEvery(at.ADD, updateBoardUserProfile);
}
