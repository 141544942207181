import { call, put, takeEvery, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import * as api from 'api/auditsBulks';

import * as schema from '../schema';
import at from '../actions/types';

import { actions as notifications } from 'state/notifications';

export function* fetchBulks(action) {
  try {
    yield put({ type: at.FETCH_BULKS_REQUEST });
    const response = action.url
      ? yield call(api.fetchBulksUrl, action.url, action.token, action.filters)
      : yield call(api.fetchBulks, action.boardId, action.token, action.filters);
    const { href, first, last, next, previous, limit, size, offset } = response.data;
    const bulkPagination = {
      href,
      first,
      last,
      next,
      previous,
      limit,
      size,
      offset,
    };
    const normalizedData = normalize(response.data.items, schema.bulks);

    yield put({ type: at.FETCH_BULKS_SUCCESS, response: normalizedData, bulkPagination });
  } catch (e) {
    yield put({ type: at.FETCH_BULKS_FAILURE, message: e.message || 'Something went wrong' });
  }
}

export function* confirmBulk(action) {
  try {
    yield put({ type: at.CONFIRM_BULK_REQUEST });
    const response = yield call(api.confirmBulk, action.bulkId, action.token);
    yield put({ type: at.CONFIRM_BULK_SUCCESS, response: response.data });
  } catch (e) {
    yield put({ type: at.CONFIRM_BULK_FAILURE, message: e.message || 'Something went wrong' });

    if (e.response) {
      const { status } = e.response;

      if (status === 500) {
        const notification = { type: 'CONFIRM_BULK', text: 'Option not available. Please, try again later.' };
        yield put(notifications.addAndRemove(notification, 5000));
      }
    }
  }
}

export function* deleteBulk(action) {
  try {
    yield put({ type: at.DELETE_BULK_REQUEST });
    const response = yield call(api.deleteBulk, action.bulkId, action.token);
    yield put({ type: at.DELETE_BULK_SUCCESS, response: response.data });
  } catch (e) {
    yield put({ type: at.DELETE_BULK_FAILURE, message: e.message || 'Something went wrong' });
  }
}

export function* stopBulk(action) {
  try {
    yield put({ type: at.STOP_BULK_REQUEST });
    const response = yield call(api.stopBulk, action.bulkId, action.token);
    yield put({ type: at.STOP_BULK_SUCCESS, response: response.data });
  } catch (e) {
    yield put({ type: at.STOP_BULK_FAILURE, message: e.message || 'Something went wrong' });
  }
}

export function* fetchBulkLicenses(action) {
  try {
    yield put({ type: at.FETCH_BULK_LICENSES_REQUEST });
    const response = action.url
      ? yield call(api.fetchBulkLicensesUrl, action.url, action.token, action.filters)
      : yield call(api.fetchBulkLicenses, action.bulkId, action.token, action.filters);
    const { href, first, last, next, previous, limit, size, offset } = response.data;
    const bulkLicensePagination = {
      href,
      first,
      last,
      next,
      previous,
      limit,
      size,
      offset,
    };
    const normalizedData = normalize(response.data.items, schema.bulkLicenses);
    yield put({ type: at.FETCH_BULK_LICENSES_SUCCESS, response: normalizedData, bulkLicensePagination });
  } catch (e) {
    yield put({ type: at.FETCH_BULK_LICENSES_FAILURE, message: e.message || 'Something went wrong' });
  }
}

export function* deleteBulkLicenses(action) {
  try {
    yield put({ type: at.DELETE_BULK_LICENSES_REQUEST });
    const response = yield call(api.deleteBulkLicense, action.bulkId, action.bulkLicenseId, action.token);
    yield put({ type: at.DELETE_BULK_LICENSES_SUCCESS, response: response.data });
    yield put({
      type: at.FETCH_BULK_LICENSES,
      bulkId: action.bulkId,
      token: action.token,
      filters: action.filters,
    });
  } catch (e) {
    yield put({ type: at.DELETE_BULK_LICENSES_FAILURE, message: e.message || 'Something went wrong' });
  }
}

export function* archiveBulkLicenses(action) {
  const state = yield select();
  const { authentication } = state;
  try {
    yield put({ type: at.ARCHIVE_BULK_LICENSES_REQUEST });
    const response = yield call(
      api.archiveBulkLicense,
      action.bulkId,
      action.bulkLicenseId,
      authentication.accessToken
    );
    yield put({ type: at.ARCHIVE_BULK_LICENSES_SUCCESS, response: response.data });
    yield put({
      type: at.FETCH_BULK_LICENSES,
      bulkId: action.bulkId,
      token: authentication.accessToken,
      filters: action.filters,
    });
  } catch (e) {
    yield put({ type: at.ARCHIVE_BULK_LICENSES_FAILURE, message: e.message || 'Something went wrong' });
  }
}

export default function* l() {
  yield takeEvery(at.FETCH_BULKS, fetchBulks);
  yield takeEvery(at.CONFIRM_BULK, confirmBulk);
  yield takeEvery(at.DELETE_BULK, deleteBulk);
  yield takeEvery(at.STOP_BULK, stopBulk);
  yield takeEvery(at.FETCH_BULK_LICENSES, fetchBulkLicenses);
  yield takeEvery(at.DELETE_BULK_LICENSES, deleteBulkLicenses);
  yield takeEvery(at.ARCHIVE_BULK_LICENSES, archiveBulkLicenses);
}
