import React from 'react';
import Modal from 'emerald-ui-2/lib/Modal';
import Loadable from 'react-loadable';
import MessageReceived from './components/messageReceived';
import AuditAssignment from './components/auditAssignment';
import ComplianceStatusChanged from './components/complianceStatusChanged';
import './styles.css';

const AsyncConversationDetail = Loadable({
  loader: () =>
    import('containers/ConversationDetail' /* webpackChunkName: "conversation-detail" */).then((m) => m.default),
  loading() {
    return <span />;
  },
});

export class NotificationsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConversationDetailDialog: false,
      conversationId: '',
    };
  }

  showConversationDetailDialog = (visible) => {
    this.setState({ showConversationDetailDialog: visible });
  };

  handleMessageReceivedClick = (conversationId) => {
    this.setState({ conversationId });
    this.props.toggleNotificationsPanel();
    this.showConversationDetailDialog(true);
  };

  renderNotifications = (notifications) => {
    const { userLogonId } = this.props;
    return notifications.map((notification) => {
      const { messages, participants, data } = notification;

      const me = participants.find((p) => p.user.id === userLogonId) || {};
      switch (messages[0].subtype) {
        case 'licensee_message_received': {
          return (
            <MessageReceived
              messages={messages}
              opened={me.opened}
              key={notification._id}
              onClick={this.handleMessageReceivedClick}
            />
          );
        }
        case 'audit_assignment': {
          return (
            <AuditAssignment
              messages={messages}
              opened={me.opened}
              extraData={data}
              key={notification._id}
              onClick={this.props.toggleNotificationsPanel}
            />
          );
        }
        case 'submit_status_changed': {
          return (
            <AuditAssignment
              messages={messages}
              opened={me.opened}
              extraData={data}
              key={notification._id}
              onClick={this.props.toggleNotificationsPanel}
            />
          );
        }
        case 'compliance_status_changed': {
          return (
            <ComplianceStatusChanged
              messages={messages}
              extraData={data}
              opened={me.opened}
              key={notification._id}
              onClick={this.props.toggleNotificationsPanel}
            />
          );
        }
        default: {
          return <div />;
        }
      }
    });
  };

  render() {
    const { todayNotifications, yesterdayNotifications, weekNotifications } = this.props;
    return (
      <div>
        <Modal
          hideOnOutsideClick
          rightDrawer
          show={this.props.notificationPanelOpened ? true : false}
          onHide={this.props.toggleNotificationsPanel}
        >
          <Modal.Header className="notifications-panel-header">
            <Modal.Title>
              <span>Notifications</span>
            </Modal.Title>
          </Modal.Header>
          {todayNotifications.length > 0 && (
            <div>
              <div className="notifications-panel-notification-wrapper">Today</div>
              {this.renderNotifications(todayNotifications)}
            </div>
          )}
          {yesterdayNotifications.length > 0 && (
            <div>
              <div className="notifications-panel-notification-wrapper">Yesterday</div>
              {this.renderNotifications(yesterdayNotifications)}
            </div>
          )}
          {weekNotifications.length > 0 && (
            <div>
              <div className="notifications-panel-notification-wrapper">Week</div>
              {this.renderNotifications(weekNotifications)}
            </div>
          )}
        </Modal>
        <AsyncConversationDetail
          active={this.state.showConversationDetailDialog}
          onClose={() => this.showConversationDetailDialog(false)}
          conversationId={this.state.conversationId}
          onSent={() => this.showConversationDetailDialog(false)}
          showGoToDetailOption={true}
        />
      </div>
    );
  }
}

export default NotificationsPanel;
