import React from 'react';
import Popover from 'emerald-ui-2/lib/Popover';
import OverlayTrigger from 'emerald-ui-2/lib/OverlayTrigger';

const AuditCreateActivity = ({ cycleEnd, notes }) => {
  return (
    <span>
      initiated an audit
      {cycleEnd && ` for the cycle ending on ${cycleEnd}`}
      {notes && ` with this reason - ${notes}`}
    </span>
  );
};

export const ElapsedDate = ({ date, displayDate }) => {
  return (
    <OverlayTrigger
      rootClose
      triggerType="hover"
      placement="bottom"
      delay={200}
      className="alter-position-elapsed-date"
      overlay={
        <Popover id="activityDate" className="activity-date-tooltip">
          <b>{date}</b>
        </Popover>
      }
    >
      <em className="text-lighter">{displayDate}</em>
    </OverlayTrigger>
  );
};

const ActivityDetail = (props) => {
  const { data, date, displayDate, type } = props;
  let activity;
  let fullName = props.fullName;
  switch (type) {
    case 'audit/create':
      activity = <AuditCreateActivity cycleEnd={data.cycleEnd} notes={data.notes} />;
      break;
    case 'audit/pass':
      activity = (
        <span>
          marked this audit as <strong>passed</strong>
        </span>
      );
      break;
    case 'audit/fail':
      activity = (
        <span>
          marked this audit as <strong>failed</strong>
        </span>
      );
      break;
    case 'audit/submit':
      activity = (
        <span>
          marked this audit as <strong>submitted</strong>
        </span>
      );
      break;
    case 'audit/unarchive':
      activity = <span>returned this audit to the current list from the archive.</span>;
      break;
    case 'audit/archive':
      activity = <span>has archived this audit.</span>;
      break;
    case 'audit/add_attachment':
      activity = (
        <span>
          added{' '}
          <a href={data.path} target="_blank" rel="noopener noreferrer">
            {data.fileName}
          </a>
        </span>
      );
      break;
    case 'audit/delete_attachment':
      activity = (
        <span>
          deleted{' '}
          <a href={data.path} target="_blank" rel="noopener noreferrer">
            {data.fileName}
          </a>
        </span>
      );
      break;
    case 'audit/set_as_under_investigation':
      activity = <span>marked this audit as under investigation.</span>;
      break;
    case 'audit/set_as_not_under_investigation':
      activity = <span>concluded the investigation of this audit.</span>;
      break;
    case 'audit/change_assigment':
      const assignmentName = data.assignmentUser ? data.assignmentUser.fullName : 'Some user';
      activity = (
        <span>
          assigned to <strong>{assignmentName}</strong>
        </span>
      );
      break;
    case 'conversation/initial':
      fullName = data.sentBy.name;
      activity = (
        <span>
          has sent a message to <strong>{data.sentTo.name}</strong> titled <i>{data.subject}</i>{' '}
        </span>
      );
      break;
    case 'conversation/reply':
      fullName = data.sentBy.name;
      activity = (
        <span>
          has replied to <strong>{data.sentTo.name}</strong> about <i>{data.subject}</i>
        </span>
      );
      break;
    case 'conversation/set_as_completed':
      fullName = data.sentBy.name;
      activity = (
        <span>
          has marked the conversation <i>{data.subject}</i> as <strong>completed</strong>
        </span>
      );
      break;
    case 'conversation/set_as_not_completed':
      fullName = data.sentBy.name;
      activity = (
        <span>
          has marked the conversation <i>{data.subject}</i> as <strong>not completed</strong>
        </span>
      );
      break;
    default:
      activity = <span>{data.text}</span>;
  }
  return (
    <span>
      <strong>{fullName}</strong>
      {` `}
      {activity}
      {` `}
      <ElapsedDate date={date} displayDate={displayDate} />
    </span>
  );
};

export default ActivityDetail;
