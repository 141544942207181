import axios from 'axios';
import { API_URL } from 'config';

export function fetchTranscript({ licensePeriodId, licenseNumber, licenseId, professionId, stateCode, userId, token }) {
  return axios.request({
    url: `${API_URL}/transcript?licensePeriodId=${licensePeriodId}&licenseNumber=${encodeURIComponent(licenseNumber)}&stateCode=${stateCode}&professionId=${professionId}&pkLicense=${licenseId}&userId=${userId}`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function postDisallowHours(data, token) {
  return axios.request({
    url: `${API_URL}/transcript/disallow`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
}


export function updateTranscript({ transcript, token }) {
  return axios.request({
    url: `${API_URL}/transcript/hours/edit`,
    method: 'put',
    data: transcript,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function postLicenseeClearToRenew({ licensePeriodId, licenseTempCompl, token }) {
  return axios.request({
    url: `${API_URL}/transcript/clear-licensee-to-renew`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      licensePeriodId,
      licenseTempCompl: +licenseTempCompl,
    },
  });
}

export function deleteWaiverScenario({ scenarioId, data, token }) {
  return axios.request({
    url: `${API_URL}/scenario/${scenarioId}`,
    method: 'delete',
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}