import moment from 'moment';

export function passStateToSelectors(selectors, state) {
  return Object.keys(selectors).reduce((prev, next) => {
    return next === 'default' ? prev : { ...prev, [next]: selectors[next](state) };
  }, {});
}

export const getFullNameFromObject = (object) => {
  const middleName = object.middleName ? `${object.middleName}` : '';
  const fullName = `${object.firstName || ''} ${middleName} ${object.lastName || ''}`;
  return fullName.trim().replace(/\s{2,}/g, ' ');
};

export const firstLetterUppercase = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const highlightInnerText = (text = '', innerText = '', tag = 'strong') => {
  if (!innerText) return text;
  let newText = text;
  const splittedTerm = innerText
    .trim()
    .replace(/\s{2,}/g, ' ')
    .split(' ');
  splittedTerm.forEach((item) => {
    let matches = text.match(new RegExp(item, 'gi')) || [];
    matches = matches.filter((elem, index, self) => index === self.indexOf(elem));
    matches.forEach((match) => {
      const regex = new RegExp(match, 'g');
      if (match) newText = newText.replace(regex, `<${tag}>${match}</${tag}>`);
    });
  });
  return newText;
};

export const isComplexPassword = (password) => {
  let anUpperCase = /[A-Z]/;
  let aLowerCase = /[a-z]/;
  let aNumber = /[0-9]/;
  let minLong = 8;
  let obj = { result: true, error: [] };
  if (password.length < minLong) {
    obj.result = false;
    obj.error.push('Be at least 8 characters.');
  }
  let numUpper = 0,
    numLower = 0,
    numNums = 0;
  for (let i = 0; i < password.length; i++) {
    if (anUpperCase.test(password[i])) numUpper++;
    else if (aLowerCase.test(password[i])) numLower++;
    else if (aNumber.test(password[i])) numNums++;
  }
  if (numUpper < 1) {
    obj.result = false;
    obj.error.push('At least one capital letter');
  }
  if (numLower < 1) {
    obj.result = false;
    obj.error.push('At least one lowercase letter');
  }
  if (numNums < 1) {
    obj.result = false;
    obj.error.push('At least one number');
  }
  return obj;
};

export const hoursAppliedAfterCycleEnd = (transcript, selectedPeriod = {}) => {
  let hoursApplied = false;
  if (!transcript) return false;
  const { items } = transcript;
  if (!items) return false;
  items.forEach((item) => {
    const { children, creditCourses } = item;
    if (children && children.length > 0) {
      children.forEach((child) => {
        const { creditCourses } = child;
        if (creditCourses && creditCourses.length > 0) {
          hoursApplied =
            creditCourses.filter((creditCourse) => {
              return moment(creditCourse.dateEarned) > moment(selectedPeriod.renewalEndDate);
            }).length > 0;
        }
      });
    }
    if (creditCourses && creditCourses.length > 0 && !hoursApplied) {
      hoursApplied =
        creditCourses.filter((creditCourse) => {
          return moment(creditCourse.dateEarned) > moment(selectedPeriod.renewalEndDate);
        }).length > 0;
    }
  });
  return hoursApplied;
};

export const parseBool = (str) => {
  return str + '' === 'true' ? true : false;
};

export const removeEmptyProperties = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

export const isValidEmail = (email) => {
  var emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const sortPeriodsDesc = (periods) => {
  return periods.slice().sort((prev, next) => {
    return moment(new Date(prev.renewalEndDate)) > moment(new Date(next.renewalEndDate)) ? -1 : 1;
  });
};

export const parseDate = (date) => {
  return date ? moment(new Date(date)).utc().format('L') : null;
};

export const momentDate = (date) => {
  return date ? moment(new Date(date)).utc() : null;
};

export const getCreditType = (creditType) => {
  return creditType === 'ROSTER' ? 'provider' : creditType === 'LICENSEE_POST' ? 'licensee' : 'board';
};

export const getStatusColor = (licenseStatus, licensesStatuses) => {
  let statusColor = 'grey';
  if (licensesStatuses.length && licenseStatus) {
    licensesStatuses.forEach((ls) => {
      if (ls.status && ls.status.toUpperCase() === licenseStatus.toUpperCase()) {
        if (ls.boards.length > 0) {
          const isRenewableStatus = ls.boards[0].isRenewable;
          if (isRenewableStatus === true) statusColor = 'green';
          if (isRenewableStatus === false) statusColor = 'red';
        }
        return;
      }
    });
  }
  return statusColor;
};

export const removeTextInsideBrackets = (text) => {
  if (!text) {
    return '';
  }
  return text.replace(/\[(.*?)\]/, '');
};

export const getDisplayCourseType = (courseType) => {
  if (!courseType) {
    return '';
  }
  return courseType === 'CD_LIVE' ? 'Live' : 'Anytime';
};

export const redirectSite = (url, path = '/') => {
  const newHostname = new URL(url).hostname;
  const currentHostname = window.location.hostname;
  if (newHostname !== currentHostname) {
    window.location.replace(`${url}${path}`);
  }
};

export const dateIsIndefinite = (date, enableSpecialcycle) => {
  const regexDate = new RegExp('12/31/9999');
  return regexDate.test(date) && enableSpecialcycle;
};

export const transcriptCycle = (date, position) => {
  const ALPHA_CHAR = 65;
  const AMOUNT_LETTERS = 26;
  const array = Array.from(Array(AMOUNT_LETTERS)).map((_e, i) => i + ALPHA_CHAR);
  const alpha = array.map((x) => String.fromCharCode(x));
  return `Citation Effective Date: ${date} ${alpha[position]}`;
};

export const getAccessToken = () => {
  const userId = localStorage.getItem('userId');
  const userData = localStorage.getItem(userId);
  const {
    authentication: { accessToken },
  } = JSON.parse(userData);
  return accessToken;
};

export const toCapitalizedCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const delay = (seconds) => new Promise((resolve) => setTimeout(resolve, seconds * 1000));

export const formatPhone = (phone) => {

  if (!phone || phone.length === 0) {
    return '-'
  };

  if (phone.includes('(')) {
    return phone;
  }

  phone = phone.split('');
  phone.splice(0, 0, '(');
  phone.splice(4, 0, ') ');
  phone.splice(8, 0, '-');

  return phone.join('');
}