import axios from 'axios';
import { API_URL } from 'config';

export function fetch(token) {
  return axios.request({
    url: `${API_URL}/roles`,
    method: 'get',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}
