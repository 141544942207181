import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const Row = ({ children, onClick, className }) => {
  const classes = `table-v2-row ${className}`;
  return (
    <div onClick={onClick} className={classes}>
      {children}
    </div>
  );
};

Row.propTypes = {
  onClick: PropTypes.func,
};

Row.defaultProps = {
  onClick: () => {},
};

export default Row;
