import React from 'react';
import { Body, Row, Col } from 'components/Table/v2';
import Avatar from 'emerald-ui-2/lib/Avatar';
import Icon from 'emerald-ui-2/lib/Icon';

import './styles.css';

const boardsCount = (user) =>
  Object.keys(user.states).map((key, i) => (
    <span key={i} className="search-user-state-count text-color">
      <span className="search-user-stateCode">{`${key}`}</span>
      {` (${user.states[key].count})`}
    </span>
  ));

const boards = (user) =>
  Object.keys(user.states).map((key) =>
    user.states[key].boards.map((b, i) => (
      <p key={i}>
        <span className="search-user-stateCode">{`${key}`}</span>
        {` ${b.name}`}
      </p>
    ))
  );

const TableBody = ({ users, onToggleRow, expandedRow, onRowClick, ...rest }) => {
  const rows = users.map((user, i) => {
    const isExpanded = i === expandedRow;
    return (
      <Row key={user.id} onClick={() => onRowClick(user.id)} className="search-user-clickable-row">
        <Col className="search-user-name-column">
          <div className="search-user-user-name-wrapper text-color">
            <Avatar
              size="sm"
              title={`${user.firstName || ''} ${user.lastName || ''}`}
              className="search-user-avatar-image"
            />
            {`${user.firstName || ''} ${user.lastName || ''}`}
          </div>
        </Col>
        <Col className="search-user-email-column text-color">{user.email}</Col>
        <Col>
          <div style={{ display: 'flex', flexDirection: 'column' }} className="text-color">
            <div>{boardsCount(user)}</div>
            <div>{isExpanded && boards(user)}</div>
          </div>
        </Col>
        <Col>
          <Icon
            className="search-user-arrow-row text-color"
            name={isExpanded ? 'arrow_drop_up' : 'arrow_drop_down'}
            onClick={(e) => {
              e.stopPropagation();
              onToggleRow(i, !isExpanded);
            }}
          />
        </Col>
      </Row>
    );
  });
  return <Body {...rest}>{rows}</Body>;
};

export default TableBody;
