import { call, put } from 'redux-saga/effects';

import * as api from 'api/boardUser';
import at from '../actions/types';

export function* createBoardPassword(action) {
  yield put({ type: at.CREATE_REQUEST });
  try {
    let response;
    const { data } = action;
    const userId = data.userId;
    const password = data.password;
    try {
      response = yield call(api.createBoardPassword, userId, password);
    } catch (e) {
      console.log(e);
      yield put({ type: at.CREATE_FAILURE, message: e.message || 'Something went wrong' });
      return;
    }
    yield put({ type: at.CREATE_SUCCESS, ...response.data });
  } catch (error) {
    console.log('error', error);
  }
}
