export const getEntityById = (state) => (id) => state.entities[id] || {};

export const getEntities = (state) => state.result.map((id) => getEntityById(state)(id));

export const getEntitiesByProfession = (state) => (profession) =>
  Object.keys(state.entities)
    .map((key) => getEntityById[key])
    .filter((ls) => ls.profession.some(profession));

export const getIsFetching = (state) => state.isFetching;

export const getErrorMessage = (state) => state.errorMessage;

export default {
  getEntityById,
  getEntities,
  getEntitiesByProfession,
  getIsFetching,
  getErrorMessage,
};
