import React, { Component } from 'react';

import Link from 'react-router-dom/Link';
import Panel from 'emerald-ui-2/lib/Panel';

import './styles.css';

class SelfReportedCEDetailLicenseeInfo extends Component {
  render() {
    const { name, number, licenseId } = this.props;

    return (
      <Panel className="self-reported-ce-detail-page-card">
        <div className="self-reported-ce-detail-page-cardHeader">
          <p>Licensee info</p>
        </div>
        <div className="self-reported-ce-detail-page-cardBody">
          <div>
            <div>
              <p className="self-reported-ce-detail-page-cardTitle">Name</p>
              <span>{name}</span>
            </div>
            <div>
              <p className="self-reported-ce-detail-page-cardTitle">Number</p>
              <span>
                <Link to={`/licenses/${licenseId}`}>{number}</Link>
              </span>
            </div>
          </div>
        </div>
      </Panel>
    );
  }
}

export default SelfReportedCEDetailLicenseeInfo;
