export default function (globalState) {
  const state = globalState.boardsAccess;
  const selectedBoard = globalState.selectedBoard;

  const getEntitiesObject = () => state.entities;

  const getEntityById = (id) => state.entities[id] || {};

  const getFetchStatusById = (id) => state.entityFetchStatus[id];

  const getSelectedBoardAccess = () => state.entities[selectedBoard.id] || {};

  const getSelectedBoardFetchStatus = () => state.entityFetchStatus[selectedBoard.id] || '';

  return {
    getEntitiesObject,
    getEntityById,
    getFetchStatusById,
    getSelectedBoardAccess,
    getSelectedBoardFetchStatus,
  };
}
