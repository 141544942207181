import axios from 'axios';
import { API_URL } from '../config';
import { PAGE_SIZE, getOffset } from 'constants.js';
import querystring from 'querystring';

export function fetchSelfReportedCE(boardId, filters, token) {
  const {
    selfReportedCESize = PAGE_SIZE,
    selfReportedCEPage = 1,
    selfReportedCETerm = '',
    selfReportedCESort,
    selfReportedCEOrder,
    minReceivedDate,
    maxReceivedDate,
    assignmentId,
    status,
    postCECreditId,
  } = filters;
  const offset = getOffset(selfReportedCEPage, selfReportedCESize);

  const newFilters = { offset, limit: selfReportedCESize };
  if (selfReportedCESort) {
    newFilters.sort = selfReportedCESort;
  }
  if (selfReportedCEOrder) {
    newFilters.order = selfReportedCEOrder;
  }
  if (selfReportedCETerm) {
    newFilters.term = selfReportedCETerm;
  }
  if (minReceivedDate) {
    newFilters.minReceivedDate = minReceivedDate;
  }
  if (maxReceivedDate) {
    newFilters.maxReceivedDate = maxReceivedDate;
  }
  if (assignmentId) {
    newFilters.assignmentId = assignmentId;
  }
  if (status) {
    newFilters.status = status;
  }
  if (postCECreditId) {
    newFilters.postCECreditId = postCECreditId;
  }

  const queryString = querystring.stringify(newFilters);

  return axios.request({
    url: `${API_URL}/licensee-ce-approval/${boardId}?${queryString}`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}
