import { call, takeLatest, put } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import at from '../actions/types';
import * as actions from '../actions';

import { createAudit, createAuditWithCustmoCycle } from './createAudit';
import { updateAudit } from './updateAudit';
import { archiveAudit } from './archiveAudit';
import { unarchiveAudit } from './unarchiveAudit';
// import { watchFetchAudits } from './fetchAudits';
// import { watchFetchAudit } from './fetchAudit';

import { getFiltersFromQuery, removePaginationFromFilters } from '../common';

// This method verify some condition to know if with should fetch the audits
// this allows to make the fetch only when it is needed to avoid to waste resources
export const auditsPageMemory = (() => {
  let previousCurrentFilters = {};
  let previousArchivedFilters = {};
  function* auditsPage({ payload }) {
    if (isEmpty(payload.query)) {
      return;
    }

    const { tab = 'current' } = payload.query;
    const filters = getFiltersFromQuery(payload.query, tab);
    if (tab === 'current') {
      if (!isEqual(removePaginationFromFilters(filters), removePaginationFromFilters(previousCurrentFilters))) {
        yield put(actions.clear('current'));
      }
      previousCurrentFilters = filters;
    } else {
      if (!isEqual(removePaginationFromFilters(filters), removePaginationFromFilters(previousArchivedFilters))) {
        yield put(actions.clear('archived'));
      }
      previousArchivedFilters = filters;
    }
    yield put(actions.fetchAudits(filters));
  }
  return auditsPage;
})();

function* auditDetailPage({ pathname }) {
  const split = pathname.split('/');
  const auditId = split[split.length - 1];
  yield put(actions.fetchAudit(auditId));
}

export function* watchLocationChange({ payload }) {
  // We call the apropiate function depending on what page we are
  if (/audits$/.test(payload.pathname)) {
    yield call(auditsPageMemory, { payload });
    return;
  }

  if (/audits\/*/.test(payload.pathname)) {
    if (isEmpty(payload.query)) {
      yield call(auditDetailPage, { pathname: payload.pathname });
    } else {
      yield call(auditsPageMemory, { payload });
    }
  }
}

export const verifyLocation = ({ type, payload }) => {
  // If the type is action type is LOCATION_CHANGE
  if (type !== LOCATION_CHANGE) {
    return false;
  }
  // If the url is /audits and there is filters
  if (/audits$/.test(payload.pathname) && !isEmpty(payload.query)) {
    return true;
  }
  // If the url is /audits/whatever
  if (/audits\/*/.test(payload.pathname)) {
    return true;
  }
  return false;
};

// This composed thunk must be the guide to create new composed thunks
export function* updateAndArchiveAudit({ auditId, data, meta }) {
  yield call(updateAudit, {
    auditId,
    data,
    meta: { addNotification: true, notification: meta.notification, archive: true },
  });
}

// export function* watchSelectedBoardChange({ payload }) {
//   const state = yield select();
//   const  { query, pathname } = state.routing.locationBeforeTransitions;

//   yield put(actions.clearEntities());
//   yield put(actions.clear('current'));
//   yield put(actions.clear('archived'));

//   // If the url is /audits/whatever
//   if (/audits\/*/.test(pathname) && isEmpty(query)) {
//     yield fork(auditDetailPage, { pathname });
//     return;
//   }

//   if (/audits$/.test(pathname) || /audits\/*/.test(pathname)) {
//     const currentFilters = getFiltersFromQuery(query, 'current');
//     const archivedFilters = getFiltersFromQuery(query, 'archived');
//     yield fork(watchFetchAudits, { filters: currentFilters, meta: { force: true }});
//     yield fork(watchFetchAudits, { filters: archivedFilters, meta: { force: true }});
//     return;
//   }
// }

export default function* root() {
  // yield takeLatest(verifyLocation, watchLocationChange);

  // yield takeLatest('SELECT_BOARD', watchSelectedBoardChange);

  // yield takeLatest(at.FETCH, watchFetchAudits);
  // yield takeLatest(at.FETCH_ONE, watchFetchAudit);

  yield takeLatest(at.UPDATE, updateAudit);
  yield takeLatest(at.ARCHIVE, archiveAudit);
  yield takeLatest(at.UNARCHIVE, unarchiveAudit);
  yield takeLatest(at.UPDATE_AND_ARCHIVE, updateAndArchiveAudit);

  yield takeLatest(at.CREATE, createAudit);
  yield takeLatest(at.CREATE_WITH_CUSTOM_CYCLE, createAuditWithCustmoCycle);
}
