import { combineReducers } from 'redux';

import at from '../actions/types';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return action.data;
    }
    case at.FETCH_ONE_SUCCESS: {
      return action.data;
    }
    case at.CREATE_SUCCESS: {
      return action.data;
    }
    default:
      return state;
  }
};

export const isFetching = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return false;
    }
    case at.FETCH_ONE_SUCCESS: {
      return false;
    }
    case at.CREATE_SUCCESS: {
      return false;
    }
    case at.FETCH_FAILURE: {
      return false;
    }
    case at.FETCH_ONE_FAILURE: {
      return false;
    }
    case at.FETCH_REQUEST: {
      return true;
    }
    case at.FETCH_ONE_REQUEST: {
      return true;
    }
    default:
      return false;
  }
};

export const fetchSucceed = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return true;
    }
    case at.FETCH_ONE_SUCCESS: {
      return true;
    }
    case at.CREATE_SUCCESS: {
      return true;
    }
    case at.FETCH_FAILURE: {
      return false;
    }
    case at.FETCH_ONE_FAILURE: {
      return false;
    }
    case at.FETCH_REQUEST: {
      return false;
    }
    case at.FETCH_ONE_REQUEST: {
      return false;
    }
    default:
      return state;
  }
};

export const errorMessage = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return '';
    }
    case at.FETCH_ONE_SUCCESS: {
      return '';
    }
    case at.FETCH_FAILURE: {
      return action.message;
    }
    case at.FETCH_ONE_FAILURE: {
      return action.message;
    }
    case at.FETCH_REQUEST: {
      return '';
    }
    case at.FETCH_ONE_REQUEST: {
      return '';
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  isFetching,
  fetchSucceed,
  errorMessage,
});
