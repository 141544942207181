import { combineReducers } from 'redux';
import at from '../actions/types';

// function removeIdFromPages(auditId, pages) {
//   const newPages = {};
//   let pageNumber = -1;
//   Object.entries(pages).forEach(([key, page]) => {
//     if (page.includes(auditId)) {
//       pageNumber = key;
//     } else {
//       newPages[key] = page;
//     }
//   });
//   if (pageNumber !== -1) {
//     newPages[pageNumber] = pages[pageNumber].filter(id => id !== auditId);
//   }
//   return newPages;
// }

export const pages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      if (action.meta.auditType !== 'current') {
        return state;
      }
      return {
        ...state,
        [action.filters.page]: action.result,
      };
    }
    case at.CLEAR: {
      if (action.meta.auditType !== 'current') {
        return state;
      }
      return {};
    }
    // This is commented to allow pagination on detail page without problems
    // case at.ARCHIVE_SUCCESS: {
    //   return removeIdFromPages(action.auditId, state);
    // }
    default:
      return state;
  }
};

export const pagination = (state = { currentPage: 1 }, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      if (action.meta.auditType !== 'current') {
        return state;
      }
      return { ...state, totalItems: action.totalItems };
    }
    case at.SET_PAGINATION: {
      if (action.meta.auditType !== 'current') {
        return state;
      }
      return action.pagination;
    }
    case at.ARCHIVE_SUCCESS: {
      return {
        ...state,
        totalItems: state.totalItems - 1,
      };
    }
    default:
      return state;
  }
};

export const fetchStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      if (action.meta.auditType !== 'current') {
        return state;
      }
      return { ...state, [action.filters.page]: 'loading' };
    }
    case at.FETCH_SUCCESS: {
      if (action.meta.auditType !== 'current') {
        return state;
      }
      return { ...state, [action.filters.page]: 'loaded' };
    }
    case at.FETCH_FAILURE: {
      if (action.meta.auditType !== 'current') {
        return state;
      }
      return { ...state, [action.filters.page]: 'failed' };
    }
    case at.FETCH_CANCELED: {
      if (action.meta.auditType !== 'current') {
        return state;
      }
      return { ...state, [action.filters.page]: 'loaded' };
    }
    default:
      return state;
  }
};

export default combineReducers({
  pages,
  pagination,
  fetchStatus,
});
