import { call, put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import * as api from 'api/boardApplications';

import at from '../actions/types';

export function* fetchReports(filters) {
  const state = yield select();
  const { authentication } = state;
  const boardId = state.selectedBoard.id;
  yield put({ type: at.FETCH_REPORTS_REQUEST, filters, meta: {} });
  try {
    yield call(delay, 300);
    let response;
    try {
      response = yield call(api.fetchReports, { boardId, filters, token: authentication.accessToken });
    } catch (e) {
      console.log(e);
      yield put({ type: at.FETCH_REPORTS_FAILURE, filters, message: e.message || 'Something went wrong', meta: {} });
      return;
    }

    yield put({ type: at.FETCH_REPORTS_SUCCESS, ...response.data, filters, meta: {} });
  } catch (error) {
    console.log('error', error);
  }
}

export function* watchFetchReports({ filters, meta }) {
  yield call(fetchReports, filters);
}
