import { takeEvery, takeLatest } from 'redux-saga/effects';

import at from '../actions/types';

import { fetchLicensesStatuses } from './fetchLicensesStatuses';

export default function* root() {
  yield takeEvery(at.FETCH, fetchLicensesStatuses);
  yield takeLatest('SELECT_BOARD', fetchLicensesStatuses);
}
