import React from 'react';
import './styles.css';

const Overlay = ({ children, className, ...rest }) => {
  const classes = `overlay ${className}`;
  return (
    <div {...rest} className={classes}>
      {children}
    </div>
  );
};

export default Overlay;
