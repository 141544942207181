import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Container from 'emerald-ui-2/lib/Container';
import Row from 'emerald-ui-2/lib/Row';
import Col from 'emerald-ui-2/lib/Col';
import Button from 'emerald-ui-2/lib/Button';
import Panel from 'emerald-ui-2/lib/Panel';
import TextField from 'emerald-ui-2/lib/TextField';
import { LoadingBox } from '../../../../components';
import { isComplexPassword } from '../../../../utils';
import './styles.css';
import logo from './logo-ceb.svg';
import config from 'config';
import * as cookies from 'utils/cookies';
import * as authRequest from 'api/auth';

import { actions as boardUserActions } from 'state/boardUser';
import { authenticate, logout, verifyToken } from '../../../../actions/index';

export class BoardAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      confirmPassword: '',
      passwordError: '',
      passwordConfirmError: '',
      complexError: null,
    };

    this.form = {};
  }
  componentDidMount() {
    const { fetchBoardUser, match } = this.props;
    const { params } = match;
    if (fetchBoardUser) fetchBoardUser(params.token);
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.boardUserRequest.entities === 'Password Updated') {
      const authResponse = await authRequest.accountsToken(this.state.username, this.state.password);
      if (authResponse && authResponse.data) {
        cookies.setCookie(config.LEGACY_COOKIE_NAME, authResponse.data.token);
        this.props.history.push('/sso');
      }
    }
  }

  handleChangePassword = (event) => {
    const password = event.target.value;
    this.setState({ password, passwordError: '' });
  };

  handleChangeConfirmPassword = (event) => {
    const confirmPassword = event.target.value;
    this.setState({ confirmPassword, passwordConfirmError: '' });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { createBoardPassword, boardUserRequest } = this.props;
    const { password, confirmPassword } = this.state;
    let passwordError = '';
    let passwordConfirmError = '';
    let complexError;

    if (password === '') {
      passwordError = "The password can't be empty";
    }

    if (password !== confirmPassword) {
      passwordConfirmError = 'The confirm password input must match the password input.';
    }

    if (confirmPassword === '') {
      passwordConfirmError = "The password can't be empty";
    }
    let validation = isComplexPassword(password);

    if (!validation.result) {
      complexError = validation.error;
    }
    this.setState({
      passwordError,
      passwordConfirmError,
      complexError,
      username: boardUserRequest.entities.userEmail,
    });

    if (passwordError === '' && passwordConfirmError === '' && !complexError) {
      createBoardPassword({
        userId: boardUserRequest.entities.userId,
        password,
      });
    }
  };

  renderPasswordErrors = () => {
    const { complexError } = this.state;
    if (complexError) {
      return (
        <ul className="board-account-complex-password-errors">
          <p>Password must:</p>
          {complexError.map((error) => {
            return <li key={error}>{error}</li>;
          })}
        </ul>
      );
    }
  };

  renderForm = () => {
    const { boardUserRequest } = this.props;
    let user = boardUserRequest.entities;
    const { password, confirmPassword, passwordError, passwordConfirmError } = this.state;
    return (
      <Container>
        <div className="board-account-logo">
          <img src={logo} alt="CE Broker" />
        </div>
        <Container>
          <Row center>
            <Col xs={6}>
              <h4 className="board-account-cardTitle">Activate Account</h4>
              <Panel className="board-account-card">
                <form
                  ref={(form) => {
                    this.form = form;
                  }}
                  id="form"
                  method="POST"
                  onSubmit={this.handleSubmit}
                >
                  <p className="board-account-cardMessage">
                    Hi,{' '}
                    <strong>
                      {user.firstName || ''} {user.lastName || ''}
                    </strong>
                    ! Please create a password for your board account.
                  </p>
                  <TextField
                    type="password"
                    label="Password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={this.handleChangePassword}
                    errorMessage={passwordError}
                  />
                  <TextField
                    type="password"
                    label="Confirm your password"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={this.handleChangeConfirmPassword}
                    errorMessage={passwordConfirmError}
                  />
                  <Button color="info" type="submit" className="board-account-cardButton">
                    Activate
                  </Button>
                  {this.renderPasswordErrors()}
                </form>
              </Panel>
            </Col>
          </Row>
          <Row center>
            <Col xs={6}>
              <div className="board-account-footerText">
                <p>
                  <small>CE Broker © 2019</small>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  };

  renderInvalidToken = () => {
    return (
      <Container>
        <div className="board-account-logo">
          <img src={logo} alt="CE Broker" />
        </div>
        <Container>
          <Row center>
            <Col xs={6}>
              <Panel className="board-account-logo-card">
                <h4 className="board-account-logo-cardMessage">
                  You've already created your board account. Click <a href={config.LAUNCHPAD_URL}>here</a> to log in
                </h4>
                <p style={{ width: '100%', textAlign: 'center' }}>Please contact to CEBroker support</p>
              </Panel>
            </Col>
          </Row>
          <Row center>
            <Col xs={6}>
              <div className="board-account-logo-footerText">
                <p>
                  <small>CE Broker © 2019</small>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  };

  render() {
    const { boardUserRequest } = this.props;
    if (boardUserRequest.fetchSucceed) {
      return this.renderForm();
    }
    if (boardUserRequest.errorMessage) {
      return this.renderInvalidToken();
    }
    return (
      <div className="board-account-logo-loader">
        <LoadingBox visible={true} text="Loading..." />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    boardUserRequest: state.boardUser,
    authentication: state.authentication,
  };
}

BoardAccount.contextTypes = {
  router: PropTypes.object,
};

export default connect(mapStateToProps, {
  fetchBoardUser: boardUserActions.fetchBoardUser,
  createBoardPassword: boardUserActions.createBoardPassword,
  authenticate,
  logout,
  verifyToken,
})(BoardAccount);
