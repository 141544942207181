import axios from 'axios';
import { API_URL } from 'config';

export function create(boardId, section = {}, token) {
  const { name: sectionName, data: sectionData } = section;
  let url = `${API_URL}/feed`;
  let payload = {
    data: {
      boardId,
    },
  };
  switch (sectionName) {
    case 'audit-detail': {
      url += `?addToAudit=${sectionData.addToAudit}`;
      payload.auditId = sectionData.auditId;
      break;
    }
    case 'self-reported-ce-detail': {
      payload.postCECreditId = sectionData.postCECreditId;
      break;
    }
    default: {
    }
  }
  return axios.request({
    url,
    method: 'post',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: payload,
  });
}

export function fetch(feedId, token) {
  return axios.request({
    url: `${API_URL}/feed/${feedId}?expandMessages=true`,
    method: 'get',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}

export function addCommentToFeed(feedId, text, data, token) {
  return axios.request({
    url: `${API_URL}/feed/${feedId}/comments`,
    method: 'post',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: {
      text,
      data,
    },
  });
}

export function deleteCommentFromFeed(feedId, commentId, token) {
  return axios.request({
    url: `${API_URL}/feed/${feedId}/comments/${commentId}`,
    method: 'delete',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}

export function updateCommentInFeed(feedId, commentId, text, token) {
  return axios.request({
    url: `${API_URL}/feed/${feedId}/comments/${commentId}`,
    method: 'put',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: {
      text,
    },
  });
}
