import { call, put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import * as api from 'api/licenses';

import at from '../actions/types';

export function* fetchLicensePeriods({ licenseId }) {
  const state = yield select();
  const { authentication } = state;
  yield put({ type: at.FETCH_REQUEST, licenseId, meta: {} });
  try {
    yield call(delay, 300);
    let response;
    try {
      response = yield call(api.fetchPeriods, { licenseId, token: authentication.accessToken });
    } catch (e) {
      console.log(e);
      yield put({ type: at.FETCH_FAILURE, licenseId, message: e.message || 'Something went wrong', meta: {} });
      return;
    }

    yield put({ type: at.FETCH_SUCCESS, licensePeriods: response.data, licenseId, meta: {} });
  } catch (error) {
    console.log('error', error);
  }
}
