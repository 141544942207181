import { call, put, select } from 'redux-saga/effects';
import * as api from 'api/users';
import at from '../actions/types';

import { actions as notifications } from 'state/notifications';

export function* addUserToBoard({ userId, data, meta = {} }) {
  const { notification = {} } = meta;
  const state = yield select();
  const { authentication, selectedBoard } = state;

  yield put({ type: at.ADD_USER_TO_BOARD_REQUEST });
  try {
    const boardId = selectedBoard.id;
    yield call(api.addUserToBoard, { userId, boardId, data, token: authentication.accessToken });
  } catch (e) {
    console.log(e);
    yield put({ type: at.ADD_USER_TO_BOARD_FAILURE, data, message: e.message || 'Something went wrong' });

    // Add failure notification
    const { failureText } = notification;
    const n = { type: 'ADD_USER_TO_BOARD', text: failureText || 'Error trying to tie the user to the board' };
    yield put(notifications.addAndRemove(n, 5000));
    if (meta.errorCallback && typeof meta.errorCallback === 'function') {
      meta.errorCallback();
    }
    return;
  }
  yield put({ type: at.ADD_USER_TO_BOARD_SUCCESS, userId, data });
  if (meta.successCallback && typeof meta.successCallback === 'function') {
    meta.successCallback();
  }
}
