import { takeEvery } from 'redux-saga/effects';

import at from '../actions/types';

import { deleteWaiverScenario, fetchTranscript, updateTranscript } from './fetchTranscript';
import { postLicenseeClearToRenew } from './licenseeClearToRenew';

export default function* root() {
  yield takeEvery(at.FETCH, fetchTranscript);
  yield takeEvery(at.UPDATE, updateTranscript);
  yield takeEvery(at.UPDATE_LICENSEE_CLEAR, postLicenseeClearToRenew);
  yield takeEvery(at.DELETE_WAIVER_SCENARIO, deleteWaiverScenario);
}
