export const PAGE_SIZE = 10;
export const PAGE_OFFSET = 1;

export const REQUEST_STATUSES = {
  REQUESTING: 'REQUESTING',
  SUCCEED: 'SUCCEED',
  FAILED: 'FAILED',
};

export function getOffset(page, limit) {
  return (page - 1) * limit;
}

export function getPage(offset, limit) {
  return offset / (limit + 1) + 1;
}

export const FAILURE_EMAIL_INVITATION_STATUS = ['BLOCKED'];

export const EMAIL_INVITATION_STATUSES = ['BLOCKED', 'DELIVERED', 'OPEN', 'SENT'];

export const LOGGED_USER_ROLES = {
  ADMINISTRATOR: 'Administrator',
  STANDARD: 'Standard',
};

export const SELF_REPORTED_CE_STATUSES = [
  {
    name: 'Approved',
    value: 'APPROVED',
  },
  {
    name: 'Needs Approval',
    value: 'NEEDS_APPROVAL',
  },
  {
    name: 'On hold',
    value: 'ON_HOLD',
  },
  {
    name: 'Pending Response',
    value: 'PENDING_RESPONS',
  },
  {
    name: 'Disapproved',
    value: 'DISAPPROVED',
  },
];

export const DOWNLOAD_PDF_STATUSES = {
  INITIAL: 'PENDING',
  PROCESSING: 'PROCESSING',
  FINISHED: 'DONE',
  DOWNLOADED: 'COMPLETED',
};

export const REACT_APP_PDF_REQUEST_POLLING_INTERVAL_SECONDS = 5;

export const DOWNLOADED_FILE_NAME = 'CCH-Report.pdf';

export const STATUS_PROVIDERS_LIST_FILTERS = {
  STATUS_DEFAULT: 'APPROVED,EXPIRED,DISAPPROVED,WITHDRAWN',
  FILTER_OPTION_STATUS: ['Approved', 'Expired', 'Disapproved', 'Withdrawn'],
};

export const COURSES_STATUSES = [
  {
    name: 'Approved',
    value: 'APPROVED',
  },
  {
    name: 'Needs Approval',
    value: 'NEEDS_APPROVAL',
  },
  {
    name: 'On hold',
    value: 'ON_HOLD',
  },
  {
    name: 'Pending Response',
    value: 'PENDING_RESPONS',
  },
  {
    name: 'Disapproved',
    value: 'DISAPPROVED',
  },
  {
    name: 'Withdrawn',
    value: 'WITHDRAWN',
  },
  {
    name: 'In progress',
    value: 'IN_PROGRESS',
  },
  {
    name: 'Expired',
    value: 'EXPIRED',
  }
];

export const COURSES_TYPE = [
  {
    name: 'Anytime',
    value: 'CD_ANYTIME',
  },
  {
    name: 'Live',
    value: 'CD_LIVE',
  }
];

