import { call, put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as api from 'api/licenses';

import at from '../actions/types';
import { actions as notifications } from 'state/notifications';

export function* updateEmail(licenseId, email) {
  const state = yield select();
  const { authentication } = state;
  yield put({ type: at.UPDATE_EMAIL_REQUEST, licenseId });
  try {
    yield call(delay, 300);
    try {
      yield call(api.updateEmail, { licenseId, email, token: authentication.accessToken });
    } catch (e) {
      console.log(e);
      yield put({ type: at.UPDATE_EMAIL_FAILURE, licenseId, message: e.message || 'Something went wrong', meta: {} });
      const n = { type: 'UPDATE_USER_EMAIL', text: 'Failed: There was an error updating email' };
      yield put(notifications.addAndRemove(n, 5000));
      return;
    }

    yield put({ type: at.UPDATE_EMAIL_SUCCESS });
    //toaster
    const n = { type: 'UPDATE_USER_EMAIL', text: 'Success: Email updated' };
    yield put(notifications.addAndRemove(n, 5000));
  } catch (error) {
    console.log('error', error);
  }
}

export function* watchUpdateEmail({ licenseId, email }) {
  yield call(updateEmail, licenseId, email);
}
