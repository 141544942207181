import { takeLatest, put, select } from 'redux-saga/effects';
// import { LOCATION_CHANGE } from 'react-router-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import at from '../actions/types';
import * as actions from '../actions';

// import { watchFetchLicenses } from './fetchLicenses';
// import { watchFetchAccountType } from './fetchAccountType';
// import { watchFetchLicense } from './fetchLicense';
// import { watchFetchEmail } from './fetchEmail';
import { watchFetchLicensesCount } from './fetchLicenses';
import { watchUpdateEmail } from './updateEmail';
import { watchResendEmail } from './resendEmail';
import { getFiltersFromQuery, removePaginationFromFilters } from '../common';

// This method verify some condition to know if with should fetch the licenses
// this allows to make the fetch only when is needed to avoid waste resources
export const licensesPageMemory = (() => {
  let previousFilters = {};
  let previousSelectedBoard;
  return function* licensesPage({ type, payload }) {
    if (isEmpty(payload.query)) {
      return;
    }

    const state = yield select();
    const { selectedBoard } = state;
    const filters = getFiltersFromQuery(payload.query);

    // Don't do anything if the  filters and the selected board haven't changed
    if (isEqual(filters, previousFilters) && selectedBoard.id === previousSelectedBoard) {
      return;
    }

    // clear the licenses state if the filters or the selected board have changed
    if (
      !isEqual(removePaginationFromFilters(filters), removePaginationFromFilters(previousFilters)) ||
      selectedBoard.id !== previousSelectedBoard
    ) {
      yield put(actions.clear());
      yield put(actions.clearEntities());
    }

    previousSelectedBoard = selectedBoard.id;
    previousFilters = filters;
    yield put(actions.fetchLicenses(filters));
  };
})();

// function* licensesDetailPage ({ pathname }) {
//   const split = pathname.split('/');
//   const licensesId = split[split.length - 1]
//   yield put(actions.fetchLicense(licensesId));
// };

// export function* watchLocationChange ({type, payload}) {
//   // We call the apropiate function depending on what page we are
//   if (/licenses$/.test(payload.pathname) && !isEmpty(payload.query)) {
//     yield call(licensesPageMemory, { type, payload });
//     return;
//   }

//   if (/(licenses\/\d+(\?)|licenses\/\d+$)/.test(payload.pathname)) {
//     console.log("BOOM", payload.pathname)
//     yield call(licensesDetailPage, { pathname: payload.pathname });
//     return;
//   }
// };

// export function* watchSelectedBoardChange({ payload }) {
//   const state = yield select();
//   const  { query, pathname } = state.routing.locationBeforeTransitions;
//   yield put(actions.clear());
//   yield put(actions.clearEntities());

//   if (/licenses$/.test(pathname)) {
//     const filters = getFiltersFromQuery(query);
//     yield put(actions.fetchLicenses(filters));
//     return;
//   }

//   // If the url is /licenses/whatever
//   if (/licenses\/*/.test(pathname)) {
//     yield call(licensesDetailPage, { pathname: pathname });
//     return;
//   }
// }

export default function* root() {
  // yield takeLatest(LOCATION_CHANGE, watchLocationChange);
  // yield takeLatest('SELECT_BOARD', watchSelectedBoardChange);

  // yield takeLatest(at.FETCH, watchFetchLicenses);
  // yield takeLatest(at.FETCH_ONE, watchFetchLicense);

  // yield takeLatest(at.FETCH_ACCOUNT_TYPE, watchFetchAccountType);
  // yield takeLatest(at.FETCH_EMAIL, watchFetchEmail);
  yield takeLatest(at.FETCH_COUNT, watchFetchLicensesCount);
  yield takeLatest(at.UPDATE_EMAIL, watchUpdateEmail);
  yield takeLatest(at.RESEND_EMAIL, watchResendEmail);
}
