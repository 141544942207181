import at from './types';

export const fetchUsers = (filters, meta) => ({ type: at.FETCH, filters, meta });

export const createUser = (data, meta) => ({ type: at.CREATE, data, meta });
export const updateUser = (userId, data, meta) => ({ type: at.UPDATE, userId, data, meta });
export const deleteUser = (userId, meta) => ({ type: at.DELETE, userId, meta });

export const clear = () => ({ type: at.CLEAR });
export const clearEntities = () => ({ type: at.CLEAR_ENTITIES });

export const addUserToBoard = (userId, data, meta) => ({ type: at.ADD_USER_TO_BOARD, userId, data, meta });

export const resendInvitation = (user, meta) => ({ type: at.RESEND, user, meta });
