import axios from 'axios';
import * as at from './types';
import { API_URL } from '../config';

export const fetchAccount = (token) => {
  return function (dispatch) {
    dispatch({ type: at.FETCH_ACCOUNT_REQUEST });
    return axios
      .get(`${API_URL}/enable_account/${token}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: at.FETCH_ACCOUNT_SUCCESS, data: response.data });
        }
      })
      .catch((err) => {
        dispatch({ type: at.FETCH_ACCOUNT_FAILURE, err });
      });
  };
};

export const createPassword = (payload) => {
  const { userId, password, auditId } = payload;
  const data = { password, auditId };
  return function (dispatch) {
    dispatch({ type: at.CREATE_PASSWORD_REQUEST });
    const request = axios.request({
      method: 'put',
      url: `${API_URL}/enable_account/${userId}`,
      data: data,
    });
    request.then((response) => {
      if (response.status === 200) {
        dispatch({ type: at.CREATE_PASSWORD_SUCCESS, userId, message: response.data });
      }
    });
    request.catch((err) => {
      dispatch({ type: at.CREATE_PASSWORD_FAILURE, error: err.response.data.message });
    });
    return request;
  };
};
